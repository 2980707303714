import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Input from '../../components/input/Input';

import { CheckEmail, inspectionPassword } from '../../common/CheckedValidation';
import WithSetUserManage from '../../common/WithSetUserManage';
import Modal from '../../containers/baseModal/BaseModal';
import { getItem, removeItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';
import { getCookie, setCookie } from '../../common/Utils';
import { UploadImage } from '../../common/S3';

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      checkOverlapEmail: false,
      isModal: false,
      bodyText: '',
    };

    this.onNext = this.closeModal;
  }

  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    window.document.body.addEventListener('keyup', this.onkeyup);
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('keyup', this.onkeyup);
  }

  onkeyup = (e) => {
    if (e.keyCode === 13) return this.signUpFetch();

    return null;
  };
  successfullyLogin = async (userData) => {
    setCookie('userData', JSON.stringify(userData));
    // 세션에 담긴 카트 아이템을 할당
    const cartData = getItem('cart') === null ? [] : getItem('cart');

    // 카트 데이터 만큼 해당 유저 카트 테이블에 포스트
    const promises = cartData.map(async (item) => {
      // console.log(item);
      try {
        const itemBody = JSON.parse(item.body);
        // 커스텀 제품 일때
        if (item.b_custom === 1) {
          // s3 upload
          const imagePromises = itemBody.images.map(async (obj) => {
            const result = await UploadImage(obj);
            return result;
          });
          const images = await Promise.all(imagePromises);
          console.log(images);

          // item에는 pid_user를 제외한 나머지 필요한 컬럼 값들이 포함
          const api = getItem('restAPI').model_desc.cart_insert;
          const body = {
            cart: {
              pid_user: userData.user.pid_user,
              ...item,
              body: JSON.stringify({
                ...itemBody,
                images,
              }),
              uri_pd: images[0].Location,
            },
          };
          console.log(JSON.parse(body.cart.body));
          await _Fetch(api, '', body);
        } else {
          // 기성품일때
          const api = getItem('restAPI').model_desc.cart_insert;
          const body = {
            cart: {
              pid_user: userData.user.pid_user,
              ...item,
            },
          };
          await _Fetch(api, '', body);
        }
      } catch (e) {
        console.log(e);
      }
    });
    // 반복 비동기 작업마다 리턴 되는 값을 배열로 지님 타입은 배열
    const result = await Promise.all(promises);
    console.log(result);

    const callCount = async () => {
      const url = getItem('restAPI').model_desc.cart_count_my;
      const query = `?pid_user=${userData.user.pid_user}`;
      const res = await _Fetch(url, query, []);
      console.log(res);
      const num = Object.values(res[0])[0];
      return num;
    };

    const count = await callCount();
    console.log(count);

    const { actions } = this.props;
    actions.setCount(count);

    removeItem('cart');
    this.setState({ isLoaded: false });
    return this.props.history.push('/');
  };
  async signUpFetch() {
    const { userManage } = this.props;
    try {
      if (!this.state.email) {
        return this.openModal('Please enter your email address');
      }

      if (!CheckEmail(this.state.email)) {
        return this.openModal('Please enter a valid email address (Ex: johndoe@domain.com).');
      }

      const checkEmail = await this.props.userManage.checkId(this.state.email);

      if (!this.state.password || !this.state.confirmPassword) {
        return this.openModal('Please enter your password');
      }

      if (
        !inspectionPassword(this.state.confirmPassword, this.state.password)
      ) {
        return this.openModal('This field must be the same as Password');
      }
      if (checkEmail) {
        const data = {
          user: {
            id: this.state.email,
            user_name: 'none',
            user_type: 0,
          },
          user_auth: {
            typ_login: 1,
            pw: this.state.password,
          },
        };
        const isSignUp = await this.props.userManage.signUp(data);
        console.log(isSignUp);
        if (isSignUp.status === 1504) {
          return this.openModal('Password is too short.');
        }
        if (isSignUp.status === 1506) {
          return this.openModal('There is already an account with this email address.');
        }
        if (isSignUp > 0) {
          const userData = await userManage.login(this.state.email, this.state.password);
          this.successfullyLogin(userData);
          this.onNext = () => this.props.history.push('/');
          return this.openModal('You have successfully signed up.');
          // return this.props.history.push('/');
        }
        return this.openModal(isSignUp.results);
      }
      return null;
    } catch (e) {
      return this.openModal('The network is in an insecure state. Please try again.');
    }
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: '',
    });
  };

  render() {
    const { history, userManage } = this.props;
    const { isModal, bodyText } = this.state;

    if (!userManage) return <div style={styles.containerEmpty} />;

    return (
      <div style={styles.container}>
        <Modal
          isModal={isModal}
          bodyText={bodyText}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        <div style={styles.inner}>
          <p style={styles.title}>Sign Up</p>

          <div style={styles.overlapNoticeBox(this.state.checkOverlapEmail)}>
            This email address is already associated with an account. If this
            account is yours, you can &nbsp;
            <u
              onClick={() => history.push('/reset/password')}
              role="button"
              tabIndex="0"
              style={styles.resetPwText}
            >
              reset your password
            </u>
          </div>

          <Input
            onChange={email => this.setState({ email })}
            style={styles.input(
              20,
              this.state.email.length > 0 && !CheckEmail(this.state.email),
            )}
            placeholder="Email address"
            placeholderColor="#868686"
            type="text"
          />
          <p
            style={styles.vaildNotice(this.state.email.length > 0 && !CheckEmail(this.state.email))}
          >
            Enter a valid email address
          </p>

          <Input
            onChange={password => this.setState({ password })}
            style={styles.input()}
            placeholder="Password"
            placeholderColor="#868686"
            type="password"
          />

          <Input
            onChange={confirmPassword => this.setState({ confirmPassword })}
            style={styles.input(
              15,
              this.state.confirmPassword.length > 0 &&
                this.state.password.length > 0 &&
                !inspectionPassword(
                  this.state.confirmPassword,
                  this.state.password,
                ),
            )}
            placeholder="Confirm Password"
            placeholderColor="#868686"
            type="password"
          />
          <p
            style={styles.vaildNotice(this.state.confirmPassword.length > 0 &&
                this.state.password.length > 0 &&
                !inspectionPassword(
                  this.state.confirmPassword,
                  this.state.password,
                ))}
          >
            This field must be the same as Password
          </p>

          <div
            role="button"
            tabIndex={-1}
            onClick={() => this.signUpFetch()}
            style={styles.accountBtn}
          >
            CREATE
          </div>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  history: PropTypes.object,
  userManage: PropTypes.object,
};

SignUp.defaultProps = {
  history: {},
  userManage: {},
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerEmpty: {
    width: '100%',
    height: 727,
  },
  inner: {
    marginTop: 50,
    marginBottom: 124,
    width: 700,
    height: 600,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    color: '#000',
    fontSize: 24,
    marginTop: 53,
  },
  input: (marginTop = 15, isVaild = false) => ({
    width: 280 - 30,
    height: 42,
    border: isVaild ? '1px solid #e85454' : '1px solid #ddd',
    outline: 'none',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#251f1b',
    marginTop,
  }),
  accountBtn: {
    cursor: 'pointer',
    width: 280,
    height: 42,
    backgroundColor: '#000',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',

    margin: 'auto',
    marginTop: 50,
  },
  vaildNotice: isVaild => ({
    width: 280,
    display: isVaild ? 'block' : 'none',
    marginTop: 4,
    fontFamily: 'Roboto Condensed',
    fontSize: 10,
    color: '#e85454',
    textAlign: 'left',
  }),
  overlapNoticeBox: isVisble => ({
    display: isVisble ? 'block' : 'none',
    width: 280,
    height: 43,
    marginTop: 10,
    color: '#d0021b',
    fontFamily: 'Roboto Condensed',
    fontSize: 11,
  }),
  resetPwText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 11,
    color: '#000',
    cursor: 'pointer',
    outline: 'none',
  },
};

export default withRouter(WithSetUserManage()(SignUp));
