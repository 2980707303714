import React from 'react';

export default function GloveAttribute({ name, value, image }) {
  return (
    <div className="overview-item">
      <h4>{name}</h4>
      {value &&
        <div className="value">{value}</div>
      }
      {image &&
        <div className="value">
          <img
            src={image}
            alt=""
          />
        </div>
      }
    </div>
  );
}
