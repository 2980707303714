import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Binding from './trim/Binding.js';
import Welting from './trim/Welting.js';
import Lacing from './trim/Lacing.js';
import Thread from './trim/Thread.js';
import { withTranslation } from 'react-i18next';

const Components = {
  Binding,
  Welting,
  Lacing,
  Stitching: Thread,
};

const Items = {
  'Single Welting': [
    'Binding',
    'Welting',
    'Lacing',
    'Stitching',
  ],
  'Double Welting': [
    'Binding',
    'Welting',
    'Lacing',
    'Stitching',
  ],
  'First Baseman Mitt': [
    'Binding',
    'Lacing',
    'Stitching',
  ],
  'Catchers Mitt': [
    'Binding',
    'Lacing',
    'Stitching',
  ],
  Softball: [
    'Binding',
    'Welting',
    'Lacing',
    'Stitching',
  ],
};

class TrimPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Binding',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activeItem: 'Binding' });
  }

  onSelectItem(item) {
    this.setState({ activeItem: item });
  }

  render() {
    const { isActive, gloveType ,t } = this.props;
    const { activeItem } = this.state;
    const ActiveComponent = Components[activeItem];

    if (!isActive) { return null; }

    return (
      <div className="glove-panel-step clearfix">
        <div className="step-item-tab-view-wrap pull-left">
          {Items[gloveType].map((item) => {
            if (typeof item === 'object') {
              return (<div
                key={item.key}
                className={classNames('glove-step-item-tab', { active: activeItem === item.key })}
                onClick={() => this.onSelectItem(item.key)}
              >
                {item.name}
              </div>);
            }
              return (<div
                key={item}
                className={classNames('glove-step-item-tab', { active: activeItem === item })}
                onClick={() => this.onSelectItem(item)}
              >
                {t(item.toLowerCase().replace(/(\s*)/g, ""))}
              </div>);
          })}
        </div>
        <ActiveComponent />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gloveType: state.glove.gloveType,
});

export default connect(mapStateToProps)(withTranslation()(TrimPanel));

