import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chkbox: false,
    };
  }

  handleChangeChk = () => {
    const { getChecked } = this.props;
    this.setState(
      prevState => ({
        chkbox: !prevState.chkbox,
      }),
      () => {
        const { chkbox } = this.state;
        getChecked(chkbox);
      },
    );
  };

  render() {
    const { chkbox } = this.state;

    return (
      <input
        type="checkbox"
        defaultChecked={chkbox}
        onChange={this.handleChangeChk}
      />
    );
  }
}

CheckInput.propTypes = {
  getChecked: PropTypes.func,
};

CheckInput.defaultProps = {
  getChecked: () => {},
};

export default CheckInput;
