import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './OurStory.scss';
import Step from '../../components/step/Step';

const STEP_MENU = ['Our Story'];

const stepSecondBottomImg = [
  '/img/material-3.jpg',
  '/img/material-1.jpg',
  '/img/material-2.jpg',
];

const playerList = [
  'Henry Sosa',
  'Eva Voortman',
  'Ellen Roberts',
  'Matt Magill',
  'Ryan Sherriff',
  'Matt Pobereyko',
  'Jacob Dorris',
  'Ginger de Weert',
  'Peter Tago',
];

const linkList = [
  'https://www.instagram.com/henry_sosa2828/',
  'https://instagram.com/eva.voortman',
  'https://instagram.com/ellroberts9',
  'https://instagram.com/magillmlb',
  'https://instagram.com/kingsherriff',
  'https://instagram.com/mpobereyko42',
  'https://instagram.com/jacobdorris',
  'https://instagram.com/gingerdeweert',
  'https://instagram.com/petey_pistola92',
];

const Menu = [
  {
    title: 'INTRODUCING',
    defaultImg: '/img/introducing_d.png',
    enableImg: '/img/introducing.png',
  },
  {
    title: 'MATERIAL',
    defaultImg: '/img/15-2.png',
    enableImg: '/img/15-2-x.png',
  },
  {
    title: 'DESIGN',
    defaultImg: '/img/16-2.png',
    enableImg: '/img/16-2-x.png',
  },
  {
    title: `
SOCIAL
RESPONSIBILITY
    `,
    defaultImg: '/img/17-2.png',
    enableImg: '/img/17-2-x.png',
  },
  {
    title: `
TEAM
GLOVEWORKS
    `,
    defaultImg: '/img/18-2.png',
    enableImg: '/img/18-2-x.png',
  },
];

const Title = t => [
  t('ourStoryIntroducingTitle'),
  t('ourStoryMaterialTitle'),
  t('ourStoryDesignTitle'),
  t('ourStorySocalResponsibiltyTitle'),
  t('ourStoryTeamGloveworksTitle'),
];

const description = t => [
  t('ourStoryIntroducingDesc'),
  '',
  '',
  '',
  t('ourStoryTeamGloveworksDesc1'),
];

const stepFive = t => t('ourStoryTeamGloveworksDesc2');

const mainImg = [
  '/img/introducing.jpg',
  '/img/material.jpg',
  '/img/design@2x.png',
  '',
  '/img/team-gloveworks.jpg',
];

class OurStory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };
  }

  renderMainImage = (step) => {
    if (step === 1) {
      return (
        <img className="img" style={{ width: 851 }} src={mainImg[0]} alt="" />
      );
    }

    if (step === 2) {
      return (
        <img className="img" style={{ width: 851 }} src={mainImg[1]} alt="" />
      );
    }

    if (step === 3) {
      return (
        <img className="img" style={{ width: 851 }} src={mainImg[2]} alt="" />
      );
    }

    if (step === 4) {
      return (
        <img
          style={{
            width: '851px',
            margin: 'auto',
            display: 'block',
            marginTop: 40,
          }}
          src="/img/social-responsibility.png"
          alt=""
        />
      );
    }

    if (step === 5) {
      return (
        <img className="img" style={{ width: 851 }} src={mainImg[4]} alt="" />
      );
    }

    // return <img className="img" src={mainImg[step - 1]} alt="" />;
    return null;
  };

  processWidth = (step) => {
    switch (step) {
      default:
        return 851;
    }
  };

  renderSecondBottomContents = (step) => {
    const { t } = this.props;

    if (step !== 2) return null;
    const arr = stepSecondBottomImg.map(item => (
      <img src={item} key={item} alt="" />
    ));

    const str = t('ourStoryMaterialBttomDesc');

    return (
      <div>
        <div className="secBottom">{arr}</div>
        <p className="bottomText" style={{ fontSize: '14px' }}>
          {str}
        </p>
      </div>
    );
  };

  renderPlayerList = () =>
    playerList.map((item, i) => (
      <a key={item} href={linkList[i]} className="linkPlayer">
        {item}
      </a>
    ));

  render() {
    const { t } = this.props;
    return (
      <div>
        <Step menu={STEP_MENU} />

        <div className="menuBox" style={{ marginTop: 20 }}>
          {Menu.map((item, i) => (
            <div
              tabIndex="-1"
              role="button"
              onClick={() => this.setState({ step: i + 1 })}
              key={item.title}
              style={styles.menuItem}
            >
              <img
                alt=""
                style={{ marginTop: i === 0 ? 14 : 0 }}
                src={
                  this.state.step === i + 1 ? item.enableImg : item.defaultImg
                }
              />
              <div style={styles.menuTitle(this.state.step === i + 1)}>
                {item.title}
              </div>
            </div>
          ))}
        </div>

        <p className="title" style={{ fontSize: 28 }}>
          {Title(t)[this.state.step - 1]}
        </p>
        {this.renderMainImage(this.state.step)}
        {this.state.step === 2 && (
          <p
            className="content"
            style={{
              whiteSpace: 'pre-line',
              width: this.processWidth(this.state.step),
              marginBottom: 0,
            }}
          >
            {t('ourStoryMaterialDesc')}

            <b style={{ fontWeight: 'bold' }}>
              {t('ourStoryMaterialSubTitle')}
            </b>
            {t('ourStoryMaterialSubDesc')}
            <b style={{ fontWeight: 'bold' }}>
              {t('ourStoryMaterialScondSubTitle')}
            </b>
            {t('ourStoryMaterialSubDesc2')}
          </p>
        )}

        {this.state.step === 3 && (
          <p
            className="content"
            style={{
              whiteSpace: 'pre-line',
              width: this.processWidth(this.state.step),
              marginBottom: 0,
            }}
          >
            {t('ourStoryDesignDesc')}
            <b style={{ fontWeight: 'bold' }}>{t('ourStoryDesignSubTitle1')}</b>
            {t('ourStoryDesignSubDesc1')}
            <b style={{ fontWeight: 'bold' }}>{t('ourStoryDesignSubTitle2')}</b>
            {t('ourStoryDesignSubDesc2')}

            <b style={{ fontWeight: 'bold' }}>{t('ourStoryDesignSubTitle3')}</b>
            {t('ourStoryDesignSubDesc3')}
          </p>
        )}

        {this.state.step === 4 && (
          <p
            className="content"
            style={{
              whiteSpace: 'pre-line',
              width: this.processWidth(this.state.step),
              marginBottom: 0,
            }}
          >
            {t('ourStorySocalResponsibiltyDesc')}

            <b
              tabIndex="-1"
              role="button"
              onClick={() => window.open('http://www.morethanagameus.com/')}
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {t('ourStorySocalResponsibiltySubTitle1')}
            </b>
            {t('ourStorySocalResponsibiltySubDesc1')}
            <br />
            <br />
            <b
              tabIndex="-1"
              role="button"
              onClick={() => window.open('https://www.kfbaseballforacure.com/')}
              style={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {t('ourStorySocalResponsibiltySubTitle2')}
            </b>
            {t('ourStorySocalResponsibiltySubDesc2')}
          </p>
        )}

        <p
          className="content"
          style={{
            display:
              this.state.step === 2 ||
              this.state.step === 3 ||
              this.state.step === 4
                ? 'none'
                : 'block',
            width: this.processWidth(this.state.step),
            marginBottom: this.state.step === 5 ? 0 : 233,
          }}
        >
          {description(t)[this.state.step - 1]}
        </p>
        {this.state.step === 5 ? (
          <div>
            <div className="linkBox">{this.renderPlayerList()}</div>
            <p
              className="content"
              style={{
                width: this.processWidth(this.state.step),
                marginTop: 0,
                whiteSpace: 'pre-line',
              }}
            >
              {stepFive(t)}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/re2pect')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>BK Lee: </b>
              {t('ourStoryTeamGloveworksDesc3')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/davisrelacing')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Davis Relacing: </b>
              {t('ourStoryTeamGloveworksDesc4')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/balltown_japan')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Ball Town: </b>
              {t('ourStoryTeamGloveworksDesc5')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/bijdevleet')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Bijdevleet: </b>
              {t('ourStoryTeamGloveworksDesc6')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/glovecowboy')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Eric Walbridge: </b>
              {t('ourStoryTeamGloveworksDesc7')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/atomsglove')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Atoms: </b>
              {t('ourStoryTeamGloveworksDesc8')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/whatproswear')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>What Pros Wear: </b>
              {t('ourStoryTeamGloveworksDesc9')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/bobbycrosby')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Dodgerfilms: </b>
              {t('ourStoryTeamGloveworksDesc10')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/baseballcentral')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Baseball Central LA: </b>
              {t('ourStoryTeamGloveworksDesc11')}
              <br />
              <br />
              <b onClick={() => window.open('https://www.instagram.com/daisukeikenaga')} role="button" tabIndex="-1" style={{ fontWeight: 'bold', cursor: 'pointer' }}>Daisuke Ikenaga: </b>
              {t('ourStoryTeamGloveworksDesc12')}
              <br />
              <br />
            </p>
          </div>
        ) : null}
        {this.state.step === 3 && (
          <img
            style={{
              margin: 'auto',
              display: 'block',
              marginTop: 40,
              marginBottom: 174,
              width: 851,
            }}
            src="/img/design@2x.jpg"
            alt=""
          />
        )}
        {this.renderSecondBottomContents(this.state.step)}
      </div>
    );
  }
}

const styles = {
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 67 + 13 + 50,
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  menuTitle: isChoose => ({
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    color: '#152935',
    fontSize: 16,
    fontWeight: isChoose ? 'bold' : 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.5,
    opacity: isChoose ? 1 : 0.4,
  }),
};

OurStory.propTypes = {
  t: PropTypes.func,
  // i18n: PropTypes.object,
};

OurStory.defaultProps = {
  t: () => {},
  // i18n: {},
};

export default withTranslation('translations')(OurStory);
