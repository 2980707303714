import React from 'react';
import { connect } from 'react-redux';

import { closeAlert } from '../../actions/creators';

const LeaveBasePanelAlert = ({ dispatch }) => (
  <div className="custom-glove-popup">
    <div className="base-alert-title">Alert Message</div>
    <div className="base-alert-content">
        You should select your glove type, leather grade, throwing hand, and size.
    </div>
    <button className="close" onClick={() => dispatch(closeAlert())} />
    <div className="popup-button ok" onClick={() => dispatch(closeAlert())}>OK</div>
  </div>
);

export default connect()(LeaveBasePanelAlert);
