import React from 'react';
import classNames from 'classnames';

import SingleWeltingRenderer from '../renderers/SingleWeltingRenderer';
import DoubleWeltingRenderer from '../renderers/DoubleWeltingRenderer';
import FirstBasemanMittRenderer from '../renderers/FirstBasemanMittRenderer';
import CatchersMittRenderer from '../renderers/CatchersMittRenderer';
import SoftballRenderer from '../renderers/SoftballRenderer';
import PropTypes from 'prop-types';

export default class Canvas extends React.Component {
  static propTypes = {
    side: PropTypes.string.isRequired,
    glove: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    imageSources: PropTypes.object.isRequired,
    onDrawStart: PropTypes.func.isRequired,
    onDrawEnd: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.renderers = {};
    this.canvas = {};
  }

  componentDidMount() {
    // this.canvas = this.refs.canvas;

    const { active } = this.props;
    if (active) {
      this.drawCanvas().catch(error => {
        console.log(error);
        // alert(error);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // 요 사이드에 그려지는 부위가 변하였는가?
    // 글러브 타입이 변하였는가?
    const needRedraw = this.props.active;

    if (needRedraw) {
      this.drawCanvas().catch(error => console.log(error));
    }
  }

  drawCanvas() {
    const { glove, onDrawStart, onDrawEnd } = this.props;
    return new Promise((resolve, reject) => {
      onDrawStart();
      this.getRenderer().render(glove).then(() => {
        onDrawEnd();
        resolve();
      }).catch((error) => {
        onDrawEnd();
        reject(error);
      });
    });
  }

  getRenderer() {
    // let { side } = this.props;
    const { gloveType } = this.props.glove;

    if (this.renderers[gloveType] == null) { this.renderers[gloveType] = this.initRenderer(); }
    // console.log(this.renderers[gloveType]);
    return this.renderers[gloveType];
  }

  initRenderer() {
    // console.log('initRenderer');
    const { side, imageSources } = this.props;
    const { gloveType } = this.props.glove;

    // console.log(this.canvas);

    switch (gloveType) {
      case 'Single Welting':
        return new SingleWeltingRenderer({ side, canvas: this.canvas, imageSources });
      case 'Double Welting':
        return new DoubleWeltingRenderer({ side, canvas: this.canvas, imageSources });
      case 'First Baseman Mitt':
        return new FirstBasemanMittRenderer({ side, canvas: this.canvas, imageSources });
      case 'Catchers Mitt':
        return new CatchersMittRenderer({ side, canvas: this.canvas, imageSources });
      case 'Softball':
        return new SoftballRenderer({ side, canvas: this.canvas, imageSources });
      default:
    }
  }

  getImage() {
    return new Promise((resolve, reject) => {
      this.drawCanvas().then(() => {
        resolve(this.canvas.toDataURL('image/jpeg'));
      }).catch((error) => {
        reject(error);
      });
    });
  }

  render() {
    const { active } = this.props;

    return (<canvas
      width="538"
      height="538"
      ref={ref => this.canvas = ref}
      className={classNames('canvas-view', { visible: active })}
    />);
  }
}
