import {uid} from 'uid';
import AWS from 'aws-sdk';
import _Fetch from './Fetches';
import { SERVER_URL } from './Settings';

const Bucket = 'glove-works';
// const Bucket = 'gloveworks-us';

const callUpload = async () => {
  const res = _Fetch({
    url: `${SERVER_URL}/gloveworks/restapi/get_param`,
    method: 'GET',
  }, '', []);

  const awsConfig = await res;
  AWS.config.update(awsConfig);
  return 'S3';
};

callUpload();

export const UploadImage = async (file, path = 'customize') => {
  const S3 = new AWS.S3();

  const buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
  const type = file.split(';')[0].split('/')[1];

  let imageExtension = file.split(';')[0].split('/');
  imageExtension = imageExtension[imageExtension.length - 1];

  const fileName = `${uid()}.${imageExtension}`;

  const param = {
    Bucket: `${Bucket}/${path}`,
    Key: fileName,
    ACL: 'public-read',
    Body: buf,
    ContentType: `image/${type}`,
    ContentEncoding: 'base64',
  };

  console.log(param);

  return new Promise((resolve, reject) => {
    S3.upload(param, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const DeleteData = (key) => {
  const S3 = new AWS.S3();
  if (key) {
    const params = {
      Bucket: `${Bucket}`,
      Key: key,
    };
    return new Promise((resolve, reject) => {
      S3.deleteObject(params, (err, data) => {
        if (err) {
          console.error(err);
          resolve(err);
        } else {
          resolve(data.key);
        }
      });
    });
  }
  return false;
};
