export default class ImageProcessLayer {
  constructor(props) {
    this.shadowImage = props.shadowImage;
    this.canvas = null;
    this.image = props.image;
    this.colorHex =  null;
    this.layerName = null;
    this.resultImage = null;
    this.blendingFunc = props.blendingFunc;
  }

  getImage = (colorHex) => {
    return new Promise((resolve, reject) => {
      if (this.colorHex !== colorHex) {
        this.processImage(colorHex).then(() => {
          this.colorHex = colorHex;
          resolve(this.resultImage);
        }).catch((error) => {
          reject(error)
        });
      } else {
        resolve(this.resultImage);
      }
    });
  }

  getImageOverwrite = (layerName, colorHex) => {
    return new Promise((resolve, reject) => {
      if (this.layerName !== layerName || this.colorHex !== colorHex) {
        this.processImage(colorHex).then(() => {
          this.colorHex = colorHex;
          resolve(this.resultImage);
        }).catch((error) => {
          reject(error)
        });
      } else {
        resolve(this.resultImage);
      }
    });
  }

  processImage = (colorHex) => {
    return new Promise((resolve, reject) => {
      this.processColoredShadow(colorHex)
        .then((coloredShadowImage) => {
          let ctx = this.getContext();
          ctx.globalCompositeOperation = 'source-over';
          ctx.clearRect(0, 0, 538, 538);
          ctx.drawImage(this.image, 0, 0, 538, 538);

          ctx.globalCompositeOperation = 'source-in';
          ctx.drawImage(coloredShadowImage, 0, 0, 538, 538);

          this.resultImage = new Image;
          this.resultImage.src = this.canvas.toDataURL("image/png");
          this.resultImage.onload = () => {
            resolve(this.resultImage);
          }
          this.resultImage.onerror = () => {
            reject('Layer resultImage load error');
          }
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  processColoredShadow = (colorHex) => {
    return new Promise((resolve, reject) => {
      let ctx = this.getContext();

      ctx.globalCompositeOperation = 'source-over';
      ctx.fillStyle = colorHex;

      ctx.clearRect(0, 0, 538, 538);

      // console.log(this.shadowImage.src);

      ctx.drawImage(this.shadowImage, 0, 0, 538, 538);
      let shadowImageData = ctx.getImageData(0,0,538,538);

      ctx.fillRect(0, 0, 538, 538);
      let fillColorImageData = ctx.getImageData(0,0,538,538);

      let coloredShadowImageData = this.blendingFunc(fillColorImageData, shadowImageData);
    
      ctx.putImageData(coloredShadowImageData, 0, 0);

      let coloredShadowImage = new Image;
      coloredShadowImage.src = this.canvas.toDataURL("image/png");
      coloredShadowImage.onload = () => {
          resolve(coloredShadowImage);
      };
      coloredShadowImage.onerror = () => {
          reject('Colored shadow image load error');
      };
    });
  }

  getContext = () => {
    if (this.canvas == null) {
      this.canvas = document.createElement('canvas');
      this.canvas.width = 538;
      this.canvas.height = 538;
    }
    // console.log(this.canvas);
    return this.canvas.getContext('2d');
  }
};
