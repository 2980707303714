import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

const WristPatchButtons = ({
  title,
  className,
  patches,
  currentPatch,
  onClick,
}) => (
  <div className={className}>
    <h4>{title}</h4>
    <div className="patches-wrap clearfix">
      {patches.map(patch =>
        (<div
          key={patch.id}
          className={classNames('patch-button', { active: currentPatch && currentPatch.id === patch.id })}
          onClick={() => onClick(patch)}
        >
          <img
            src={patch.image.url}
            alt=""
          />
          <div className="name">{patch.name}</div>
        </div>))}
    </div>
  </div>
);

const mapStateToProps = state => ({
  patches: state.builder.wristPatches,
});

export default connect(mapStateToProps)(WristPatchButtons);
