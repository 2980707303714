/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import PropTypes from "prop-types";

class JaFaq extends Component {
  render() {
    const { history } = this.props;

    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>よくある質問と答え</p>

          <p style={styles.subTitle(64)}>[1] グラブ</p>

          <p style={styles.question(10)}>
            1.
            グラブビルダーにあるオプション以外に他の機能をカスタムグラブに追加できますか？
          </p>
          <p style={styles.descrpiotn(10)}>
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.dropbox.com/s/ypnn3wif1btdnrq/OPTION%20LIST.xlsx?dl=0"
              target="_blank"
            >
              ここ
            </a>
            をクリックするとお客様に最適なグラブを作るためにグラブワークスが提案する特別オプションについてご確認いただけます。オプションを追加される場合はお支払いの際に「スペシャルリクエスト」の欄に記載していただければ確認後、追加料金のお支払いのためのインボイスを発行致します。カスタムグラブの製作過程の特性上、ご注文確定後にはオプションの追加ができないためあらかじめご了承ください。
          </p>

          <p style={styles.question(24)}>
            2. グラブ製品を受け取るまではどれくらいかかりますか？
          </p>
          <p style={styles.descrpiotn(10)}>
            ストックグラブまたはグラブアクセサリーの場合、ご注文から1－2営業日以内に発送致します。平均配送期間は3－5営業日です。
            <br />
            <br />
            {/* OUTLIER - 240201 - 요청사항 2 */}
            {/* カスタムグラブの場合、ご注文から4－6週ほどお時間をいただきます。グラブワークスのカスタムグラブは最初から最後まで手作業で行われるハンドメイド製品のため既製品のグラブよりも各製作段階での時間を多くいただきます。あらかじめご了承ください。熟練の職人が細かく精巧な技術で作り出すグラブは厳密な最終品質検査の段階を経た後発送されます。ゆっくりと急ぐグラブワークスを目指します。 */}
            カスタムグラブの場合、おおよその目安ですが、通常の場合ご注文（決済完了、詳細の確認完了）を頂いてから発送まで4週～16週程かかります。特殊オプションが多い場合、年末年始などの長期休業日を挟んでの製作の場合には出荷までの時間が延びる場合がございます。発送通知は、お申込みの際に登録されたお客様のメールアドレス宛までにご連絡致します。グラブワークスのカスタムグラブは最初から最後まで手作業で行われるハンドメイド製品のため既製品のグラブよりも各製作段階での時間を多くいただきます。あらかじめご了承ください。熟練の職人が細かく精巧な技術で作り出すグラブは厳密な最終品質検査の段階を経た後発送されます。ゆっくりと急ぐグラブワークスを目指します。
          </p>

          <p style={styles.question(24)}>
            3.
            注文後、希望していたロゴをグラブに使用できないという連絡を受けました。なぜでしょうか？
          </p>
          <p style={styles.descrpiotn(10)}>
            グラブワークスのグラブには他社や他人の著作権を侵害する恐れがあるもの、敵対的な政治メッセージ、ヴァンダリズム、ヘイトスピーチを含むロゴはご使用いただけません。またグラブに使用されたロゴに関する著作権の侵害の可否を問う義務、またそれによって発生する法的な問題に対する全ての責任はお客様にあるということをあらかじめご確認ください。
          </p>

          <p style={styles.subTitle(64)}>[2] 革</p>

          <p style={styles.question(10)}>
            1.
            グラブを受け取ったのですが、ウェブサイトで選んだ革の色と違います。なぜでしょうか？
          </p>
          <p style={styles.descrpiotn(10)}>
            お客様のモニターディスプレイの設定の違い、写真撮影の際の照明によってウェブサイト上のグラブビルダーが表示した色やグラブの写真が実際のグラブの色味とは多少異なる場合もあることをあらかじめご了承ください。
          </p>

          <p style={styles.question(24)}>
            2.
            白いグラブを注文したのですが、グレーのような色味の製品が届きました/白のレーシングをした白いグラブを注文したのですが、グラブ本体と紐の色が違います。
          </p>
          <p style={styles.descrpiotn(10)}>
            グラブワークスの白いグラブは天然皮革と染色方法の特性上、純白の‘スノーホワイト(Snow-white)’ではない白に近い‘オフホワイト(Off-white)’です。グラブワークスのグラブに使用される皮革は顔料ではなく染料で色を出します。水に溶けない顔料を革の上に塗る顔料染色とは違い染料染色は水に溶ける性質の染料を水に溶かし、革を浸けて加工するため革全体に色が付きます。そのためパソコンやスマートフォンの画面上の色彩よりも多少暗く感じられるかもしれませんが、自然な色味と革の質感を生かせる利点であること、また最高級の革でこそ可能な加工です。
          </p>

          <p style={styles.question(24)}>
            3.
            グラブの表面にストレッチマークかすり傷があります。これは何でしょうか？
          </p>
          <p style={styles.descrpiotn(10)}>
            グラブワークスの革は自然から来ています。つまりこの革は環境に適応するため絶えず変化してきたということです。革ごとに違う質感と色を持つためこの革が天然製品であることを意味しつつも、グラブの強度や機能面では全く問題はありません。
          </p>

          <p style={styles.question(24)}>
            4. 内皮(Lining)または手入れ部分のファー(Lining Fur)は何色ですか？
          </p>
          <p style={styles.descrpiotn(10)}>
            グラブの内皮は捕球面と同じカラーで作られます。手入れ部分のファーの色はゲームデ―グラブの場合は黒、プロステアハイドとプロキップグラブの場合は白です。またファーストバックグラブにはファーが付いていません。
          </p>

          <p style={styles.question(24)}>
            5. ゲームデ―とプロスティアハイド、プロキップの違いは何ですか？
          </p>
          <p style={styles.descrpiotn(10)}>
            グラブワークスのグラブに使用されている革はヘビーテキサスステアハイド(Heavy
            Texas Steer-hide)とヨーロピアンキップ(European Kip)です。
          </p>

          <p style={styles.subTitle(64)}>[3] カスタマイズ</p>

          <p style={styles.question(10)}>
            1. 注文確定後、必ずメールが届きますか？
          </p>
          <p style={styles.descrpiotn(10)}>
            ご注文確認のメールは送付致しません。ご注文状況は、
            <font
              style={{ ...styles.email, textDecoration: "underline" }}
              tabIndex="-1"
              role="button"
              onClick={() => history.push("./orderTracking")}
            >
              “order tracking(注文状況)”
            </font>
            メニューにメールアドレスと注文番号を入力するとご確認いただけます。
          </p>

          <p style={styles.question(24)}>
            2.
            注文確定後、変更したい部分があります。注文を修正またはキャンセルすることはできますか？
          </p>
          <p style={styles.descrpiotn(10)}>
            ウェブサイトでご注文受付後、注文履歴にてご注文内容に誤りがないかご確認下さい。48時間以内にキャンセルまたは変更が無い場合、承った内容にて製作を開始します。製作開始後の返金はできませんので、ご了承ください。
          </p>

          <p style={styles.subTitle(64)}>[4] 配送</p>

          <p style={styles.question(10)}>1. どのように配送されますか？</p>
          <p style={styles.descrpiotn(10)}>
            お荷物は全てDHL、FEDEX、SF
            EXPRESS経由で配送されます。グラブワークスの工場及び倉庫は中国、アメリカ、日本に展開しています。
          </p>

          <p style={styles.question(24)}>2. 私書箱宛ての配送はできますか？</p>
          <p style={styles.descrpiotn(10)}>
            できません。実際の住所をご入力ください。
          </p>

          <p style={styles.question(24)}>3. 関税を払う必要がありますか？</p>
          <p style={styles.descrpiotn(10)}>
            ご注文の商品を日本国外から国内へ配送する場合、輸入関税等諸税が課せられます。配送の受取人は輸入者(IOR)であり、輸入関税について全ての責任を負います。
            <br />
            <br />
            輸入関税は、お手元にお荷物が届いた際、配送業者にお支払いください。輸入関税等諸税の詳細及び領収証が必要な場合、配送確認メールにて配送業者にお問合せください。
          </p>

          <p style={styles.subTitle(64)}>[返品、交換及び返金]</p>

          <p style={styles.question(10)}>
            1.
            注文したグラブを受け取ったが、気に入りません。返品または交換できますか？
          </p>
          <p style={styles.descrpiotn(10)}>
            グラブワークスの製品はオーダーメイドです。弊社の過失による商品不良及び損傷がある場合のみ、返品・交換を承ります。お手数ですが、
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            までご連絡ください。
          </p>

          <p style={styles.question(24)}>2. 保証プログラムはありますか？</p>
          <p style={styles.descrpiotn(10)}>
            製品には6ヶ月の保証をお付けしております。ご購入後6ヶ月以内に品質上の問題が生じた場合、お手数ですが
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            までご連絡ください。私たちの最優先事項は、お客様の満足です。
          </p>

          <p style={styles.subTitle(64)}>[ETC]　その他</p>

          {/* OUTLIER - 20240122 - 5번 */}
          {/* <p style={styles.question(10)}>
            口座番号を失くしてしまいました! どうしましょう?
          </p>

          <p style={styles.descrpiotn(10)}>
            振込先：みずほ銀行芝支店 普通口座 4637525 グラブワークスジャパン株式会社
          </p> */}

          <p style={styles.question(10)}>
            その他ご質問やお問合せは、メールにて受け付けております。
          </p>
          <p style={styles.descrpiotn(10)}>
            - English and Korean Inquiry::{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:contact@gloveworks.net")
              }
            >
              contact@gloveworks.net
            </u>
            <br />- Japanese inquiry:{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                // OUTLIER - 20240123 - 추가 변경
                // (document.location.href = "mailto:gloveworks.jp@gloveworks.net")
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              {/* gloveworks.jp@gloveworks.net */}
              donald@gloveworks.net
            </u>
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: "auto",
    marginBottom: 64,
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  subTitle: (marginTop) => ({
    color: "#000",
    lineHeight: 1.33,
    fontWeight: "bold",
    fontSize: 18,
    marginTop,
  }),
  question: (marginTop) => ({
    marginTop,
    color: "#007dff",
    lineHeight: 1.5,
    fontWeight: "600",
    fontSize: 16,
  }),
  descrpiotn: (marginTop) => ({
    marginTop,
    color: "#142935",
    lineHeight: 1.57,
    fontSize: 14,
  }),
  email: {
    color: "#007dff",
    cursor: "pointer",
    outline: "none",
    fontWeight: "bold",
  },
};

JaFaq.propTypes = {
  history: PropTypes.object,
};

JaFaq.defaultProps = {
  history: {},
};

export default JaFaq;
