import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { selectThrowingHand } from '../../actions/creators';
import { withTranslation } from 'react-i18next';

let ThrowingHandButton = ({ active, throwingHand, dispatch }) => (
  <div
    className={classNames('throwing-hand-button', { active })}
    onClick={() => {
        if (!active) { dispatch(selectThrowingHand(throwingHand)); }
      }}
  >
    {throwingHand.btnname}
  </div>
);

const mapStateToProps = (state, props) => ({
  active: state.glove.throwingHand && props.throwingHand.id === state.glove.throwingHand.id,
});

ThrowingHandButton = connect(mapStateToProps)(ThrowingHandButton);

const ThrowingHands = ({t}) => {
  return (
    <div className="throwing-hand-buttons clearfix">
      <h4>{t('ThroWingHAND')}</h4>
      {/* <ThrowingHandButton throwingHand={{ id: 1, name:t('right') }} />
      <ThrowingHandButton throwingHand={{ id: 2, name:t('left') }} /> */}
      <ThrowingHandButton throwingHand={{ id: 1, name: 'RIGHT', btnname:t('right')}} />
      <ThrowingHandButton throwingHand={{ id: 2, name: 'LEFT', btnname:t('left') }} />
    </div>
  );
}

export default withTranslation()(ThrowingHands);
