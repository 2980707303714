import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, find } from 'ramda';
import { selectSize } from '../../actions/creators';
import { withTranslation } from 'react-i18next';

const Sizes = ({ dispatch, sizes,t }) => {
  // console.log(sizes);
  // const { sizes } = this.props;
  // console.log(sizes);

  return (
    <div className="size-select-wrap">
      <h4>{t('size')}</h4>
      <select onChange={e => dispatch(selectSize(find(s => s.id === parseInt(e.target.value, 10), sizes)))}>
        {isEmpty(sizes) &&
          <option value>Select your glove type first</option>
        }
        {sizes.map(size =>
          <option key={size.id} value={size.id}>{size.inches}{size.youth ? ' (YOUTH)' : ''}</option>)}
      </select>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { gloveType } = state.glove;

  // console.log(state.builder.sizes[gloveType].length);

  return {
    sizes: state.builder.sizes[gloveType],
  };
};

export default connect(mapStateToProps)(withTranslation()(Sizes));
