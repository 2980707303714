import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routers from './routers/RootRouter';
// import './common/GlobalStyles';
import { Provider as CountProvider } from './Context';
import getRestAPI from './common/getRestAPI';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    getRestAPI().then(() => {
      this.setState({
        isLoaded: true,
      });
    });
  }

  render() {
    if (!this.state.isLoaded) return null;
    // console.log(JSON.parse(window.sessionStorage.getItem('restAPI')));
    return (
      /**
       * @param {Boolean} forceRefresh BrowserRouter props
       */
      <CountProvider>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </CountProvider>
    );
  }
}

export default App;
