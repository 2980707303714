import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../components/input/Input';
import DivButton from '../../components/button/DivButton';
import {
  CountryDropdown,
  RegionDropdown,
} from '../../lib/country/rcrs';
import { onlyEnglish, onlyEnglishAndNumber } from '../../common/CheckedValidation';

class BillingAddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: 1,
      country: '',
      state: '',
    };

    this.input = null;
    this.lastNameInput = null;
    this.companyInput = null;
    this.addressInput = null;
    this.etcInput = null;
    this.cityInput = null;
    this.zipCodeInput = null;
    this.phoneNumberInput = null;
  }

  componentDidMount() {
    if (this.input) {
      // this.input.focus();
    }
  }

  render() {
    return (
      <div style={this.props.style}>
        <p style={{ color: 'red', marginBottom: 20 }}>Ensure that the full name and address are typed in English only. Other languages are not allowed to put below.</p>
        <div style={styles.nameBox}>
          <DivButton
            style={styles.halfInputBox(this.state.focus === 1)}
            // onClick={() => this.input.focus()}
          >
            <p style={styles.inputTitle}>First name</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 1 })}
              ref={comp => this.input = comp}
              style={styles.input(280)}
              onChange={(firstName) => {
                if (onlyEnglish(firstName)) { return this.props.getAddr('name_first', firstName); }
                return this.input.value = null;
              }}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>
          <DivButton
            style={styles.halfInputBox(this.state.focus === 2)}
            // onClick={() => this.lastNameInput.focus()}
          >
            <p style={styles.inputTitle}>Last name</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 2 })}
              ref={comp => this.lastNameInput = comp}
              style={styles.input(280)}
              onChange={(listName) => {
                if (onlyEnglish(listName)) { return this.props.getAddr('name_last', listName); }
                return this.lastNameInput.value = null;
              }}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>
        </div>

        <DivButton
          style={styles.fullInputBox(this.state.focus === 3)}
          // onClick={() => this.companyInput.focus()}
        >
          <p style={styles.inputTitle}>Company (optional)</p>
          <Input
            onBlur={() => this.setState({ focus: 0 })}
            onFocus={() => this.setState({ focus: 3 })}
            ref={comp => this.companyInput = comp}
            style={styles.input(590)}
            onChange={(company) => {
              if (onlyEnglish(company)) { return (this.props.getAddr('company', company)); }
              return this.companyInput.value = '';
            }}
            placeholder=""
            placeholderColor="transparent"
            type="text"
          />
        </DivButton>

        <DivButton
          style={styles.fullInputBox(this.state.focus === 4)}
          // onClick={() => this.addressInput.focus()}
        >
          <p style={styles.inputTitle}>Address</p>
          <Input
            onBlur={() => this.setState({ focus: 0 })}
            onFocus={() => this.setState({ focus: 4 })}
            ref={comp => this.addressInput = comp}
            style={styles.input(590)}
            onChange={(address) => {
              if (onlyEnglishAndNumber(address)) { return this.props.getAddr('addr', address); }
              return this.addressInput.value = '';
            }}
            placeholder=""
            placeholderColor="transparent"
            type="text"
          />
        </DivButton>

        {/* <DivButton
          style={styles.fullInputBox(this.state.focus === 5)}
          onClick={() => this.etcInput.focus()}
          >
          <p style={styles.inputTitle}>Apartment, suite, etc. (optional)</p>
          <Input
            onBlur={() => this.setState({ focus: 0 })}
            onFocus={() => this.setState({ focus: 5 })}
            ref={comp => this.etcInput = comp}
            style={styles.input(590)}
            // onChange={etc => this.props.getAddr('addr_etc', !etc ? 'none' : etc)}
            onChange={etc => this.props.getAddr('addr_etc', etc)}
            placeholder=""
            placeholderColor="transparent"
            type="text"
          />
        </DivButton> */}

        <DivButton
          style={styles.fullInputBox(this.state.focus === 6)}
          // onClick={() => this.cityInput.focus()}
        >
          <p style={styles.inputTitle}>City</p>
          <Input
            onBlur={() => this.setState({ focus: 0 })}
            onFocus={() => this.setState({ focus: 6 })}
            ref={comp => this.cityInput = comp}
            style={styles.input(590)}
            onChange={(city) => {
              if (onlyEnglish(city)) { return this.props.getAddr('city', city); }
              return this.cityInput.value = '';
            }}
            placeholder=""
            placeholderColor="transparent"
            type="text"
          />
        </DivButton>

        <div style={{ ...styles.nameBox, marginTop: 20 }}>
          <div style={styles.countryInputBox(226, this.state.focus === 7)}>
            <p style={styles.inputTitle}>Country</p>
            <CountryDropdown
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 7 })}
              style={styles.selcet(183 + 14 + 7 + 7)}
              value={this.state.country}
              onChange={(country) => {
                this.props.getAddr('country', country);
                this.setState({ country });
              }}
            />
          </div>

          <div style={styles.countryInputBox(224, this.state.focus === 8)}>
            <p style={styles.inputTitle}>State</p>
            <RegionDropdown
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 8 })}
              style={styles.selcet(183 + 14 + 7 + 7 - 2)}
              onChange={(state) => {
                this.props.getAddr('state', state);
                this.setState({ state });
              }}
              country={this.state.country}
              value={this.state.state}
            />
          </div>

          <DivButton
            style={styles.countryInputBox(140, this.state.focus === 9)}
            // onClick={() => this.zipCodeInput.focus()}
          >
            <p style={styles.inputTitle}>Zip Code</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 9 })}
              ref={comp => this.zipCodeInput = comp}
              style={styles.input(120)}
              onChange={zipCode => this.props.getAddr('zipcode', zipCode)}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>
        </div>

        <DivButton
          style={styles.fullInputBox(this.state.focus === 10)}
          // onClick={() => this.phoneNumberInput.focus()}
        >
          <p style={styles.inputTitle}>Phone Number</p>
          <Input
            onBlur={() => this.setState({ focus: 0 })}
            onFocus={() => this.setState({ focus: 10 })}
            ref={comp => this.phoneNumberInput = comp}
            style={styles.input(590)}
            onChange={phone => this.props.getAddr('phone', phone)}
            placeholder="Please enter your cell phone number."
            placeholderColor="#868686"
            type="text"
          />
        </DivButton>

        <p
          style={{
            fontFamily: 'Metropolis',
            fontSize: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#4a4a4a',
            marginTop: 8,
          }}
        >
          *add your country code with ‘+’ as prefix. i.e+91
        </p>

      </div>
    );
  }
}

BillingAddressForm.propTypes = {
  style: PropTypes.object,
  getAddr: PropTypes.func,
};

BillingAddressForm.defaultProps = {
  style: {},
  getAddr: () => {},
};

const styles = {
  halfInputBox: isFocus => ({
    width: 300,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
  }),
  inputTitle: {
    fontSize: 12,
    color: '#515356',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    marginTop: 6,
  },
  input: width => ({
    width,
    marginTop: 5,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '#251f1b',
    fontSize: 16,
  }),
  nameBox: {
    width: 610,
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullInputBox: isFocus => ({
    width: 610,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
    marginTop: 20,
  }),
  countryInputBox: (width, isFocus) => ({
    width,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
  }),
  selcet: width => ({
    width,
    marginTop: 5,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '#251f1b',
    fontSize: 16,
    transform: 'translate(-7px, 0px)',
    MsTransform: 'translate(-7px, 0px)',
    Webkittransform: 'translate(-7px, 0px)',
  }),

  checkBox: {
    width: 12 + 5 + 129,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 21,
  },
  ckecedText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#666666',
  },
  btnsBox: {
    width: 610,
    position: 'absolute',
    marginTop: 51,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: (marginLeft = 0) => ({
    width: 110,
    height: 40,
    backgroundColor: '#251f1b',
    color: '#fff',
    fontWeight: 'bold',
    lineHeight: 1.43,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft,
    fontSize: 14,
  }),
};

export default BillingAddressForm;
