import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  checkedCurrencySymbol,
  convertDate,
  settingCost,
  getPerItemCouponCost,
  getCookie,
  setCookie,
  returnComma,
} from '../../common/Utils';
import { getItem, removeItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';

import Modal from '../../containers/baseModal/BaseModal';

import Step from '../../components/step/Step';
import CartItem from '../../components/item/CheckOutCartItem';
import ConfirmCartModal from '../../components/modal/ConfirmCartModal';
import DivButton from '../../components/button/DivButton';

import { Consumer } from '../../Context';
import { YEN_INCOM_TAX_PER } from '../../common/Constants';

const STEP_MENU = ['Shop', 'My Cart', 'Check out'];
const STEP = ['SHIPPING INFO', 'PAYMENT INFO', 'ORDER REVIEW'];

const fail = '/svg/fail.svg';

function subtotal(lanCode, data) {
  let cost = 0;
  data.map(item => (cost += settingCost(lanCode, item) * item.quantity));
  return cost;
}

function sumQty(data) {
  let qty = 0;
  data.map(item => (qty += item.quantity));
  return qty;
}

class NewOrderReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 3,
      isViewDetailModal: false,
      orderData: null,
      data: null,
      isPaidFali: false,
      paymentInfo: null,
      isModal: false,
      bodyText: '',
    };

    this.viewDetailData = null;
    this.userData = null;
    this.onNext = this.closeModal;
  }

  componentWillReceiveProps(nextProps) {
    // removeItem('orderReviewData');
    // nextProps.history.push('/');
  }

  componentDidMount() {
    setTimeout(()=>this.initialize(), 500);
    // this.initialize();
  }

  componentWillUnmount() {
    console.log('will unmount');
    const { actions } = this.props;
    actions.reset();
    // removeItem('orderReviewData');
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: '',
    });
  };

  async initialize() {
    try {
      let data = getItem('orderReviewData');
      let cnt = 0;

      while (!data && cnt < 5) {
        console.log('cnt', cnt);
        data = getItem('orderReviewData');
        cnt += 1;
      }

      if (!data) {
        alert('There is no data information.');
        // this.setState({ isPaidFali: true });
        this.props.history.push('/');
      }

      console.log('coupon', data.coupon, data);

      const paymentApi = getItem('restAPI').model_desc.payment_get_detail;
      const paymentRes = await _Fetch(
        paymentApi,
        `?pid=${data.pidPayment}`,
        [],
      );
      if (
        paymentRes.payment.stt_payment === 10 ||
        paymentRes.payment.stt_payment === 5
      ) {
        this.userData =
          getCookie('userData') === ''
            ? null
            : JSON.parse(getCookie('userData'));

        const cartDelApi = getItem('restAPI').model_desc.cart_delete;

        const promises = data.item.map(async (item) => {
          const cartDelBody = {
            cart: {
              pid_cart: item.pid_cart,
              pid_user: this.userData.user.pid_user,
            },
          };

          const res = await _Fetch(cartDelApi, '', cartDelBody);
          return res;
        });

        const promisesArr = await Promise.all(promises);
        console.log(promisesArr);

        this.setState(
          {
            orderData: paymentRes.od,
            paymentInfo: paymentRes.payment,
            data,
          },
          () => console.log('Your payment is complete.'),
        );
      } else {
        console.log(data);
        // alert('결제 승인 오류 입니다. 다시 재결재 해주세요');
        this.setState({ isPaidFali: true });
        // this.props.history.push('/cart');
      }
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
    }

    // 세션 스토리지 값에 있는 데이터를 삭제
    // removeItem('orderReviewData');
  }

  // coupon total Cost
  calCouponTotalCost(couponData) {
    console.log('cal', couponData);
    if (!couponData) return null;

    // 아이템 마다 쿠폰 가격 저장 되어있는 배멸
    const couponTotalCostArr = this.state.data.item.map(item =>
      getPerItemCouponCost(item, couponData, this.props.i18n.language) *
        item.quantity);

    // 배열에 있는 값들 합산
    const couponTotalCost = couponTotalCostArr.reduce((a, b) => a + b, 0);
    // console.log(couponTotalCost, couponTotalCostArr);
    return couponTotalCost;
  }

  // 상품별 coupon total Cost
  calGoodsCouponTotalCost(data, coupon) {
    if (!coupon) return 0;

    // 아이템 마다 쿠폰 가격 저장 되어있는 배멸
    const couponTotalCostArr = data.map(item =>
      getPerItemCouponCost(item, coupon, this.props.i18n.language) *
        item.quantity);
    // console.log(couponTotalCostArr);
    // 배열에 있는 값들 합산
    const couponTotalCost = couponTotalCostArr.reduce((a, b) => a + b, 0);
    // console.log(couponTotalCost, couponTotalCostArr);
    return couponTotalCost;
  }

  renderStep() {
    return STEP.map((item, i) => (
      <div style={styles.stepItemBox} key={shortid.generate()}>
        <div style={styles.stepItemCircle(this.state.step > i)}>{i + 1}</div>
        <p style={styles.stepItemText(this.state.step > i)}>{item}</p>
      </div>
    ));
  }

  renderModal() {
    if (this.viewDetailData) {
      return (
        <ConfirmCartModal
          data={this.viewDetailData}
          isModal={this.state.isViewDetailModal}
          onClose={() => {
            this.viewDetailData = null;
            // document.body.style.overflow = 'auto';
            this.setState({ isViewDetailModal: false });
          }}
        />
      );
    }
    return null;
  }

  renderItems(data, title, shippingCost, odData) {
    const { i18n } = this.props;

    if (data.length === 0) return null;
    return (
      <div style={styles.itemBox}>
        <div style={styles.itemBoxHeader}>
          <p style={styles.orderRevieText}>ORDER REVIEW</p>
          <p style={styles.orderNumberText}>{odData.pid_od}</p>
        </div>
        <p style={styles.productTitle(20)}>
          {convertDate(odData.date_creation)}
        </p>
        <p style={Object.assign(styles.productTitle(24), { marginBottom: 20 })}>
          {`${title} PRODUCT`}
        </p>
        {data.map((item, i) => (
          <CartItem
            coupon={this.state.data.coupon}
            onViewDtail={(glove) => {
              setTimeout(() => window.scrollTo(0, 0), 1);
              this.viewDetailData = glove;
              this.setState({ isViewDetailModal: true });
              // document.body.style.overflow = 'hidden';
            }}
            data={item}
            key={shortid.generate()}
            marginBottom={i === this.props.data.length - 1 ? 17 : 20}
          />
        ))}

        <div style={styles.couponBox(8)}>
          <p style={styles.cartSubTotalTtitle}>
            {data[0].b_custom === 2 ? 'Stock Subtotal' : 'Customize Subtotal'}
          </p>
          <p style={styles.cartSubTotalCost}>
            {checkedCurrencySymbol(i18n.language)}
            {i18n.language === 'en'
              ? returnComma((
                    subtotal(i18n.language, data) -
                    this.calGoodsCouponTotalCost(data, this.state.data.coupon)
                  ).toFixed(2))
              : returnComma(subtotal(i18n.language, data) -
                    this.calGoodsCouponTotalCost(data, this.state.data.coupon))}
          </p>
        </div>

        <div style={styles.couponBox(8)}>
          <p style={styles.cartSubTotalTtitle}>
            {data[0].b_custom === 2 ? 'Stock Shipping' : 'Customize Shipping'}
          </p>
          <p style={styles.cartSubTotalCost}>
            {checkedCurrencySymbol(i18n.language)}
            {i18n.language === 'en'
              ? returnComma(shippingCost.toFixed(2))
              : returnComma(shippingCost)}
          </p>
        </div>

        <div style={styles.cartUnderline} />

        <div style={styles.totalBox}>
          <p style={styles.totalText}>
            {data[0].b_custom === 2 ? 'Stock Total' : 'Customize Total'}
          </p>
          <p style={styles.totalCost}>
            {checkedCurrencySymbol(i18n.language)}
            {i18n.language === 'en'
              ? returnComma((
                    subtotal(i18n.language, data) +
                    shippingCost -
                    this.calGoodsCouponTotalCost(data, this.state.data.coupon)
                  ).toFixed(2))
              : returnComma(subtotal(i18n.language, data) +
                    shippingCost -
                    this.calGoodsCouponTotalCost(data, this.state.data.coupon))}
          </p>
        </div>
      </div>
    );
  }

  renderThxText = (language) => {
    if (language === 'ko') {
      return (
        <p style={{ marginTop: 50, marginBottom: 50, textAlign: 'center' }}>
          감사합니다. 주문이 완료되었습니다. 주문 진행 상황은
          {' '}
          <u
            style={styles.email}
            onClick={() => this.props.history.push('/orderTracking')}
            role="button"
            tabIndex="-1"
          >
          ORDER TRACKING
          </u>
          을 통해 확인하실 수 있습니다.
        </p>
      );
    }

    if (language === 'ja') {
      return (
        <p style={{ marginTop: 50, marginBottom: 50, textAlign: 'center' }}>
          ありがとうございます。ご注文が完了しました。ご注文状況は
          <u
            style={styles.email}
            onClick={() => this.props.history.push('/orderTracking')}
            role="button"
            tabIndex="-1"
          >
          ORDER TRACKING
          </u>
          でご確認いただけます。
        </p>
      );
    }

    return (
      <p style={{ marginTop: 50, marginBottom: 50, textAlign: 'center' }}>
        Thank you for shopping at Gloveworks! Your order was received and is in process. You can always check the status of your order by
        {' '}
        ”
        <u
          style={styles.email}
          onClick={() => this.props.history.push('/orderTracking')}
          role="button"
          tabIndex="-1"
        >
          ORDER TRACKING
        </u>
        ”
      </p>
    );
  }

  render() {
    const { i18n } = this.props;
    const {
      data, orderData, isPaidFali, paymentInfo, isModal, bodyText,
    } = this.state;

    if (isPaidFali) {
      return (
        <div>
          <Modal
            isModal={isModal}
            bodyText={bodyText}
            onClose={this.closeModal}
            onNext={this.onNext}
          />

          <Step menu={STEP_MENU} />

          <div style={styles.failBox}>
            <div style={styles.failTitleBox}>
              <img src={fail} alt="" />
              <p style={styles.failTitle}>Failed</p>
            </div>
            <p style={styles.failText}>
              Sorry, Order payment failed. Please retry your payment.
            </p>
          </div>

          <DivButton
            style={styles.failBtn}
            onClick={() => this.props.history.push('/cart')}
          >
            GO TO CART
          </DivButton>
        </div>
      );
    }

    if (!data && !orderData) return null;

    console.log(data);

    const stock = data.item.filter(item => item.b_custom === 2);
    const customize = data.item.filter(item => item.b_custom === 1);

    const stockShipping = data.shippingCostData.stockTotalFee;
    const customizeShipping = data.shippingCostData.customTotalFee;

    const qty = sumQty(data.item);
    const isDiscountShppingFee = qty > 3;

    console.log('qty', qty);

    const beforeDiscountShppingFee = isDiscountShppingFee
      ? (stockShipping + customizeShipping) * 0.2
      : 0;

    const discountShppingFee =
      i18n.language === 'en'
        ? beforeDiscountShppingFee.toFixed(2)
        : Math.ceil(beforeDiscountShppingFee);

    const tax =
      i18n.language === 'ja'
        ? Math.floor(Number((subtotal(i18n.language, data.item) -
                this.calCouponTotalCost(data.coupon)) *
                YEN_INCOM_TAX_PER))
        : 0;

    const addrArr = [data.shippingAddr, data.billingAddr];
    const payMethode = (type) => {
      switch (type) {
        case  10 : return 'Paypal'
        case  11 : return 'Payment Wall'
        case  20 : return 'Paypal'
        case  21 : return 'bank'
        case  22 : return 'Payment Wall'
        case  30 : return 'Paypal'
        case  31 : return 'bank'
        default : return 'Inicis'
      }
    }
    return (
      <div>
        {this.renderModal()}
        <Step menu={STEP_MENU} />

        {this.renderThxText(i18n.language)}

        <div style={styles.stepBox}>{this.renderStep()}</div>
        {this.renderItems(
          stock,
          'STOCK',
          stockShipping,
          orderData[customize.length > 0 ? 1 : 0],
        )}
        {this.renderItems(
          customize,
          'CUSTOMIZE',
          customizeShipping,
          orderData[stock.length > 0 ? 1 : 0],
        )}

        <div style={styles.itemBox}>
          <p style={styles.totalTitle(32)}>Total</p>
          {subtotal(i18n.language, stock) > 0 ? (
            <div style={styles.totalRowBox(16)}>
              <p style={styles.totalRowText}>Stock Total</p>
              <p style={styles.totalRowText}>
                {checkedCurrencySymbol(i18n.language)}
                {i18n.language === 'en'
                  ? returnComma(subtotal(i18n.language, stock).toFixed(2))
                  : returnComma(subtotal(i18n.language, stock))}
              </p>
            </div>
          ) : null}

          {subtotal(i18n.language, customize) > 0 ? (
            <div style={styles.totalRowBox(8)}>
              <p style={styles.totalRowText}>Customize Total</p>
              <p style={styles.totalRowText}>
                {checkedCurrencySymbol(i18n.language)}
                {i18n.language === 'en'
                  ? returnComma(subtotal(i18n.language, customize).toFixed(2))
                  : returnComma(subtotal(i18n.language, customize))}
              </p>
            </div>
          ) : null}

          {i18n.language === 'ja' ? (
            <div style={styles.totalRowBox(8)}>
              <p
                style={{
                  ...styles.totalTitle(32),
                  marginTop: 5,
                  marginLeft: 0,
                }}
              >
                消費税10%
              </p>
              <p style={styles.totalRowText}>
                {checkedCurrencySymbol(i18n.language)}
                {returnComma(Math.floor(tax))}
              </p>
            </div>
          ) : null}

          {this.calCouponTotalCost(data.coupon) > 0 ? (
            <div style={styles.totalRowBox(8)}>
              <p style={styles.totalRowText}>Coupon Discount</p>
              <p style={{ ...styles.totalRowText, color: '#d0021b' }}>
                -{checkedCurrencySymbol(i18n.language)}
                {i18n.language === 'en'
                  ? returnComma(this.calCouponTotalCost(data.coupon).toFixed(2))
                  : returnComma(Math.ceil(this.calCouponTotalCost(data.coupon)))}
              </p>
            </div>
          ) : null}

          <p style={styles.totalTitle(32)}>Shipping Fee</p>

          {stockShipping > 0 ? (
            <div style={styles.totalRowBox(16)}>
              <p style={styles.totalRowText}>Stock Shipping</p>
              <p style={styles.totalRowText}>
                {checkedCurrencySymbol(i18n.language)}
                {i18n.language === 'en'
                  ? returnComma(stockShipping.toFixed(2))
                  : returnComma(stockShipping)}
              </p>
            </div>
          ) : null}

          {customizeShipping > 0 ? (
            <div style={styles.totalRowBox(8)}>
              <p style={styles.totalRowText}>Customize Shipping</p>
              <p style={styles.totalRowText}>
                {checkedCurrencySymbol(i18n.language)}
                {i18n.language === 'en'
                  ? returnComma(customizeShipping.toFixed(2))
                  : returnComma(customizeShipping)}
              </p>
            </div>
          ) : null}

          {isDiscountShppingFee && (
            <div style={styles.totalRowBox(8)}>
              <p style={styles.totalRowText}>Shipping Discount</p>
              <p style={{ ...styles.totalRowText, color: '#d0021b' }}>
                -{checkedCurrencySymbol(i18n.language)}
                {i18n.language === 'en'
                  ? returnComma(Number(discountShppingFee).toFixed(2))
                  : returnComma(Math.ceil(discountShppingFee))}
              </p>
            </div>
          )}

          <div style={styles.cartUnderline} />
          <div style={styles.totalBox}>
            <p style={styles.totalText}>Total</p>
            <p style={styles.totalCost}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === 'en'
                ? returnComma((
                      subtotal(i18n.language, data.item) +
                      stockShipping +
                      customizeShipping +
                      tax -
                      this.calCouponTotalCost(data.coupon) -
                      discountShppingFee
                    ).toFixed(2))
                : returnComma(subtotal(i18n.language, data.item) +
                      stockShipping +
                      customizeShipping +
                      tax -
                      this.calCouponTotalCost(data.coupon) -
                      discountShppingFee)}
            </p>
          </div>
        </div>

        <div style={styles.itemBox}>
          <p style={styles.orderInfoTitle}>ORDER INFORMATION</p>
          <div style={styles.addrBox}>
            {addrArr.map((item, i) => (
              <div style={styles.innerAddrBox} key={shortid.generate()}>
                <p style={styles.addrTitle}>
                  {i === 0 ? 'Shippiing Address' : 'Billing Address'}
                </p>
                <p style={styles.addrText}>
                  {item.addr_etc === 'none' || !item.addr_etc
                    ? null
                    : `${item.addr_etc}, `}
                  {`${item.addr}, ${item.city}, ${item.state}, ${item.country}`}
                  <br />
                  Zip : {item.zipcode}
                  <br />
                </p>
              </div>
            ))}
          </div>

          <div style={styles.paymentBox}>
            <p style={styles.addrTitle}>Payment Method</p>
            {paymentInfo && (payMethode(paymentInfo.typ_pay)) !== 'bank' ? (
              <p style={styles.addrText}>{
                payMethode(paymentInfo.typ_pay)
              }</p>
            ) : (
              <div style={{ marginTop: 9 }}>
                <p style={styles.transferTitle}>Account Transfer</p>
                <p style={{ ...styles.depositorText, marginTop: 10 }}>
                  - Name of depositor : {this.state.data.senderId}
                </p>
                <br />
                <p style={styles.depositorText}>
                  {' '}
                  - Deposit bank :{' '}
                  {i18n.language === 'ja' ? '振込み' : '무통장입금'}{' '}
                </p>
                <br />
                <p
                  style={{
                    ...styles.depositorText,
                    fontWeight: '600',
                    fontStyle: 'italic',
                  }}
                >
                  {i18n.language === 'ja'
                    ? '* 1〜2日以内にご入金確認がならない時オーダーが自動的にキャンセルされます。'
                    : '*1~2일내에 입금 확인이 안될 시 오더가 자동 취소됩니다.'}
                </p>
                <p style={{ ...styles.depositorText, marginTop: 15 }}>
                  {' '}
                  - {i18n.language === 'ja' ? '口座番号を失くしてしまいました! どうしましょう?' : '계좌 번호를 잃어버렸어요! 어떻게 하죠?'}{' '}
                </p>
                <p
                  style={{
                    ...styles.depositorText,
                    fontWeight: '600',
                    fontStyle: 'italic',
                    marginTop: 10,
                  }}
                >
                  {i18n.language === 'ja'
                    ? '振込先：みずほ銀行芝支店 普通口座 4637525 グラブワークスジャパン株式会社'
                    :
                    `
아래의 계좌로 입금부탁드립니다.
입금하실 계좌: 우리은행 176-014453-13-101
예금주 : ㈜ 아산 코퍼레이션
                    `}
                </p>

              </div>
            )}
          </div>
        </div>

        <div style={styles.bottomBtnsBox}>
          <DivButton
            style={styles.bottomButton}
            onClick={() => {
              removeItem('orderReviewData');
              this.props.history.push('/orderHistory', { index: 1 });
            }}
          >
            VIEW ORDER LIST
          </DivButton>
          <DivButton
            style={styles.bottomButton}
            onClick={() => {
              removeItem('orderReviewData');
              this.props.history.push('/');
            }}
          >
            GO TO HOME
          </DivButton>
        </div>
      </div>
    );
  }
}

const styles = {
  email: {
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: '#007dff',
    cursor: 'pointer',
  },
  stepBox: {
    width: 540,
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    marginTop: 37,
    paddingBottom: 38 - 22,
  },
  stepItemBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  stepItemCircle: isVisible => ({
    width: 30,
    height: 30,
    backgroundColor: isVisible ? '#007dff' : 'rgba(204, 204, 204, 0.5)',
    borderRadius: 15,
    color: isVisible ? '#fff' : '#aaa',
    fontSize: 16,
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  }),
  stepItemText: isVisible => ({
    textAlign: 'center',
    color: isVisible ? '#251f1b' : 'rgba(204, 204, 204, 0.5)',
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    marginTop: 9,
  }),
  itemBox: {
    width: 996,
    backgroundColor: '#fff',
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
    margin: 'auto',
    marginTop: 22,
    paddingBottom: 20,
  },
  productTitle: marginTop => ({
    color: '#251f1b',
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    lineHeight: 1.39,
    marginTop,
    marginLeft: 30,
  }),
  itemBoxHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 30,
    marginTop: 30,
  },
  orderRevieText: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#251f1b',
  },
  orderNumberText: {
    marginLeft: 31,
    fontSize: 18,
    lineHeight: 1.39,
    color: '#251f1b',
  },
  couponBox: marginTop => ({
    display: 'flex',
    marginTop,
    height: 24,
    alignItems: 'center',
  }),
  cartSubTotalTtitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    marginLeft: 618,
    width: 168,
    textAlign: 'left',
  },
  cartSubTotalCost: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    marginLeft: 20,
    width: 150,
    textAlign: 'right',
  },
  cartUnderline: {
    width: 948,
    height: 2,
    backgroundColor: 'rgba(21, 41, 53, 0.1)',
    margin: 'auto',
    marginTop: 17,
  },
  totalBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 21,
  },
  totalText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    color: '#251f1b',
    lineHeight: 1.38,
    letterSpacing: 0.9,
    marginLeft: 618,
    fontWeight: '600',
    width: 150,
  },
  totalCost: {
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    textAlign: 'right',
    color: '#251f1b',
    lineHeight: 1.25,
    letterSpacing: 1.5,
    fontWeight: '600',
    width: 180,
    marginLeft: 10,
  },
  totalTitle: marginTop => ({
    marginTop,
    marginLeft: 48,
    color: '#251f1b',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1.25,
    letterSpacing: 1.1,
  }),
  totalRowBox: marginTop => ({
    width: 150 + 600 + 150,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    marginTop,
  }),
  totalRowText: {
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
  },
  orderInfoTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#251f1b',
    marginTop: 24,
    marginLeft: 30,
  },
  addrBox: {
    width: 420 * 2 + 96,
    height: 25 + 8 + 120,
    margin: 'auto',
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  innerAddrBox: {
    width: 420,
    height: '100%',
  },
  addrTitle: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#251f1b',
  },
  addrText: {
    marginTop: 8,
    fontSize: 16,
    color: '#251f1b',
  },
  paymentBox: {
    marginTop: 16,
    marginLeft: 30,
  },
  bottomBtnsBox: {
    margin: 'auto',
    width: 204 * 2 + 49,
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 80,
    marginBottom: 80,
  },
  bottomButton: {
    width: 204,
    height: 60,
    backgroundColor: '#251f1b',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 1.11,
    letterSpacing: 1,
  },
  failBox: {
    margin: 'auto',
    marginTop: 138,
    backgroundColor: '#fff',
    width: 996,
    height: 195,
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
  },
  failTitleBox: {
    marginTop: 40,
    marginLeft: 60,
    display: 'flex',
    width: 52 + 16.5 + 80,
    height: 50.4,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  failTitle: {
    fontSize: 24,
    fontWeight: '600',
    letterSpacing: 1.3,
    color: '#000',
  },
  failText: {
    width: 306,
    fontSize: 20,
    letterSpacing: 1,
    color: '#000',
    marginTop: 16.6,
    marginLeft: 60,
  },
  failBtn: {
    width: 204,
    height: 60,
    backgroundColor: '#251f1b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: 1,
    lineHeight: 1.1,
    color: '#fff',
    margin: 'auto',
    marginTop: 48,
    marginBottom: 1020,
  },
  transferTitle: {
    fontSize: 16,
    color: '#251f1b',
  },
  depositorText: {
    fontSize: 16,
    color: '#251f1b',
  },
};

NewOrderReview.propTypes = {
  data: PropTypes.object,
  i18n: PropTypes.object,
  history: PropTypes.object,
  actions: PropTypes.object,
};

NewOrderReview.defaultProps = {
  data: {},
  i18n: {},
  history: {},
  actions: null,
};

const defaultMapContextToProps = value => ({
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withRouter(withTranslation('translations')(NewOrderReview)));
