import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { selectTab } from '../actions/creators';
import { withTranslation } from 'react-i18next';
const Tabs = ({ currentTab, dispatch ,t}) => (
  <div id="custom-glove-panel-step-tabs">
    <div
      className={classNames('custom-glove-panel-step-tab custom-glove-panel-step-tab-1', { active: currentTab === 'base' })}
      onClick={() => {
          dispatch(selectTab('base'));
        }}
    >
      <div className="name">{t('customizeTab1')}</div>
      <div className="icon base" />
    </div>
    <div
      className={classNames('custom-glove-panel-step-tab custom-glove-panel-step-tab-2', { active: currentTab === 'shell' })}
      onClick={() => {
          dispatch(selectTab('shell'));
        }}
    >
      <div className="name">{t('customizeTab2')}</div>
      <div className="icon shell" />
    </div>
    <div
      className={classNames('custom-glove-panel-step-tab custom-glove-panel-step-tab-3', { active: currentTab === 'trim' })}
      onClick={() => {
          dispatch(selectTab('trim'));
        }}
    >
      <div className="name">{t('customizeTab3')}</div>
      <div className="icon trim" />
    </div>
    <div
      className={classNames('custom-glove-panel-step-tab custom-glove-panel-step-tab-4', { active: currentTab === 'personalize' })}
      onClick={() => {
          dispatch(selectTab('personalize'));
        }}
    >
      <div className="name">{t('customizeTab4')}</div>
      <div className="icon personalize" />
    </div>
  </div>
);

const mapStateToProps = state => ({
  currentTab: state.tabs.currentTab,
});

export default connect(mapStateToProps)(withTranslation()(Tabs));
