import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { updateGlove } from '../../actions/creators';
import TextOptions from '../TextOptions';
import FlagButtons from '../FlagButtons';

import gloveControl from '../higher-order/GloveControl';

import { checkedCurrencySymbol, returnComma } from '../../../../common/Utils';

const ThumbPersonalizationTypeButton = gloveControl(({
  active,
  onClick,
  children,
}) => (
  <div
    className={classNames('glove-personalization-type-button clearfix', { active })}
    onClick={onClick}
  >
    {children}
  </div>
));

const ThumbPersonalize = ({
  thumbTypes,
  currentType,
  currentText,
  onTextChange,
  currentFont,
  onFontClick,
  textColors,
  currentTextColor,
  onTextColorClick,
  currentNumber,
  onNumberChange,
  currentFlag,
  onFlagClick,
  i18n,
}) => (
  <div className="step-item-view-wrap pull-right">
    <div className="personalization-preview-wrap">
      <h4>THUMB PREVIEW</h4>
      <div className="personalization-preview thumb-personalization-preview">
        <img
          className="background"
          alt="Personalization preview thumb"
          src="/personalization-preview-thumb.png"
        />
        <div className="drawArea">
          <p
            className={classNames('thumb-text', currentFont.className)}
            style={{ color: currentTextColor.hexCode, fontSize: currentText && currentText.length < 4 ? '18px' : '14px' }}
          >
            {currentText}
          </p>
          {(() => {
            switch (currentType.name) {
              case 'NONE':
                return null;
              case 'DEFAULT':
                return (
                  <div>
                    <svg
                      className="thumb-circle"
                      width="40"
                      height="40"
                    >
                      <circle
                        id="c"
                        cx="20"
                        cy="20"
                        r="17"
                        stroke={currentTextColor.hexCode}
                        strokeWidth="3"
                        fillOpacity="0.0"
                      />
                    </svg>
                    <svg
                      className="thumb-default-logo"
                      width="17px"
                      height="22px"
                    >
                      <g fill={currentTextColor.hexCode}>
                        <polygon points="14.823,8.467 14.823,19.052 12.706,19.052 14.823,21.169 16.94,19.052 16.94,6.35" />
                        <polygon points="12.706,10.592 10.597,12.701 10.589,12.701 10.589,19.052 8.472,19.052 8.453,6.366 4.234,10.585 6.351,10.585 6.351,12.702 2.117,12.702 2.117,2.117 6.351,2.117 6.351,6.351 8.468,4.234 8.468,2.117 6.351,0 2.117,0 0,2.117 0,12.702 2.117,14.819 6.351,14.819 6.355,14.815 6.355,14.818 6.355,19.052 8.472,21.169 10.589,21.169 12.706,19.052" />
                      </g>
                    </svg>
                  </div>
                );
              case 'NUMBER':
                return (
                  <div>
                    <svg
                      className="thumb-circle"
                      width="40"
                      height="40"
                    >
                      <circle
                        id="c"
                        cx="20"
                        cy="20"
                        r="17"
                        stroke={currentTextColor.hexCode}
                        strokeWidth="3"
                        fillOpacity="0.0"
                      />
                    </svg>
                    <p
                      className="thumb-number"
                      style={{ color: currentTextColor.hexCode }}
                    >
                      {currentNumber}
                    </p>
                  </div>
                );
              case 'FLAG':
                return (<img
                  className="thumb-flag"
                  src={currentFlag && currentFlag.icon.flagPreview.url}
                  alt=""
                        />);
              default:
            }
          })()}
        </div>
      </div>
    </div>
    <div className="text-wrap">
      <TextOptions
        className="glove-personalization-type-view"
        textName="THUMB TEXT"
        currentText={currentText}
        onTextChange={onTextChange}
        currentFont={currentFont}
        onFontClick={onFontClick}
        colors={textColors}
        currentColor={currentTextColor}
        onColorClick={onTextColorClick}
      />
    </div>
    <div className="personalization-type-wrap">
      <h4>CIRCLE OPTION</h4>
      {thumbTypes.map(type =>
        (<ThumbPersonalizationTypeButton
          key={type.id}
          attrName="thumbPersonalizationType"
          attrValue={type}
         >
          <div className="pull-left">{type.name}</div>
          {type.price > 0 &&
            <div className="price pull-right">{checkedCurrencySymbol(i18n.language) + returnComma(parseInt(type.price, 10))}</div>
            }
          </ThumbPersonalizationTypeButton>))}
    </div>
    <div className="personalization-type-view-wrap">
      {(() => {
switch (currentType.name) {
          case 'NONE':
            return null;
          case 'NUMBER':
            return (<div>
              <h4>TYPE YOUR THUMB NUMBER</h4>
              <input
                type="text"
                maxLength="2"
                value={currentNumber || ''}
                onChange={e => onNumberChange(e.target.value)}
              />
            </div>);
          case 'FLAG':
            return (<FlagButtons
              className="personalization-type-view"
              title="SELECT YOUR FLAG"
              currentFlag={currentFlag}
              onClick={onFlagClick}
            />);
          default:
        }
})()}
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const {
    thumbPersonalizationType,
    thumbPersonalizationText,
    thumbPersonalizationFont,
    thumbPersonalizationTextColor,
    thumbPersonalizationNumber,
    thumbPersonalizationFlag,
  } = state.glove;

  return {
    thumbTypes: state.builder.thumbPersonalizationTypes,
    currentType: thumbPersonalizationType,
    currentText: thumbPersonalizationText,
    currentFont: thumbPersonalizationFont || { id: 1, fontClass: 'HarlowSolidSBOP-Regular' },
    textColors: state.builder.embroideryColors,
    currentTextColor: thumbPersonalizationTextColor,
    currentNumber: thumbPersonalizationNumber,
    currentFlag: thumbPersonalizationFlag,
  };
};

const mapDispatchToProps = dispatch => ({
  onTextChange: text => dispatch(updateGlove({ thumbPersonalizationText: text })),
  onFontClick: font => dispatch(updateGlove({ thumbPersonalizationFont: font })),
  onTextColorClick: color => dispatch(updateGlove({ thumbPersonalizationTextColor: color })),
  onNumberChange: number => dispatch(updateGlove({ thumbPersonalizationNumber: number })),
  onFlagClick: flag => dispatch(updateGlove({ thumbPersonalizationFlag: flag })),
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThumbPersonalize));
