export default (a, b) => {
  let c,
    d,
    e,
    f = a.data,
    g = b.data;
const test = [];
  for (d = 0, e = f.length; e > d; d += 4) {
    test.push(f[d + 3] !== 0 && (c = g[d],c >= 128 
    ? (f[d] = (255 - (255 - f[d]) * (255 - c) / 127 + (c - 128 + f[d])) / 2,
    f[d + 1] = (255 - (255 - f[d + 1]) * (255 - c) / 127 + (c - 128 + f[d + 1])) / 2,
    f[d + 2] = (255 - (255 - f[d + 2]) * (255 - c) / 127 + (c - 128 + f[d + 2])) / 2
    )
      : (f[d] = (c / 127 * f[d] + (c - 128 + f[d])) / 2,
      f[d + 1] = (c / 127 * f[d + 1] + (c - 128 + f[d + 1])) / 2,
      f[d + 2] = (c / 127 * f[d + 2] + (c - 128 + f[d + 2])) / 2
      )
    ));
  }
  return a;
};
