import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// import { reactI18nextModule } from 'react-i18next';

function initLng(str) {
  switch (str) {
    case "en":
      return "en";
    case "ko":
      return "ko";
    case "ja":
      return "ja";
    default:
      // const word = navigator.language.split('-')[0];
      // console.log(word);
      window.sessionStorage.setItem("lang", "en");
      return "en";
  }
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  // .use(reactI18nextModule)
  .init({
    lng: initLng(window.sessionStorage.getItem("lang")),
    resources: {
      en: {
        translations: {
          headerMenuOurStory: "OUR STORY",
          headerMenuCustom: "CUSTOM",
          headerMenuShop: "SHOP",
          headerMenuGallery: "GALLERY",
          headerMenuOrderTracking: "ORDER TRACKING",
          dropBoxLogin: "LOGIN",
          orderTrackingTrackYourOrder: "TRACK YOUR ORDER",
          orderTrackingDesc:
            "To track your order, enter your email address and order number below.",
          orderTrackingTrackMyOrder: "TRACK MY ORDER",
          cartCouponCode: "Coupon code",
          cartApplyCoupon: "APPLY COUPON",
          cartDesc: "One coupon code can be applied to multiple products.",
          cartTotal: "Cart Total",
          cartSubTotal: "Sub Total",
          cartTaxDesc:
            "This subtotal does not include shipping fee, import duties and taxes.",
          cartAgree: `I agree and acknowledge that all my custom glove selections are
            correct and that once this order is placed there is no modifying or
            canceling the order. *`,
          cartCheckOut: "CHECK OUT",

          cartCheckPrepaid: `A transaction request with the same information has been submitted within 15 minutes of a previous attempt. This may result in duplicate transactions. Do you wish to continue to check-out?`,
          cartCheckPrepaidLeft: `Check order history`,
          cartCheckPrepaidRight: `Continue to check-out`,

          Login: "Login",
          LoginButton: "LOG IN",
          LoginDesc:
            "If you don’t already have an account click the button below to create your account.",
          LoginCreateAccount: "CREATE ACCOUNT",
          LoginSignInFacebook: "SIGN IN WITH FACEBOOK",
          LoginSignInGoogle: "SIGN IN WITH GOOGLE",
          LoginForgotDesc: "So you can’t get in to your account? Did you",
          LoginForgotPassword: "forget your password",
          contactTitle: "CONTACT",
          contactUsFirst: "Please contact us at ",
          contactUsSecond: "if you have any questions.",
          contactUsThrid: "We will respond as soon as possible.",
          contactUsForth:
            "Follow us on our social media channels for the latest news and events including special offers.",
          faqTitle: "FAQ",
          ShoppingPolicyTitle: "SHIPPING POLICY",
          TermsTitle: "TERMS OF SERVICE",
          privateTitle: "PRIVATE POLICY",
          footerContactTitle: "CONTACT",

          shippingAddress: "SHIPPING ADDRESS",
          shippingMethod: "SHIPPING METHOD",
          shippingBillingAddr: "BILLING ADDRESS",

          paymentInfoProduct: "PRODUCT",
          payemntInfoPaymetnMethod: "PAYMENT METHOD",

          shoppingAddressFormDesc:
            "Ensure that the full name and address are typed in English only. Other languages are not allowed to put below.",
          mainYours:
            "Gloveworks understands the importance of your glove. It is an extension of your hand, a part of you. At Gloveworks we take pride in creating you a custom, one-of-a-kind glove with the highest quality materials and the finest craftsmanship in the industry. By cutting out the middlemen, we can offer you a superior product at an amazing price. When the time comes, you need to know you can count on your glove. Use the Gloveworks Glove Builder to create your personal masterpiece, because when you complete your glove, your glove completes you.",

          firstNameTrans: "First name",
          lastNameTrans: "Last name",
          companyTrans: "Company (Optional)",
          addressTrans: "Address",
          cityTrans: "City",
          countryTrans: "Country",
          stateTrans: "State",
          zipCodeTrans: "Zip Code",
          phoneNumberTrans: "Phone Number",

          orderTrackingStatusMenu1: "ORDER RECEIVED/ REVIEWED",
          orderTrackingStatusMenu2: "LEATHER SELECTION & CUTTING",
          orderTrackingStatusMenu3: "EMBROIDERY & SEWING",
          orderTrackingStatusMenu4: "QI & SHIPPING PREPARATION",
          orderTrackingStatusMenu5: "SHIPPED",

          orderTrackingStatusMenu6: "ON DELIVERY",
          orderTrackingStatusMenu7: "DELIVERED",

          orderTrackingStatusDecs1: `We are reviewing your order details. This stage takes 1 - 3
            business days after your order is received. If there’s any issue
            regarding your glove (e.g. copyright infringement, embroidery work
            trouble) which requires your attention, we will send you the email
            to discuss the issue. If there is no e-mail from us within 1 - 3
            business days, don’t worry - your order is confirmed! Please note
            that there is no refund or exchanging after this stage.`,
          orderTrackingStatusDecs2: `Now we start your glove! Each glove begins with the selection of
            materials and cutting out the individual pieces that will later be
            assembled and sewn together. Trained technicians find the best
            location on the hide to add the die cut molds, as different parts
            of the hide are better for the certain parts of the glove. The
            shell palm and back areas are most important in this selection
            process so are very carefully arranged. The cut sections are then
            grouped together and passed onto the embroidery & sewing steps.
            This stage takes 10-15 business days after your order is confirmed.`,
          orderTrackingStatusDecs3: `Following the cutting process, the glove is now ready to be
            embroidered with patches, logo, and player name. You can take a
            sneak peek of your embroidery below. After embroidery are
            completed on the glove, it’s time to bring it to life by sewing
            the individual sections together into one piece! This stage takes
            14 - 21 business days. *The sneak peek may not be available
            depending on the condition, schedule or circumstance of
            production. * Please be advised that any special request beyond
            what the current builder offers may require 1-2 weeks of extended
            production time.`,
          orderTrackingStatusDecs4: `Your glove is finished! And now going through the quality
            inspection. This stage takes 1 - 3 business days. During the
            quality inspection, we check every aspect of your glove, and
            prepare for delivery. After this stage, your glove is ready for
            shipment!`,
          orderTrackingStatusDecs5: `This stage takes 2 - 7 business days. Now your glove is on delivery.`,
          orderTrackingStatusDecs6: `Delivery completed. Let’s play ball!`,

          orderTrackingEditButtonText: "AMEND THE ORDER",
          orderTrackingViewButtonText: "Track the shipment",

          ourStoryIntroducingTitle:
            "WHEN YOU COMPLETE YOUR GLOVE, YOUR GLOVE COMPLETES YOU",
          ourStoryMaterialTitle: "NOT ALL LEATHER IS CREATED EQUAL",
          ourStoryDesignTitle: "WHATEVER YOU WANT, WE GOT YOU",
          ourStorySocalResponsibiltyTitle: "BASEBALL BRINGS PEOPLE TOGETHER",
          ourStoryTeamGloveworksTitle: "NOT ONLY A TEAM, BUT A FAMILY",

          ourStoryIntroducingDesc: `Hi, I’m Donald Kim, founder and CEO of Gloveworks, and I’ve got baseball in my blood. Not in a weird medical emergency kind of way. But in the good way.

My father has been manufacturing baseball gloves for all the major brands for as long as I can remember. The gloves you’ve been buying from top baseball brands have been coming from my father’s factory. And in 2010, I started working at that same factory, eager to learn all about the family business.

And learn I did! I learned everything I could about all the ways to work with leather, from the limits of the material to its possibilities. I also learned about what it takes to make a glove, and how much of that cost is diverted to middlemen. Middlemen who don’t really affect your glove at all.

So in 2014, I put everything I learned into practice. I made Gloveworks. Using our extensive knowledge of the materials and process, we want to make it easy and affordable for you to create your own high quality customized baseball glove — all by cutting out the middleman.
Because when you complete your glove, your glove completes you.`,
          ourStoryMaterialDesc: `There’s a lot of jargon when it comes to leather, but really, there’s only two things you need to know: where does your raw leather come from and where was your leather tanned?

At Gloveworks, we’ve developed a close relationship with the tanneries we team up with. I’ve personally spent time with each of these tanneries and admire their work and share their vision. We consider them partners in our process, not just vendors in a supply chain. And because of this close relationship, I’m able to hand select the best materials for your glove. This includes the ability to select exclusive colors, textures, and even specific scents of leather. While others have to order through buyers and in bulk, I’m able to choose the exact leather you want for your perfect glove.

That’s why I’m proud to introduce you to them. They’re the real MVPs.

`,
          ourStoryMaterialSubTitle:
            "CCL Leather (中崎皮革, a.k.a. Nakazaki, CCL afterwards) :",
          ourStoryMaterialSubDesc: `  CCL leather was established in 1969 in Tainan, Taiwan. The company’s name 中崎 is a combination of initials 「中」 of 中華民國 (Taiwan) and 「崎」of 山崎化学 (Yamazaki Kagaku), a tannery based in Saitama, Japan.  Yamazaki is one of the founding members and first investors of CCL. CCL has built close ties with Japanese tanneries and American hide suppliers. It has been 49 years that CCL introduces fine leather for baseball gloves, but its effort in R&D to make it even better still continues.

`,
          ourStoryMaterialScondSubTitle: "GT Leather (ex-Nishioka leather) :",
          ourStoryMaterialSubDesc2: ` GT Leather, formerly known as Nishioka Leather Factory, started its business in 1964 as a tannery for baseball glove leather. When heavy chemical treatments were common to make leather flawless like plastics, GT Leather focused to develop the skills to save originality of natural leather – its beautiful texture and unique scent which are lost during chemical treatment. Now GT Leather is loved by baseball fans for its vibrant color collection and iconic leather scent. GT Leather has never compromised its standard in fine leather in its history of more than 50 years. Its matchless quality where durability and flexibility are perfectly harmonized clearly represent the company’s principal and belief. This is why GT Leather is the top leather factory in Japan, and will be the one for many years to come.
`,

          ourStoryMaterialBttomDesc: `(From the left) Goto-san and staffs at GT leather
factory, Drying hides at GT leather factory in Himeji,
Hyogo, Japan, Processing hide with worker in CCL leather`,
          ourStoryDesignDesc: `A baseball player’s glove is more than a piece of equipment. It’s an extension of their body. So naturally, your baseball glove should be as unique and personalized as your own arm.

Want a super stiff glove? We got you.
Want a glove with a personalized logo? We got you.
Want different colors on every single finger? We got you.
How about an infielder’s glove with finger sleeves like a pitcher’s? Oh you know we got you.

Once you’ve designed your glove, we have the best materials and craftsmen ready and waiting to make your vision a reality.

`,
          ourStoryDesignSubTitle1: "Glove Builder",
          ourStoryDesignSubDesc1: `
Design your perfect baseball glove with ease. Choose from 20 colors of leather and 24 kinds of webbing, in any combination.

`,
          ourStoryDesignSubTitle2: "Master Craftsmen",
          ourStoryDesignSubDesc2: `
The Gloveworks production team has over 20 years’ experience. Our master patternmakers Mr. Wei and Mr. Lee can also assist you in creating all new patterns and web styles for your one-of-a-kind glove.

`,
          ourStoryDesignSubTitle3: "State of the Art Embroidery",
          ourStoryDesignSubDesc3: `
Think of a picture, logo, or any other symbol that’s important to you. Our top-of-the-line embroidery equipment and in-house embroidery designer can add whatever you have in mind to your glove to make yours truly special and entirely personal.
`,
          ourStorySocalResponsibiltyDesc: `Baseball might just be a game, but it embodies the best in the human spirit: leadership, bravery, teamwork, and community. It doesn’t matter if you play on the sandlot or in a stadium, baseball brings people together.
We want to make sure those same ideals live in the heart of our business as well, which is why we are proud to support these social causes. Because we love baseball, and humans play baseball, so we love humans.

`,

          ourStorySocalResponsibiltySubTitle1: "More Than A Game:",
          ourStorySocalResponsibiltySubDesc1: ` Founded in 2010, More Than A Game strives to show athletes how much of a positive impact they can have on local communities. Through the game of baseball, MTAG has provided an outlet for athletes to unite, educate, and empower communities. Gloveworks helps the cause by providing gloves to help MTAG create teams, leagues, and providing the opportunity for youth to have positive experiences on the baseball diamond.
`,
          ourStorySocalResponsibiltySubTitle2: "Friedman Baseball:",
          ourStorySocalResponsibiltySubDesc2: ` In honor of Karen Friedman, this youth baseball camp is held annually in Fredericksburg, Virginia. A one-day camp, all proceeds are split equally between The Breast Cancer Research Foundation, and assisting King George High School students with college assistance through the Karen Friedman Memorial Scholarship. We are proud to help this great cause every year in support of both a cure, and furthering education among our youth.

`,

          ourStoryTeamGloveworksDesc1: `Meet Team Gloveworks. We don’t see this as just a sponsorship. It’s more of a partnership with a shared vision and passion. Check out their Instagrams to find out more about these great players.
`,
          ourStoryTeamGloveworksDesc2: `Team Gloveworks is not only a team, but a family. Because it’s more than the players. It’s all the fantastic people we are proud to partner with. And if you’re reading this, shucks, we’re talking about you too. So a big shout out to all of you guys!`,
          ourStoryTeamGloveworksDesc3: "The next Glove Guru. You’re the best.",
          ourStoryTeamGloveworksDesc4:
            "The world’s best relace specialist & the Official partner of Gloveworks US. That’s not an easy title to have. But you’ve earned it.",
          ourStoryTeamGloveworksDesc5:
            "Thank you Yokoyama-san, the official partner of Gloveworks Japan, You are my soul mate!",
          ourStoryTeamGloveworksDesc6:
            "Huge thank you to Tim for designing our wonderful Gloveworks logo.",
          ourStoryTeamGloveworksDesc7:
            "Also known as the Glove Cowboy. You’re the best (and only) cowboy I know. You inspire me daily.",
          ourStoryTeamGloveworksDesc8:
            "The best Japanese glove maker and our sensei. Okada-san, ありがとうございます.",
          ourStoryTeamGloveworksDesc9: "Thank you Mike, Our very first VIPs.",
          ourStoryTeamGloveworksDesc10:
            "Thank you Bobby Crosby and all of you Team Bobby & Team Benny!",
          ourStoryTeamGloveworksDesc11:
            "Must visit place in LA if you are a big fan of baseball, art and culture!",
          ourStoryTeamGloveworksDesc12:
            "Baseball guy traveled all around the world literally. Thanks god that I have a great friend like you. How great is he? He is on Wikepedia! And he is my best friend Yay!",

          customizeTab1: "BASE",
          customizeTab2: "SHELL",
          customizeTab3: "TRIM",
          customizeTab4: "PERSONALIZE",
          customizeTotal: "TOTAL",
          customizePurchase: "PURCHASE NOW",
          customizeType: "GLOVE TYPE",
          customizeSingleWelting: "Single Welting",
          customizeDoubleWelting: "Double Welting",
          customizeFirstBasemanMittg: "First Baseman Mitt",
          customizeCatchersMitt: "Catchers Mitt",
          customizeSoftball: "Softball",
          customizeLEATHERGRADE: "LEATHER GRADE",
          GAMEDAY: "GAME DAY",
          Koreantannedsteerhide: "Korean tanned U.S steer hide.",
          PROSTEERHIDE: "PRO STEERHIDE",
          Japanesetannedprosteerhide: "Japanese tanned U.S steer hide.",
          PROKIP: "PRO KIP",
          JapanesetannedEuropeankip: "Japanese tanned North American kip.",
          ThroWingHAND: "Throwing Hand",

          right: "RIGHT",
          left: "LEFT",
          size: "SIZE",
          customizeNotice:
            "Please bear in mind that the color of actual item may vary from the color displayed on your screen.",
          inspirations: "INSPIRATIONS",
          choosePreferred: "Please choose your preferred leather grade first.",
          findForMe:
            "* Please click here to find out how to choose the right pattern and size for me.",
          builderProvides:
            "Please clickthis link to see what's possible and addtional price other than the builder provides.",
          totalPriceNotice:
            "Items in your order may be subject to taxes, customs duties and fees levied by the destination country. The recipient of the shipment is responsible for all Import Fees.",
          inspirationsNotice:
            "Need some help for your glove? Please choose your preferred leather grade first. Depending on the leather type of your choice, you will find various Inspiration gloves we can offer. Please click the glove design you like most, and specify your throwing hand. Now, you are all set to go! Of course, you can amend personalization options such as web design and color. ",
          web: "Web",
          palm: "Palm",
          wrist: "Wrist",
          thumb: "Thumb",
          indexFinger: "Index Finger",
          middleFinger: "Middle Finger",
          ringFinger: "Ring Finger",
          pinky: "Pinky",
          fingerOption: "Finger Option",
          binding: "Binding",
          welting: "Welting",
          lacing: "Lacing",
          stitching: "Stitching",
          lining: "Lining",
          stamping: "Stamping",
          orderSummary: "Order Summary ",
          orderSummarySub:
            " *please ensure the accuracy of all glove builder selections. Once approved by the customer, changes cannot be made. ",
          addToCart: "ADD TO CART",
          specialRequests: "Special Requests",
          specialRequestsText:
            "Please describe additional special requests (such as glove firmness or softness, long laces, etc.) in the space below. Gloveworks will work to meet all special requests, but cannot guarantee they can be included with your build specifications and some requests may incur additional charges.",

          thumbwrist: "Thumb & Wrist",
          center: "Center",
          bridge: "Bridge",
          ushape: "U-shape",
          thumbside: "Thumb side",
          pinkyside: "Pinky side",
          target: "Target",
          fingerhood: "Finger Hood",

          footerText: `Company name: Asan corporation Inc.
          Business Registration N.:120-81-53663
          Mail-order sales License N. 2020-SeoulGeumcheon-1921
          Director: Kim Yoonsuk Web Master: Kim Dongouk
          Contact: donald@gloveworks.net
          Suite #704 IT Premier Tower, 88 Gasan Digital 1-ro, Geumcheon-gu, Seoul, Korea
          Tel: +82-2-839-8898
          `,
        },
      },
      ko: {
        translations: {
          headerMenuOurStory: "글러브웍스 이야기",
          headerMenuCustom: "오더글러브",
          headerMenuShop: "스토어",
          headerMenuGallery: "갤러리",
          headerMenuOrderTracking: "주문조회",
          dropBoxLogin: "로그인",
          orderTrackingTrackYourOrder: "주문조회",
          orderTrackingDesc:
            "주문조회를 위해 이메일 주소와 주문번호를 입력해주세요.",
          orderTrackingTrackMyOrder: "주문 조회하기",
          cartCouponCode: "쿠폰코드를 입력하세요.",
          cartApplyCoupon: "쿠폰 적용하기",
          cartDesc: "쿠폰 한 개가 상품 여러 개에 적용가능합니다.",
          cartTotal: "장바구니 합계",
          cartSubTotal: "총 합계",
          cartTaxDesc:
            "총 합계금액에는 배송비와 세금이 포함되어 있지 않습니다.",
          cartAgree:
            "오더 글러브 주문내역을 정확히 확인했으며 주문확정을 한 후에는 주문수정이나 취소가 불가능하다는 점에 동의합니다.",
          cartCheckOut: "주문하기",

          cartCheckPrepaid: `최근 15분안에 동일한 구매내역이 있습니다. \n 중복결제일 가능성이 있으니 구매내역을 확인해주세요.`,
          cartCheckPrepaidLeft: `구매내역 보기`,
          cartCheckPrepaidRight: `계속구매 하기`,

          Login: "로그인하기",
          LoginButton: "로그인",
          LoginDesc:
            "새로 방문하셨나요? 계정을 만들고 싶으시면 아래 버튼을 눌러주세요",
          LoginCreateAccount: "새 계정 만들기",
          LoginSignInFacebook: "페이스북으로 가입하기",
          LoginSignInGoogle: "구글 G+로 가입하기",
          LoginForgotDesc: "로그인에 문제가 있으신가요?",
          LoginForgotPassword: "비밀번호를 잊어버리셨나요",
          contactTitle: "문의하기",
          contactUsFirst: "궁금한 점이 있으시면 ",
          contactUsSecond: "으로 이메일을 보내주세요.",
          contactUsThrid: "가능한 한 빠른 시일 안에 답변 드리겠습니다.",
          contactUsForth:
            "글러브웍스 SNS를 팔로우하고 세일정보를 포함해 글러브웍스의 최신소식을 더욱 쉽게 받아보세요!",
          faqTitle: "자주 받는 질문",
          ShoppingPolicyTitle: "배송정책",
          TermsTitle: "이용약관",
          privateTitle: "개인정보처리방침",
          footerContactTitle: "문의하기",

          shippingAddress: "배송 주소",
          shippingMethod: "배송 방법",
          shippingBillingAddr: "청구지 주소",

          paymentInfoProduct: "상품",
          payemntInfoPaymetnMethod: "결제 방법",

          shoppingAddressFormDesc:
            "성함 및 주소는 영어로 입력해주세요. (한국어 입력 불가)",
          mainYours:
            "글러브웍스는 글러브가 당신에게 얼마나 중요한지 이해합니다. 당신을 위한 또 하나의 손이 되어야 하니까요. 우리에게는 최고의 소재와 최고의 기술로 당신을 위한 단 하나의 맞춤형 글러브를 만든다는 자부심이 있습니다. 그리고 불필요한 중간단계를 걷어냄으로써 훌륭한 글러브를 아주 놀라운 가격에 선사할 수 있죠. 가장 절실한 순간에 글러브웍스는 당신의 든든한 지원군이 되어줄 거예요. 우리의 글러브 빌더를 통해 꿈에 그리던 걸작품을 현실로 만들어보세요. 당신은 글러브를 완성하고 글러브는 당신을 완성해줄테니까요.",

          firstNameTrans: "First name",
          lastNameTrans: "Last name",
          companyTrans: "Company (Optional)",
          addressTrans: "Address",
          cityTrans: "City",
          countryTrans: "Country",
          stateTrans: "State",
          zipCodeTrans: "Zip Code",
          phoneNumberTrans: "Phone Number",

          orderTrackingStatusMenu1: "주문확정/검토",
          orderTrackingStatusMenu2: "가죽 선별 & 재단",
          orderTrackingStatusMenu3: "자수 및 봉제",
          orderTrackingStatusMenu4: "최종품질검사 및 배송준비",
          orderTrackingStatusMenu5: "발송",

          orderTrackingStatusMenu6: "ON DELIVERY",
          orderTrackingStatusMenu7: "DELIVERED",

          orderTrackingStatusDecs1: `주문한 글러브의 세부사항을 검토하는 단계로, 주문 후 영업일 기준 1-3일 정도 소요됩니다. 글러브 주문과 관련해 고객과의 상의가 필요한 경우(예: 로고가 저작권을 침해하거나 너무 복잡해서 자수가 불가능한 경우 등) 이메일이 발송됩니다. 주문 후 3일이 지나도록 아무런 연락이 없더라도 걱정 마세요. 주문이 확정됐다는 의미니까요! 이 단계가 지난 후에는 환불이나 주문변경이 어렵다는 점 양해 부탁드립니다.`,
          orderTrackingStatusDecs2: `드디어 글러브 만들기가 시작됩니다! 글러브 만들기는 소재를 선택해 하나 하나 잘라내는 데에서 출발합니다. 이후 이 조각들을 함께 모아 조립하고 꿰매게 되죠. 글러브웍스의 숙련된 기술자들은 가죽을 펴놓고 몰드로 형태를 따기에 가장 좋은 부위를 찾아냅니다. 글러브의 어느 부분이냐에 따라 알맞은 가죽 부위가 각기 다르니까요. 이 선별과정에서 가장 중요한 부분은 글러브 쉘의 손바닥과 등 부위입니다. 따라서 아주 조심스레 작업이 이뤄지죠. 재단된 가죽들은 잘 정리해서 자수 및 봉제 단계로 넘어갑니다. 이 단계에서 필요한 기간은 10-15일 정도입니다.`,
          orderTrackingStatusDecs3: `가죽 재단이 끝나고 났으니 이제 글러브 위에 패치를 붙이거나 로고와 이름을 새길 준비가 다 되었네요. 아래 버튼을 누르면 글러브의 자수사진을 살짝 엿볼 수도 있습니다. 자수가 모두 완성되면 드디어 각 부분을 하나로 잘 조립해 꿰맴으로써 글러브에 생명을 불어넣는 작업이 시작됩니다!
            *자수 미리보기 사진은 생산 여건이나 스케쥴, 상황 등에 따라 제공되지 않을 수도 있습니다.
            *현재 글러브 빌더에서 제공하는 옵션 외에 특별요청이 있는 경우 글러브 생산에 필요한 시간이 1-2주 가량 더 늘어날 수 있음을 양해 부탁드립니다.`,
          orderTrackingStatusDecs4: `글러브 생산이 완료됐습니다! 그리고 이제는 품질검사에 들어갈 차례. 이 검사에는 1일에서 3일 정도 걸립니다. 최종품질검사에서 우리는 글러브 구석구석을 살펴보고 아무런 이상이 없으면 배송을 준비합니다. 이 단계를 무사히 거치고 나면 이제 발송만 남았군요!`,
          orderTrackingStatusDecs5: `이 단계는 최종목적지에 따라 2-7일 정도 소요됩니다. 이제 글러브가 고객님의 집으로 향하고 있네요. 다음에서 조회가 가능합니다.`,
          orderTrackingStatusDecs6: `Delivery completed. Let’s play ball!`,

          orderTrackingEditButtonText: "주문수정",
          orderTrackingViewButtonText: "배송조회",

          ourStoryIntroducingTitle:
            "당신은 글러브를 완성시키고, 또 글러브는 당신을 완성시켜줄테니까",
          ourStoryMaterialTitle: "하늘 아래 같은 가죽은 없어",
          ourStoryDesignTitle: "원하는 건 무엇이든",
          ourStorySocalResponsibiltyTitle: "사람들을 하나로 묶어주는 야구",
          ourStoryTeamGloveworksTitle: "팀? 아니 가족!",

          ourStoryIntroducingDesc: `안녕, 난 도널드야. 글러브웍스를 처음 만든 사람이지. 그리고 내 몸엔 야구의 피가 흐르고 있어. 아, 그러니까 이게 되게 뻔한 소리로 들릴 지 모르지만 진짜 난 뼛속까지 야구, 야구, 야구거든.

우리 아버지는 1970년대부터 유명한 글러브 회사들을 위해 야구글러브를 만들어 왔어. 그러니까, 다들 어렸을 적에 야구 배우면서 꼈던 글러브들 있잖아. 그게 사실은 우리 아버지 공장에서 만든 거라고! 어른이 된 나는 바로 그 공장에서 일을 시작 했어. 야구라면 뭐든지 좋았거든.

그리고 어떻게 됐게? 나는 가죽을 직접 만져가며 배울 수 있는 건 닥치는 대로 다 배웠어. 가죽이 지닌 한계부터 그 가능성까지, 글러브를 만들려면 무엇이 필요한 지, 또 글러브 가격에 거품이 얼마나 끼는지도. 그러다가 결국 글러브 품질에는 전혀 도움도 되지 않는 비용이 단계단계마다 붙는다는 걸 깨달았지.

마침내 2014년이 됐어. 나는 내가 알게 된 모든 걸 실행에 옮겨 보기로 했지. 그렇게 글러브웍스가 탄생한 거야! 우리에겐 글러브를 만드는 재료와 방법에 대한 풍부한 지식이 있어. 우리가 이제 누구나 쉽게, 무엇보다도 합리적인 가격으로  최고의 커스텀 글러브를 가질 수 있게 해줄게. 거품 따윈 모두 걷어버리자고.

당신은 글러브를 완성시키고, 또 글러브는 당신을 완성시켜줄테니까.`,
          ourStoryMaterialDesc: `가죽 얘기만 나오면 온갖 현란한 표현들이 다 등장하지. 하지만, 사실은 말이야, 가죽에서 알아야할 거는 단 두 개라는 걸 잊지 마. 생가죽이 어디서 온 건지, 그리고 그 가죽을 어디서 염색했는지 라고.

글러브웍스는 함께 일하는 태너리, 즉 가죽공장과 아주 친밀한 관계를 맺고 있어. 각 태너리와 사사로울 정도로 충분히 함께 시간을 보내. 그러면서 늘 이들의 장인정신에 감동하지. 글러브웍스와 태너리 - 우리는 같은 곳을 바라 봐. 갑과 을, 아니면 벤더와 바이어의 관계가 아니라 글러브를 함께 만드는 파트너라고. 그리고 이러한 파트너십 덕에 글러브웍스는 글러브를 만들 수 있는 최고의 재료를 확보할 수 있어. 익스클루시브한 컬러와 질감, 그리고 특별한 가죽의 향을 택할 수 있는 정도로 말이야. 다른 브랜드들이 중개상을 통해 대량으로 주문을 해야만 할 때, 나는 우리 고객, 그러니까 바로 당신에게 완벽한 글러브를 만들어주기 위해 딱 맞아 떨어지는 가죽을 찾아낼 수 있는 거야.

그래서 우리의 자랑스러운 태너리를 꼭 소개하고 싶어. 우리의 진정한 챔피언들이지.

`,

          ourStoryMaterialSubTitle: "CCL 레더 a.k.a 나카자키Nakazaki: ",
          ourStoryMaterialSubDesc: ` CCL 레더는 1969년 대만 타이난 지역에서 설립됐습니다. CCL의 다른 이름인 ‘중기(中崎, 중국어로는 ‘충치’)’는 중화민국(中華民國)의 중(中)과 일본 사이타마 현에 위치한 태너리인 야마자키 카가쿠(山崎化学)의 기(崎)가 합쳐져 지어졌습니다. 야마자키는 CCL의 창업멤버이자 첫 투자자이며, 그 외에도  CCL은 일본 태너리들 및 미국 가죽 공급업체들과 돈독한 관계를 유지하고 있습니다. CCL는 50년 가까이 야구 글러브를 위한 고급 가죽을 공급해왔습니다. 그리고 더 좋은 품질의 제품을 만들기 위해 R&D에 아낌없는 노력을 쏟고 있습니다.

`,
          ourStoryMaterialScondSubTitle: "고토우제혁소: ",
          ourStoryMaterialSubDesc2: ` 고토우 제혁소의 전신인 니시오카 헤이 제혁소는 1964년에 창업하고 야구 글러브용 가죽 중심으로 만들어 온 내력이 깊은 탄나ー이었습니다.통상의 가죽 가공에서는 화학 약품을 사용하여 가죽에 여러가지 처리를 합니다.그러나, 그것은 동시에 가죽 본래의 풍채나 감촉이 없어져 버리는 것도 의미합니다.고토 제혁소는, 구태여 그러한 약품을 사용하지 않는 것으로, 가죽 그 자체가 가지는, 풍조, 터치감을 표현하는 "날개"의 가죽이야말로, 야구 글러브를 만든다고 믿고 있습니다.전통과 신념의 이와 같은 제혁소는, 내구성이나 유연성, 그리고 선명한 색상을 구현할 수 있는 것과 동시에, 가죽 그 자체의 품질을 나타내는 가죽을 만들어 낼 수 있는 확실한 기술력을 보유하고 있습니다.그것이 고토우 제혁소가 50년 이상, 일본 톱 제혁소로 자리를 지키던 이유입니다.
`,

          ourStoryMaterialBttomDesc: `(왼쪽부터) GT가죽공장에서 고토상과 직원들
염색을 자연 건조 시키는 과정(효고현 히메지에 위치)
가죽을 가공 중인 장인의 모습(대만 CCL공장)`,
          ourStoryDesignDesc: `야구선수에게 글러브는 단순히 장비가 아니야. 또 하나의 손이 되어야 하지. 그러니까 당연히 야구 글러브는 오직 당신만을 위해, 당신 손에 꼭 맞게 만들어져야 해.

진짜 단단한 글러브가 필요하다고? 걱정 마, 우리가 해줄게.

당신만의 로고가 들어간 글러브를 원해? 걱정 마, 우리가 해줄게.

손가락 하나하나마다 다른 색깔을 두르고 싶니? 걱정 마, 우리가 해줄게.

내야수용 글러브에 투수용 핑거후드를 넣고 싶다고? 아, 그런 것도 우리가 해주는 거 알지?

일단 디자인만 해. 나머지는 우리에게 맡기면 돼. 최고의 소재와 최고의 장인들이 그 꿈을 현실로 만들어 줄 거야.

`,
          ourStoryDesignSubTitle1: "글러브 빌더",
          ourStoryDesignSubDesc1: `
상상 속의 완벽한 글러브를 만드는 건 어렵지 않아. 20가지 색상의 가죽과 24종류의 웹을 원하는 대로 조합할 수 있으니까.

`,
          ourStoryDesignSubTitle2: "최고의 장인",
          ourStoryDesignSubDesc2: `
글러브웍스 생산팀은 20년 이상 손발을 맞춰 왔어. 패턴의 달인 미스터 웨이와 미스터 리는 세상에서 단 하나뿐인 당신의 글러브를 위해 새로운 패턴과 웹을 개발해주지.

`,
          ourStoryDesignSubTitle3: "자수라는 예술",
          ourStoryDesignSubDesc3: `
당신에게 의미 있는 그림, 로고, 아니 아무 심볼이나 떠올려봐. 글러브웍스의 자수 디자이너가 최고의 자수기계를 통해 당신 마음 속 바로 그 이미지를 글러브 위에 새겨줄 테니까. 그렇게 이 세상 단 하나 뿐인, 당신만의 특별한 글러브가 탄생하지.
`,
          ourStorySocalResponsibiltyDesc: `야구는 단순히 스포츠일 뿐일까? 아니, 야구에는 인간정신의 정수를 담겨 있어. 리더십, 용기, 팀워크, 그리고 공동체정신까지. 야구는 사람들을 하나로 묶어주지. 공을 던지는 장소가 동네 골목길이던, 스타디움이던 상관없어.
글러브웍스의 심장은 이 모든 정신들을 담고 있어. 이를 바탕으로 글러브웍스가 펼치는 다양한 사회공헌활동은 우리의 자랑이기도 해. 우리는 야구를 사랑하고, 사람은 야구를 하고, 고로 우리는 사람을 사랑하지.

`,

          ourStorySocalResponsibiltySubTitle1:
            "모어 댄 어 게임(More Than A Game):",
          ourStorySocalResponsibiltySubDesc1: ` 모어 댄 어 게임은 운동선수들이 지역사회에 얼마나 긍정적인 영향력을 미칠 수 있는지 보여주기 위해 2010년 설립됐어. MRAG는 선수들이 야구를 통해 지역사회를 하나로 묶어주고 교육시키며 자신감을 고취시켜줄 수 있는 장을 제공하지. 글러브웍스는 MTAG가 야구팀과 리그를 만들어 청소년들이 그라운드 위에서 긍정적인 경험을 할 수 있도록 지원하는 활동을 돕기 위해 글러브를 제공하고 있어.
`,
          ourStorySocalResponsibiltySubTitle2:
            "프리드만 베이스볼(Friedman Baseball):",
          ourStorySocalResponsibiltySubDesc2: ` 이 청소년 야구캠프는 캐런 프리드먼(Karen Friedman)을 기리며 매년 버지니아주 프레드릭스버그(Fredericksburg)에서 열려. 하루 동안 진행되는 이 캠프에서 조성된 기금은 유방암 연구재단(Breast Cancer Research Foundation)과 카렌 프리드만 기념 장학금(Karen Friedman Memorial Scholarship)에 전달돼. 그리고 카렌 프리드만 기념 장학금은 대학에 진학하는 킹 조지 고등학교(King George High School) 학생들을 위해 쓰이지. 우리는 난치병 치료를 지원하고 청소년들에게 더 나은 교육의 기회를 지원하는 이 훌륭한 활동에 기여하고 있다는 것이 자랑스러워.

`,
          ourStoryTeamGloveworksDesc1: `팀 글러브웍스를 만나봐. 우리가 맺고 있는 건 스폰서십이 아니야. 비전과 열정을 나누는 파트너십이지. 이 훌륭한 선수들과 더 가까워지고 싶다면 인스타그램을 방문해보도록.
`,
          ourStoryTeamGloveworksDesc2: `팀 글러브웍스는 단순히 팀이라 부를 수 없어. 우린 하나의 가족이야. 선수들부터 우리와 함께 하는 파트너들까지. 그리고 이 글을 읽는 당신, 그래 당신도 빼놓을 순 없지. 그러니 우리  팀 글러브웍스, 글러브 끼고 소리 질러!`,
          ourStoryTeamGloveworksDesc3:
            "차세대 글러브 구루. 그냥 더 이상 설명이 필요 없는 최고의 크루",
          ourStoryTeamGloveworksDesc4:
            "세계 최고의 레이싱 전문가. 이런 타이틀 아무나 다는 거 아니야. Davis라면 당연히 자격 있고 말고.",
          ourStoryTeamGloveworksDesc5:
            "글러브웍스 재팬의 공식파트너. 고마워요, 요코야마 상! 당신은 내 소울메이트라구요.",
          ourStoryTeamGloveworksDesc6:
            "글러브웍스 로고를 탄생시켜준 Tim에게 크나큰 감사를.",
          ourStoryTeamGloveworksDesc7:
            "Glove Cowboy 로도 불리는 사나이. 내가 아는 최고의 (그리고 유일한) 이 카우보이로부터 우리는 매일 영감을 얻지.",
          ourStoryTeamGloveworksDesc8:
            "일본 최고의 글러브 메이커. 그리고 우리의 스승이신 Okada 상, ありがとうございます.",
          ourStoryTeamGloveworksDesc9:
            "글러브웍스 최초이자 최고의 VIP인 Mike, 고마워요.",
          ourStoryTeamGloveworksDesc10:
            "Bobby Crosby와 Team Bobby, 그리고 Team Benny에게도 늘 땡큐!",
          ourStoryTeamGloveworksDesc11:
            "야구와 예술, 그리고 문화를 사랑하는 사람이라면 LA에서 꼭 가봐야하는 곳!",
          ourStoryTeamGloveworksDesc12:
            "문자 그대로 세계를 누비는 야구 사나이. 당신 같은 훌륭한 친구가 있다니 정말 행운이에요! 그가 얼마나 대단한 사람이냐고? 심지어 위키피디아에도 나온다고! 그런 사람이 내 베프라니..와우!",

          customizeTab1: "기본옵션",
          customizeTab2: "쉘 옵션",
          customizeTab3: "기타 색상",
          customizeTab4: "자수 및 스탬핑",
          customizeTotal: "총 금액",
          customizePurchase: "구매하기",
          customizeType: "글러브타입",
          customizeSingleWelting: "싱글웰팅",
          customizeDoubleWelting: "더블웰팅",
          customizeFirstBasemanMittg: "1루미트",
          customizeCatchersMitt: "포수미트",
          customizeSoftball: "소프트볼",
          customizeLEATHERGRADE: "가죽등급",
          GAMEDAY: "게임데이",
          Koreantannedsteerhide:
            "한국에 위치한 태너리에서 가공된 미국산 스티어하이드",
          PROSTEERHIDE: "프로스티어하이드",
          Japanesetannedprosteerhide:
            "일본에 위치한 태너리에서 가공된 미국산 스티어하이드",
          PROKIP: "프로킵",
          JapanesetannedEuropeankip:
            "일본에 위치한 태너리에서 가공된 북미산 킵가죽",
          ThroWingHAND: "좌·우투 선택",
          right: "우투",
          left: "좌투",
          size: "사이즈",
          customizeNotice: "실제 색상과 차이가 있을 수 있습니다.",
          inspirations: "인스퍼레이션",
          choosePreferred: "가죽등급을 먼저 선택해주세요",
          findForMe: "나에게 맞는 사이즈와 패턴 찾기",
          builderProvides: "추가옵션과 가격 알아보기",
          totalPriceNotice:
            "배송 받는 국가의 관세법에 따라 최종가격에 관부가세가 부과될 수 있습니다.",
          inspirationsNotice:
            "글러브를 만드는 데 도움이 필요하세요? 우선, 선호하는 가죽등급을 선택해주세요. 선택한 가죽등급에 따라 글러브웍스는 다양한 인스퍼레이션을 제시해 드립니다. 가장 선호하는 글러브 디자인을 클릭한 뒤 공을 던지는 손의 방향을 선택해주세요. 이제 모든 것이 준비됐네요! 물론 취향에 따라 웹 디자인이나 색상 등 여러 커스텀 옵션을 바꿔볼 수도 있습니다.",
          web: "웹",
          palm: "포구면",
          wrist: "손목",
          thumb: "엄지손가락",
          indexFinger: "집게손가락",
          middleFinger: "가운데손가락",
          ringFinger: "약손가락",
          pinky: "새끼손가락",
          fingerOption: "손가락 옵션",
          binding: "바인딩",
          welting: "웰팅",
          lacing: "끈피",
          stitching: "실색상",
          lining: "내피",
          stamping: "스탬프",
          orderSummary: "주문서확인",
          orderSummarySub:
            "*글러브가 제작에 들어가면 변경하실 수 없으니, 아래에 입력하신 내용이 모두 정확한지 최종확인부탁드립니다. ",
          addToCart: "장바구니에 담기",
          specialRequests: "추가요청사항",
          specialRequestsText:
            "추가로 요청할 사항(ex. 글러브 강도, 끈피 길게, 기타 사항 등) 이 있으시다면 이곳에 입력해주세요. 요청사항은 추가금이 발생할 수 도 있으며, 오더에 따라 불가능할 수 도 있다는 점 유의해주시고 필요시 따로 고객에게 이메일 연락을 드리겠습니다.",
          thumbwrist: "엄지&손목",
          center: "손가락패널",
          bridge: "브릿지",
          ushape: "웹주위U",
          thumbside: "엄지쪽부분",
          pinkyside: "새끼쪽부분",
          target: "타겟",
          fingerhood: "손가락 커버",

          footerText: `Company name: Asan corporation Inc.
          Business Registration N.:120-81-53663
          Mail-order sales License N. 2020-SeoulGeumcheon-1921
          Director: Kim Yoonsuk Web Master: Kim Dongouk
          Contact: donald@gloveworks.net
          Suite #704 IT Premier Tower, 88 Gasan Digital 1-ro, Geumcheon-gu, Seoul, Korea
          Tel: +82-2-839-8898
          `,
        },
      },
      ja: {
        translations: {
          headerMenuOurStory: "グラブワークスについて",
          headerMenuCustom: "カスタム",
          headerMenuShop: "ショップ",
          headerMenuGallery: "ギャラリー",
          headerMenuOrderTracking: "注文状況",
          dropBoxLogin: "ログイン",
          orderTrackingTrackYourOrder: "注文状況",
          orderTrackingDesc: "メールアドレスと注文番号を入力してください。",
          orderTrackingTrackMyOrder: "注文状況を確認",
          cartCouponCode: "クーポンコード",
          cartApplyCoupon: "クーポンを使用する",
          cartDesc: "クーポンの併用はできません。",
          cartTotal: "商品合計",
          cartSubTotal: "小計",
          cartTaxDesc: "小計には送料、消費税、関税などが含まれていません。",
          cartAgree:
            "注文内容に間違いがないことを確認し、注文確定後の変更やキャンセルは不可であることを了承します。",
          cartCheckOut: "決済",

          cartCheckPrepaid: `直前（15分内）に同アカウントから決済が成立しています。二重決済の可能性があります。このまま決済を続けますか。`,
          cartCheckPrepaidLeft: `注文履歴を確認する`,
          cartCheckPrepaidRight: `決済まで進む`,

          Login: "ログイン",
          LoginButton: "ログイン",
          LoginDesc:
            "アカウントをお持ちでない方は、新規登録をクリックしてください。",
          LoginCreateAccount: "新規登録",
          LoginSignInFacebook: "facebookアカウントでサインイン",
          LoginSignInGoogle: "Googleアカウントでサインイン",
          LoginForgotDesc: "ログインでお困りですか?",
          LoginForgotPassword: "パスワードをお忘れですか",
          contactTitle: "お問い合わせ",
          contactUsFirst: "ご質問等は",
          contactUsSecond: "までお問い合わせください。",
          contactUsThrid: "",
          contactUsForth:
            "グラブワークスの公式アカウントをフォローして、最新のニュースやイベントをチェックしてください。",
          faqTitle: "よくある質問",
          ShoppingPolicyTitle: "特定商取引法に基づく表記",
          TermsTitle: "利用規約",
          privateTitle: "個人情報保護方針",
          footerContactTitle: "お問い合わせ",

          shippingAddress: "お届け先",
          shippingMethod: "SHIPPING METHOD",
          shippingBillingAddr: "BILLING ADDRESS",

          paymentInfoProduct: "PRODUCT",
          payemntInfoPaymetnMethod: "PAYMENT METHOD",

          shoppingAddressFormDesc:
            "名前と住所は英語で入力してください。(日本語入力不可)",
          mainYours:
            "グラブワークスは、一人一人のグラブを大切に考えます。グラブはあなたのもう一つの手になるものだからです。私たちは自信を持って、最高の素材と最高の技術で、あなたのためのたった一つのカスタムグラブを作ります。優れた製品をリーズナブルな価格で提供するため、余計なものをそぎ落としました。思いを込めて作られたグラブは、あなたの心強い味方となることでしょう。グラブワークスのビルダーを利用して、理想を実現してみませんか。あなたがグラブを完成させ、グラブがあなたを完成させるのです。",

          firstNameTrans: "名",
          lastNameTrans: "姓",
          companyTrans: "会社名（お届け先が会社である場合）",
          addressTrans: "市区町村以降の住所",
          cityTrans: "市区町村",
          countryTrans: "国",
          stateTrans: "都道府県を選択",
          zipCodeTrans: "郵便番号",
          phoneNumberTrans: "携帯電話番号",

          orderTrackingStatusMenu1: "注文確認/検討",
          orderTrackingStatusMenu2: "レザーの選別と裁断",
          orderTrackingStatusMenu3: "刺繍と縫製",
          orderTrackingStatusMenu4: "最終品質検査及び配送準備",
          orderTrackingStatusMenu5: "発送",

          orderTrackingStatusMenu6: "ON DELIVERY",
          orderTrackingStatusMenu7: "DELIVERED",

          orderTrackingStatusDecs1: `注文したグラブの詳細事項を検討するステップで、注文後1-3営業日程度かかります。注文についてお客様とのご相談が必要な場合（例：ロゴの著作権侵害や、あまりに複雑なデザインのため刺繍が不可能である場合等）、メールを送付致します。ご注文後3日経っても連絡がない場合は注文が確定したということですので、ご心配の必要はありません。ご注文確定後はキャンセルや変更ができませんので、ご注意ください。`,
          orderTrackingStatusDecs2: `ついに製作がスタートします。グラブの製作は、素材を一つ一つ切り出すところから始まります。この後、ピースを組み合わせて一つにしていくのです。グラブワークスの熟練の職人たちはレザーを隈なく見て、形づくるのに最適な部分を探します。グラブの部位によって適する部分はそれぞれ異なるからです。この選別の過程で最も重要な部位は、シェルの捕球面と背面です。そのため、ここではとても慎重に作業が行われます。裁断したレザーを整えてから、刺繍や縫製へ移ります。このステップでの所要期間は10-15営業日程度です。`,
          orderTrackingStatusDecs3: `レザーの裁断が終わったので、グラブにパッチを付けたりロゴや名前を入れたりする準備ができました。下のボタンをクリックすると、グラブの刺繍イメージを見ることができます。刺繍が全て完成したら、いよいよそれぞれの部品を一つに組み立て、グラブに生命を吹き込む作業が始まります。
            ＊刺繍のプレビューは生産状況やスケジュール等によってご利用いただけない場合があります。
            ＊現在グラブビルダーで提供しているオプションの他にスペシャル・リクエストを承った場合、製品の完成が1-2週間ほど遅れることがあります。`,
          orderTrackingStatusDecs4: `グラブが完成しました。そして次は品質検査に入ります。この検査には1-3日程度かかります。最終品質検査でグラブの細部まで注意深く検査し、何も異常が見られなければ配送の準備を始めます。このステップを無事に終えれば、残るは発送のみです。`,
          orderTrackingStatusDecs5: `お届け先によって2-7日程度の時間がかかります。いよいよグラブはお客様の元へと向かいます。次でご照会いただけます。`,
          orderTrackingStatusDecs6: `Delivery completed. Let’s play ball!`,

          orderTrackingEditButtonText: "注文修正",
          orderTrackingViewButtonText: "配送追跡",

          ourStoryIntroducingTitle:
            "WHEN YOU COMPLETE YOUR GLOVE, YOUR GLOVE COMPLETES YOU",
          ourStoryMaterialTitle: "NOT ALL LEATHER IS CREATED EQUAL",
          ourStoryDesignTitle: "WHATEVER YOU WANT, WE GOT YOU",
          ourStorySocalResponsibiltyTitle: "BASEBALL BRINGS PEOPLE TOGETHER",
          ourStoryTeamGloveworksTitle: "NOT ONLY A TEAM, BUT A FAMILY",

          ourStoryIntroducingDesc: `やあ、僕はドナルド。グラブワークスの創設者だよ。僕の身体には野球の血が流れてる。すごくありきたりの話になってしまうかもしれないけど、本当に僕は骨の髄まで野球、野球、野球人間なんだ。

僕の父さんは1970年代から有名なグラブ会社のためにグラブを作り続けてきた。つまり皆が子供の頃はめていたグラブたちさ。それって実は僕の父さんの工場で作られたものだったわけ!大人になった僕はすぐにその工場で仕事を始めた。野球のことだったら何でも良かったんだ。

それからどうなったと思う？僕は革を直に触りながら、学べるものは片っ端から学んだ。革の限界からその可能性まで。グラブを作るためには何が必要なのか、グラブの価格にどれだけの数字が水増しされているのかも。そのうちグラブの質には全く関係ないコストが製作過程で上乗せされていたことに僕は気付いたのさ。

それから2014年。僕は僕が知る全てのことを実行に移すことにした。こうしてグラブワークスが誕生した!僕たちにはグラブを作る材料と方法について豊富な知識がある。僕たちが誰でも気軽に、何よりも合理的な価格で最高のカスタムグラブを手にできるようにしてあげるよ。水増しなんて全く無しでね。`,
          ourStoryMaterialDesc: `革のことになるととにかく色んな表現で騒ぎ立てる。でも実は革について知るべきことはたったの二つだということを忘れないでほしい。生の革がどこから来たのか、その革をどこで染め上げたのか。

グラブワークスは共に働くタンナー、つまり革工場ととても親密な関係を結んでいる。それぞれのタンナーとプライベートでも一緒に過ごすほど十分な時間を共にする。そしていつも彼らの匠魂に感動するんだ。グラブワークスとタンナー。僕たちは同じ位置にいる。上下関係でもベンダーとバイヤーの関係でもなく、グラブを共に作るパートナーなんだ。こういうパートナーシップのお陰でグラブワークスはグラブを作る最高の材料を確保できる。唯一無二なカラーと質感、それと特別な革の匂いを選べるほどにね。他のブランドたちが仲介業者を通して大量に注文をしなければならないとき、僕たちはお客様に、つまりは貴方のために完璧なグラブを作るのにぴったりの革を見つけることができるわけ。

僕たちの誇りのタンナーを紹介したい。彼らが真のチャンピオンたちさ。

`,

          ourStoryMaterialSubTitle: "中崎皮革 (CCL Leather) : ",
          ourStoryMaterialSubDesc: `中崎皮革は1969年、台湾の台南で設立されました。 社名「中崎」とは、「中華民国」の「中」と「山崎化学」の「崎」を組み合わせた名前です。日本の埼玉県に位置する革のタンナーだった山崎化学が初期投資をし、中崎皮革と共に設立しました。中崎皮革は49年間、日本のタンナーやアメリカのサプライヤーとの継続的な協力を通じて野球グラブ専用の革を開発及び生産をしています。

`,
          ourStoryMaterialScondSubTitle: "ごとう製革所 : ",
          ourStoryMaterialSubDesc2: `ごとう製革所の前身である西岡和平製革所は1964年に創業し、野球グラブ用の革中心に製造してきた由来の深いタンナーでした。通常、革の加工では化学薬品を用いて革に様々な処理を施します。しかしそれは同時に革本来の風合いや感触が失われてしまうことも意味します。ごとう製革所はあえてそれらの薬品を使用しないことで、革そのものが持つ風合いを表現する「素上げ」の革こそが野球グラブを作るのだと信じています。伝統と信念を持つごとう製革所は、耐久性や悠然性、そして鮮明なカラーの具現ができると同時に、革本来の品質が表れる革を生み出せる確かな技術力を持っています。これがごとう製革所が50年以上日本トップ製革所としての座を守ってきた理由です。
`,

          ourStoryMaterialBttomDesc: `【左から】ごとう製革所の後藤さんと職人。
兵庫県姫路市に所在するごとう製革所。
台湾の中崎皮革で職人が革を加工する姿。`,
          ourStoryDesignDesc: `野球選手たちにとってグラブは単なる装備じゃない。もう一つの手であるべきだ。だからこそ野球グラブは自分のためだけの、自分の手にぴったりフィットするものでなきゃ。

本当に頑丈なグラブが必要だって？心配しないで、僕たちがやるよ。

自分だけのロゴが入ったグラブが欲しい？心配しないで、僕たちがやるよ。

指一本一本に違うカラーを纏いたい？心配しないで、僕たちがやるよ。

内野手用のグラブに投手用の指カバーを付けたいって？あぁ、そんなのも僕たちがやるよ、わかってるだろ？

まずはデザインだけして。あとは僕たちに任せて。最高の素材と最高の匠たちがその夢を現実にしてくれる。

`,
          ourStoryDesignSubTitle1: "グラブビルダー",
          ourStoryDesignSubDesc1: `
空想のなかの完璧なグラブを作るのは難しくない。20通りのカラーの革と24種類のウェブを望むままに組み合わせることができるからさ。

`,
          ourStoryDesignSubTitle2: "最高の匠",
          ourStoryDesignSubDesc2: `
グラブワークスの製造チームは20年以上も一緒にやってきた。パターンの達人、ミスター・ウェイとミスター・リーはこの世にたった一つの貴方のグラブのために新しいパターンとウェブを作り出すんだ。

`,
          ourStoryDesignSubTitle3: "刺繍という芸術",
          ourStoryDesignSubDesc3: `
貴方にとって意味がある絵、ロゴ、いやシンボルでも何でも思い浮かべてみて。グラブワークスの刺繍デザイナーが最高の機械で、貴方の心に浮かんだイメージをそのままグラブに再現する。こうしてこの世でたった一つの、貴方だけのグラブが生まれるんだ。
`,

          ourStorySocalResponsibiltyDesc: `野球はただのスポーツだろうか？いいや、野球には人間の精神の神髄が見て取れる。リーダーシップ、勇気、チームワーク、そして共同体精神。野球は人を一つにする。球を投げる場所が町の路地だろうが、スタジオだろうが、関係なく。

グラブワークスのハートにはこの全ての精神が込められている。そのハートでグラブワークスが繰り広げる様々な社会貢献活動は僕たちの自慢さ。僕たちは野球を愛し、皆は野球をする。つまり僕たちは皆を愛するのさ。

`,

          ourStorySocalResponsibiltySubTitle1: "More Than A Game:",
          ourStorySocalResponsibiltySubDesc1: ` アスリートが社会にどれほど良い影響を与えることができるかを示すため2010年に設立された。MTAGは野球を通じて人々の団結と教育の機会を提供し、地域を盛り上げている。グラブワークスは、MTAGが青少年のために野球チームやリーグを作り、彼らがグラウンドでかけがえのない経験を得るための支援活動に協力し、グラブを提供している。
`,
          ourStorySocalResponsibiltySubTitle2: "Friedman Baseball:",
          ourStorySocalResponsibiltySubDesc2: ` この青少年野球キャンプは、カレン・フリードマン(Karen Friedman)を称えて毎年バージニア州フレデリックスバーグ(Fredericksberg)で開かれる。このキャンプで集まった収益は、乳がん研究財団(Breast Cancer Research Foundation)とカレン・フリードマン記念奨学金(Karen Friedman Memorial Scholarship)に充てられる。そしてカレン・フリードマン記念奨学金は、大学に進学するキングジョージ高校(King George High School)の学生たちのために使われる。私たちは、難病治療を支援し青少年たちによりよい教育の機会を与えるという素晴らしい活動に寄与していることを誇らしく思う。

`,
          ourStoryTeamGloveworksDesc1: `チームグラブワークスにようこそ。僕たちが繋がっているのはスポンサーじゃない。ビジョンと情熱を分かち合うパートナーだ。この素晴らしい選手たちのことをもっと知りたければインスタグラムを見てね。
`,
          ourStoryTeamGloveworksDesc2: `チームグラブワークスは単純なチームじゃない。僕たちは一つの家族だ。選手からはじまり、僕たちと共にするパートナーまで。それから今これを読んでいる貴方、そう貴方も例外じゃないよ。さあ、我らがグラブワークス、グラブをはめて叫ぶんだ!`,
          ourStoryTeamGloveworksDesc3:
            "次世代のグラブマスター。それ以上の説明は何も要らない、最高のクルー。",
          ourStoryTeamGloveworksDesc4:
            "世界最高のレーシング専門家。こういうタイトルは誰でももらえるものじゃない。Davisなら勿論その資格ありだ。",
          ourStoryTeamGloveworksDesc5:
            "グラブワークスジャパンの公式パートナー。ありがとう横山さん！あなたは僕のソウルメイトです。",
          ourStoryTeamGloveworksDesc6:
            "グラブワークスのロゴを誕生させたTimに大きな感謝を。",
          ourStoryTeamGloveworksDesc7:
            "グラブカウボーイと呼ばれる男。僕が知る限り最高の(そして唯一の)このカウボーイから僕たちは毎日インスピレーションを受けるんだ。",
          ourStoryTeamGloveworksDesc8:
            "日本最高のグラブメーカー。そして僕たちの師匠であるOkadaさん、ありがとうございます。",
          ourStoryTeamGloveworksDesc9:
            "グラブワークスの最初の、そして最高のVIP、Mike、ありがとう。",
          ourStoryTeamGloveworksDesc10:
            "Bobby CrosbyとTeam Bobby、そしてTeam Bennyにも。いつもサンキュー!",
          ourStoryTeamGloveworksDesc11:
            "野球と芸術そして文化を愛する者なら、LAに行ったら絶対に外せないスポット！",
          ourStoryTeamGloveworksDesc12:
            "文字通り、世界中を飛び回る野球人。こんなに素晴らしい友人を持って、本当にラッキーだ！彼がどれだけすごい人かって？Wikipediaまであるんだから！そんな人が僕の親友だなんて・・・！",

          customizeTab1: "基本スタイル",
          customizeTab2: "本体",
          customizeTab3: "細部",
          customizeTab4: "その他",
          customizeTotal: "合計",
          customizePurchase: "確認画面へ",
          customizeType: "グラブスタイル",
          customizeSingleWelting: "シングルハミ出し",
          customizeDoubleWelting: "ダブルハミ出し",
          customizeFirstBasemanMittg: "ファーストミット",
          customizeCatchersMitt: "キャッチャーミット",
          customizeSoftball: "ソフトボール",
          customizeLEATHERGRADE: "レザーグレード",
          GAMEDAY: "ゲームデイ",
          Koreantannedsteerhide: "韓国鞣しによるU.Sステアハイドレザー",
          PROSTEERHIDE: "プロステアハイド",
          Japanesetannedprosteerhide: "日本鞣しによるU.Sステアハイドレザー",
          PROKIP: "プロキップ",
          JapanesetannedEuropeankip: "日本鞣しによる北米キップレザー",
          ThroWingHAND: "利き手",
          right: "右投げ",
          left: "左投げ",
          size: "サイズ",
          customizeNotice:
            "실実際の商品の色は、画面に表示されている色と異なる場合がありますのでご注意ください。",
          inspirations: "まずはお好みの革グレードをお選びください。",
          choosePreferred:
            "*ご自身に合うパターンとサイズを選択する方法につきましては、ここをクリックしてください。",
          findForMe:
            "ご注文の商品には税金、関税、手数料がかかる場合があります。 荷物の受取人は料金のお支払いをお願いします。",
          builderProvides:
            "シュミレーションで反映されないその他可能オプションにつきましては、こちらのリンクをクリックしてご確認ください。",
          totalPriceNotice:
            "ご注文の商品には税金、関税、手数料がかかる場合があります。 荷物の受取人は料金のお支払いをお願いします。",
          inspirationsNotice:
            "オーダーグラブについてお困りでしょうか？ まずはお好みの革グレードをお選びください。 お好みの革の種類に応じて、さまざまなインスピレーショングラブをご用意しています。 一番好きなグラブのデザインをクリックして、投げ手を指定してください。 これで、準備が整いました。 もちろん、Webデザインや色などのオプションを修正することも可能です。",
          web: "ウェブ",
          palm: "手の平",
          wrist: "手首",
          thumb: "親指",
          indexFinger: "人差し指",
          middleFinger: "中指",
          ringFinger: "薬指",
          pinky: "小指",
          fingerOption: "指あて・カバー",
          binding: "ヘリ革",
          welting: "ハミ出し",
          lacing: "レース",
          stitching: "ステッチ",
          lining: "平裏",
          stamping: "刻印",
          orderSummary: "注文内容",
          orderSummarySub:
            "*グラブの製作に入ると変更ができないので、下記のご注文の内容に間違いがないのか、もう一度ご確認をお願いします。",
          addToCart: "カートに入れる",
          specialRequests: "追加のお願い",
          specialRequestsText:
            "追加のお願いがある際にはこちらにご記載をお願いします。ご記載して頂いたお願いは追加の費用が発生する場合がございます。又、ご注文の内容によっては製作ができない場合があり、その際にはお客様にメールにてご連絡させて頂きます。",

          thumbwrist: "親指と手首",
          center: "センターパネル",
          bridge: "ブリッジ",
          ushape: "ウェブ周囲U",
          thumbside: "親指サイド",
          pinkyside: "ピンキーサイド",
          target: "ターゲット",
          fingerhood: "指カバー",

          // OUTLIER - 20240122 - 3번
          // footerText : `グラブワークスジャパン株式会社
          // 東京都港区芝浦4－10－1－3713
          // 〒108-0023
          // (050) 5479-8973
          // donald@gloveworks.net
          // Mail: gloveworks.jp@gloveworks.net
          // `,
          footerText: `グラブワークスジャパン株式会社
          東京都港区芝浦4－10－1－3713
          〒108-0023
          (050) 5479-8973
          Mail: donald@gloveworks.net
          `,
        },
      },
    },
    fallbackLng: "en", // 사용자의 언어가 번역 할수 없을때 사용되는 언어

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export default i18n;
