import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import shortid from 'shortid';
import HoverBox from '../../components/hoverBox/HoverBox';
import { DeleteData } from '../../common/S3';
import { getItem, removeItem } from '../../common/StorageUtils';
import { getCookie } from '../../common/Utils';
import _Fetch from '../../common/Fetches';
import { Consumer } from '../../Context';
import { KR_URL , JP_URL, EN_URL} from "../../common/Constants";
const COUNTRY = ['ENG', '한국어', '日本語'];
const COUNTRY_DROP = ['ENGLISH', '한국어', '日本語'];
const down = '/svg/arrow-down.svg';

function checkDefaultLng(str) {
  switch (str) {
    case 'ko':
      return 2;
    case 'ja':
      return 3;
    default:
      return 1;
  }
}

function checkeLng(i) {
  switch (i) {
    case 2:
      window.sessionStorage.setItem('lang', 'ko');
      // document.location.href = KR_URL;
      return 'ko';
    case 3:
      window.sessionStorage.setItem('lang', 'ja');
      // document.location.href = JP_URL;
      return 'ja';
    default:
      window.sessionStorage.setItem('lang', 'en');
      // document.location.href = EN_URL;
      return 'en';
  }
}
function normalCheckeLng(i) {
  console.log(i)
  switch (i) {
    case 2:
      return 'ko';
    case 3:
      return 'ja';
    default:
      return 'en';
  }
}

class LangDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      select: checkDefaultLng(window.sessionStorage.getItem('lang')),
      // select: checkDefaultLng(props.i18n.language),
      isOpen: false,
    };

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  judgmentCardData = async (chooseLang) => {
    if (chooseLang === window.sessionStorage.getItem('lang')) return null;
    const userData = getCookie('userData') === '' ? null : JSON.parse(getCookie('userData'));
    if (userData) {
      const api = getItem('restAPI').model_desc;
      const res = await _Fetch(api.cart_get_my, `?pid_user=${userData.user.pid_user}`, []);
      console.log('결과값', res);
      if (res.length > 0) {
        const results = res.map(async (item) => {
          const itemBody = JSON.parse(item.body);
          if (item.b_custom === 1) {
            console.log(itemBody);
            const promises = itemBody.images.map(async (req) => {
              try {
                return await DeleteData(req.Key);
              } catch (e) {
                return console.log(e);
              }
            });

            await Promise.all(promises);

            if (itemBody.glove.wristPersonalizationLogo) {
              await DeleteData(itemBody.glove.wristPersonalizationLogo.image.s3.Key);
            }
          }
          const body = {
            cart: {
              pid_cart: item.pid_cart,
              pid_user: userData.user.pid_user,
            },
          };
          const promise = await _Fetch(api.cart_delete, '', body);
          return promise;
        });

        const done = await Promise.all(results);
        return done;
      }
    }
    return removeItem('cart');
  };

  componentWillReceiveProps(nextProps) {
    console.log('헤더 lang Box', nextProps.i18n.language);
    this.setState({ select: checkDefaultLng(nextProps.i18n.language) });
  }

  componentDidMount() {
    this.checkUrlLanguage();
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }
  checkUrlLanguage =  async () => {
    const { i18n, actions } = this.props;
    const location = window.location.origin;
    const urlCheck = () => {
      switch (location) {
        case KR_URL:
          return 1;
        case JP_URL:
          return 2;
        default:
          return 3;
      }
    }
    this.changeLanguage(urlCheck())
  }
  handleDocumentClick(event) {
    if (!this.node.contains(event.target)) {
      if (this.state.isOpen) {
        this.setState({ isOpen: false });
      }
    }
  }

  handleMouseDown(event) {
    if (event.type === 'mousedown' && event.button !== 0) return;
    event.stopPropagation();
    event.preventDefault();

    if (!this.props.disabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }
  changeLanguageOnClick = (i) => {
    const pathname =  window.location.pathname ?  window.location.pathname : '';
    const search =  window.location.search ? window.location.search : '';
    const path = pathname + search;
    this.changeLanguage(i);
    if(i === 1) return document.location.href = KR_URL + path;
    if(i === 2) return document.location.href = JP_URL + path;
    document.location.href = EN_URL + path;
  }
  changeLanguage(i) {
    const { i18n, actions } = this.props;
    this.setState({ select: i + 1, isOpen: false }, async () => {
      await this.judgmentCardData(normalCheckeLng(i + 1));
      actions.reset();
      i18n.changeLanguage(checkeLng(i + 1));
    });
  }

  renderDropBox() {
    return COUNTRY_DROP.map((item, i) =>
      (<HoverBox
        key={shortid.generate()}
        onClick={() => this.changeLanguageOnClick(i)}
        hoverBoxStyles={i === 0 ? hoverBoxStyles : hoverBoxStylesHan}
        choose={(i + 1) === this.state.select}
      >
        {item}
      </HoverBox>));
  }

  render() {
    return (
      <div
        style={styles.container}
        ref={arg => this.node = arg}
      >
        <div
          role="button"
          tabIndex="0"
          style={styles.innerCotainer}
          onMouseDown={e => this.handleMouseDown(e)}
          onTouchEnd={e => this.handleMouseDown(e)}
        >
          <p style={styles.selectText(this.state.select)}>{COUNTRY[this.state.select - 1]}</p>
          <img
            src={down}
            style={styles.img}
            alt=""
          />
        </div>

        <div style={styles.dropBox(this.state.isOpen)}>
          {this.state.isOpen ? this.renderDropBox() : null}
        </div>

      </div>
    );
  }
}

const hoverBoxStyles = {
  container: (isHovering, isChoose) => ({
    width: '100%',
    height: 24,
    backgroundColor: isHovering || isChoose ? '#edd167' : 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    color: '#251f1b',
    fontSize: 11,
    fontWeight: 300,
  },
};

const hoverBoxStylesHan = {
  container: (isHovering, isChoose) => ({
    width: '100%',
    height: 24,
    backgroundColor: isHovering || isChoose ? '#edd167' : 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    color: '#251f1b',
    fontSize: 11,
    fontWeight: 300,
  },
};

const styles = {
  container: {
    width: 60,
    marginLeft: 24,
    cursor: 'pointer',
    position: 'relative',
  },
  selectText: select => ({
    fontFamily: select === 1 ? 'Metropolis' : 'SpoqaHanSans',
    fontSize: 13,
    color: '#251f1b',
    marginLeft: 5,
  }),
  innerCotainer: {
    width: '100%',
    height: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropBox: isOpen => ({
    display: isOpen ? 'block' : 'none',
    position: 'absolute',
    width: '100%',
    height: 72,
    backgroundColor: '#fff',
    zIndex: 255,
    border: 'solid 1px #ece9e9',
  }),
};

LangDropdown.propTypes = {
  disabled: PropTypes.bool,
  i18n: PropTypes.object,
  actions: PropTypes.object,
};

LangDropdown.defaultProps = {
  disabled: false,
  i18n: {},
  actions: null,
};

const defaultMapContextToProps = value => ({
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withTranslation('translations')(LangDropdown));
