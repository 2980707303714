import React, { Component } from 'react';

import _Fetch from './Fetches';
import { getItem } from './StorageUtils';

const WithMutilRequest = arr => WrappedComponent => class extends Component {
  state = {
    data: null,
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    if (typeof arr !== 'undefined') {
      const promises = arr.map(async (req, i) => {
        try {
          const api = getItem('restAPI')[req.database][req.api];
          const res = await _Fetch(api, req.query, req.body);
          return res;
        } catch (e) {
          return console.log('Multi 레스트풀 API 데이터 가져오지 못했습니다.');
        }
      });

      const promisesArr = await Promise.all(promises);

      const results = {};
      promisesArr.map((item, i) => results[arr[i].api] = item);

      this.setState({ data: results });
    }
  }

  render() {
    const { data } = this.state;
    // console.log('WrappedComponent', this);
    return (
      <WrappedComponent {...this.props} data={data} />
    );
  }
};

export default WithMutilRequest;
