import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';

import HoverBox from '../../components/hoverBox/HoverBox';
import { getCookie } from '../../common/Utils';

const menu = ['Order History', 'Address Book', 'Log Out'];

class Dropbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.node = {};
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }

  handleDocumentClick(event) {
    if (!this.node.contains(event.target)) {
      if (this.state.isOpen) {
        this.setState({ isOpen: false });
      }
    }
  }

  handleMouseDown(event) {
    if (event.type === 'mousedown' && event.button !== 0) return;
    event.stopPropagation();
    event.preventDefault();

    if (!this.props.disabled) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  }

  renderRow() {
    return menu.map((item, i) => (
      <HoverBox
        role="button"
        tabIndex="0"
        key={shortid.generate()}
        onClick={() =>
          this.setState({ isOpen: false }, () =>
            (i === 2 ? this.props.onHome() : this.props.onMypage(i + 1)))
        }
        hoverBoxStyles={hoverBoxStyles}
        choose={i + 1 === this.state.choose}
      >
        {item}
      </HoverBox>
    ));
  }

  render() {
    const { t } = this.props;
    return (
      <div style={styles.container} ref={node => (this.node = node)}>
        <div
          role="button"
          tabIndex="0"
          style={styles.valueBox}
          onMouseDown={
            getCookie('userData')
              ? e => this.handleMouseDown(e)
              : () => this.props.onLogin()
          }
          onTouchEnd={
            getCookie('userData')
              ? e => this.handleMouseDown(e)
              : () => this.props.onLogin()
          }
        >
          {getCookie('userData') ? 'MY ACCOUNT' : t('dropBoxLogin')}
        </div>

        <div style={styles.itemBox}>
          {this.state.isOpen ? this.renderRow() : null}
        </div>
      </div>
    );
  }
}

const hoverBoxStyles = {
  container: (isHovering, isChoose) => ({
    width: '100%',
    height: 40,
    backgroundColor: isHovering || isChoose ? '#edd167' : '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    color: '#251f1b',
    fontSize: 14,
    fontWeight: 300,
  },
};

const styles = {
  container: {
    position: 'relative',
    zIndex: 1,
  },
  valueBox: {
    width: 140,
    fontFamily: 'Roboto',
    fontSize: 14,
    letterSpacing: 2,
    color: '#251f1b',
    cursor: 'pointer',
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontWeight: 300,
  },
  itemBox: {
    marginTop: -1,
    overflowY: 'auto',
    position: 'absolute',
    top: '100%',
    width: 140,
    WebkitOverflowScrolling: 'touch',
  },
};

Dropbox.propTypes = {
  disabled: PropTypes.bool,
  onHome: PropTypes.func,
  onMypage: PropTypes.func,
  onLogin: PropTypes.func,
  t: PropTypes.func,
};

Dropbox.defaultProps = {
  disabled: false,
  onHome: () => {},
  onMypage: () => {},
  onLogin: () => {},
  t: () => {},
};

export default withTranslation('translations')(Dropbox);
