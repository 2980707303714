import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

const CustomImageButtons = ({
  title,
  className,
  images,
  currentCustomImage,
  onClick,
}) => (
  <div className={className}>
    <h4>{title}</h4>
    <div className="images-wrap clearfix">
      {images.map(image =>
        (<div
          key={image.id}
          className={classNames('custom-image-button', { active: currentCustomImage && currentCustomImage.id === image.id })}
          onClick={() => onClick(image)}
        >
          <img
            src={image.image.url}
            alt=""
          />
          <div className="name">{image.name}</div>
        </div>))}
    </div>
  </div>
);

const mapStateToProps = state => ({
  images: state.builder.customImages,
});

export default connect(mapStateToProps)(CustomImageButtons);
