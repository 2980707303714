import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const Center = ({
  customPythons,
  indexCurrentCustomPython,
  onIndexCustomPythonClick,
  middleCurrentCustomPython,
  onMiddleCustomPythonClick,
  ringCurrentCustomPython,
  onRingCustomPythonClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="CENTER INDEX COLOR"
      className="center-index-color-wrap"
      images={customPythons}
      currentCustomImage={indexCurrentCustomPython}
      onClick={onIndexCustomPythonClick}
    />
    <CustomPythonButtons
      title="CENTER MIDDLE COLOR"
      className="center-middle-color-wrap"
      images={customPythons}
      currentCustomImage={middleCurrentCustomPython}
      onClick={onMiddleCustomPythonClick}
    />
    <CustomPythonButtons
      title="CENTER RING COLOR"
      className="center-ring-color-wrap"
      images={customPythons}
      currentCustomImage={ringCurrentCustomPython}
      onClick={onRingCustomPythonClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { centerIndexPython, centerMiddlePython, centerRingPython } = state.glove;

  return {
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    indexCurrentCustomPython: centerIndexPython,
    middleCurrentCustomPython: centerMiddlePython,
    ringCurrentCustomPython: centerRingPython,
  };
};

const mapDispatchToProps = dispatch => ({
  onIndexCustomPythonClick: color => dispatch(updateGlove({ centerIndexPython: color })),
  onMiddleCustomPythonClick: color => dispatch(updateGlove({ centerMiddlePython: color })),
  onRingCustomPythonClick: color => dispatch(updateGlove({ centerRingPython: color })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Center), 'fingerSide');
