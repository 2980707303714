import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../components/input/Input';
import DivButton from '../../components/button/DivButton';

class AccountAddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: 0,
    };

    this.firstNameInput = null;
  }

  render() {
    const { getFirstName } = this.props;

    return (
      <div style={this.props.style}>
        <div style={styles.nameBox}>
          <DivButton
            style={styles.halfInputBox(this.state.focus === 1)}
            // onClick={() => this.firstNameInput.focus()}
          >
            <p style={styles.inputTitle}>Name</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 1 })}
              ref={comp => this.firstNameInput = comp}
              style={styles.input(280)}
              onChange={(firstName) => {
                if (firstName) {
                  this.firstNameInput.value = firstName;
                  return getFirstName(firstName);
                }
                return this.firstNameInput.value = '';
              }}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>
        </div>
      </div>
    );
  }
}

AccountAddressForm.propTypes = {
  style: PropTypes.object,
  getFirstName: PropTypes.func,
};

AccountAddressForm.defaultProps = {
  style: {},
  getFirstName: () => {},
};

const styles = {
  halfInputBox: isFocus => ({
    width: 300,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
  }),
  inputTitle: {
    fontSize: 12,
    color: '#515356',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    marginTop: 6,
  },
  input: width => ({
    width,
    marginTop: 5,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '#251f1b',
    fontSize: 16,
  }),
  nameBox: {
    width: 610,
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullInputBox: isFocus => ({
    width: 610,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
    marginTop: 20,
  }),
  countryInputBox: (width, isFocus) => ({
    width,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
  }),
  selcet: width => ({
    width,
    marginTop: 5,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '#251f1b',
    fontSize: 16,
    transform: 'translate(-7px, 0px)',
    MsTransform: 'translate(-7px, 0px)',
    Webkittransform: 'translate(-7px, 0px)',
  }),

  checkBox: {
    width: 12 + 5 + 129,
    display: 'flex',
    alignItems: 'center',
    marginTop: 21,
  },
  ckecedText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#666666',
    marginLeft: 5,
  },
  btnsBox: {
    width: 610,
    position: 'absolute',
    marginTop: 51,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: (marginLeft = 0) => ({
    width: 110,
    height: 40,
    backgroundColor: '#251f1b',
    color: '#fff',
    fontWeight: 'bold',
    lineHeight: 1.43,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft,
    fontSize: 14,
  }),
};

export default AccountAddressForm;
