import React, { Component } from 'react';

class Notice extends Component {
  render() {
    return (
      <p
        style={{
          fontSize: 16,
          lineHeight: 1.5,
          color: '#142935',
          width: 880,
          whiteSpace: 'pre-wrap',
          height: 580,
          margin: 'auto',
        }}
      >
        {`
First of all, we understand your concerns about online security and privacy. And we’d like to emphasize that we do not sell, share, rent or trade your electronically provided personal information to others. We’ll do our best to keep your personal and payment information remains secure. Your privacy is protected.
This Privacy Policy describes how your personal information is collected and used when you visit or make a purchase from www.gloveworks.net (the “Site”).

PERSONAL INFORMATION WE COLLECT
When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.

We collect Device Information using the following technologies:
- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
- “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.

Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information, email address, and phone number. We refer to this information as “Order Information”.
        `}
      </p>
    );
  }
}

export default Notice;
