import React from 'react';
import { connect } from 'react-redux';

const PreviewLoadingIndicator = ({ isLoading, dispatch }) => <div className="loading-indicator" style={{ display: (isLoading ? 'block' : 'none') }} />;


const mapStateToProps = state => ({
  isLoading: state.preview.isLoading,
});

export default connect(mapStateToProps)(PreviewLoadingIndicator);
