import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { selectGloveType } from '../../actions/creators';
import { withTranslation } from 'react-i18next';

let GloveTypeButton = ({
  active, gloveType, image, dispatch,t,type
}) =>
  (
    <div
      className={classNames('glove-type-button', { active })}
      onClick={() => {
        if (!active) { dispatch(selectGloveType(type)); }
      }}
    >
      <div className="img_wrap">
        <img
          src={image}
          alt=""
        />
      </div>
      <div className="name">{gloveType}</div>
    </div>
  );
const mapStateToProps = (state, props) => ({
  active: props.type === state.glove.gloveType,
});

GloveTypeButton = connect(mapStateToProps)(GloveTypeButton);

const GloveTypes = ({ gloveTypes, gloveTypeImages ,t}) => {
  return (
    <div className="glove-type-buttons clearfix">
      <h4>{t('customizeType')}</h4>
      {gloveTypes.map(item =>
        (console.log(item ,'item'),<GloveTypeButton
          key={item}
          gloveType={gloveText(item ,t)}
          type={item}
          image={gloveTypeImages[item]}
        />))}
    </div>
  );
}
const gloveText = (item ,t) => {
  switch(item) {
   case "Single Welting" : return   t('customizeSingleWelting')
   case "Double Welting": return t('customizeDoubleWelting')
   case "First Baseman Mitt" : return t('customizeFirstBasemanMittg')
   case "Catchers Mitt" : return t('customizeCatchersMitt')
   case "Softball" : return t('customizeSoftball')
  }
}
export default withTranslation('translations')(GloveTypes);
