import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';

import DivButton from '../button/DivButton';

import {
  checkedCurrencySymbol,
  settingCost,
  getPerItemCouponCost,
  returnComma,
} from '../../common/Utils';

const trash = '/svg/trash.svg';

function renderOpt(otp) {
  if (otp.length === 0) return null;

  const arr = otp.map((item, i) => (<div
    key={shortid.generate()}
    style={styles.optionBox}
  >
    <p style={styles.keyTitle}>{item.title}</p>
    <p style={styles.optValue}>{item.context}</p>
  </div>));

  return (<div style={styles.otpContainer}>
    {arr}
  </div>);
}

function renderCoupon(data, couponData, i18n) {
  if (!couponData) return null;
  const perCouponCost = getPerItemCouponCost(data, couponData, i18n.language);

  if (perCouponCost > 0) {
    const cost = `${checkedCurrencySymbol(i18n.language)}${i18n.language === 'en' ? returnComma((settingCost(i18n.language, data) - perCouponCost).toFixed(2)) : returnComma(settingCost(i18n.language, data) - perCouponCost)}`;
    return cost;
  }

  return null;
}

class MyCartItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputQty: props.quantity,
    };
  }

  render() {
    const { data, i18n, coupon } = this.props;
    // console.log(coupon, JSON.parse(data.body));

    return (
      <div style={styles.container(this.props.marginBottom)}>
        <div style={styles.innerContainer(this.props.marginBottom !== 0)}>
          <img
            alt=""
            style={styles.img}
            src={data.uri_pd}
          />

          <div style={styles.centerBox}>

            {
              data.b_custom === 1 ? <div style={styles.cutomizeTitleBox}>
                <p style={styles.title}>Customize</p>
                <u
                  onClick={() => this.props.onViewDtail()}
                  role="button"
                  tabIndex="-1"
                  style={styles.viewDetailText}
                >
                  View Detail
                </u>
              </div>
              : <p style={styles.title}>{JSON.parse(data.body).title}</p>
            }

            { renderOpt(JSON.parse(data.body).options) }

            <div style={styles.counterBox(JSON.parse(data.body).options.length === 0)}>
              <p style={styles.qtyText}>QTY</p>

              <input
                type="number"
                onChange={e => this.setState({ inputQty: Number(e.target.value) > 0 ? e.target.value : 1 })}
                min={1}
                value={this.state.inputQty}
                style={styles.qtyInput}
              />
              <DivButton
                style={styles.updateBtn}
                onClick={() => this.props.onCalculation(this.state.inputQty)}
              >
                Update
              </DivButton>
            </div>

          </div>

          <div style={styles.rightBox}>
            <p style={styles.cost(renderCoupon(data, coupon, i18n))}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === 'en' ? returnComma(settingCost(i18n.language, data).toFixed(2)) : returnComma(settingCost(i18n.language, data))}
            </p>
            <p style={styles.couponText}>
              {renderCoupon(data, coupon, i18n)}
            </p>
            <div
              role="button"
              tabIndex="-1"
              style={styles.trashBox}
              onClick={() => this.props.onDelete()}
            >
              <img
                src={trash}
                alt=""
                style={styles.trash}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

MyCartItem.propTypes = {
  marginBottom: PropTypes.number,
  data: PropTypes.object,
  onDelete: PropTypes.func,
  quantity: PropTypes.number,
  onCalculation: PropTypes.func,
  i18n: PropTypes.object,
  onViewDtail: PropTypes.func,
  coupon: PropTypes.object,
};

MyCartItem.defaultProps = {
  marginBottom: 24,
  data: {},
  onDelete: () => {},
  quantity: 1,
  onCalculation: () => {},
  i18n: {},
  onViewDtail: () => {},
  coupon: null,
};

const styles = {
  container: marginBottom => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom,
  }),
  innerContainer: isBorder => ({
    width: 624,
    display: 'flex',
    borderBottom: isBorder ? '2px solid rgba(37, 31, 27, 0.12)' : 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    paddingBottom: 22,
  }),
  img: {
    width: 98,
    height: 98,
  },
  centerBox: {
    width: 400,
    marginLeft: 24,
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    fontWeight: 600,
    color: '#251f1b',
  },
  counterBox: isMarginTop => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: isMarginTop ? 48 : 0,
  }),
  qtyText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.38,
    letterSpacing: 1,
    color: '#251f1b',
  },
  rightBox: {
    width: 88,
    marginLeft: 14,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
  },
  cost: isCoupon => ({
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    letterSpacing: 0.9,
    textAlign: 'right',
    color: '#251f1b',
    fontWeight: 'bold',
    textDecoration: isCoupon ? 'line-through' : 'none',
  }),
  trashBox: {
    width: '100%',
    height: 30,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trash: {
    cursor: 'pointer',
  },
  optionBox: {
    display: 'flex',
    marginBottom: 4,
  },
  keyTitle: {
    width: 220,
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
    opacity: 0.4,
  },
  optValue: {
    width: 140,
    textAlign: 'right',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
  },
  otpContainer: {
    marginBottom: 24,
    marginTop: 16,
  },
  cutomizeTitleBox: {
    width: 220 + 140,
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewDetailText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#c77b46',
    lineHeight: 1.14,
    cursor: 'pointer',
    outline: 'none',
  },
  qtyInput: {
    width: 48,
    height: 30,
    border: 'solid 1px #251f1b',
    color: '#251f1b',
    lineHeight: 1.14,
    fontWeight: '600',
    fontSize: 14,
    textAlign: 'center',
    outline: 'none',
    marginLeft: 40,
  },
  updateBtn: {
    width: 64,
    height: 30,
    backgroundColor: '#251f1b',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 1.67,
    marginLeft: 16,
  },
  couponText: {
    fontSize: 16,
    fontWeight: '600',
    textAlign: 'right',
    color: '#d0021b',
    position: 'absolute',
    top: 16,
    right: 0,
  },
};

export default withTranslation('translations')(MyCartItem);
