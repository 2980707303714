import React, { Component } from 'react';
import shortid from 'shortid';

import Step from '../../components/step/Step';
import DivButton from '../../components/button/DivButton';

const STEP_MENU = ['Gallery'];

const instarImg = '/img/galleryInstaLogo.png';

// const accessToken = '7428080577.eb3fdf0.f681d7194537450fb73b125e5d890e7c';
const accessToken = '496449146.d058c13.c2b4fbf483794148b6c3598003358106';

async function getInstagramData(url) {
  const settings = {
    async: true,
    crossDomain: true,
    url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  };
  const { ajax } = window.$;
  const res = await ajax(settings);
  return res;
}

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  async initialize() {
    const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}&count=9`;
    this.setState({
      data: await getInstagramData(url),
    });
  }

  async onMore(nextUrl) {
    const res = await getInstagramData(nextUrl);
    const { data } = this.state.data;
    const totalData = data.concat(res.data);

    this.setState({
      data: {
        ...res,
        data: totalData,
      },
    });
  }

  isRenderMore() {
    const hasBarQty = Object.prototype.hasOwnProperty.call(this.state.data.pagination, 'next_url');
    const obj = this.state.data.pagination;
    console.log(obj);
    if (hasBarQty) {
      return (
        <DivButton
          style={styles.moreBtn}
          onClick={() => this.onMore(obj.next_url)}
        >
          View more
        </DivButton>
      );
    }
    return <div style={{ height: 146, clear: 'both' }} />;
  }

  render() {
    // console.log(this.state.data);
    return (
      <div>
        <Step menu={STEP_MENU} />
        <p style={styles.title}>GALLERY</p>
        <img src={instarImg} style={styles.img} alt="" />

        { this.state.data ?
          <div>
            <div style={styles.itemBox}>
              {this.state.data.data.map((item, i) => (
                <div
                  style={styles.rowItemsBox(i % 3 === 0 ? 0 : 18, item.images.standard_resolution.url)}
                  key={shortid.generate()}
                />
              ))}
            </div>

            {this.isRenderMore()}

          </div>
          : <div style={{ height: 1300 }} />
        }
      </div>
    );
  }
}

const styles = {
  title: {
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 1.5,
    color: '#251f1b',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 50,
  },
  img: {
    width: 164,
    height: 40,
    margin: 'auto',
    marginTop: 20,
    display: 'block',
  },
  itemBox: {
    width: (320 * 3) + (18 * 2),
    margin: 'auto',
    marginTop: 40,
  },
  rowItemsBox: (marginLeft, url) => ({
    width: 320,
    height: 320,
    marginBottom: 18,
    marginLeft,
    float: 'left',
    backgroundSize: '100% auto',
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#000',
  }),
  itemImg: {
    width: 320,
    height: 320,
  },
  moreBtn: {
    margin: 'auto',
    width: 93,
    height: 22,
    clear: 'both',
    marginBottom: 146 - (40 - 18),
    textAlign: 'center',
    color: '#007dff',
    lineHeight: 1.38,
    fontWeight: '600',
    letterSpacing: 1,
    fontSize: 16,
    position: 'relative',
    paddingTop: 40 - 18,
  },
};

export default Gallery;
