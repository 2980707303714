import { SELECT_TAB } from '../constants/ActionTypes';

export default function tabs(state = { currentTab: 'base' }, action) {
  switch (action.type) {
    case SELECT_TAB:
      return {
        currentTab: action.tab,
      };
    default:
      return state;
  }
}
