import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  checkedCurrencySymbol,
  settingCostWithCompare,
  returnComma,
} from '../../common/Utils';

const sale = '/svg/sale.svg';

class MainCollectionsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };
  }

  handleMouseHover(str) {
    if (str === 'onMouseLeave') {
      this.setState({
        isHovering: false,
      });
    } else {
      this.setState({
        isHovering: true,
      });
    }
  }

  render() {
    const { i18n } = this.props;
    return (
      <div
        role="menuitem"
        tabIndex="-1"
        style={Object.assign(styles.container(this.props.data.cover_uri), this.props.style)}
        onClick={e => this.props.onClick(e)}
        onMouseEnter={() => this.handleMouseHover('onMouseEnter')}
        onMouseLeave={() => this.handleMouseHover('onMouseLeave')}
      >
        { settingCostWithCompare(i18n.language, this.props.data).compare > 0 ? <img src={sale} alt="sale" style={styles.sale} /> : null }
        <div style={styles.layer(this.state.isHovering)}>

          <div style={styles.titleBox}>
            <p style={styles.title}>{this.props.data.title}</p>
          </div>

          { settingCostWithCompare(i18n.language, this.props.data).compare > 0 ?
            <div style={styles.cost}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === 'en' ? returnComma(settingCostWithCompare(i18n.language, this.props.data).compare.toFixed(2)) : returnComma(settingCostWithCompare(i18n.language, this.props.data).compare)}
            </div> : <div style={styles.cost} /> }
          <div style={styles.discountCost}>
            {checkedCurrencySymbol(i18n.language)}
            {i18n.language === 'en' ? returnComma((settingCostWithCompare(i18n.language, this.props.data).cost).toFixed(2)) : returnComma(settingCostWithCompare(i18n.language, this.props.data).cost)}
          </div>
        </div>

      </div>
    );
  }
}

const styles = {
  container: imgUrl => ({
    width: 210,
    height: 210,
    backgroundSize: 'cover',
    cursor: 'pointer',
    backgroundColor: '#fff',
    backgroundImage: `url(${imgUrl})`,
    position: 'relative',
    backgroundPosition: 'center',
  }),
  sale: {
    width: 32,
    height: 32,
    marginTop: 10,
    marginLeft: 168,
  },
  layer: isHovering => ({
    position: 'absolute',
    top: 110,
    left: 0,
    display: isHovering ? 'block' : 'none',
    width: '100%',
    height: 100,
    backgroundColor: 'rgba(21, 41, 53, 0.8)',
  }),
  titleBox: {
    marginTop: 0,
    height: 32,
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontSize: 10,
    letterSpacing: 0.4,
    lineHeight: 1.6,
    color: '#fff',
    textAlign: 'center',
    margin: 8,
  },
  cost: {
    width: 190,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto Condensed',
    fontSize: 10,
    lineHeight: 2,
    color: '#fff',
    margin: 'auto',
    marginTop: 12,
    textDecoration: 'line-through',
  },
  discountCost: {
    width: 190,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    lineHeight: 1.67,
    color: '#edd167',
    fontWeight: 'bold',
    margin: 'auto',
  },
  like: {
    width: 34,
    height: 34,
    position: 'absolute',
    top: 271,
    right: 29,
    cursor: 'pointer',
  },
};

MainCollectionsCard.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  style: PropTypes.object,
  // t: PropTypes.func,
  i18n: PropTypes.object,
};

MainCollectionsCard.defaultProps = {
  data: {},
  style: {},
  onClick: () => {},
  // t: () => {},
  i18n: {},
};

export default withTranslation('translations')(MainCollectionsCard);
