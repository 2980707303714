import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const WebColor = ({
  customPythons,
  currentCustomPython,
  onCustomPythonClick,
}) => {
  // console.log(customPythons);
  return (<div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="WEB COLOR"
      className="web-color-wrap"
      images={customPythons}
      currentCustomImage={currentCustomPython}
      onClick={onCustomPythonClick}
    />
  </div>)
};

const mapStateToProps = (state) => {
  const { webPython } = state.glove;

  return {
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    currentCustomPython: webPython,
  };
};

const mapDispatchToProps = dispatch => ({
  onCustomPythonClick: color => dispatch(updateGlove({ webPython: color })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WebColor), 'fingerSide');
