import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';

import {
  checkedCurrencySymbol,
  settingCost,
  getPerItemCouponCost,
  returnComma,
} from '../../common/Utils';

function renderOpt(otp) {
  if (otp.length === 0) return null;

  const arr = otp.map((item, i) => (
    <div key={shortid.generate()} style={styles.optionBox}>
      <p style={styles.keyTitle}>{item.title}</p>
      <p style={styles.optValue}>{item.context}</p>
    </div>
  ));

  return <div style={styles.otpContainer}>{arr}</div>;
}

function renderCoupon(data, couponData, i18n) {
  if (!couponData) return null;
  const perCouponCost = getPerItemCouponCost(data, couponData, i18n.language);
  // console.log(perCouponCost);

  if (perCouponCost > 0) {
    const str = `${checkedCurrencySymbol(i18n.language)}${
      i18n.language === 'en'
        ? returnComma((settingCost(i18n.language, data) - perCouponCost).toFixed(2))
        : returnComma(settingCost(i18n.language, data) - perCouponCost)
    }`;
    return str;
  }

  return null;
}

class CheckOutCartItem extends PureComponent {
  render() {
    const { data, i18n, coupon } = this.props;
    // console.log(coupon);
    return (
      <div style={styles.container(this.props.marginBottom)}>
        <div style={styles.innerContainer}>
          <img alt="" style={styles.img} src={data.uri_pd} />

          <div style={styles.centerBox}>
            <div style={styles.titleBox}>
              <p style={styles.title}>{JSON.parse(data.body).title}</p>
              {data.b_custom === 1 ? (
                <u
                  style={styles.viewDetailText}
                  role="button"
                  tabIndex="-1"
                  onClick={() => this.props.onViewDtail(data)}
                >
                  View Detail
                </u>
              ) : null}
            </div>
            {renderOpt(JSON.parse(data.body).options)}
          </div>

          <p style={styles.cost(93)}>
            {checkedCurrencySymbol(i18n.language)}
            {i18n.language === 'en'
              ? returnComma(settingCost(i18n.language, data).toFixed(2))
              : returnComma(settingCost(i18n.language, data))}
          </p>
          <p style={styles.multiply}>X</p>
          <p style={styles.quantity}>{data.quantity}ea</p>

          <div style={styles.rightBox}>
            <p
              style={Object.assign(
                styles.cost(0, renderCoupon(data, coupon, i18n)),
                { fontWeight: 'bold', fontSize: 14 },
              )}
            >
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === 'en'
                ? returnComma((settingCost(i18n.language, data) * data.quantity).toFixed(2))
                : returnComma(settingCost(i18n.language, data) * data.quantity)}
            </p>
            <p style={styles.couponCost}>{renderCoupon(data, coupon, i18n)}</p>
          </div>
        </div>
      </div>
    );
  }
}

CheckOutCartItem.propTypes = {
  marginBottom: PropTypes.number,
  data: PropTypes.object,
  i18n: PropTypes.object,
  onViewDtail: PropTypes.func,
  coupon: PropTypes.object,
};

CheckOutCartItem.defaultProps = {
  marginBottom: 20,
  data: {},
  i18n: {},
  onViewDtail: () => {},
  coupon: null,
};

const styles = {
  container: marginBottom => ({
    width: '100%',
    minHeight: 120,
    display: 'flex',
    justifyContent: 'center',
    marginBottom,
    backgroundColor: '#fff',
  }),
  innerContainer: {
    width: 948,
    display: 'flex',
    borderBottom: '2px solid rgba(37, 31, 27, 0.12)',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    paddingBottom: 22,
  },
  img: {
    width: 98,
    height: 98,
  },
  centerBox: {
    // width: 412,
    width: 360,
    marginLeft: 24,
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 18,
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    fontWeight: 600,
    color: '#251f1b',
  },
  viewDetailText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#c77b46',
    lineHeight: 1.14,
    cursor: 'pointer',
    outline: 'none',
  },
  counterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  qtyText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.38,
    letterSpacing: 1,
    color: '#251f1b',
    marginRight: 17,
  },
  rightBox: {
    width: 88,
    marginLeft: 95,
  },
  cost: (marginLeft, isCoupon) => ({
    width: 88,
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 1,
    textAlign: 'right',
    color: '#251f1b',
    marginLeft,
    textDecoration: isCoupon ? 'line-through' : 'none',
  }),
  trashBox: {
    width: '100%',
    height: 30,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trash: {
    cursor: 'pointer',
  },
  optionBox: {
    width: 360,
    display: 'flex',
    marginBottom: 4,
    alignItems: 'center',
  },
  keyTitle: {
    width: 220,
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
    opacity: 0.4,
  },
  optValue: {
    width: 140,
    textAlign: 'right',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
  },
  otpContainer: {
    marginBottom: 24,
  },
  multiply: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 1,
    color: '#251f1b',
    marginLeft: 20,
  },
  quantity: {
    width: 40,
    textAlign: 'left',
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 1,
    color: '#251f1b',
    marginLeft: 8,
  },
  couponCost: {
    width: 88,
    textAlign: 'right',
    color: '#e85454',
    fontSize: 16,
    fontWeight: 'bold',
  },
};

export default withTranslation('translations')(CheckOutCartItem);
