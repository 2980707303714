import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';

import { getItem } from '../../common/StorageUtils';
import {
  getCookie,
  setCookie,
  checkedCurrencySymbol,
  returnComma,
} from '../../common/Utils';
import _Fetch from '../../common/Fetches';

import Modal2 from '../../containers/baseModal/BaseModal';

import Step from '../../components/step/Step';
import AddressItem from '../../components/item/AddressItem';
import CheckBox from '../../components/checkBox/CheckBox';
import DivButton from '../../components/button/DivButton';

import AddressForm from '../../containers/form/AddressForm';
import BillingAddressForm from '../../containers/form/BillingAddressForm';
import ShoppingAddressForm from '../../containers/form/ShoppingAddressForm';

import Modal from '../../lib/modal';

import CountryData from './localization.json';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

const STEP_MENU = ['Shop', 'My Cart', 'Check out'];
const STEP = ['SHIPPING INFO', 'PAYMENT INFO', 'ORDER REVIEW'];

const arrowW = '/svg/arrow_wr.svg';
const img = '/svg/noun.svg';

const shippingFeeTpyes = [
  'Asia Zone1',
  'Asia Zone2',
  'Asia Zone3',
  'Europe zone1',
  'Europe zone2',
  'NORTHERN AMERICA',
  'LATIN AMER. & CARIB',
  'OCEANIA',
  'Others',
];

class ShippingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      chooseAddress: 1,
      isAddNewAddressModal: false,
      billingAddressType: 1,
      data: null,
      customTotalFee: 0,
      stockTotalFee: 0,
      isModal2: false,
      bodyText2: '',
    };

    this.userData = null;

    // 업데이트 할 주소 목록을 모달 주소 폼에 전달하기위히 주소값을 담을 변수.
    this.updateData = null;

    // 체크한 shoppin address 정보를 담기 위한 변수.
    this.checkedAddress = null;

    // 빌딩 어드레스 정보를 담을 변수.
    this.billingAddr = {
      name_first: null,
      name_last: null,
      phone: null,
      company: null,
      country: null,
      state: null,
      city: null,
      zipcode: null,
      addr: null,
      addr_etc: null,
    };

    // 배송비 가격을 담을 데이터
    this.shippingFeeData = null;

    this.shippingCost = null;

    this.onNext = this.closeModal;
  }

  componentDidMount() {
    this.initData();
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  processShippingFee = () => {
    if (!this.checkedAddress) return null;
    const idx = CountryData.findIndex(o => o.country === this.checkedAddress.country);

    if (idx > -1) {
      console.log(idx, CountryData[idx].region, CountryData[idx]);
      const sIdx = shippingFeeTpyes.findIndex(ob => ob === CountryData[idx].region);
      if (sIdx > -1) return this.calSippingFee(sIdx + 1);
    }
    return this.calSippingFee(9);
  };

  openModal = (bodyText2) => {
    this.setState({
      isModal2: true,
      bodyText2,
    });
  };

  closeModal = () => {
    this.setState({
      isModal2: false,
      bodyText2: '',
    });
  };

  async initData() {
    this.userData = JSON.parse(getCookie('userData'));
    try {
      const api = getItem('restAPI').model_desc.user_addr_get_my;
      const res = await _Fetch(
        api,
        `?pid_user=${this.userData.user.pid_user}`,
        [],
      );

      const sortCtg = (a, b) => {
        if (a.b_base < b.b_base) {
          return 1;
        }
        if (a.b_base > b.b_base) {
          return -1;
        }
        return 0;
      };

      // 디폴트 주소가 맨 위로 위치하게 위한 정렬
      const sortedAddr = res.sort((a, b) => sortCtg(a, b));
      // 디폴트 주소를 담기 위한 사전 처리
      const selectedAddr = sortedAddr[0];

      this.checkedAddress = selectedAddr;

      // get delivery fee
      const feeApi = getItem('restAPI').model_desc.delivery_fee_get;
      const feeRes = await _Fetch(feeApi);

      this.shippingFeeData = feeRes;

      console.log(feeRes);

      this.setState(
        {
          data: sortedAddr,
          isAddNewAddressModal: false,
        },
        () => this.processShippingFee(),
      );
    } catch (e) {
      console.log(e);
    }
  }

  async initDefaultAddress(item) {
    try {
      if (item.b_base === 1 && this.state.data.length > 0) {
        const idx = this.state.data.findIndex(x => x.b_base === item.b_base);
        if (idx === -1) {
          console.log(idx);
          return null;
        }
        console.log(this.state.data[idx]);
        const putApi = getItem('restAPI').model_desc.user_addr_update;
        const putBody = {
          user_addr: {
            b_base: 0,
            pid_user: this.userData.user.pid_user,
            pid_addr: this.state.data[idx].pid_addr,
          },
        };
        return await _Fetch(putApi, '', putBody);
      }
      return null;
    } catch (e) {
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        return this.props.history.push('/login');
      }
      return console.log(e);
    }
  }

  async updateDefaultAddress(item) {
    try {
      if (item.b_base === 1 && this.state.data.length > 0) {
        const idx = this.state.data.findIndex(x => x.b_base === item.b_base);
        if (idx === -1) {
          console.log(idx);
          return null;
        }
        console.log(this.state.data[idx]);
        const putApi = getItem('restAPI').model_desc.user_addr_update;
        const putBody = {
          user_addr: {
            b_base: 0,
            pid_user: this.userData.user.pid_user,
            pid_addr: this.state.data[idx].pid_addr,
          },
        };
        const update = await _Fetch(putApi, '', putBody);

        return update ? this.setState({ chooseAddress: 1 }, () => this.initData()) : this.initData();
      }
      return this.initData();
    } catch (e) {
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        return this.props.history.push('/login');
      }
      return console.log(e);
    }
  }


  processShippingNumber = () => {
    if (!this.checkedAddress) return 9;
    const idx = CountryData.findIndex(o => o.country === this.checkedAddress.country);

    if (idx > -1) {
      console.log(idx, CountryData[idx].region, CountryData[idx]);
      const sIdx = shippingFeeTpyes.findIndex(ob => ob === CountryData[idx].region);
      if (sIdx > -1) return sIdx;
      return 8;
    }
    return 8;
  };

  async onPostShoppingAddress(item) {
    try {
      const api = getItem('restAPI').model_desc.user_addr_insert;
      const body = {
        user_addr: {
          ...item,
          pid_user: this.userData.user.pid_user,
        },
      };
      await _Fetch(api, '', body);
      this.initDefaultAddress(item);
      // this.processShippingFee();
      this.initData();
    } catch (e) {
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
      console.log(e);
    }
  }

  async updateAdress(item) {
    try {
      const api = getItem('restAPI').model_desc.user_addr_update;
      const body = {
        user_addr: {
          ...item,
          pid_user: this.userData.user.pid_user,
        },
      };
      console.log(body);
      await _Fetch(api, '', body);
      this.updateDefaultAddress(item);
      console.log('update');
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
    }
  }

  async onDeleteAddres(item) {
    try {
      const api = getItem('restAPI').model_desc.user_addr_delete;
      const body = {
        user_addr: {
          pid_addr: item.pid_addr,
          pid_user: this.userData.user.pid_user,
        },
      };
      console.log(body);
      await _Fetch(api, '', body);
      this.initData();
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
    }
  }

  // 배송비 계산 부분
  calSippingFee(value) {
    const { itemData } = this.props;
    console.log('배송비 아이템 개별 적용을 위한', this.shippingFeeData, value, itemData);

    const custom = itemData.filter(item => item.b_custom === 1);
    const stock = itemData.filter(item => item.b_custom === 2);

    const getConntry = (langCode) => {
      console.log(langCode);
      switch (langCode) {
        case 'ko':
          return 'won';
        case 'ja':
          return 'yen';
        default:
          return 'dol';
      }
    };

    const customFeeArr = [];
    const stockFeeArr = [];

    if (custom.length > 0) {
      custom.map((item) => {
        for (let i = 0; i < item.quantity; i += 1) {
          customFeeArr.push(this.shippingFeeData[JSON.parse(item.body).typ_delivery - 1][
            `${getConntry(this.props.i18n.language)}_country${value}`
          ]);
        }
        return null;
      });
    }

    if (stock.length > 0) {
      stock.map((item) => {
        for (let i = 0; i < item.quantity; i += 1) {
          if (JSON.parse(item.body).typ_delivery !== 4) {
            stockFeeArr.push(this.shippingFeeData[JSON.parse(item.body).typ_delivery - 1][
              `${getConntry(this.props.i18n.language)}_country${value}`
            ]);
          }
        }
        return null;
      });
    }

    // console.log(JSON.parse(custom[0].body).typ_delivery, JSON.parse(stock[0].body).typ_delivery);
    console.log(customFeeArr, stockFeeArr);

    const customTotalFee = customFeeArr.reduce((total, i) => {
      let sum = total;
      sum += i;
      return sum;
    }, 0);

    const stockTotalFee = stockFeeArr.reduce((total, i) => {
      let sum = total;
      sum += i;
      return sum;
    }, 0);

    console.log(customTotalFee, stockTotalFee);

    console.log(customTotalFee, stockTotalFee);

    this.shippingCost = {
      customTotalFee,
      stockTotalFee,
    };

    this.setState({
      customTotalFee,
      stockTotalFee,
    });
  }

  renderStep() {
    return STEP.map((item, i) => (
      <div style={styles.stepItemBox} key={shortid.generate()}>
        <div style={styles.stepItemCircle(this.state.step > i)}>{i + 1}</div>
        <p style={styles.stepItemText(this.state.step > i)}>{item}</p>
      </div>
    ));
  }

  renderAddress() {
    // console.log(this.state.data);
    return this.state.data.map((item, i) => (
      <AddressItem // changes made here
        onUpdate={(updateData) => {
          this.updateData = updateData;
          this.setState({ isAddNewAddressModal: true });
        }}
        onDelete={data => this.onDeleteAddres(data)}
        data={item}
        key={shortid.generate()}
        isChecked={i + 1 === this.state.chooseAddress}
        onCecked={checkAddr =>
          this.setState({ chooseAddress: i + 1 }, () => {
            this.checkedAddress = checkAddr;
            this.processShippingFee();
          })
        }
      />
    ));
  }

  renderModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.isAddNewAddressModal}
        contentLabel="Add New Address"
        onRequestClose={() => {
          this.setState({ isAddNewAddressModal: false });
          // document.body.style.overflow = 'auto';
        }}
        style={AddressModalStyles}
      >
        <p style={styles.modalTitle}>ADD NEW ADDRESS</p>

        <AddressForm
          data={this.updateData}
          onUpdate={item => this.updateAdress(item)}
          onSave={item => this.onPostShoppingAddress(item)}
          onCancel={() => {
            this.setState({ isAddNewAddressModal: false });
            // document.body.style.overflow = 'auto';
          }}
          style={styles.addressForm}
        />
      </Modal>
    );
  }

  renderBillingAddress() {
    const { t } = this.props;
    return (
      <div style={styles.shippingBox}>
        <div style={styles.addressBoxTitle}>{t('shippingBillingAddr')}</div>

        <div style={styles.blinngRowBox}>
          <CheckBox
            styles={shippingCheckBoxStyles}
            isDiv
            isChecked={this.state.billingAddressType === 1}
            onClick={() => this.setState({ billingAddressType: 1 })}
          />
          <p style={styles.blinngRowText}>Same As Shipping Address</p>
        </div>
        <div style={styles.blinngRowBox}>
          <CheckBox
            styles={shippingCheckBoxStyles}
            isDiv
            isChecked={this.state.billingAddressType === 2}
            onClick={() => {
              this.billingAddr = {
                name_first: null,
                name_last: null,
                phone: null,
                company: null,
                country: null,
                state: null,
                city: null,
                zipcode: null,
                addr: null,
                addr_etc: null,
              };
              this.setState({ billingAddressType: 2 });
            }}
          />
          <p style={styles.blinngRowText}>Enter New Billing Address</p>
        </div>

        {this.state.billingAddressType === 2 ? (
          <div style={{ marginTop: 24 }}>
            <BillingAddressForm
              getAddr={(key, value) => (this.billingAddr[key] = value)}
              style={styles.billingAddressForm}
            />
          </div>
        ) : null}
      </div>
    );
  }

  renderShoppingAddressBody() { // something need to be fixed here for long address information.
    if (this.state.data.length > 0) {
      return (
        <div>
          {this.renderAddress()}

          {this.state.data.length > 4 ? null : (
            <DivButton
              style={styles.addAddressBtn}
              onClick={() => {
                this.updateData = null;
                this.setState({ isAddNewAddressModal: true });
                // document.body.style.overflow = 'hidden';
              }}
            >
              <p style={styles.addAddressText}>+ ADD NEW ADDRESS</p>
            </DivButton>
          )}
        </div>
      );
    }

    return (
      <div style={{ marginLeft: 32 }}>
        <ShoppingAddressForm
          onSave={item => this.onPostShoppingAddress(item)}
        />
      </div>
    );
  }

  renderShippingFee = () => {
    const { t } = this.props;
    const shippingIdx = this.processShippingNumber();
    console.log('shippingIdx', shippingIdx);
    return (
      <div style={styles.shippingBox}>
        <div style={styles.addressBoxTitle}>
          {t('shippingMethod')}
          <div className="tip">
            <img alt="" src={img} className="tooltip" />
            <div className="tip-detail-price">
              Items in your order may be subject to taxes, customs duties and
              fees levied by the destination country. The recipient of the
              shipment is responsible for all Import Fees.
            </div>
          </div>
        </div>

        <p style={styles.regionText}>{shippingFeeTpyes[shippingIdx]}</p>

        <div style={styles.shippingRowBox}>
          <p style={styles.shppingCostText}>
            {this.state.stockTotalFee > 0 && this.checkedAddress
              ? `Stock Shipping Fee - ${checkedCurrencySymbol(this.props.i18n.language) + returnComma(this.state.stockTotalFee)}`
              : null}
            <br />
            {this.state.customTotalFee > 0 && this.checkedAddress
              ? `Custom Shipping Fee - ${checkedCurrencySymbol(this.props.i18n.language) + returnComma(this.state.customTotalFee)}`
              : null}
          </p>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.data) return <div style={styles.emptyContainer} />;
    const { isModal2, bodyText2 } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Modal2
          isModal={isModal2}
          bodyText={bodyText2}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        {this.renderModal()}
        <Step menu={STEP_MENU} />

        <div style={styles.stepBox}>{this.renderStep()}</div>

        <div style={styles.addressBox}>
          <div style={styles.addressBoxTitle}>{t('shippingAddress')}</div>
          {this.renderShoppingAddressBody()}
        </div>

        {this.renderShippingFee()}

        {this.renderBillingAddress()}

        <div style={styles.bottomBox}>
          <DivButton
            style={styles.bottomBtn}
            onClick={() => {
              if (!this.shippingCost) {
                return this.openModal('please chooes shipping method');
              }

              // 빌딩 어드레스가 Enter New Billing Address 체크 되었을 때
              if (this.state.billingAddressType === 2) {
                if (!this.billingAddr.name_first) {
                  return this.openModal('Please enter firstName');
                }
                if (!this.billingAddr.name_last) {
                  return this.openModal('Please enter lastName');
                }
                // if (!this.billingAddr.company) { return this.openModal('company 입력하세요'); }
                if (!this.billingAddr.addr) return this.openModal('Please enter address');
                if (!this.billingAddr.city) return this.openModal('Please enter city');

                if (!this.billingAddr.country) {
                  return this.openModal('Please enter country');
                }
                if (!this.billingAddr.state) return this.openModal('Please enter state');

                if (!this.billingAddr.zipcode) {
                  return this.openModal('Please enter zipCode');
                }
                if (!this.billingAddr.phone) {
                  return this.openModal('Please enter phoneNumber');
                }

                // 추가 주소 인풋창을 클릭도 안할시 none 할당
                // if (!this.billingAddr.addr_etc) {
                //   this.billingAddr.addr_etc = 'none';
                // }
              } else {
                // 빌딩 어드레스가 쇼핑 어드레스와 같을 때
                // 페이팔 결제시 추후 데이터 가공 작업 필요할거라고 예측
                this.billingAddr = this.checkedAddress;
              }
              // console.log(this.billingAddr);
              return this.props.onNext(
                this.checkedAddress,
                this.billingAddr,
                this.shippingCost,
                this.renderShippingFee,
              );
            }}
          >
            <p style={styles.bottomBtnText}>NEXT</p>
            <img src={arrowW} style={styles.bottomBtnArrow} alt="w_arrow" />
          </DivButton>
        </div>
      </div>
    );
  }
}

ShippingInfo.propTypes = {
  onNext: PropTypes.func,
  history: PropTypes.object,
  itemData: PropTypes.array,
  i18n: PropTypes.object,
};

ShippingInfo.defaultProps = {
  onNext: () => {},
  history: {},
  itemData: null,
  i18n: {},
};

const AddressModalStyles = {
  content: {
    width: 730,
    height: 707,
    backgroundColor: '#fff',
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

const shippingCheckBoxStyles = {
  container: {
    width: 18,
    height: 18,
    border: 'solid 2px #251f1b',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    borderRadius: 9,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float: 'left',
  },
  img: {
    display: null,
  },
  check: {
    width: 8,
    height: 8,
    backgroundColor: '#007dff',
    borderRadius: 4,
    cursor: 'pointer',
  },
};

const styles = {
  emptyContainer: {
    width: '100%',
    height: 1300,
  },
  stepBox: {
    width: 540,
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    marginTop: 37,
  },
  stepItemBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  stepItemCircle: isVisible => ({
    width: 30,
    height: 30,
    backgroundColor: isVisible ? '#007dff' : 'rgba(204, 204, 204, 0.5)',
    borderRadius: 15,
    color: isVisible ? '#fff' : '#aaa',
    fontSize: 16,
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  }),
  stepItemText: isVisible => ({
    textAlign: 'center',
    color: isVisible ? '#251f1b' : 'rgba(204, 204, 204, 0.5)',
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    marginTop: 9,
  }),
  addressBox: {
    width: 996,
    backgroundColor: '#fff',
    margin: 'auto',
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
    marginTop: 38,
  },
  addressBoxTitle: {
    color: '#251f1b',
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    lineHeight: 1.39,
    display: 'flex',
    marginLeft: 30,
    alignItems: 'center',
    height: 75,
  },
  addAddressBtn: {
    width: 240,
    height: 40,
    position: 'relative',
    backgroundColor: '#000',
    cursor: 'pointer',
    marginTop: 30,
    marginBottom: 39,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 30,
  },
  addAddressText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    letterSpacing: 0.2,
    color: '#ffffff',
  },
  modalTitle: {
    marginTop: 35,
    marginLeft: 60,
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 1.39,
    color: '#251f1b',
  },
  input: (width = 200) => ({
    width,
    height: 40,
    border: '1px solid #d9d9d9',
    backgroundColor: '#f7f7f7',
    outline: 'none',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    marginTop: 6,
    paddingLeft: 15,
    paddingRight: 15,
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
  }),
  shippingBox: {
    width: 996,
    backgroundColor: '#fff',
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
    margin: 'auto',
    marginTop: 20,
    paddingBottom: 30,
  },
  blinngRowBox: {
    marginLeft: 35,
    display: 'flex',
    alignItems: 'center',
  },
  shippingRowBox: {
    marginLeft: 30,
    display: 'flex',
    height: 40,
    alignItems: 'center',
    marginTop: 8,
  },
  shppingCostText: {
    lineHeight: 1.45,
    letterSpacing: 1.3,
    color: '#251f1b',
    fontFamily: 'Metropolis',
    fontSize: 18,
    fontWeight: 600,
  },
  shippinRowText: {
    float: 'left',
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    lineHeight: 1.56,
    color: '#251f1b',
  },
  blinngRowText: {
    float: 'left',
    marginLeft: 10,
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    lineHeight: 1.56,
    color: '#251f1b',
  },
  cartBox: {
    width: 996,
    backgroundColor: '#fff',
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
    margin: 'auto',
    marginTop: 20,
    paddingBottom: 20,
  },
  cartBoxTitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    color: '#251f1b',
    letterSpacing: 1,
    lineHeight: 1.39,
    fontWeight: 'bold',
    height: 76,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
  },
  couponBox: marginTop => ({
    display: 'flex',
    marginTop,
    height: 24,
    alignItems: 'center',
  }),
  couponTitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    marginLeft: 642,
    width: 168,
    textAlign: 'right',
  },
  couponDiscount: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    marginLeft: 50,
    width: 88,
    textAlign: 'right',
  },
  cartSubTotalTtitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    marginLeft: 642,
    width: 168,
    textAlign: 'right',
    fontWeight: 600,
  },
  cartSubTotalCost: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    marginLeft: 50,
    width: 88,
    textAlign: 'right',
    fontWeight: 600,
  },
  cartUnderline: {
    width: 948,
    height: 2,
    backgroundColor: 'rgba(21, 41, 53, 0.1)',
    margin: 'auto',
    marginTop: 17,
  },
  totalBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 21,
  },
  totalText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    color: '#251f1b',
    lineHeight: 1.38,
    letterSpacing: 0.9,
    marginLeft: 762,
  },
  totalCost: {
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    width: 120,
    textAlign: 'right',
    color: '#251f1b',
    lineHeight: 1.25,
    letterSpacing: 1.5,
    marginLeft: 20,
  },
  bottomBtn: {
    width: 204,
    height: 60,
    backgroundColor: '#251f1b',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomBtnText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
    lineHeight: 1.11,
    letterSpacing: 1,
  },
  bottomBtnArrow: {
    width: 11,
    height: 11,
    position: 'absolute',
    right: 34,
    zIndex: 2,
    top: '50%',
    transform: 'translate(0px, -50%)',
    MsTransform: 'translate(0px, -50%)',
    Webkittransform: 'translate(0px, -50%)',
  },
  bottomBox: {
    margin: 'auto',
    marginTop: 27,
    marginBottom: 34,
    width: 996,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  nonmemberInnerBox: {
    width: 743,
    height: 18 + 248 + 40,
    marginLeft: 30,
    marginTop: 24,
  },
  addressForm: {
    marginTop: 24,
    marginLeft: 60,
  },
  billingAddressForm: {
    marginTop: 4,
    marginLeft: 32,
  },
  select: {
    position: 'relative',
    width: 330,
    height: 40,
    border: '1px solid #251f1b',
    marginTop: 5,
    outline: 'none',
    borderRadius: 0,
    background: "url('/svg/triangle.svg') no-repeat 95% 50%",
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    paddingLeft: 14,
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
  },
  regionText: {
    fontFamily: 'Metropolis',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 1,
    color: '#251f1b',
    marginLeft: 30,
  },
};

ShippingInfo.propTypes = {
  t: PropTypes.func,
};

ShippingInfo.defaultProps = {
  t: () => {},
};

export default withRouter(withTranslation('translations')(ShippingInfo));
