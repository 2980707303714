import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

export default function CustomPythonButtons ({
  title,
  className,
  images,
  currentCustomImage,
  onClick,
}) {
  return (
    <div className={className}>
      <h4>{title}</h4>
      <div className="images-wrap clearfix">
        {images.map(image =>
          {
            if (image.url)
              return (
                <div
                  key={image.id}
                  className={classNames('custom-image-button', { active: currentCustomImage && currentCustomImage.id === image.id })}
                  style={{ backgroundImage: `url(${image.url})`, backgroundColor: image.hexCode }}
                  onClick={() => onClick(image)}
                >
                  <div className="name">{image.name}</div>
                </div>
              );
            return (
              <div
                key={image.id}
                className={classNames('custom-image-button', { active: currentCustomImage && currentCustomImage.id === image.id })}
                style={{ backgroundColor: image.hexCode }}
                onClick={() => onClick(image)}
              >
                <div className="name">{image.name}</div>
              </div>
            );
          }
        )
        }
      </div>
    </div>
  );
}
