import React from 'react';
import { connect } from 'react-redux';

import { changeGloveType, closeAlert } from '../../actions/creators';

const ChangeGloveTypeAlert = ({ gloveType, dispatch }) => {
  const message = `You have chosen ${gloveType}.<br />The color & personalization options will be reset by choosing this.`;

  return (
    <div className="custom-glove-popup">
      <div className="base-alert-title">Alert Message</div>
      <div
        className="base-alert-content"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <button
        className="close"
        onClick={() => dispatch(closeAlert())}
      />
      <div
        className="popup-button yes"
        onClick={() => {
          dispatch(changeGloveType(gloveType))
          .then(() => dispatch(closeAlert()));
        }}
      >
        OK
      </div>
      <div className="popup-button cancel" onClick={() => dispatch(closeAlert())}>Cancel</div>
    </div>
  );
};

export default connect()(ChangeGloveTypeAlert);
