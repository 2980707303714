import React, { Component } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import Card from '../../components/card/MainCollectionsCard';
import DivButton from '../../components/button/DivButton';

const Left = '/svg/arrow-left.svg';
const Right = '/svg/arrow-right.svg';

class CollectionsSlide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLeft: false,
      isRight: false,
    };

    this.slider = {};
  }

  componentDidMount() {
    this.initailSiderArrows();
  }

  initailSiderArrows() {
    this.setState({
      isLeft: !(this.slider.scrollLeft === 0),
      isRight: !(
        this.slider.scrollWidth ===
        this.slider.scrollLeft + this.slider.offsetWidth
      ),
    });
  }

  renderItems() {
    return this.props.data.map((item, i) => (
      <div
        tabIndex={i}
        role="button"
        onClick={() => this.props.onClick(item.pid_pd)}
        key={shortid.generate()}
      >
        <Card style={styles.cardBox(i === 0 ? 0 : 24)} data={item} />
      </div>
    ));
  }

  render() {
    return (
      <div style={styles.collcectionsBox}>
        <p style={styles.collectionsTitle}>What&#39;s Hot</p>
        <div style={styles.colUnderline} />

        <div style={styles.sliderBox}>
          <div style={styles.sliderArrowsBox}>
            <DivButton onClick={() => (this.slider.scrollLeft -= 234)}>
              <img src={this.state.isLeft ? Left : null} alt="" />
            </DivButton>
            <DivButton onClick={() => (this.slider.scrollLeft += 234)}>
              <img src={this.state.isRight ? Right : null} alt="" />
            </DivButton>
          </div>

          <div
            onScroll={(e) => {
              console.log(e.target.scrollLeft);
              if (this.props.data.length > 4) {
                this.setState({
                  isLeft: !(e.target.scrollLeft === 0),
                  isRight: !(
                    e.target.scrollWidth ===
                    e.target.scrollLeft + e.target.offsetWidth
                  ),
                });
              }
            }}
            style={styles.sliderItemListBox}
            ref={comp => (this.slider = comp)}
          >
            {this.renderItems()}
          </div>
        </div>
      </div>
    );
  }
}

CollectionsSlide.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
};

CollectionsSlide.defaultProps = {
  data: [],
  onClick: () => {},
};

const styles = {
  collcectionsBox: {
    width: 996,
    height: 29 + 16 + 2 + 40 + 210,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    marginTop: 142,
  },
  collectionsTitle: {
    fontSize: 24,
    letterSpacing: 0.2,
    color: '#000',
    fontWeight: '600',
  },
  colUnderline: {
    marginTop: 16,
    width: 60,
    height: 2,
    backgroundColor: '#251f1b',
  },
  sliderBox: {
    width: 996,
    height: 210,
    position: 'relative',
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  sliderArrowsBox: {
    position: 'absolute',
    width: 996 + 20 + 20,
    display: 'flex',
    justifyContent: 'space-between',
    height: 24,
    top: '50%',
    transform: 'translate(0px, -50%)',
    MsTransform: 'translate(0px, -50%)',
    Webkittransform: 'translate(0px, -50%)',
    alignItems: 'center',
    zIndex: 1,
    border: 'none',
  },
  sliderItemListBox: {
    width: 210 * 4 + 24 * 3,
    height: 'auto',
    display: 'flex',
    overflow: 'hidden',
    zIndex: 2,
    margin: 'auto',
  },
  cardBox: marginLeft => ({
    width: 210,
    height: 210,
    marginLeft,
    border: 'solid 1px #eeeeee',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
  }),
};

export default CollectionsSlide;
