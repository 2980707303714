import axios, { get, post } from 'axios';
import {
  none, isNil, find, pickBy,
  // test
} from 'ramda';
import camelize from 'camelize';
// import {Inflector} from 'inflected';
import $ from 'jquery';

import _Fetch from '../../../common/Fetches';
import { getItem } from '../../../common/StorageUtils';


import {
  SELECT_TAB,
  OPEN_ALERT,
  CLOSE_ALERT,
  SELECT_GLOVE_TYPE,
  CHANGE_LEATHER_GRADE,
  SELECT_THROWING_HAND,
  SELECT_SIZE,
  CHANGE_PREVIEW_SIDE,
  SET_PREVIEW_LOADING,
  REQUEST_PRESETS,
  RECEIVE_PRESETS,
  UPDATE_GLOVE,
  // UPLOAD_WRIST_LOGO,
  REQUEST_PREVIEW_IMAGES,
  COMPLETE_PREVIEW_IMAGES,
} from '../constants/ActionTypes';

// axios.defaults.baseURL = 'http://www.gloveworks.net';

import presets from './presets.json';
import presets2 from './presets2.json';

import initData from '../builder.json';
const Inflector = require('inflected')
export function changeGloveType(gloveType) {
  return (dispatch, getState) => {
    dispatch({
      type: SELECT_GLOVE_TYPE,
      gloveType,
    });

    const { defaults, sizes } = getState().builder;
    const size = sizes[gloveType][0];
    dispatch(updateGlove({
      ...defaults[gloveType],
      size,
    }));

    return Promise.resolve();
  };
}

export function selectGloveType(gloveType) {
  return {
    type: OPEN_ALERT,
    alertType: 'CHANGE_GLOVE_TYPE',
    alertProps: { gloveType },
  };
}

export function changeLeatherGrade(leatherGrade) {
  return (dispatch, getState) => {
    const { stampings } = getState().builder;

    dispatch({
      type: CHANGE_LEATHER_GRADE,
      leatherGrade,
      stamping: stampings[leatherGrade.id][0],
      defaults: getState().builder.defaults[getState().glove.gloveType],
    });

    dispatch(fetchPresets());

    return Promise.resolve();
  };
}

export function selectLeatherGrade(leatherGrade) {
  return (dispatch, getState) => {
    if (isNil(getState().glove.leatherGrade)) {
      changeLeatherGrade(leatherGrade)(dispatch, getState);
    } else {
      dispatch({
        type: OPEN_ALERT,
        alertType: 'CHANGE_LEATHER_GRADE',
        alertProps: { leatherGrade },
      });
    }
  };
}

export function selectPreset(preset) {
  return {
    type: OPEN_ALERT,
    alertType: 'SELECT_PRESET',
    alertProps: { preset },
  };
}

export function applyPreset(preset) {
  const gloveTypeKeys = {
    'Single Welting': 'singleWelting',
    'Double Welting': 'doubleWelting',
    'First Baseman Mitt': 'firstBase',
    'Catchers Mitt': 'catcher',
    Softball: 'softball',
  };

  return (dispatch, getState) => {
    const gloveType = gloveTypeKeys[getState().glove.gloveType];

    dispatch({
      type: UPDATE_GLOVE,
      attributes: pickBy((v, k) => !isNil(v), preset.customization[gloveType]),
    });

    return Promise.resolve();
  };
}

export function selectThrowingHand(throwingHand) {
  return {
    type: SELECT_THROWING_HAND,
    throwingHand,
  };
}

export function selectSize(size) {
  return {
    type: SELECT_SIZE,
    size,
  };
}

export function selectTab(tab) {
  return (dispatch, getState) => {
    const {
      gloveType, leatherGrade, throwingHand, size,
    } = getState().glove;

    if (none(isNil, [gloveType, leatherGrade, throwingHand, size])) {
      dispatch({
        type: SELECT_TAB,
        tab,
      });
    } else {
      dispatch({
        type: OPEN_ALERT,
        alertType: 'LEAVE_BASE_PANEL',
      });
    }
  };
}

export function openAlert() {
  return {
    type: OPEN_ALERT,
  };
}

export function closeAlert() {
  return {
    type: CLOSE_ALERT,
  };
}

export function changePreviewSide(side) {
  return {
    type: CHANGE_PREVIEW_SIDE,
    side,
  };
}

export function setPreviewLoading(isLoading) {
  return {
    type: SET_PREVIEW_LOADING,
    isLoading,
  };
}

function requestPresets() {
  return {
    type: REQUEST_PRESETS,
  };
}

function receivePresets(json) {
  const { presets, meta: { prev, next } } = json;

  return {
    type: RECEIVE_PRESETS,
    presets,
    pagination: { prev, next },
  };
}

export function fetchPresets(page = 1) {
  return (dispatch, getState) => {
    dispatch(requestPresets());

    const { gloveType, leatherGrade } = getState().glove;
    // 쿼리로 붙는다
    const params = {
      glove_type: gloveType.replace(/ /g, ''),
      leather_grade: leatherGrade.id,
      page,
    };

    const api = getItem('restAPI').model_desc.custom_preset_get;
    const res = async () => {
      const results = await _Fetch(api, '', []);
      return results;
    };

    const emptyObj = {
      presets: [],
      meta: {
        prev: null,
        next: null,
      },
    };

    return res()
    .then(data => {
      console.log(data);

      if (params.glove_type === 'SingleWelting') {
        const singleData = data.filter(item => item.typ_glove === 1);
        console.log(singleData);
        switch (params.leather_grade) {
          case 1:
            return dispatch(receivePresets(filterLeatherGradeData(singleData, 1, page)));
          case 2:
            return dispatch(receivePresets(filterLeatherGradeData(singleData, 2, page)));
          default:
            return dispatch(receivePresets(filterLeatherGradeData(singleData, 3, page)));
        }
      }

      if (params.glove_type === 'DoubleWelting') {
        return dispatch(receivePresets(emptyObj));
      }

      if (params.glove_type === 'FirstBasemanMitt') {
        return dispatch(receivePresets(emptyObj));
      }

      if (params.glove_type === 'CatchersMitt') {
        return dispatch(receivePresets(emptyObj));
      }

      if (params.glove_type === 'Softball') {
        return dispatch(receivePresets(emptyObj));
      }

      return dispatch(receivePresets(emptyObj));
    })
    .catch(e => console.log(e));
  }
}

function filterLeatherGradeData(singleData, leatherGradeIdx, page) {
  const webTypesArr = Object.values(initData.builder.webTypes);
  const wristTypesArr = Object.values(initData.builder.wristTypes);

  const singleGameData = singleData.filter(item => item.leather_grade === leatherGradeIdx);

  const covertSingleGameData = singleGameData.map(item => {

    const getLeatherGradeName = idx => {
      switch (idx) {
        case 1:
          return 'GAME DAY';
        case 2:
          return 'PRO STEERHIDE';
        default:
          return 'PRO KIP';
      }
    };

    const getPriceAndDesc = (idx) => {
      switch (idx) {
        case 1:
          return {
            price: 99.9,
            description: 'Korean tanned steerhide.',
          }
        case 2:
          return {
            price: 159.9,
            description: 'Japanese tanned pro steerhide.',
          }
        default:
          return {
            price: 199.9,
            description: 'Japanese tanned European kip.',
          }
      }
    };

    const filterColors = (data) => data.split('||');

    // console.log(webColor);
    return {
      id: item.pid_preset,
      leather_grade: {
        id: item.leather_grade,
        name: getLeatherGradeName(item.leather_grade),
        price: getPriceAndDesc(item.leather_grade).price,
        description: getPriceAndDesc(item.leather_grade).description,
      },
      photo: { url: item.uri_preset },
      customization: {
        type: 'single_welting',
        single_welting: {
          web_type: webTypesArr[0].filter(res => res.id === Number(item.web_typ))[0],
          wrist_type: wristTypesArr.filter(res => res.id === Number(item.wrist_typ))[0],
          finger_option: {
            id: filterColors(item.finger_opt)[0],
            name: filterColors(item.finger_opt)[1],
            "price":"0.0",
            "color_selectable":false
          },

          web_color: {
            id: filterColors(item.web_color)[0],
            name: filterColors(item.web_color)[1],
            hex_code: filterColors(item.web_color)[2],
            color_code: filterColors(item.web_color)[3],
            activated: true,
          },
          palm_color: {
            id: filterColors(item.palm_color)[0],
            name: filterColors(item.palm_color)[1],
            hex_code: filterColors(item.palm_color)[2],
            color_code: filterColors(item.palm_color)[3],
            activated: true,
          },
          wrist_color: {
            id: filterColors(item.wrist_color)[0],
            name: filterColors(item.wrist_color)[1],
            hex_code: filterColors(item.wrist_color)[2],
            color_code: filterColors(item.wrist_color)[3],
            activated: true,
          },
          thumb_inside_color: {
            id: filterColors(item.thumb_in_color)[0],
            name: filterColors(item.thumb_in_color)[1],
            hex_code: filterColors(item.thumb_in_color)[2],
            color_code: filterColors(item.thumb_in_color)[3],
            activated: true,
          },
          thumb_outside_color: {
            id: filterColors(item.thumb_out_color)[0],
            name: filterColors(item.thumb_out_color)[1],
            hex_code: filterColors(item.thumb_out_color)[2],
            color_code: filterColors(item.thumb_out_color)[3],
            activated: true,
          },
          index_inside_color: {
            id: filterColors(item.indexfinger_in_color)[0],
            name: filterColors(item.indexfinger_in_color)[1],
            hex_code: filterColors(item.indexfinger_in_color)[2],
            color_code: filterColors(item.indexfinger_in_color)[3],
            activated: true,
          },
          index_outside_color: {
            id: filterColors(item.indexfinger_out_color)[0],
            name: filterColors(item.indexfinger_out_color)[1],
            hex_code: filterColors(item.indexfinger_out_color)[2],
            color_code: filterColors(item.indexfinger_out_color)[3],
            activated: true,
          },
          middle_inside_color: {
            id: filterColors(item.middlefinger_in_color)[0],
            name: filterColors(item.middlefinger_in_color)[1],
            hex_code: filterColors(item.middlefinger_in_color)[2],
            color_code: filterColors(item.middlefinger_in_color)[3],
            activated: true,
          },
          middle_outside_color: {
            id: filterColors(item.middlefinger_out_color)[0],
            name: filterColors(item.middlefinger_out_color)[1],
            hex_code: filterColors(item.middlefinger_out_color)[2],
            color_code: filterColors(item.middlefinger_out_color)[3],
            activated: true,
          },
          ring_inside_color: {
            id: filterColors(item.ringfinger_in_color)[0],
            name: filterColors(item.ringfinger_in_color)[1],
            hex_code: filterColors(item.ringfinger_in_color)[2],
            color_code: filterColors(item.ringfinger_in_color)[3],
            activated: true,
          },
          ring_outside_color: {
            id: filterColors(item.ringfinger_out_color)[0],
            name: filterColors(item.ringfinger_out_color)[1],
            hex_code: filterColors(item.ringfinger_out_color)[2],
            color_code: filterColors(item.ringfinger_out_color)[3],
            activated: true,
          },
          pinky_inside_color: {
            id: filterColors(item.pinky_in_color)[0],
            name: filterColors(item.pinky_in_color)[1],
            hex_code: filterColors(item.pinky_in_color)[2],
            color_code: filterColors(item.pinky_in_color)[3],
            activated: true,
          },
          pinky_outside_color: {
            id: filterColors(item.pinky_out_color)[0],
            name: filterColors(item.pinky_out_color)[1],
            hex_code: filterColors(item.pinky_out_color)[2],
            color_code: filterColors(item.pinky_out_color)[3],
            activated: true,
          },
          finger_option_color: {
            id: filterColors(item.finger_opt_color)[0],
            name: filterColors(item.finger_opt_color)[1],
            hex_code: filterColors(item.finger_opt_color)[2],
            color_code: filterColors(item.finger_opt_color)[3],
            activated: true,
          },
          binding_color: {
            id: filterColors(item.binding_color)[0],
            name: filterColors(item.binding_color)[1],
            hex_code: filterColors(item.binding_color)[2],
            color_code: filterColors(item.binding_color)[3],
            activated: true,
          },
          welting_color: {
            id: filterColors(item.welting_color)[0],
            name: filterColors(item.welting_color)[1],
            hex_code: filterColors(item.welting_color)[2],
            color_code: filterColors(item.welting_color)[3],
            activated: true,
          },
          lacing_color: {
            id: filterColors(item.lacing_color)[0],
            name: filterColors(item.lacing_color)[1],
            hex_code: filterColors(item.lacing_color)[2],
            color_code: filterColors(item.lacing_color)[3],
            activated: true,
          },
          thread_color: {
            id: filterColors(item.stitching_color)[0],
            name: filterColors(item.stitching_color)[1],
            hex_code: filterColors(item.stitching_color)[2],
            color_code: filterColors(item.stitching_color)[3],
            activated: true,
          },
        },
      },
    };
  });

  const maxLength = covertSingleGameData.length;

  const interval = 4;

  const getEnd = (page, maxLength, interval, start) => {
    if (maxLength < interval) return 1;
    if (page === 1) return 3;
    return start + interval;
  };

  const start = page === 1 ? 0 : ((page - 1) * interval) - 1;
  const end = getEnd(page, maxLength, interval, start);

  const prev = page === 1 ? null : page - 1;
  const next = maxLength === end ? null : page + 1;

  const preset = covertSingleGameData.slice(start, end);

  const json = camelize({
    presets: preset,
    meta: {
      prev: prev,
      next: next,
    },
  });

  console.log(json); // preset

  return json;
}

export function updateGlove(attributes) {
  console.log(attributes); // Update Glove
  return (dispatch, getState) => {
    const types = getState().builder.wristPersonalizationTypes;

    if (getState().glove.wristPersonalizationType && attributes.wristType) {
      if (getState().glove.wristPersonalizationType.name === 'DEFAULT' && attributes.wristType.name === 'VELCRO') {
        attributes.wristPersonalizationType = find(t => t.name === 'NONE', types);
      } else if (getState().glove.wristPersonalizationType.name === 'NONE' && attributes.wristType.name === 'OPEN') {
        attributes.wristPersonalizationType = find(t => t.name === 'DEFAULT', types);
      }
    }

    dispatch({
      type: UPDATE_GLOVE,
      attributes,
    });
  };
}

function getCSRFToken() {
  // console.log('ramda에 정의된 펑션을 알아야합니다.');
  try {
    return find(e => e.name === 'csrf-token')(document.getElementsByTagName('meta')).content;
  } catch (e) {
    return null;
  }
}

export function uploadWristLogo(file) {
  console.log('logo 이미지 업데이트');
  const obj = {
    image: {
      file,
      url: URL.createObjectURL(file),
      logoPreview: {
        url: URL.createObjectURL(file),
      },
    },
  };

  return updateGlove({ wristPersonalizationLogo: obj });
}

export function confirmPurchase() {
  return (dispatch) => {
    dispatch({
      type: OPEN_ALERT,
      alertType: 'CONFIRM_PURCHASE',
    });
  };
}

export function requestPreviewImages() {
  return {
    type: REQUEST_PREVIEW_IMAGES,
  };
}

export function completePreviewImages(images) {
  return {
    type: COMPLETE_PREVIEW_IMAGES,
    images,
  };
}

function setGloveForm(glove, images) {
  const gloveTypeKeys = {
    'Single Welting': 'SingleWelting',
    'Double Welting': 'DoubleWelting',
    'First Baseman Mitt': 'FirstBase',
    'Catchers Mitt': 'Catcher',
    Softball: 'Softball',
  };

  $('#new_glove').append(`<input id="glove_leather_grade_id" name="glove[leather_grade_id]" value="${glove.leatherGrade.id}" />`);
  $('#new_glove').append(`<input id="glove_throwing_hand_id" name="glove[throwing_hand_id]" value="${glove.throwingHand.id}" />`);
  $('#new_glove').append(`<input id="glove_size_id" name="glove[size_id]" value="${glove.size.id}" />`);
  $('#new_glove').append(`<input id="glove_customization_type" name="glove[customization_type]" value="${gloveTypeKeys[glove.gloveType]}" />`);

  $.each(pickBy((v, k) => v && typeof (v) === 'object' && v.id, glove), (k, v) => {
    const name = `${Inflector.underscore(k)}_id`;
    $('#new_glove').append(`<input id="glove_customization_attributes_${name}" name="glove[customization_attributes][${name}]" value="${v.id}" />`);
  });

  $.each(pickBy((v, k) => typeof (v) !== 'object', glove), (k, v) => {
    const name = `${Inflector.underscore(k)}`;
    $('#new_glove').append(`<input id="glove_customization_attributes_${name}" name="glove[customization_attributes][${name}]" value="${v}" />`);
  });

  $('#new_glove').append(`<input id="glove_image_1" name="glove[image_1]" value="${images[0]}" />`);
  $('#new_glove').append(`<input id="glove_image_2" name="glove[image_2]" value="${images[1]}" />`);
  $('#new_glove').append(`<input id="glove_image_3" name="glove[image_3]" value="${images[2]}" />`);

  const request = $('#glove_additional_request').val();
  $('#new_glove').append(`<input id="glove_request" name="glove[request]" value="${request}" />`);
}

export function submitOrder() {
  return (dispatch, getState) => {
    setGloveForm(getState().glove, getState().preview.images);

    $('#new_glove').submit();

    return Promise.resolve();
  };
}
