import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import DivButton from '../button/DivButton';

function renderRow(menu, history) {
  const arr = [];

  menu.map((item, i, thisArg) => {
    if (i + 1 === thisArg.length) {
      return arr.push(<p key={shortid.generate()} style={styles.lastMenuText}>
        {item}
      </p>);
    }
    return arr.push(<DivButton
      onClick={item === 'Shop' ? () => history.push('/shop') : () => {}}
      key={shortid.generate()}
      style={styles.menuText}
    >
      {item} &gt;&nbsp;
    </DivButton>);
    // return arr.push(<p key={shortid.generate()} style={styles.menuText}>&nbsp;{item} &gt;&nbsp;</p>);
  });

  return arr;
}

class Step extends PureComponent {
  render() {
    const { history } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.innerContainer}>
          <DivButton
            onClick={() => history.push('/')}
            style={styles.firstMenuText}
          >
            {' '}
            HOME &gt;&nbsp;
          </DivButton>
          {renderRow(this.props.menu, history)}
        </div>
      </div>
    );
  }
}

Step.propTypes = {
  history: PropTypes.object,
  menu: PropTypes.array,
};

Step.defaultProps = {
  history: PropTypes.object,
  menu: {},
};

const styles = {
  container: {
    width: '100%',
    height: 34,
    backgroundColor: '#f2f1ef',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: 910,
    height: '100%',
    paddingLeft: 30,
    boxSizing: 'border-box',
  },
  firstMenuText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    letterSpacing: 0.4,
    float: 'left',

    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  menuText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    letterSpacing: 0.4,
    float: 'left',

    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  lastMenuText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    letterSpacing: 0.4,
    float: 'left',
    fontWeight: '600',
    margin:0,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
};

export default withRouter(Step);
