// export const SERVER_URL = process.env.NODE_ENV === 'development' ?
//   'http://14.32.136.156:7410' : 'https://server.gloveworks.net';

// 7370

export const SERVER_URL = process.env.NODE_ENV === 'development' ?
  'http://localhost:7376' : 'https://server.gloveworks.net';
  // 'https://server.gloveworks.net' : 'https://server.gloveworks.net';

export default SERVER_URL;
