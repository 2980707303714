import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const FingerOption = ({
  options,
  currentOption,
  onOptionClick,
  customPythons,
  currentCustomPython,
  onCustomPythonClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <div className="finger-option-type-wrap">
      <h4>FINGER OPTION</h4>
      {options.map(option =>
          (<div
            key={option.id}
            className={classNames('finger-option-button', { active: currentOption && currentOption.id === option.id })}
            onClick={() => onOptionClick(option)}
          >
            {option.name}
          </div>))}
    </div>
    {currentOption && currentOption.colorSelectable &&
    <CustomPythonButtons
      title="FINGER OPTION COLOR"
      className="finger-option-color-wrap"
      images={customPythons}
      currentCustomImage={currentCustomPython}
      onClick={onCustomPythonClick}
    />
      }
  </div>
);

// TODO: receive from API
const mapStateToProps = (state) => {
  const { fingerOption, fingerOptionPython } = state.glove;
  return {
    options: [{
      id: 1,
      name: 'NONE',
      colorSelectable: false,
    }, {
      id: 2,
      name: 'FINGER HOOD',
      colorSelectable: true,
    }, {
      id: 3,
      name: 'FINGER PAD',
      colorSelectable: true,
    }],
    currentOption: fingerOption,
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    currentCustomPython: fingerOptionPython,
  };
};

const mapDispatchToProps = dispatch => ({
  onOptionClick: option => dispatch(updateGlove({ fingerOption: option })),
  onCustomPythonClick: python => dispatch(updateGlove({ fingerOptionPython: python })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(FingerOption), 'fingerSide');
