import React, { PureComponent } from "react";
import { Route, withRouter } from "react-router-dom";
import Header from "../containers/newHeader/NewHeader";
import Footer from "../containers/footer/Footer";

import Main from "../pages/main/Main";
import Login from "../pages/login/Login";
import ResetPassword from "../pages/login/ResetPassword";
import SignUp from "../pages/signUp/SignUp";
import Shop from "../pages/shop/Shop";
import ItemDetail from "../pages/shop/ItemDetail";
import Customize from "../pages/customize/Customize";
import Cart from "../pages/cart/Cart";
import OrderReview from "../pages/cart/NewOrderReview";
import OurStory from "../pages/ourStory/OurStory";
import Gallery from "../pages/gallery/Gallery";
import Faq from "../pages/faq/Faq";
import ShoppingPolicy from "../pages/shoppingPolicy/ShoppingPolicy";
import Contact from "../pages/contact/Contact";
import OrderHistory from "../pages/myAccount/OrderHistory";
import OrderTracking from "../pages/orderTracking/OrderTracking";
import RestAPI from "../pages/restApi/RestAPI";
import TermsOfService from "../pages/termsOfService/TermsOfService";
import PrivacyPolicy from "../pages/privacypolicy/PrivacyPolicy";
import { BASE_URL, WWW_URL, KR_URL, JP_URL, EN_URL } from "../common/Constants";
import "../common/GlobalStyles";
import axios from "axios";
import RouteChangeTracker from "../lib/builder/components/RouteChangeTracker";

class RootRouter extends PureComponent {
  componentDidMount() {
    document.body.style.backgroundColor = "#fafafa";
    this.getCurrentUrl();
  }

  //현재 url 체크
  getCurrentUrl = () => {
    const currentUrl = window.location.origin;
    if (currentUrl === BASE_URL || currentUrl === WWW_URL) {
      this.setCountry();
    }
  };

  //url 이동
  setCountry = async () => {
    const response = await axios.get(
      "https://extreme-ip-lookup.com/json/?key=ICozJUFKNP5KCFbghfJ3"
    );
    const pathname = window.location.pathname ? window.location.pathname : "";
    const search = window.location.search ? window.location.search : "";
    const path = pathname + search;
    const countryCode = response.data.countryCode;
    switch (countryCode) {
      case "KR":
        return (document.location.href = KR_URL + path);
      case "JP":
        return (document.location.href = JP_URL + path);
      default:
        document.location.href = EN_URL + path;
    }
  };
  render() {
    return (
      <div style={{ backgroundColor: "#fff" }}>
        <Header />
        <RouteChangeTracker history={this.props.history} />
        <Route exact path="/" component={Main} />

        <Route path="/login" component={Login} />

        <Route path="/reset/password" component={ResetPassword} />

        <Route path="/signUp" component={SignUp} />

        <Route exact path="/shop" component={Shop} />

        <Route path="/shop/:id" component={ItemDetail} />

        <Route path="/customize" component={Customize} />

        <Route path="/cart" component={Cart} />

        <Route path="/orderReview" component={OrderReview} />

        <Route path="/ourStory" component={OurStory} />

        <Route path="/gallery" component={Gallery} />

        <Route path="/faq" component={Faq} />

        <Route path="/shoppingPolicy" component={ShoppingPolicy} />

        <Route path="/termsOfService" component={TermsOfService} />

        <Route path="/privacyPolicy" component={PrivacyPolicy} />

        <Route path="/contact" component={Contact} />

        <Route path="/orderHistory" component={OrderHistory} />

        <Route path="/orderTracking" component={OrderTracking} />

        <Route path="/restAPI" component={RestAPI} />

        <Footer />
      </div>
    );
  }
}

export default withRouter(RootRouter);
