import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

function renderCheckBox(isChecked, isDiv, styles, checkImg) {
  if (isChecked) {
    if (isDiv) {
      return <div style={styles.check} />;
    }
    return (<img
      style={styles.img}
      src={checkImg}
      alt="checkBox"
    />);
  }
  return null;
}

class CheckBox extends PureComponent {
  render() {
    const {
      styles, isChecked, checkImg, isDiv,
    } = this.props;

    return (
      <div
        role="button"
        tabIndex="0"
        style={typeof styles.container === 'object' ? styles.container : styles.container(isChecked)}
        onClick={() => this.props.onClick()}
      >
        { renderCheckBox(isChecked, isDiv, styles, checkImg) }
      </div>
    );
  }
}

CheckBox.propTypes = {
  styles: PropTypes.object,
  isChecked: PropTypes.bool,
  checkImg: PropTypes.string,
  isDiv: PropTypes.bool,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {
  isDiv: false,
  isChecked: false,
  styles: {},
  checkImg: '',
  onClick: () => {},
};

export default CheckBox;
