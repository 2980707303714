import React from 'react';
import { connect } from 'react-redux';
import className from 'classnames';
import { isEmpty } from 'ramda';

import { withTranslation } from 'react-i18next';
import { fetchPresets, selectPreset } from '../actions/creators';
const tip = '/assets/images/tip.png';

const Presets = ({
  presets,
  pagination,
  fetchPage,
  onPresetSelect,
  t,
}) => (
  <div className="custom-glove-presets-wrap">
    <h4>
       {t('inspirations')}&nbsp;
      <div className="tip">
        <img
          alt="Tip"
          className="tip-img"
          src={tip}
        />
        <div className="tip-detail">
          {t('inspirationsNotice')}
        </div>
      </div>
    </h4>
    <div id="custom-glove-presets">
      {(() => {
          if (isEmpty(presets)) {
            return (
              <p className="description">
                {t('choosePreferred')}
              </p>
            );
          }
            return (
              <div>
                <ul className="glove-presets-list clearfix">
                  {presets.map(preset =>
                    (<li
                      key={preset.id}
                      className="glove-preset"
                      onClick={() => onPresetSelect(preset)}
                    >
                      <img
                        src={preset.photo.url}
                        alt=""
                      />
                     </li>))}
                </ul>
                <div className="glove-presets-page-buttons">
                  <button
                    className={className('previous-glove-presets', { disabled: pagination.prev === null })}
                    style={{cursor: pagination.prev === null ? 'arrow':'pointer'}}
                    onClick={() => pagination.prev !== null && fetchPage(pagination.prev)}
                  >
                    « Previous page
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className={className('next-glove-presets', { disabled: pagination.next === null })}
                    style={{cursor: pagination.next === null ? 'arrow':'pointer'}}
                    onClick={() => pagination.next !== null && fetchPage(pagination.next)}
                  >
                    Next Page »
                  </button>
                </div>
              </div>
            );
        })()}
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const { presets = [], pagination } = state.presets;

  return {
    presets,
    pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchPage: page => dispatch(fetchPresets(page)),
  onPresetSelect: preset => dispatch(selectPreset(preset)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Presets));
