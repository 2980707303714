import {
  OPEN_ALERT,
  CLOSE_ALERT,
} from '../constants/ActionTypes';

export default function alert(state = {}, action) {
  switch (action.type) {
    case OPEN_ALERT:
      return {
        isOpen: true,
        alertType: action.alertType,
        alertProps: action.alertProps,
      };
    case CLOSE_ALERT:
      return {
        isOpen: false,
      };
    default:
      return state;
  }
}
