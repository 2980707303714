import React, { Component } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import DivButton from '../../components/button/DivButton';

import { settingCostWithCompare } from '../../common/Utils';

const Left = '/svg/arrow-left.svg';
const Right = '/svg/arrow-right.svg';
const sale = '/svg/sale_big.svg';

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgNum: 1,
      isLeft: false,
      isRight: false,
    };

    this.slider = {};
  }

  componentDidMount() {
    this.initailSiderArrows();
  }

  initailSiderArrows() {
    this.setState({
      isLeft: !(this.slider.scrollLeft === 0),
      isRight: !(this.slider.scrollWidth === this.slider.scrollLeft + this.slider.offsetWidth),
    });
  }

  renderSumnail() {
    return this.props.data.map((item, i) => (
      <div
        role="button"
        tabIndex={i}
        onClick={() => this.setState({ imgNum: i + 1 })}
        key={shortid.generate()}
      >
        <img
          src={item}
          alt=""
          style={styles.sumnailImg(i === this.state.imgNum - 1, i !== 0 ? 20 : 0)}
        />
      </div>));
  }

  render() {
    const { i18n } = this.props;
    // console.log(this.props.parentData);
    return (
      <div style={styles.itemDetailBox}>
        { settingCostWithCompare(i18n.language, this.props.parentData.pd).compare > 0 ? <img src={sale} style={styles.sale} alt="" /> : null }
        <img
          src={this.props.data[this.state.imgNum - 1]}
          alt=""
          style={styles.itemImg}
        />

        <div style={styles.sliderBox}>

          <div style={styles.sliderArrowsBox}>
            <DivButton
              onClick={() => this.slider.scrollLeft -= 130}
            >
              <img src={this.state.isLeft ? Left : null} alt="" />
            </DivButton>
            <DivButton
              onClick={() => this.slider.scrollLeft += 130}
            >
              <img src={this.state.isRight ? Right : null} alt="" />
            </DivButton>
          </div>

          <div
            onScroll={(e) => {
              console.log(e.target.scrollLeft);
              if (this.props.data.length > 4) {
                this.setState({
                  isLeft: !(e.target.scrollLeft === 0),
                  isRight: !(e.target.scrollWidth === e.target.scrollLeft + e.target.offsetWidth),
                });
              }
            }}
            style={styles.sumnailBox}
            ref={comp => this.slider = comp}
          >
            {this.renderSumnail()}
          </div>

        </div>

      </div>
    );
  }
}

Gallery.propTypes = {
  data: PropTypes.array,
  i18n: PropTypes.object,
  parentData: PropTypes.object,
};

Gallery.defaultProps = {
  data: [],
  i18n: {},
  parentData: {},
};

const styles = {
  itemDetailBox: {
    height: 500 + 110 + 15,
    width: 500,
    marginTop: 32,
    position: 'relative',
  },
  itemImg: {
    width: 500,
    height: 500,
  },
  sliderBox: {
    width: 500,
    height: 110,
    position: 'relative',
    marginTop: 15,
  },
  sumnailImg: (isChoose, marginLeft = 0) => ({
    marginLeft,
    width: 110,
    height: 110,
    cursor: 'pointer',
    border: isChoose ? 'solid 2px #c77b46' : 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    float: 'left',
  }),
  sliderArrowsBox: {
    position: 'absolute',
    width: 500 + 36 + 36,
    display: 'flex',
    justifyContent: 'space-between',
    transform: 'translate(-36px, -50%)',
    MsTransform: 'translate(-36px, -50%)',
    Webkittransform: 'translate(-36px, -50%)',
    height: 24,
    top: '50%',
    alignItems: 'center',
    zIndex: 1,
  },
  sumnailBox: {
    width: 500,
    display: 'flex',
    overflow: 'hidden',
    zIndex: 2,
    position: 'relative',
  },
  sale: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
};

export default withTranslation('translations')(Gallery);
