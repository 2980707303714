import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import ColorButtons from '../ColorButtons';

const Thread = ({
  colors,
  currentColor,
  onColorClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <ColorButtons
      title="STITCHING COLOR"
      className="thread-color-wrap"
      colors={colors}
      currentColor={currentColor}
      onClick={onColorClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { threadColor } = state.glove;
  return {
    colors: state.builder.threadColors,
    currentColor: threadColor,
  };
};

const mapDispatchToProps = dispatch => ({
  onColorClick: color => dispatch(updateGlove({ threadColor: color })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Thread);
