import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Input from '../../components/input/Input';

import WithSetUserManage from '../../common/WithSetUserManage';
import { getItem, removeItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';
import { getCookie, setCookie } from '../../common/Utils';
import { UploadImage } from '../../common/S3';
import { Consumer } from '../../Context';
import Modal from '../../containers/baseModal/BaseModal';

const facebookLogo = '/svg/facebook2.svg';
const googleLogo = '/svg/google2.svg';
const loader = '/assets/images/ajax_loader.gif';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      pw: null,
      isLoaded: false,
      isModal: false,
      bodyText: '',
    };

    this.onNext = this.closeModal;
  }

  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    window.document.body.addEventListener('keyup', this.onkeyup);
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('keyup', this.onkeyup);
  }

  onkeyup = (e) => {
    const { userManage } = this.props;
    if (e.keyCode === 13) return this.login(userManage);

    return null;
  };

  successfullyLogin = async (userData) => {
    setCookie('userData', JSON.stringify(userData));
    // 세션에 담긴 카트 아이템을 할당
    const cartData = getItem('cart') === null ? [] : getItem('cart');

    // 카트 데이터 만큼 해당 유저 카트 테이블에 포스트
    const promises = cartData.map(async (item) => {
      // console.log(item);
      try {
        const itemBody = JSON.parse(item.body);
        // 커스텀 제품 일때
        if (item.b_custom === 1) {
          // s3 upload
          const imagePromises = itemBody.images.map(async (obj) => {
            const result = await UploadImage(obj);
            return result;
          });
          const images = await Promise.all(imagePromises);
          console.log(images);

          // item에는 pid_user를 제외한 나머지 필요한 컬럼 값들이 포함
          const api = getItem('restAPI').model_desc.cart_insert;
          const body = {
            cart: {
              pid_user: userData.user.pid_user,
              ...item,
              body: JSON.stringify({
                ...itemBody,
                images,
              }),
              uri_pd: images[0].Location,
            },
          };
          console.log(JSON.parse(body.cart.body));
          await _Fetch(api, '', body);
        } else {
          // 기성품일때
          const api = getItem('restAPI').model_desc.cart_insert;
          const body = {
            cart: {
              pid_user: userData.user.pid_user,
              ...item,
            },
          };
          await _Fetch(api, '', body);
        }
      } catch (e) {
        console.log(e);
      }
    });
    // 반복 비동기 작업마다 리턴 되는 값을 배열로 지님 타입은 배열
    const result = await Promise.all(promises);
    console.log(result);

    const callCount = async () => {
      const url = getItem('restAPI').model_desc.cart_count_my;
      const query = `?pid_user=${userData.user.pid_user}`;
      const res = await _Fetch(url, query, []);
      console.log(res);
      const num = Object.values(res[0])[0];
      return num;
    };

    const count = await callCount();
    console.log(count);

    const { actions } = this.props;
    actions.setCount(count);

    removeItem('cart');
    this.setState({ isLoaded: false });
    return this.props.history.push('/');
  };

  async login(userManage) {
    try {
      if (!this.state.email) {
        return this.openModal('please make out your email');
      }
      if (!this.state.pw) {
        return this.openModal('please make out your password');
      }

      this.setState({ isLoaded: true });

      const userData = await userManage.login(this.state.email, this.state.pw);
      console.log('userData', userData);
      if (userData.status === 1125) {
        return this.openModal('Invalid account or password.');
      }
      if (userData.status === 1500) {
        return this.openModal('Invalid account or password.');
      }
      const hasQty = Object.prototype.hasOwnProperty.call(userData, 'token');
      // 로그인 토큰이 존재 하는경우
      if (hasQty) {
        return this.successfullyLogin(userData);
      }
      return this.openModal("Couldn't find your GW Account or Wrong password");
    } catch (e) {
      console.log(e);
      return this.openModal(e);
    }
  }

  async goolgeLogin() {
    try {
      this.setState({ isLoaded: true });

      const userData = await this.props.userManage.googleLogin();
      console.log('google user data', userData);

      const hasQty = Object.prototype.hasOwnProperty.call(userData, 'token');
      // 로그인 토큰이 존재 하는경우
      if (hasQty) {
        return this.successfullyLogin(userData);
      }
      return this.openModal("Couldn't find your GW Account or Wrong password");
    } catch (e) {
      return this.openModal(e);
    }
  }

  async fbLogin() {
    try {
      this.setState({ isLoaded: true });

      const userData = await this.props.userManage.fbLogin();
      console.log('facebook user data', userData);

      const hasQty = Object.prototype.hasOwnProperty.call(userData, 'token');
      // 로그인 토큰이 존재 하는경우
      if (hasQty) {
        return this.successfullyLogin(userData);
      }
      return this.openModal("Couldn't find your GW Account or Wrong password");
    } catch (e) {
      return this.openModal(e);
    }
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: '',
      isLoaded: false,
    });
  };

  render() {
    const { history, userManage, t } = this.props;
    const { isLoaded, isModal, bodyText } = this.state;

    // 로그인시 http://localhost:7311/login 주소로 접속시 메인 화면으로
    if (getCookie('userData')) return setTimeout(() => history.replace('/'), 1);
    if (!userManage) return <div style={styles.containerEmpty} />;
    return (
      <div style={styles.container}>
        <Modal
          isModal={isModal}
          bodyText={bodyText}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        {isLoaded ? (
          <div style={styles.demmer}>
            <img src={loader} alt="" />
          </div>
        ) : null}
        <div style={styles.loginBox}>
          <div style={styles.left}>
            <p style={styles.login}>{t('Login')}</p>
            <Input
              onChange={email => this.setState({ email })}
              style={styles.loginInput}
              placeholder="Email"
              placeholderColor="#868686"
              type="text"
            />

            <Input
              onChange={pw => this.setState({ pw })}
              style={styles.pwInput}
              placeholder="Password"
              placeholderColor="#868686"
              type="password"
            />

            <div
              role="button"
              tabIndex="0"
              style={styles.loginBtn}
              onClick={() => this.login(userManage)}
            >
              {t('LoginButton')}
            </div>
          </div>
          <div style={styles.right}>
            <p style={styles.rightNotice}>
              {t('LoginDesc')}
            </p>

            <div
              role="button"
              tabIndex="0"
              style={styles.accountBtn}
              onClick={() => history.push('/signUp')}
            >
              {t('LoginCreateAccount')}
            </div>

            <p style={styles.or}>OR</p>

            <div
              role="button"
              tabIndex="0"
              style={styles.facebookBtn}
              onClick={() => this.fbLogin()}
            >
              {t('LoginSignInFacebook')}
              <img src={facebookLogo} alt="" style={styles.facebookLogo} />
            </div>

            <div
              role="button"
              tabIndex="0"
              style={styles.googleBtn}
              onClick={() => this.goolgeLogin()}
            >
              {t('LoginSignInGoogle')}
              <img src={googleLogo} alt="" style={styles.facebookLogo} />
            </div>
          </div>
        </div>

        <div style={styles.bottomNoticeBox}>
          <p style={styles.noticeText}>
            {t('LoginForgotDesc')} &nbsp;
          </p>
          <div
            role="button"
            tabIndex="0"
            onClick={() => history.push('reset/password')}
          >
            <u style={styles.noticeTextWithUnderline}>{t('LoginForgotPassword')}</u>
          </div>

          <p style={styles.noticeText}>?</p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  demmer: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerEmpty: {
    width: '100%',
    height: 727,
  },
  loginBox: {
    display:'flex',
    width: 800,
    height: 335,
    background: '#fff',
    marginTop: 100,
  },
  left: {
    width: '50%',
    height: '100%',
    borderRight: '1px solid #d9d9d9',
    float: 'left',
  },
  login: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: 24,
    color: '#000',
    marginTop: 45,
    marginLeft: 60,
  },
  loginInput: {
    marginTop: 21,
    width: 280 - 30,
    height: 42,
    border: '1px solid #ddd',
    outline: 'none',

    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#251f1b',
    marginLeft: 60,
  },
  pwInput: {
    marginTop: 15,
    width: 280 - 30,
    height: 42,
    border: '1px solid #ddd',
    outline: 'none',

    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#251f1b',
    marginLeft: 60,
  },
  checkBox: {
    width: 12 + 7 + 107,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 19,
    marginLeft: 60,
  },
  ckecedText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#666666',
  },
  loginBtn: {
    cursor: 'pointer',
    width: 280,
    height: 42,
    backgroundColor: '#000',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',

    marginTop: 54,
    marginLeft: 60,
  },
  right: {
    width: 399,
    height: '100%',
    float: 'right',
    textAlign: 'center',
  },
  rightNotice: {
    width: 280,
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    marginTop: 46,
    lineHeight: 1.29,
    color: '#666666',
    display: 'inline-block',
    textAlign: 'left',
  },
  accountBtn: {
    cursor: 'pointer',
    width: 280,
    height: 42,
    backgroundColor: '#000',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',

    margin: 'auto',
    marginTop: 12,
  },
  or: {
    marginTop: 16,
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#251f1b',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  facebookBtn: {
    cursor: 'pointer',
    width: 280,
    height: 42,
    backgroundColor: '#3b5998',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',

    margin: 'auto',
    marginTop: 19,
  },
  facebookLogo: {
    marginLeft: 5,
  },
  googleBtn: {
    cursor: 'pointer',
    width: 280,
    height: 42,
    backgroundColor: '#de1e00',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold',

    margin: 'auto',
    marginTop: 20,
  },
  bottomNoticeBox: {
    width: 800,
    height: 70,
    backgroundColor: '#f3f3f3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 219,
  },
  noticeText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#5f5f5f',
  },
  noticeTextWithUnderline: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#5f5f5f',
    cursor: 'pointer',
  },
};

Login.propTypes = {
  history: PropTypes.object,
  userManage: PropTypes.object,
  actions: PropTypes.object,
  t: PropTypes.func,
};

Login.defaultProps = {
  history: {},
  userManage: {},
  actions: null,
  t: () => {},
};

const defaultMapContextToProps = value => ({
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withRouter(WithSetUserManage()(withTranslation('translations')(Login))));
