import React from 'react';

const arrow = './svg/slide-copy.svg';

function Arrow({ direction, onClick }) {
  const style = {
    wrapper: {
      position: 'absolute',
      top: '50%',
      height: '32px',
      width: '32px',
      textAlign: 'center',
      background: 'rgba(231, 236, 239, 0.4)',
      borderRadius: 16,
      boxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      zIndex: 1,
      marginTop: '-20px',
    },
    arrow: {},
  };
  if (direction === 'left') {
    style.wrapper.left = '10px';
    style.arrow.transform = 'rotate(-180deg)';
  } else if (direction === 'right') {
    style.wrapper.right = '10px';
  }
  return (
    <div className={`arrow-${direction}`} style={style.wrapper} onClick={onClick} >
      <img alt="" src={arrow} style={style.arrow} />
    </div>
  );
}

const ArrowLeft = ({ onClick }) => (
  <Arrow direction="left" onClick={onClick} />
);

const ArrowRight = ({ onClick }) => (
  <Arrow direction="right" onClick={onClick} />
);

export {
  ArrowLeft,
  ArrowRight,
};
