import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import {
  getCookie,
  getCostSymbol,
  returnComma,
} from '../../common/Utils';

import DivButton from '../button/DivButton';

import SingleWeltingSummary from '../../lib/builder/components/summary/SingleWeltingSummary';
import DoubleWeltingSummary from '../../lib/builder/components/summary/DoubleWeltingSummary';
import FirstBasemanMittSummary from '../../lib/builder/components/summary/FirstBasemanMittSummary';
import CatchersMittSummary from '../../lib/builder/components/summary/CatchersMittSummary';
import SoftballSummary from '../../lib/builder/components/summary/SoftballSummary';


const SummaryComponents = {
  'Single Welting': SingleWeltingSummary,
  'Double Welting': DoubleWeltingSummary,
  'First Baseman Mitt': FirstBasemanMittSummary,
  'Catchers Mitt': CatchersMittSummary,
  Softball: SoftballSummary,
};

class ConfirmCartModal extends Component {
  render() {
    const { data } = this.props;
    const body = JSON.parse(data.body);
    console.log(body);
    const Summary = SummaryComponents[body.glove.gloveType];
    const userData = getCookie('userData') === '' ? null : JSON.parse(getCookie('userData'));
    return (
      <div>
        <DivButton style={styles.overlay} onClick={() => this.props.onClose()} >
          gloveworks
        </DivButton>
        <div className="custom-glove-cart-popup custom-glove-popup-confirmation-cart">
          <div className="base-alert-title">Order Confirmation</div>
          <button className="close" onClick={() => this.props.onClose()} />
          <div className="popup-row clearfix">
            <div className="popup-col-4 popup-overview">
              <div className="pull-left price-wrap">
                TOTAL<br />
                <span className="price">
                  {getCostSymbol(data.typ_cost)}
                  {data.typ_cost === 1 ? returnComma(data.cost.toFixed(2)) : returnComma(data.cost)}
                </span>
              </div>
              <div className="pull-right">
                <button className="proceed-to-purchase" onClick={() => this.props.onClose()}>
                  CLOSE
                </button>
              </div>
            </div>
          </div>

          <div className="popup-row clearfix">
            {body.images.map((item, i) =>
              (<div key={shortid.generate()} className="popup-col-1">
                <img
                  alt=""
                  src={userData ? item.Location : item}
                  className="overview-image"
                />
              </div>))}
          </div>

          <Summary glove={body.glove} />

          { body.additionalRequest ?
            <div className="popup-row clearfix">
              <div className="popup-col-4 additional_requirements">
                <h2>Additional Request</h2>
                <p>{body.additionalRequest}</p>
              </div>
            </div>
            : null
          }

        </div>
      </div>
    );
  }
}

ConfirmCartModal.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
};

ConfirmCartModal.defaultProps = {
  onClose: () => {},
  data: null,
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    color: 'transparent',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 255,
  },
};

export default ConfirmCartModal;
