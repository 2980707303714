import GloveRenderer from './GloveRenderer';

export default class FirstBasemanMittRenderer extends GloveRenderer {
  constructor(props) {
    super(props);
    this.gloveType = 'First Baseman Mitt';
  }

  renderWebSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let wristShadowImageKey, palmShadowImageKey, uShapeShadowImageKey,
        thumbSideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.wristPython.url !== undefined && glove.wristPython.url !== null && glove.wristPython.name.toLowerCase().includes("monogram")) {
      wristShadowImageKey = 'shadowPython';
    } else if (glove.wristPython.name.toLowerCase().includes("paisley")) {
      wristShadowImageKey = 'shadowPaisley';
    } else {
      wristShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.uShapePython.url !== undefined && glove.uShapePython.url !== null && glove.uShapePython.name.toLowerCase().includes("monogram")) {
      uShapeShadowImageKey = 'shadowPython';
    } else if (glove.uShapePython.name.toLowerCase().includes("paisley")) {
      uShapeShadowImageKey = 'shadowPaisley';
    } else {
      uShapeShadowImageKey = 'shadow';
    }
    if (glove.thumbSidePython.url !== undefined && glove.thumbSidePython.url !== null && glove.thumbSidePython.name.toLowerCase().includes("monogram")) {
      thumbSideShadowImageKey = 'shadowPython';
    } else if (glove.thumbSidePython.name.toLowerCase().includes("paisley")) {
      thumbSideShadowImageKey = 'shadowPaisley';
    } else {
      thumbSideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('wrist', glove.wristPython.hexCode, wristShadowImageKey),
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('uShape', glove.uShapePython.hexCode, uShapeShadowImageKey),
      this.renderLayer('thumbSide', glove.thumbSidePython.hexCode, thumbSideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      let webLeatherImageKey,
        webLacingImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id === 49) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPython';
        } else {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id === 49) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPaisley';
        } else {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPaisley';
        }
      } else {
        if (glove.webType.id === 49) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1Shadow';
        } else {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2Shadow';
        }
      }

      return Promise.all([
        this.renderLayer(webLeatherImageKey, glove.webPython.hexCode, webShadowImageKey),
        this.renderLayer(webLacingImageKey, glove.lacingColor.hexCode, webShadowImageKey),
      ]);
    }).then(() => {
      let webThreadImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id === 49) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPython';
        } else {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id === 49) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPaisley';
        } else {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPaisley';
        }
      } else {
        if (glove.webType.id === 49) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1Shadow';
        } else {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2Shadow';
        }
      }

      return this.renderLayer(webThreadImageKey, glove.threadColor.hexCode, webShadowImageKey);
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })

  renderFingerSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let webShadowImageKey;
    let palmShadowImageKey, wristShadowImageKey,
        uShapeShadowImageKey, pinkySideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
      webShadowImageKey = 'shadowPython';
    } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
      webShadowImageKey = 'shadowPaisley';
    } else {
      webShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.wristPython.url !== undefined && glove.wristPython.url !== null && glove.wristPython.name.toLowerCase().includes("monogram")) {
      wristShadowImageKey = 'shadowPython';
    } else if (glove.wristPython.name.toLowerCase().includes("paisley")) {
      wristShadowImageKey = 'shadowPaisley';
    } else {
      wristShadowImageKey = 'shadow';
    }
    if (glove.uShapePython.url !== undefined && glove.uShapePython.url !== null && glove.uShapePython.name.toLowerCase().includes("monogram")) {
      uShapeShadowImageKey = 'shadowPython';
    } else if (glove.uShapePython.name.toLowerCase().includes("paisley")) {
      uShapeShadowImageKey = 'shadowPaisley';
    } else {
      uShapeShadowImageKey = 'shadow';
    }
    if (glove.pinkySidePython.url !== undefined && glove.pinkySidePython.url !== null && glove.pinkySidePython.name.toLowerCase().includes("monogram")) {
      pinkySideShadowImageKey = 'shadowPython';
    } else if (glove.pinkySidePython.name.toLowerCase().includes("paisley")) {
      pinkySideShadowImageKey = 'shadowPaisley';
    } else {
      pinkySideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('web', glove.webPython.hexCode, webShadowImageKey),
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('wrist', glove.wristPython.hexCode, wristShadowImageKey),
      this.renderLayer('uShape', glove.uShapePython.hexCode, uShapeShadowImageKey),
      this.renderLayer('pinkySide', glove.pinkySidePython.hexCode, pinkySideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  })

  renderPalmSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let palmShadowImageKey,
        thumbSideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.thumbSidePython.url !== undefined && glove.thumbSidePython.url !== null && glove.thumbSidePython.name.toLowerCase().includes("monogram")) {
      thumbSideShadowImageKey = 'shadowPython';
    } else if (glove.thumbSidePython.name.toLowerCase().includes("paisley")) {
      thumbSideShadowImageKey = 'shadowPaisley';
    } else {
      thumbSideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('thumbSide', glove.thumbSidePython.hexCode, thumbSideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      let webLeatherImageKey,
        webLacingImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id === 49) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPython';
        } else {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id === 49) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPaisley';
        } else {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPaisley';
        }
      } else {
        if (glove.webType.id === 49) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1Shadow';
        } else {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2Shadow';
        }
      }

      return Promise.all([
        this.renderLayer(webLeatherImageKey, glove.webPython.hexCode, webShadowImageKey),
        this.renderLayer(webLacingImageKey, glove.lacingColor.hexCode, webShadowImageKey),
      ]);
    }).then(() => {
      let webThreadImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id === 49) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPython';
        } else {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id === 49) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPaisley';
        } else {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPaisley';
        }
      } else {
        if (glove.webType.id === 49) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1Shadow';
        } else {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2Shadow';
        }
      }

      return this.renderLayer(webThreadImageKey, glove.threadColor.hexCode, webShadowImageKey);
    })
      .then(() => this.renderStampingLayer('stampingGW', glove.palmPython.hexCode, 'shadow'))
      .then(() => {
        if (glove.stamping == null) {
          return Promise.resolve();
        }
        if (glove.stamping.id === 1) {
          return this.renderStampingLayer('stampingGameDay1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 2) {
          return this.renderStampingLayer('stampingGameDay2', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 3) {
          return this.renderStampingLayer('stampingProSteerhide1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 4) {
          return this.renderStampingLayer('stampingProSteerhide2', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 5) {
          return this.renderStampingLayer('stampingProKip1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 6) {
          return this.renderStampingLayer('stampingProKip2', glove.palmPython.hexCode, 'shadow');
        }
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })
}
