import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../containers/baseModal/BaseModal';

import Input from '../../components/input/Input';
import DivButton from '../../components/button/DivButton';
import { CountryDropdown, RegionDropdown } from '../../lib/country/rcrs';

import CheckBox from '../../components/checkBox/CheckBox';
import {
  onlyEnglish,
  onlyEnglishAndNumber,
} from '../../common/CheckedValidation';

const check = '/svg/check.svg';

class AddressForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: 1,
      country: '',
      state: '',
      isChecked: false,
      isModal: false,
      bodyText: '',
    };

    this.firstNameInput = null;
    this.lastNameInput = null;
    this.companyInput = null;
    this.addressInput = null;
    this.etcInput = {
      value: '',
    };
    this.cityInput = null;
    this.zipCodeInput = null;
    this.phoneNumberInput = null;
    this.onNext = this.closeModal;
  }

  componentDidMount() {
    if (this.firstNameInput) {
      // this.firstNameInput.focus();
    }
    this.initInputValues();
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: '',
    });
  };

  initInputValues() {
    const { data } = this.props;
    console.log(data);
    if (data) {
      this.firstNameInput.value = data.name_first;
      this.lastNameInput.value = data.name_last;
      this.companyInput.value = data.company;
      this.addressInput.value = data.addr;
      this.etcInput.value = data.addr_etc === 'none' ? null : data.addr_etc;
      this.cityInput.value = data.city;
      this.zipCodeInput.value = data.zipcode;
      this.phoneNumberInput.value = data.phone;

      this.setState({
        country: data.country,
        state: data.state,
        isChecked: data.b_base === 1,
      });
    }
  }

  render() {
    const { isModal, bodyText } = this.state;
    return (
      <Fragment>
        <Modal
          isModal={isModal}
          bodyText={bodyText}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        <div style={this.props.style}>
          <p style={{ color: 'red', marginBottom: 20 }}>
            Ensure that the full name and address are typed in English only.
            Other languages are not allowed to put below.
          </p>
          <div style={styles.nameBox}>
            <DivButton
              style={styles.halfInputBox(this.state.focus === 1)}
              // onClick={() => this.firstNameInput.focus()}
            >
              <p style={styles.inputTitle}>First name</p>
              <Input
                onBlur={() => this.setState({ focus: 0 })}
                onFocus={() => this.setState({ focus: 1 })}
                ref={comp => (this.firstNameInput = comp)}
                style={styles.input(280)}
                onChange={(firstName) => {
                  if (onlyEnglish(firstName)) {
                    return (this.firstNameInput.value = firstName);
                  }
                  return (this.firstNameInput.value = '');
                }}
                placeholder=""
                placeholderColor="transparent"
                type="text"
              />
            </DivButton>
            <DivButton
              style={styles.halfInputBox(this.state.focus === 2)}
              // onClick={() => this.lastNameInput.focus()}
            >
              <p style={styles.inputTitle}>Last name</p>
              <Input
                onBlur={() => this.setState({ focus: 0 })}
                onFocus={() => this.setState({ focus: 2 })}
                ref={comp => (this.lastNameInput = comp)}
                style={styles.input(280)}
                onChange={(listName) => {
                  if (onlyEnglish(listName)) {
                    return (this.lastNameInput.value = listName);
                  }
                  return (this.lastNameInput.value = '');
                }}
                placeholder=""
                placeholderColor="transparent"
                type="text"
              />
            </DivButton>
          </div>

          <DivButton
            style={styles.fullInputBox(this.state.focus === 3)}
            // onClick={() => this.companyInput.focus()}
          >
            <p style={styles.inputTitle}>Company (optional)</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 3 })}
              ref={comp => (this.companyInput = comp)}
              style={styles.input(590)}
              onChange={(company) => {
                if (onlyEnglish(company)) {
                  return (this.companyInput.value = company);
                }
                return (this.companyInput.value = '');
              }}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>

          <DivButton
            style={styles.fullInputBox(this.state.focus === 4)}
            // onClick={() => this.addressInput.focus()}
          >
            <p style={styles.inputTitle}>Address</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 4 })}
              ref={comp => (this.addressInput = comp)}
              style={styles.input(590)}
              onChange={(address) => {
                if (onlyEnglishAndNumber(address)) {
                  return (this.addressInput.value = address);
                }
                return (this.addressInput.value = '');
              }}
              // onChange={address => this.addressInput.value = address}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>

          <DivButton
            style={styles.fullInputBox(this.state.focus === 6)}
            // onClick={() => this.cityInput.focus()}
          >
            <p style={styles.inputTitle}>City</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 6 })}
              ref={comp => (this.cityInput = comp)}
              style={styles.input(590)}
              onChange={(city) => {
                if (onlyEnglish(city)) {
                  return (this.cityInput.value = city);
                }
                return (this.cityInput.value = '');
              }}
              // onChange={city => this.cityInput.value = city}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </DivButton>

          <div style={{ ...styles.nameBox, marginTop: 20 }}>
            <div style={styles.countryInputBox(226, this.state.focus === 7)}>
              <p style={styles.inputTitle}>Country</p>
              <CountryDropdown
                onBlur={() => this.setState({ focus: 0 })}
                onFocus={() => this.setState({ focus: 7 })}
                style={styles.selcet(183 + 14 + 7 + 7)}
                value={this.state.country}
                onChange={country => this.setState({ country })}
              />
            </div>

            <div style={styles.countryInputBox(224, this.state.focus === 8)}>
              <p style={styles.inputTitle}>State</p>
              <RegionDropdown
                onBlur={() => this.setState({ focus: 0 })}
                onFocus={() => this.setState({ focus: 8 })}
                style={styles.selcet(183 + 14 + 7 + 7 - 2)}
                onChange={state => this.setState({ state })}
                country={this.state.country}
                value={this.state.state}
              />
            </div>

            <DivButton
              style={styles.countryInputBox(140, this.state.focus === 9)}
              // onClick={() => this.zipCodeInput.focus()}
            >
              <p style={styles.inputTitle}>Zip Code</p>
              <Input
                onBlur={() => this.setState({ focus: 0 })}
                onFocus={() => this.setState({ focus: 9 })}
                ref={comp => (this.zipCodeInput = comp)}
                style={styles.input(120)}
                onChange={zipCode => (this.zipCodeInput.value = zipCode)}
                placeholder=""
                placeholderColor="transparent"
                type="text"
              />
            </DivButton>
          </div>

          <DivButton
            style={styles.fullInputBox(this.state.focus === 10)}
            // onClick={() => this.phoneNumberInput.focus()}
          >
            <p style={styles.inputTitle}>Phone Number</p>
            <Input
              onBlur={() => this.setState({ focus: 0 })}
              onFocus={() => this.setState({ focus: 10 })}
              ref={comp => (this.phoneNumberInput = comp)}
              style={styles.input(590)}
              onChange={city => (this.phoneNumberInput.value = city)}
              placeholder="Please enter your cell phone number."
              placeholderColor="#868686"
              type="text"
            />
          </DivButton>

          <p
            style={{
              fontFamily: 'Metropolis',
              fontSize: 12,
              fontWeight: 'normal',
              fontStyle: 'normal',
              fontStretch: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              color: '#4a4a4a',
              marginTop: 8,
            }}
          >
            *add your country code with ‘+’ as prefix. i.e+91
          </p>

          <div style={styles.checkBox}>
            <CheckBox
              checkImg={check}
              styles={checkBoxStyles}
              onClick={() =>
                this.setState({ isChecked: !this.state.isChecked })
              }
              isChecked={this.state.isChecked}
            />
            <p style={styles.ckecedText}>Set as default address</p>
          </div>

          <div style={styles.btnsBox}>
            <DivButton
              style={styles.button()}
              onClick={() => {
                if (!this.firstNameInput.value) {
                  return this.openModal('plaese set firstName');
                }
                if (!this.lastNameInput.value) {
                  return this.openModal('plaese set lastName');
                }
                if (!this.addressInput.value) {
                  return this.openModal('plaese set address');
                }
                if (!this.cityInput.value) {
                  return this.openModal('plaese set city');
                }

                if (!this.state.country) {
                  return this.openModal('plaese set country');
                }
                if (!this.state.state) {
                  return this.openModal('plaese set state');
                }

                if (!this.zipCodeInput.value) {
                  return this.openModal('plaese set zipCode');
                }
                if (!this.phoneNumberInput.value) {
                  return this.openModal('plaese set phoneNumber');
                }

                const obj = {
                  name_first: this.firstNameInput.value,
                  name_last: this.lastNameInput.value,
                  phone: this.phoneNumberInput.value,
                  company: !this.companyInput ? '' : this.companyInput.value,
                  country: this.state.country,
                  state: this.state.state,
                  city: this.cityInput.value,
                  zipcode: this.zipCodeInput.value,
                  addr: this.addressInput.value,
                  addr_etc: !this.etcInput ? '' : this.etcInput.value,
                  b_base: this.state.isChecked ? 1 : 0,
                };
                if (this.props.data) {
                  return this.props.onUpdate({
                    ...obj,
                    pid_addr: this.props.data.pid_addr,
                  });
                }
                return this.props.onSave(obj);
              }}
            >
              SAVE
            </DivButton>
            <DivButton
              style={styles.button(30)}
              onClick={() => this.props.onCancel()}
            >
              CANCEL
            </DivButton>
          </div>
        </div>
      </Fragment>
    );
  }
}

AddressForm.propTypes = {
  onCancel: PropTypes.func,
  style: PropTypes.object,
  onSave: PropTypes.func,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};

AddressForm.defaultProps = {
  onCancel: () => {},
  style: {},
  onSave: () => {},
  data: null,
  onUpdate: () => {},
};

const checkBoxStyles = {
  container: {
    width: 12,
    height: 12,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: 'solid 1px #979797',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    width: 12,
    height: 12,
    cursor: 'pointer',
  },
};

const styles = {
  halfInputBox: isFocus => ({
    width: 300,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
  }),
  inputTitle: {
    fontSize: 12,
    color: '#515356',
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    marginTop: 6,
  },
  input: width => ({
    width,
    marginTop: 5,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '#251f1b',
    fontSize: 16,
  }),
  nameBox: {
    width: 610,
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullInputBox: isFocus => ({
    width: 610,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
    marginTop: 20,
  }),
  countryInputBox: (width, isFocus) => ({
    width,
    height: 50,
    borderRadius: 3,
    backgroundColor: '#fff',
    border: isFocus ? 'solid 1px #007dff' : 'solid 1px #e5e5e5',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '0 10px 0 10px',
  }),
  selcet: width => ({
    width,
    marginTop: 5,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '#251f1b',
    fontSize: 16,
    transform: 'translate(-7px, 0px)',
    MsTransform: 'translate(-7px, 0px)',
    Webkittransform: 'translate(-7px, 0px)',
  }),

  checkBox: {
    width: 12 + 5 + 129,
    display: 'flex',
    alignItems: 'center',
    marginTop: 21,
  },
  ckecedText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    color: '#666666',
    marginLeft: 5,
  },
  btnsBox: {
    width: 610,
    position: 'absolute',
    marginTop: 51,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: (marginLeft = 0) => ({
    width: 110,
    height: 40,
    backgroundColor: '#251f1b',
    color: '#fff',
    fontWeight: 'bold',
    lineHeight: 1.43,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft,
    fontSize: 14,
  }),
};

export default AddressForm;
