// 어떤 변화가 일어나야 할 지 나타내는 객체 입니다.
export const SIDEBAR_MODE = 'SIDEBAR_MODE';
export const SETSUBTOTAL = 'SETSUBTOTAL';

export function setSidebarMode(visible) {
  return {
    type: SIDEBAR_MODE,
    sidebarMode: visible,
  };
}

export function setSubtotal(value) {
  return {
    type: SETSUBTOTAL,
    value,
  };
}
