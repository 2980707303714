/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import PropTypes from "prop-types";

class KoFaq extends Component {
  render() {
    const { history } = this.props;

    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>자주 묻는 질문과 답</p>

          <p style={styles.subTitle(64)}>[1] 글러브</p>

          <p style={styles.question(10)}>
            1. 글러브빌더에서 제공하는 옵션 외에 또 다른 기능들을 커스텀
            글러브에 추가할 수 있나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            <a
              style={{ textDecoration: "underline", fontWeight: "bold" }}
              href="https://www.dropbox.com/s/ypnn3wif1btdnrq/OPTION%20LIST.xlsx?dl=0"
              target="_blank"
            >
              이곳
            </a>
            을 클릭하면 사용자에게 최적화된 글러브를 만들기 위해 글러브웍스가
            제안하는 특별옵션의 종류에 무엇이 있는지 확인하실 수 있습니다. 옵션
            추가를 원하시는 경우 ‘특별요청’ 란에 기재해주세요. 옵션에 따라
            추가비용이 부과될 수 있으며 이 경우 결제를 위한 인보이스가
            발행됩니다. 커스텀 글러브 제작과정의 특성 상 주문확정 후에는 옵션
            추가가 어려운 점을 양해 부탁드립니다.
          </p>

          <p style={styles.question(24)}>
            2. 글러브·제품을 받을 때까지 얼마나 걸리나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            스톡글러브 또는 글러브 액세서리의 경우 주문 후 1-2일 이내에
            발송됩니다. 평균 배송기간은 3-5일 입니다.
            <br />
            <br />
            {/* OUTLIER - 240201 - 요청사항 2번 */}
            {/* 커스텀 글러브의 경우 주문 후 4-6주 가량 소요됩니다. 글러브웍스
            커스텀 글러브는 처음부터 끝까지 수작업으로 제작되는 제품으로 기성
            글러브보다 각 제작단계에서 더 많은 시간이 소요된다는 점을 양해
            부탁드립니다. 숙련된 기술의 장인들이 세심하고도 정교한 손길로
            만들어낸 글러브는 엄격한 최종품질검사 단계를 거쳐 발송됩니다. 천천히
            서두르는 글러브웍스가 되겠습니다. */}
            커스텀 글러브의 경우 주문 후 4-16주 가량 소요됩니다. 특수 옵션이
            많은 경우, 연말 연시 등의 장기 휴무일을 사이에 둔 제작의 경우에는
            배송까지의 시간이 연장되는 경우가 있습니다.글러브웍스 커스텀
            글러브는 처음부터 끝까지 수작업으로 제작되는 제품으로 기성
            글러브보다 각 제작단계에서 더 많은 시간이 소요된다는 점을 양해
            부탁드립니다. 숙련된 기술의 장인들이 세심하고도 정교한 손길로
            만들어낸 글러브는 엄격한 최종품질검사 단계를 거쳐 발송됩니다. 천천히
            서두르는 글러브웍스가 되겠습니다.
          </p>

          <p style={styles.question(24)}>
            3. 주문 후 제가 원하는 로고를 글러브에 사용할 수 없다는 연락을
            받았습니다. 왜 그런가요?
          </p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스 글러브에는 타사나 타인의 저작권을 침해하거나 혐오나
            증오의 메시지, 적대적인 정치적 메시지, 반달리즘, 욕설 등을 담은
            로고는 사용할 수 없습니다.
            <br />
            <br />
            또한 글러브에 사용되는 로고에 대한 저작권 침해여부를 확인하는 의무와
            이로 인해 발생하는 법적 문제에 대한 책임은 모두 고객에게 있음을
            알려드립니다.
          </p>

          <p style={styles.subTitle(64)}>[2] 가죽</p>

          <p style={styles.question(10)}>
            1. 글러브를 받았는데 웹사이트에서 제가 선택한 가죽색깔과 달라보여요.
            왜 그런가요?
          </p>
          <p style={styles.descrpiotn(10)}>
            개개인의 모니터 디스플레이 설정의 차이나 사진촬영 시 조명으로 인해
            웹사이트 상의 글러브 빌더에서 제시하는 색상이나 글러브 사진이 실제
            글러브 색상과 차이가 있을 수 있음을 양해 부탁드립니다.
          </p>

          <p style={styles.question(24)}>
            2. 흰색 글러브를 주문했는데 회색 글러브처럼 보이는 제품이 왔어요 /
            흰색으로 레이싱 한 흰색 글러브를 주문했는데 글러브 본체와 끈 색깔이
            달라요.
          </p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스의 흰색 글러브는 천연가죽과 염색방식의 특성 상 순백의
            ‘스노우화이트(Snow-white)’가 아닌 회색에 가까운
            ‘오프화이트(Off-White)’입니다. 글러브웍스 글러브에 사용되는 가죽은
            안료가 아닌 염료를 통해 색상을 냅니다. 물에 녹지 않는 안료를 가죽
            위에 칠하는 안료염색과는 달리 염료염색은 물에 녹는 성질을 지닌
            염료를 물에 푼 후 가죽을 담궈 가공하기 때문에 가죽 전체에 색이
            배입니다. 따라서 컴퓨터나 전화기 화면 상의 그래픽 색상보다는 어두워
            보일 수 있지만 자연스러운 색감과 함께 가죽 질감을 온전히 드러내는
            장점을 지니며 그만큼 최상품 가죽에만 가능한 가공입니다.
          </p>

          <p style={styles.question(24)}>
            3. 글러브 표면에 스트레치 마크가 보여요. 이것은 무엇인가요?
          </p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스의 가죽은 자연에서 왔습니다. 다시 말해, 이 가죽이 환경에
            적응하기 위해 끊임없이 변화해왔다는 의미입니다. 가죽마다 다른 질감과
            색상을 가졌다는 것은 이 가죽이 천연제품이라는 사실을 증명해주면서도
            글러브의 강도나 기능에는 전혀 영향을 주지 않습니다. 가죽은 가죽 한
            장 한 장마다, 아니 한 장의 가죽 안에서도 부위마다 서로 다른 모습을
            지닙니다. 또한 염색작업 시 가죽마다 다르게 형성된 세포구조에 따라
            염료가 조금씩 다르게 흡수되면서 미세한 색감 차이가 발생할 수
            있습니다. 당신의 글러브가 특별할 수 있는 또 다른 이유입니다.
          </p>

          <p style={styles.question(24)}>
            4. 내피(lining) 또는 입수부 털(lining fur)은 무슨 색으로
            만들어지나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            글러브 내피 색상은 포구면 색상과 동일하게 만들어집니다. 입수부 털의
            색상은 게임데이 글러브일 경우 검은색, 프로 스티어하이드와 프로 킵
            글러브의 경우 흰색입니다. 또한 패스트백 글러브에는 털이 부착되지
            않습니다.
          </p>

          <p style={styles.question(24)}>
            5. 게임데이와 프로 스티어하이드, 프로 킵 사이에는 무슨 차이가
            있나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스 글러브에 사용되는 가죽은 헤비 텍사스 스티어하이드(Heavy
            Texas Steer-hide)와 유러피안 킵(European Kip)입니다.
            <br />
            <br />
            게임데이 시리즈는 한국과 대만에서 가공한 스티어하이드로
            만들어집니다. 쉽게 브레이크-인 되며 바로 사용할 수 있을 만큼
            부드럽습니다. 따라서 처음으로 커스텀 글러브를 주문하시거나 어린
            선수들이 사용하기에 적합합니다.
            <br />
            <br />
            프로 스티어하이드 시리즈는 일본에서 가공한 스티어하이드로
            만들어집니다. 프로 선수들이 가장 선호하는 소재로, 거세한 수소의 뒤쪽
            어깨가죽에서 얻어지며 내구성이 뛰어나고 단단한 느낌을 줍니다.
            <br />
            <br />
            프로 킵 시리즈는 일본에서 가공한 유러피안 킵으로 만들어집니다.
            유러피안 킵은 생후 3개월 미만의 송아지에게서 얻는 최고급 가죽이며,
            가장 가볍고도 부드러운 가죽이라는 특징을 지닙니다. 스피드를
            중요시하는 프로 선수들이 선호하는 가죽이기도 합니다.
            <br />
            <br />
            글러브웍스는 야구 글러브에 사용되는 가죽 생산에 특화된 세계 최고의
            태너리들과 오랫동안 든든한 파트너로 함께 해왔습니다.
          </p>

          <p style={styles.subTitle(64)}>[3] 커스텀 관련</p>

          <p style={styles.question(10)}>
            1. 주문을 한 후 별도로 주문확정메일이 발송되나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            아니요, 주문확정메일이 별도로 발송되지는 않습니다. 그러나{" "}
            <font
              style={{
                ...styles.email,
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              tabIndex="-1"
              role="button"
              onClick={() => history.push("./orderTracking")}
            >
              이곳
            </font>
            에서 이메일과 주문번호를 가지고 주문내역과 생산현황을 확인할 수
            있습니다.
          </p>

          <p style={styles.question(24)}>
            2. 주문을 했는데 바꾸고 싶은 부분이 생겼습니다. 주문을 변경하거나
            취소할 수 있나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            주문 후 24시간 이내에는 고객님 계정에 접속해 주문을 다시 검토하는
            것이 가능합니다. 정해진 기한 내에는 사이즈, 색상, 자수 등 글러브와
            관련한 세부사항이 정확한지 확인하고 자유로이 수정할 수 있으며,
            글러브를 구매하지 않기로 마음을 바꿨다면 주문을 취소할 수도
            있습니다. 주문 후 24시간이 지나면 계정에 저장된 세부사항에 따라
            주문이 확정되어 글러브 생산이 시작되며 더 이상의 수정이
            불가능합니다. 주문확정 이후에는 환불이나 교환이 어렵다는 점을 양해
            부탁드립니다.
          </p>

          <p style={styles.subTitle(64)}>[4] 배송</p>

          <p style={styles.question(10)}>
            1. 글러브웍스 제품은 어느 운송사를 통해 발송되나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            모든 물품은 DHL, FEDEX, SF EXPRESS(중국 본토 내) 등과 같은
            정식배송업체를 통해 발송됩니다. 글러브웍스의 공장과 물류창고는
            중국과 미국, 일본에 위치하고 있습니다.
          </p>

          <p style={styles.question(24)}>
            2. PO BOX나 RR 주소로도 배달이 가능하나요?
          </p>
          <p style={styles.descrpiotn(10)}>
            죄송합니다. 배송업체의 정책 상 PO박스나 RR 주소로 배달이 어려운 점
            양해 부탁드립니다.
          </p>

          <p style={styles.question(24)}>3. 관세가 부과되나요?</p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스의 제품은 중국에서 생산·발송되며 배송 목적국의 물품수입
            관련 법규에 의거해 수입통관 시 관부가세가 부과됩니다. 관부과세는
            제품 수령 시 특송업체에 직접 지불하시면 됩니다.
            <br />
            <br />
            관부과세 또는 수입통관과 관련해 세부정보, 영수증, 관련 서류 등이
            필요한 경우 배송안내 이메일에 기재된 특송업체에게 요청하실 수
            있습니다.
            <br />
            <br />더 자세한 내용은{" "}
            <a
              style={{ textDecoration: "underline", fontWeight: "bold" }}
              href="https://www.customs.go.kr/kcshome/main/content/ContentView.do?contentId=CONTENT_ID_000000481&layoutMenuNo=10"
              target="_blank"
            >
              이곳
            </a>
            을 참고해주세요.
          </p>

          <p style={styles.subTitle(64)}>[반품·교환·환불]</p>

          <p style={styles.question(10)}>
            1. 커스텀 글러브를 받았는데 마음에 들지 않아요. 환불이나 교환이
            가능한가요?
          </p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스 커스텀 글러브는 고객 개개인의 취향에 따라 독창적으로
            만들어진 제품입니다. 따라서 제품 제작과정에서 저희 측 잘못으로
            발생한 오류나 결함, 손상이 아닌 단순 변심이나 고객 경우 반품이나
            교환이 불가합니다. 저희 측 잘못으로 문제가 발생했을 경우 제품 수령
            후 2주 이내에{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            로 이메일을 보내주십시오(한국어 서비스 가능).
          </p>

          <p style={styles.question(24)}>2. 워런티 프로그램이 있나요?</p>
          <p style={styles.descrpiotn(10)}>
            글러브웍스는 제품수령 후 6개월 간 워런티를 보장합니다. 제품 수령 후
            6개월 이내에 품질문제가 발생했을 경우{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            로 이메일을 보내주시면 저희가 문제를 해결할 수 있는 최선의 방법을
            찾아내겠습니다.
          </p>

          <p style={styles.subTitle(64)}>[기타]</p>

          <p style={styles.question(10)}>
            계좌 번호를 잃어버렸어요! 어떻게 하죠?
          </p>

          <p style={styles.descrpiotn(10)}>
            아래의 계좌로 입금부탁드립니다. <br />
            입금하실 계좌: 우리은행 176-014453-13-101 <br />
            예금주 : ㈜ 아산 코퍼레이션
          </p>

          <p style={styles.question(10)}>
            그 외 궁금한 부분이 있으시다고요? 언제든 이메일을 써주세요!
          </p>
          <p style={styles.descrpiotn(10)}>
            - 영어·한국어:{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:contact@gloveworks.net")
              }
            >
              contact@gloveworks.net
            </u>
            <br />- 일본어:{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                // OUTLIER - 20240123 - 추가 변경
                // (document.location.href = "mailto:gloveworks.jp@gloveworks.net")
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              {/* gloveworks.jp@gloveworks.net */}
              donald@gloveworks.net
            </u>
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: "auto",
    marginBottom: 64,
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  subTitle: (marginTop) => ({
    color: "#000",
    lineHeight: 1.33,
    fontWeight: "bold",
    fontSize: 18,
    marginTop,
  }),
  question: (marginTop) => ({
    marginTop,
    color: "#007dff",
    lineHeight: 1.5,
    fontWeight: "600",
    fontSize: 16,
  }),
  descrpiotn: (marginTop) => ({
    marginTop,
    color: "#142935",
    lineHeight: 1.57,
    fontSize: 14,
  }),
  email: {
    color: "#007dff",
    cursor: "pointer",
    outline: "none",
    fontWeight: "bold",
  },
};

KoFaq.propTypes = {
  history: PropTypes.object,
};

KoFaq.defaultProps = {
  history: {},
};

export default KoFaq;
