import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withTranslation } from 'react-i18next';

import { setItem, getItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';
import {
  checkedCurrencySymbol,
  settingCostWithCompare,
  getCookie,
  setCookie,
  returnComma,
} from '../../common/Utils';

import Gallery from '../../containers/gallery/Gallery';
import BasicPost from '../../containers/posts/BasicPost';

import Step from '../../components/step/Step';
import DivButton from '../../components/button/DivButton';
import Item from '../../components/card/MainCollectionsCard';

import Modal2 from '../../containers/baseModal/BaseModal';

import Modal from '../../lib/modal';

import { Consumer } from '../../Context';

const STEP_MENU = ['Shop', 'Product Details'];

const arrowWhite = '/svg/arrow_wr.svg';
const Close = '/svg/close.svg';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

function renderOptions(item, title) {
  // console.log('select item', item);
  const arr = [];

  arr.push(<option
    key={shortid.generate()}
    value={0}
    disabled
    hidden
  >{`Choose ${title}`}</option>);

  item.map((data, j) => {
    const key = `${item}${j}`;
    return arr.push(<option key={key} value={data}>
      {data}
    </option>);
  });
  return arr;
}

class ItemDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      data: null,
      isModal2: false,
      bodyText2: '',
    };

    // 옵션을 위한 관련 변수
    this.options = [];
    this.optionsLength = 0;

    this.userData = null;
    this.onNext = this.closeModal;
  }

  componentDidMount() {
    document.body.style.backgroundColor = '#fafafa';
    // console.log(this.slider.scrollWidth, this.slider.offsetWidth, this.slider.scrollLeft);
    this.initialize();
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  openModal = (bodyText2) => {
    this.setState({
      isModal2: true,
      bodyText2,
    });
  };

  closeModal = () => {
    this.setState({
      isModal2: false,
      bodyText2: '',
    });
  };

  async initialize() {
    try {
      this.userData = JSON.parse(getCookie('userData'));

      const api = getItem('restAPI').model_desc.pd_get_detail;
      const res = await _Fetch(
        api,
        `?pid=${this.props.history.location.pathname.split('/')[2]}`,
        [],
      );
      console.log('stock data', res);

      // 이미지 갤러리를 위한 이미지 배열로 컨버팅 과정
      const galleryImages = [];
      for (let i = 1; i < 9; i += 1) {
        if (res.pd_dt[0][`image_uri${i}`] !== 'none') {
          galleryImages.push(res.pd_dt[0][`image_uri${i}`]);
        }
      }

      res.pd_inv = res.pd_inv.filter(o => o.b_active);

      // 옵션을 위한 사전 처리
      const optionsTitleArr = res.pd_dt[0].option_title
        .split('||')
        .filter(String);

      const options = optionsTitleArr.map((item, i) => {
        const pdOtp = res.pd_dt[0][`option_detail${i + 1}`];
        if (pdOtp && pdOtp !== 'none') {
          const opt = pdOtp.split('||').filter(String);
          return {
            title: item,
            opt,
          };
        }
        return null;
      });

      this.optionsLength = options.length;
      // console.log(this.optionsLength);

      // 추천 아이템 사전 처리 (해당 들어온 카테고리 타입의 4종류 아이템을 보여준다)
      const recommendApi = getItem('restAPI').model_desc.pd_get_seqctg_active;
      const recommends = await _Fetch(
        recommendApi,
        `?seq=0&interval=4&ctg=${res.pd.pid_category}`,
        [],
      );

      const data = Object.assign(res, { galleryImages, options, recommends });

      console.log('process Data', data);

      this.setState({
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  // 카트에 아이템 정보를 세션 에다가 저장
  async setCartData(data, options, userData) {
    const { actions } = this.props;

    console.log('inv data', data.pd_inv);
    console.log('option', options);

    const selectObj = {};

    options.map((item, i) => (selectObj[`op_name${i + 1}`] = item.context));

    console.log('selectObj', selectObj);

    const checkedInv = data.pd_inv.filter((x) => {
      console.log(x);
      if (options.length === 1 && x.op_name1.trim() === selectObj.op_name1.trim()) {
        return x;
      }

      if (
        options.length === 2 &&
        (x.op_name1 === selectObj.op_name1 && x.op_name2.trim() === selectObj.op_name2.trim())
      ) {
        return x;
      }

      if (
        options.length === 3 &&
        (x.op_name1.trim() === selectObj.op_name1.trim() &&
          x.op_name2.trim() === selectObj.op_name2.trim() &&
          x.op_name3.trim() === selectObj.op_name3.trim())
      ) {
        return x;
      }

      return null;
    });

    const selectInvCount = checkedInv[0] && checkedInv[0].stock;
    if (data.pd.pid_pd > 1) {
      if (selectInvCount < 1 || selectInvCount === undefined) return this.openModal('Out of stock');
    }
    console.log('inv', checkedInv[0], selectInvCount);
    // console.log('inv', checkedInv[0], selectInvCount, checkedInv[0].pid_pd_inv);


    // type은 기성품은 2, 커스텀은 1
    const obj = {
      pid_item: data.pd.pid_pd,
      uri_pd: data.pd.cover_uri,
      body: JSON.stringify({
        options,
        title: data.pd.title,
        weight: data.pd.weight,
        pid_category: data.pd.pid_category,
        typ_delivery: data.pd.typ_delivery,
        pid_pd_inv: checkedInv[0].pid_pd_inv,
      }),
      price_dol: data.pd.cost_dollar,
      price_yen: data.pd.cost_yen,
      price_won: data.pd.cost_won,
      quantity: 1,
      b_custom: 2,
    };

    if (userData) {
      try {
        const cartGetApi = getItem('restAPI').model_desc.cart_get_my;
        const cartMyRes = await _Fetch(
          cartGetApi,
          `?pid_user=${userData.user.pid_user}`,
          [],
        );

        if (cartMyRes.length === 0 && data.pd.typ_delivery === 4) {
          return this.openModal('Unfortunately Afterfield is not sold separately due to shipping issue. You should purchase other items together such as a glove or any of items in our website.) together if you want to get Afterfield.');
        }

        const oilCount = cartMyRes.filter(item => JSON.parse(item.body).typ_delivery === 4);

        if (
          oilCount.length > 0 &&
          JSON.parse(oilCount[0].body).typ_delivery === data.pd.typ_delivery
        ) {
          return this.openModal('already oli item');
        }

        const api = getItem('restAPI').model_desc.cart_insert;
        const body = {
          cart: {
            pid_user: userData.user.pid_user,
            ...obj,
          },
        };
        // cart DB에 적용
        await _Fetch(api, '', body);
        console.log(body);
        document.body.style.overflow = 'hidden';
        actions.increment();
        return this.setState({ isModal: true });
      } catch (e) {
        console.log(e);
        if (e.status === 1164) {
          this.openModal('로그인 정보가 유효하지 않습니다. 다시 로그인 해주시길 바랍니다.');
          setCookie('userData', '', -1);
          this.props.history.push('/login');
        }
      }
    }

    // 비로그인시 세션에 카트를 담기위한 사전 처리
    if (getItem('cart') === null && !userData) {
      // console.log('setting cart');
      if (data.pd.typ_delivery === 4) {
        return this.openModal('Unfortunately Afterfield is not sold separately due to shipping issue. You should purchase other items together such as a glove or any of items in our website.) together if you want to get Afterfield.');
      }
      setItem('cart', [obj]);
      document.body.style.overflow = 'hidden';
      actions.increment();
      return this.setState({ isModal: true });
    }
    // 비로그인이고 세션에 값이 있을때
    if (getItem('cart') && !userData) {
      const arr = getItem('cart');

      const oilCount = arr.filter(item => JSON.parse(item.body).typ_delivery === 4);

      console.log(oilCount.length);

      if (
        oilCount.length > 0 &&
        JSON.parse(oilCount[0].body).typ_delivery === data.pd.typ_delivery
      ) {
        return this.openModal('already oli item');
      }

      arr.push(obj);
      // console.log('push cart');

      setItem('cart', arr);
      document.body.style.overflow = 'hidden';
      actions.increment();
      return this.setState({ isModal: true });
    }

    return true;
  }

  renderModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.isModal}
        contentLabel="Add Item In Cart"
        onRequestClose={() => {
          this.setState({ isModal: false });
          this.options = [];
          document.body.style.overflow = 'auto';
        }}
        style={styles.modal}
      >
        <DivButton
          style={styles.modalCloseImgBtn}
          onClick={() => {
            this.setState({ isModal: false });
            this.options = [];
            document.body.style.overflow = 'auto';
          }}
        >
          <img src={Close} alt="" />
        </DivButton>
        <p style={styles.modalTitle}>Just added to your cart</p>
        <DivButton
          style={styles.modalBtn}
          onClick={() => this.props.history.push('/cart')}
        >
          <div style={styles.modalBtnContentsBox}>
            <p style={styles.modalBtnText}>VIEW CART</p>
            <img src={arrowWhite} style={styles.modalBtnArrow} alt="w_arrow" />
          </div>
        </DivButton>
      </Modal>
    );
  }

  renderItemInfo() {
    const { i18n } = this.props;
    return (
      <div style={styles.topRightBox}>
        <p style={styles.itemName}>{this.state.data.pd.title}</p>

        <div style={styles.likebox}>
          {settingCostWithCompare(i18n.language, this.state.data.pd).compare >
          0 ? (
            <p style={styles.compareText}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === 'en'
                ? returnComma(settingCostWithCompare(
                      i18n.language,
                      this.state.data.pd,
                    ).compare.toFixed(2))
                : returnComma(settingCostWithCompare(i18n.language, this.state.data.pd)
                      .compare)}
            </p>
          ) : null}

          <p
            style={styles.cost(settingCostWithCompare(i18n.language, this.state.data.pd)
                .compare > 0)}
          >
            {checkedCurrencySymbol(i18n.language)}
            {i18n.language === 'en'
              ? returnComma(settingCostWithCompare(
                    i18n.language,
                    this.state.data.pd,
                  ).cost.toFixed(2))
              : returnComma(settingCostWithCompare(i18n.language, this.state.data.pd)
                    .cost)}
          </p>
        </div>

        {this.state.data.options.map((item, i) => (
          <div key={shortid.generate()} style={styles.selectBox}>
            <p style={styles.colorBoxTitle}>{item.title}</p>

            <select
              defaultValue={0}
              onChange={(e) => {
                this.options[i] = {
                  title: item.title,
                  context: e.target.value,
                };
                // console.log(this.options);
              }}
              style={styles.select}
            >
              {renderOptions(item.opt, item.title)}
            </select>
          </div>
        ))}

        <div
          role="button"
          tabIndex="0"
          style={styles.bottomBtn}
          onClick={() => {
            if (this.optionsLength === this.options.length) {

              try{
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = window.url;
                  }
                };
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-10904637938/rzyBCJqTvL4DEPKj3s8o',
                    'event_callback': callback
                });
              }catch(e){
                console.log('gtag error')
              }


              return this.setCartData(
                this.state.data,
                this.options,
                this.userData,
              );
            }
            return this.openModal('Please choose product options');
          }}
        >
          <p style={styles.bottomBtnText}>ADD TO CART</p>
          <img src={arrowWhite} style={styles.bottomBtnArrow} alt="w_arrow" />
        </div>
      </div>
    );
  }

  renderRecommendItems() {
    if (this.state.data.recommends.length === 0) return null;
    return (
      <div>
        <div style={styles.inspirationTitleBox}>
          <p style={styles.inspirationTitle}>YOU MIGHT ALSO LIKE</p>
          <div style={styles.inspirationUnderline} />
        </div>

        <div style={styles.recommandBox}>
          {this.state.data.recommends.map((item, i) => (
            <div
              key={shortid.generate()}
              style={styles.recommandItemBox(i === 0)}
            >
              <Item
                data={item}
                onClick={() => {
                  this.props.history.push(`/shop/${item.pid_pd}`);
                  window.location.reload(true);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { isModal2, bodyText2 } = this.state;
    if (!this.state.data) {
      return <div style={styles.emptyBox} />;
    }

    return (
      <div>
        <Modal2
          isModal={isModal2}
          bodyText={bodyText2}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        {this.renderModal()}
        <Step menu={STEP_MENU} />

        <div style={styles.topBox}>
          <Gallery
            data={this.state.data.galleryImages}
            parentData={this.state.data}
          />
          {this.renderItemInfo()}
        </div>

        <div style={styles.underline} />

        <BasicPost data={this.state.data.pd_dt[0]} />

        <div style={styles.emphasisArea}>
          <p style={styles.emphasBlackText}>
            {this.state.data.pd_dt[0].sub_black === 'none'
              ? null
              : this.state.data.pd_dt[0].sub_black}
          </p>
          <p style={styles.emphasRedText}>
            {this.state.data.pd_dt[0].sub_red === 'none'
              ? null
              : this.state.data.pd_dt[0].sub_red}
          </p>
        </div>

        {this.renderRecommendItems()}
      </div>
    );
  }
}

const styles = {
  emptyBox: {
    width: '100%',
    height: 1300,
  },
  topBox: {
    width: 996,
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  topRightBox: {
    float: 'right',
    width: 330,
  },
  itemName: {
    width: '100%',
    height: 75,
    marginTop: 39,
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#251f1b',
  },
  likebox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cost: isSale => ({
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 1.33,
    color: isSale ? '#d0021b' : '#251f1b',
  }),
  bottomBtn: {
    width: 330,
    height: 40,
    backgroundColor: '#000',
    marginTop: 30,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomBtnArrow: {
    position: 'absolute',
    right: 16,
    zIndex: 255,
    top: 15,
  },
  bottomBtnText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 1.14,
    letterSpacing: 1,
  },
  underline: {
    width: 1115,
    height: 2,
    backgroundColor: '#edede9',
    margin: 'auto',
    marginTop: 30,
    clear: 'both',
  },
  inspirationUnderline: {
    marginTop: 10,
    width: 44,
    height: 1,
    backgroundColor: '#251f1b',
  },
  inspirationTitleBox: {
    width: 320 + 636 + 40,
    margin: 'auto',
    marginTop: 72,
  },
  inspirationTitle: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    fontSize: 16,
    color: '#251f1b',
    lineHeight: 1.38,
    margin: 0,
    letterSpacing: 1,
  },
  recommandBox: {
    width: 210 * 4 + 52 * 3,
    height: 210,
    margin: 'auto',
    marginTop: 22,
    marginBottom: 60,
  },
  colorBox: {
    width: '100%',
    marginTop: 30,
  },
  colorBoxTitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.4,
    textAlign: 'left',
    color: '#251f1b',
  },
  selectBox: {
    width: '100%',
    marginTop: 20,
    WebkitAappearance: 'none',
    appearance: 'none',
  },
  select: {
    position: 'relative',
    width: '100%',
    height: 40,
    border: '1px solid #251f1b',
    marginTop: 5,
    outline: 'none',
    borderRadius: 0,
    background: "url('/svg/triangle.svg') no-repeat 95% 50%",
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    paddingLeft: 14,
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
  },
  modal: {
    content: {
      width: 480,
      height: 260,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      position: 'relative',
    },
    overlay: {
      zIndex: 3,
    },
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#251f1b',
    textAlign: 'center',
    marginTop: 60,
  },
  modalBtn: {
    width: 204,
    height: 60,
    backgroundColor: '#251f1b',
    margin: 'auto',
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBtnArrow: {
    marginLeft: 16,
  },
  modalBtnText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
  },
  modalBtnContentsBox: {

    width: 'auto',
    display: 'flex',
  },
  modalCloseImgBtn: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  youtube: {
    height: '378',
    width: '672',
  },
  optInput: {
    width: 330,
    height: 40,
    border: 'solid 1px #251f1b',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozSBoxSizing: 'border-box',
    padding: '10px 39px 10px 14px',
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
    backgroundColor: 'transparent',
    marginTop: 5,
  },
  recommandItemBox: isMarginLeft => ({
    width: 210,
    height: 210,
    float: 'left',
    marginLeft: isMarginLeft ? 0 : 52,
    border: 'solid 1px #eee',
    boxSizing: 'border-box',
  }),
  compareText: {
    lineHeight: 1.33,
    color: '#9b9b9b',
    fontWeight: '600',
    fontSize: 18,
    textDecoration: 'line-through',
    marginRight: 20,
  },
  emphasisArea: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: 60,
    width: 996,
  },
  emphasBlackText: {
    fontSize: 14,
    color: '#4a4a4a',
    fontWeight: 'normal',
  },
  emphasRedText: {
    fontSize: 14,
    color: '#e85454',
    fontWeight: 'normal',
    marginTop: 16,
  },
};

ItemDetail.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.object,
  actions: PropTypes.object,
};

ItemDetail.defaultProps = {
  history: {},
  i18n: {},
  actions: null,
};

const defaultMapContextToProps = value => ({
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withRouter(withTranslation('translations')(ItemDetail)));
