import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Step from '../../components/step/Step';
import EnService from './EnService';
import KoService from './KoService';
import JaService from './JaService';

const STEP_MENU = ['Terms of service'];

class TermsOfService extends Component {
  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  render() {
    const { i18n } = this.props;
    return (
      <Fragment>
        <Step menu={STEP_MENU} />
        {i18n.language === 'ko' && <KoService />}
        {i18n.language === 'en' && <EnService />}
        {i18n.language === 'ja' && <JaService />}
      </Fragment>
    );
  }
}

TermsOfService.propTypes = {
  i18n: PropTypes.object,
};

TermsOfService.defaultProps = {
  i18n: {},
};

export default withTranslation('translations')(TermsOfService);
