import React from 'react';
import { connect } from 'react-redux';

import { applyPreset, closeAlert } from '../../actions/creators';

const SelectPresetAlert = ({ preset, dispatch }) =>
  // const message = `You have chosen our "Inspiration" template.<br />By choosing this,<br />you will lose previously chosen leather color options.`

  (
    <div className="custom-glove-popup">
      <div className="base-alert-title">Alert Message</div>
      <div className="base-alert-content">
        You have chosen our "Inspiration" template. By choosing this,<br />you will lose previously chosen leather color options.
      </div>
      <button
        className="close"
        onClick={() => dispatch(closeAlert())}
      />
      <div
        className="popup-button yes"
        onClick={() => {
          dispatch(applyPreset(preset))
            .then(() => dispatch(closeAlert()));
        }}
      >
        OK
      </div>
      <div
        className="popup-button cancel"
        onClick={() => dispatch(closeAlert())}
      >
        Cancel
      </div>
    </div>
  );
export default connect()(SelectPresetAlert);
