import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const Palm = ({ customPythons, currentCustomPython, onCustomPythonClick, }) => (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="PALM COLOR"
      className="palm-color-wrap"
      images={customPythons}
      currentCustomImage={currentCustomPython}
      onClick={onCustomPythonClick}
    />
  </div>
);

const mapStateToProps = state => ({
  customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
  currentCustomPython: state.glove.palmPython,
});

const mapDispatchToProps = dispatch => ({
  onCustomPythonClick: python => dispatch(updateGlove({ palmPython: python })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Palm), 'palmSide');
