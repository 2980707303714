import React, { Component } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { withTranslation } from "react-i18next";

import Modal from "../../containers/baseModal/BaseModal";

import Step from "../../components/step/Step";
import DivButton from "../../components/button/DivButton";

const STEP_MENU = ["Contact"];
const contactImg = "/img/contact.png";

const facebook = "/svg/facebook_g.svg";
const instagram = "/svg/insta_g.svg";
const twitter = "/svg/twitter_g.svg";
// const pinterest = '/svg/pinterest_g.svg';
const mailto = "/img/mailto_g.png";

const icons = [facebook, instagram, twitter, mailto];

function onHerf(openModal, index) {
  switch (index) {
    case 0:
      // return openModal(index);
      return window.open("https://www.facebook.com/gloveworks");
    case 1:
      // return openModal(index);
      return window.open("http://instagram.com/gloveworks_official");
    case 2:
      // return openModal(index);
      return window.open("https://twitter.com/thegloveworks");
    default:
      return (document.location.href = "mailto:contact@gloveworks.net");
  }
}

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      bodyText: "",
    };

    this.onNext = this.closeModal;
  }

  componentDidMount() {
    window.document.body.style.backgroundColor = "#fafafa";
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: "",
    });
  };

  renderEnText = (t) => (
    <p style={styles.text}>
      {t("contactUsFirst")}{" "}
      <u
        style={styles.email}
        onClick={() =>
          (document.location.href = "mailto:contact@gloveworks.net")
        }
        role="button"
        tabIndex="-1"
      >
        contact@gloveworks.net
      </u>{" "}
      {t("contactUsSecond")}
      <br />
      {t("contactUsThrid")}
      <br />
      <br />
      {t("contactUsForth")}
    </p>
  );

  renderEnText = (t) => (
    <p style={styles.text}>
      {t("contactUsFirst")}
      <u
        style={styles.email}
        onClick={() =>
          // OUTLIER - 20240122 - 3번
          // (document.location.href = this.props.i18n.language === 'en' ? 'mailto:contact@gloveworks.net' : 'mailto:gloveworks.jp@gloveworks.net')
          (document.location.href =
            this.props.i18n.language === "en"
              ? "mailto:contact@gloveworks.net"
              : "mailto:donald@gloveworks.net")
        }
        role="button"
        tabIndex="-1"
      >
        {/* OUTLIER - 20240122 - 3번 */}
        {/* { this.props.i18n.language === 'en' ? 'contact@gloveworks.net' : 'gloveworks.jp@gloveworks.net'} */}
        {this.props.i18n.language === "en"
          ? "contact@gloveworks.net"
          : "donald@gloveworks.net"}
      </u>{" "}
      {t("contactUsSecond")}
      <br />
      {t("contactUsThrid")}
      <br />
      <br />
      <br />
      <br />
      <strong style={{ fontWeight: "bold" }}>Gloveworks Tiny Shop</strong>
      <br />
      <br />
      467 Main St Warrenton
      <br />
      <br />
      Georgia USA 30828
      <br />
      <br />
      <br />
      <br />
      <strong style={{ fontWeight: "bold" }}>STORE HOURS</strong>
      <br />
      <br />
      Business hours open by appointment (Appointment: contact to{" "}
      <u
        style={styles.email}
        onClick={() => (document.location.href = "mailto:dan@gloveworks.net")}
        role="button"
        tabIndex="-1"
      >
        dan@gloveworks.net
      </u>
      )
      <br />
      <br />
      <br />
      <br />
      {t("contactUsForth")}
    </p>
  );

  renderKoText = (t) => (
    <p style={styles.text}>
      궁금한 점이 있으시면{" "}
      <u
        style={styles.email}
        onClick={() =>
          (document.location.href = "mailto:contact@gloveworks.net")
        }
        role="button"
        tabIndex="-1"
      >
        contact@gloveworks.net
      </u>{" "}
      으로 이메일을 보내주세요.
      <br />
      가능한 빠른 시일 안에 답변 드리겠습니다.
      <br />
      <br />
      <br />
      <br />
      <strong style={{ fontWeight: "bold" }}>글러브웍스 타이니샵</strong>
      <br />
      <br />
      467 Main St Warrenton
      <br />
      <br />
      Georgia USA 30828
      <br />
      <br />
      <br />
      <br />
      <strong style={{ fontWeight: "bold" }}>운영시간</strong>
      <br />
      <br />
      방문시 예약이 필요합니다. (예약문의:{" "}
      <u
        style={styles.email}
        onClick={() => (document.location.href = "mailto:dan@gloveworks.net")}
        role="button"
        tabIndex="-1"
      >
        dan@gloveworks.net
      </u>
      )
      <br />
      <br />
      <br />
      <br />
      글러브웍스 SNS를 팔로우하고 세일정보를 포함해 글러브웍스의 최신소식을 더욱
      쉽게 받아보세요!
    </p>
  );

  render() {
    const { t, i18n } = this.props;
    console.log(i18n.language);

    const { isModal, bodyText } = this.state;
    return (
      <div>
        <Modal
          isModal={isModal}
          bodyText={bodyText}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        <Step menu={STEP_MENU} />
        <p style={styles.title}>{t("contactTitle")}</p>
        <img src={contactImg} style={styles.img} alt="" />
        {(i18n.language === "en" || i18n.language === "ja") &&
          this.renderEnText(t)}
        {i18n.language === "ko" && this.renderKoText()}

        <div style={styles.iconBox}>
          {icons.map((item, i) => (
            <DivButton
              key={shortid.generate()}
              onClick={() => onHerf(this.openModal, i)}
            >
              <img src={item} alt="" />
            </DivButton>
          ))}
        </div>
      </div>
    );
  }
}

const styles = {
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  img: {
    width: 476,
    height: 357,
    margin: "auto",
    marginTop: 47,
    display: "block",
  },
  text: {
    fontWeight: 300,
    fontFamily: "Roboto",
    margin: "auto",
    marginTop: 40,
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: "#142935",
    width: 854,
    textAlign: "center",
  },
  email: {
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    color: "#007dff",
    cursor: "pointer",
  },
  iconBox: {
    margin: "auto",
    marginTop: 26,
    width: 40 * 5 + 12 * 4,
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40,
  },
};

Contact.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
};

Contact.defaultProps = {
  t: () => {},
  i18n: {},
};

export default withTranslation("translations")(Contact);
