import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { updateGlove, uploadWristLogo } from '../../actions/creators';
import WristPatchButtons from '../WristPatchButtons';
import TextOptions from '../TextOptions';
import FlagButtons from '../FlagButtons';
import CustomImageButtons from '../CustomImageButtons';

import gloveControl from '../higher-order/GloveControl';

import { checkedCurrencySymbol, returnComma } from '../../../../common/Utils';

const WristPersonalizationTypeButton = gloveControl(({
  active,
  onClick,
  children,
}) => (
  <div
    className={classNames('glove-personalization-type-button clearfix', { active })}
    onClick={onClick}
  >
    {children}
  </div>
));

const WristPersonalize = ({
  wristType,
  personalizationTypes,
  currentType,
  currentPatch,
  onPatchClick,
  currentText,
  onTextChange,
  currentFont,
  onFontClick,
  textColors,
  currentTextColor,
  onTextColorClick,
  currentLogo,
  onLogoUpload,
  currentFlag,
  onFlagClick,
  currentCustomImage,
  onCustomImageClick,
  i18n,
}) => {
  let fontSize;
  if (currentText) {
    if (currentText.length < 3) {
      fontSize = '50px';
    } else if (currentText.length < 5) {
      fontSize = '34px';
    } else if (currentText.length < 7) {
      fontSize = '23px';
    } else if (currentText.length < 9) {
      fontSize = '15px';
    }
  }

  return (
    <div className="step-item-view-wrap pull-right">
      <div className="personalization-preview-wrap">
        <h4>WRIST PREVIEW</h4>
        <div className="personalization-preview wrist-personalization-preview">
          <img
            className="background"
            alt="Personalization preview"
            src="/personalization-preview.png"
          />
          <div className="drawArea">
            {(() => {
              switch (currentType.name) {
                case 'NONE':
                  return null;
                case 'DEFAULT':
                  if (wristType && wristType.name === 'VELCRO') {
                    return null;
                  }
                  return (
                    <div className="wrist-patch">
                      <img
                        src={currentPatch && currentPatch.image.url}
                        alt=""
                      />
                    </div>
                  );

                case 'TEXT':
                  return (<p
                    className={classNames('wrist-text', currentFont.className)}
                    style={{ color: currentTextColor.hexCode, fontSize }}
                          >
                    {currentText}
                  </p>);
                case 'LOGO':
                  return (
                    <div className="wrist-logo">
                      <img
                        src={currentLogo && currentLogo.image.logoPreview.url}
                        alt=""
                      />
                    </div>
                  );
                case 'FLAG':
                  return (<img
                    style={{
                      width: 120,
                      height: 75,
                    }}
                    className="wrist-flag"
                    src={currentFlag && currentFlag.icon.flagPreview.url}
                    alt=""
                          />);
                case 'IMAGE':
                  return (<img
                    className="wrist-image"
                    src={currentCustomImage && currentCustomImage.image.customImagePreview.url}
                    alt=""
                          />);
                default:
              }
            })()}
          </div>
        </div>
      </div>
      <div className="personalization-type-wrap">
        <h4>WRIST EMBROIDERY TYPE</h4>
        {personalizationTypes.map(type => (type.name !== 'DEFAULT' || !wristType || wristType.name !== 'VELCRO') &&
          <WristPersonalizationTypeButton
            key={type.id}
            attrName="wristPersonalizationType"
            attrValue={type}
          >
            <div className="pull-left">{type.name}</div>
            {type.price > 0 &&
              <div className="price pull-right">{checkedCurrencySymbol(i18n.language) + returnComma(parseInt(type.price, 10))}</div>
            }
          </WristPersonalizationTypeButton>)}
      </div>
      <div className="personalization-type-view-wrap">
        {(() => {
switch (currentType.name) {
          case 'NONE':
            return null;
          case 'DEFAULT':
            if (wristType && wristType.name === 'VELCRO') {
              return null;
            }
              return (<WristPatchButtons
                className="personalization-type-view"
                title="SELECT YOUR PATCH"
                currentPatch={currentPatch}
                onClick={onPatchClick}
              />);

          case 'TEXT':
            return (<TextOptions
              className="glove-personalization-type-view"
              textName="WRIST TEXT"
              currentText={currentText}
              onTextChange={onTextChange}
              currentFont={currentFont}
              onFontClick={onFontClick}
              colors={textColors}
              currentColor={currentTextColor}
              onColorClick={onTextColorClick}
            />);
          case 'LOGO':
            return (
              <div className="personalization-type-view">
                <h4>LOGO UPLOAD</h4>
                <input
                  type="file"
                  className="upload-logo"
                  onChange={e => onLogoUpload(e.target.files[0])}
                />
                <div className="uploaded-logo-wrap">
                  {currentLogo &&
                    <img
                      src={currentLogo.image.url}
                      alt=""
                    />
                  }
                </div>
              </div>
            );
          case 'FLAG':
            return (<FlagButtons
              className="personalization-type-view"
              title="SELECT YOUR FLAG"
              currentFlag={currentFlag}
              onClick={onFlagClick}
            />);
          case 'IMAGE':
            return (<CustomImageButtons
              className="personalization-type-view"
              title="SELECT YOUR IMAGE"
              currentCustomImage={currentCustomImage}
              onClick={onCustomImageClick}
            />);
          default:
        }
})()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    wristType,
    wristPersonalizationType,
    wristPatch,
    wristPersonalizationText,
    wristPersonalizationFont,
    wristPersonalizationTextColor,
    wristPersonalizationLogo,
    wristPersonalizationFlag,
    wristPersonalizationCustomImage,
  } = state.glove;

  // console.log(wristPersonalizationLogo, wristPersonalizationCustomImage);

  return {
    wristType,
    personalizationTypes: state.builder.wristPersonalizationTypes,
    currentType: wristPersonalizationType,
    currentPatch: wristPatch,
    currentText: wristPersonalizationText,
    currentFont: wristPersonalizationFont,
    textColors: state.builder.embroideryColors,
    currentTextColor: wristPersonalizationTextColor,
    currentLogo: wristPersonalizationLogo,
    currentFlag: wristPersonalizationFlag,
    currentCustomImage: wristPersonalizationCustomImage,
  };
};

const mapDispatchToProps = dispatch => ({
  onPatchClick: patch => dispatch(updateGlove({ wristPatch: patch })),
  onTextChange: text => dispatch(updateGlove({ wristPersonalizationText: text })),
  onFontClick: font => dispatch(updateGlove({ wristPersonalizationFont: font })),
  onTextColorClick: color => dispatch(updateGlove({ wristPersonalizationTextColor: color })),
  onLogoUpload: logo => dispatch(uploadWristLogo(logo)),
  onFlagClick: flag => dispatch(updateGlove({ wristPersonalizationFlag: flag })),
  onCustomImageClick: image => dispatch(updateGlove({ wristPersonalizationCustomImage: image })),
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WristPersonalize));
