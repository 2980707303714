import React from 'react';
import { connect } from 'react-redux';

import GloveTypes from './base/GloveTypes';
import LeatherGrades from './base/LeatherGrades';
import ThrowingHands from './base/ThrowingHands';
import Sizes from './base/Sizes';

class BasePanel extends React.Component {
  render() {
    const {
      // isFetching,
      gloveTypes, leatherGrades, gloveTypeImages, isActive,
    } = this.props;

    // console.log(gloveTypes, gloveTypeImages);

    if (!isActive) { return null; }

    return (
      <div className="glove-panel-step glove-panel-step-1 clearfix">
        <div className="step-item-view-wrap step-item-view-wrap-full">
          <GloveTypes gloveTypes={gloveTypes} gloveTypeImages={gloveTypeImages} />
          <LeatherGrades leatherGrades={leatherGrades} />
          <ThrowingHands />
          <Sizes />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { builder } = state;
  return {
    // isFetching: builder.isFetching,
    gloveTypes: builder.gloveTypes,
    leatherGrades: builder.leatherGrades || [],
    gloveTypeImages: builder.gloveTypeImages,
  };
};

export default connect(mapStateToProps)(BasePanel);
