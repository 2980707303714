import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import _Fetch from '../../common/Fetches';
import { getItem } from '../../common/StorageUtils';
import { getCookie } from '../../common/Utils';
import { Consumer } from '../../Context';

class Badge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: props.count,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    console.log('badge componentWillReceiveProps', nextProps, history);
    if (nextProps.history.location.pathname === '/orderReview') {
      return this.setState({ count: 0 });
    }
    return this.setState({ count: nextProps.count });
  }

  initialize = async () => {
    const { actions, count, history } = this.props;
    if (history.location.pathname === '/orderReview') {
      actions.reset();
    } else {
      const afterCount = await this.propcessCount();
      // console.log(afterCount, count);
      if (afterCount !== count) {
        actions.setCount(afterCount);
      }
    }
  };

  propcessCount = async () => {
    const userData =
      getCookie('userData') === '' ? null : JSON.parse(getCookie('userData'));

    // console.log(userData);

    if (userData) {
      try {
        const api = getItem('restAPI').model_desc.cart_count_my;
        const res = await _Fetch(
          api,
          `?pid_user=${userData.user.pid_user}`,
          [],
        );
        console.log('logins');
        return Object.values(res[0])[0];
      } catch (e) {
        console.log(e);
        return 0;
      }
    } else {
      if (getItem('cart') && getItem('cart').length > 0) {
        return getItem('cart').length;
      }
      return 0;
    }
  };

  render() {
    const { count } = this.state;
    const isVisbleQty =
      (getCookie('userData') && count > 0) || (getItem('cart') && count > 0);
    return (
      <div
        style={{
          width: 18,
          height: 18,
          borderRadius: 9,
          backgroundColor: '#edd167',
          position: 'absolute',
          top: -9,
          right: -9,
          display: isVisbleQty ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          letterSpacing: 'normal',
        }}
      >
        {count}
      </div>
    );
  }
}

Badge.propTypes = {
  history: PropTypes.object,
  count: PropTypes.number,
  actions: PropTypes.object,
};

Badge.defaultProps = {
  history: {},
  count: 0,
  actions: null,
};

const defaultMapContextToProps = value => ({
  count: value.count,
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withRouter(Badge));
