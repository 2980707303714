import React, { Component } from "react";

class EnShoppingPolicy extends Component {
  render() {
    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>Shipping policy</p>

          <p style={styles.subTitle(64)}>Shipping Information</p>
          <p style={styles.description(10)}>
            The shipping confirmation email including the tracking number and
            the shipping carrier will be sent once the glove’s shipped. Also,
            you can track the order status at our website by logging in to your
            account.
          </p>

          <p style={styles.subTitle(64)}>Shipping Method</p>
          <p style={styles.description(10)}>
            All packages will be shipped via registered mail such as DHL, FEDEX,
            SF EXPRESS (local Chinese). Our factories and warehouses are located
            in China, US and Japan.
            <br />
            <br />
            Please bear in mind that we don’t ship to PO BOX or RR address
            according to couriers’ policy. Customer shall provide us the
            physical address to get the parcel correctly.
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>Shipping Rates</p>
          <p style={styles.description(10)}>
            Our shipping rates according to the products and regions are as
            following:
            <br />
            <br />
            <b style={styles.descTitle}>
              1. Glove(Custom glove/Stock glove/Trainer glove/Pancake glove/Pro
              One series) & Pounding Pad
            </b>
            <br />
            {/* OUTLIER - 20240122 - 4번 내용 */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp; U.S. & Canada US$30.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; U.S. & Canada US$40.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; Latin America US$75.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; Latin America US$75.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; Europe US$35.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; Europe US$40.00 - US$45.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; Australia & New Zealand US$35.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; Australia & New Zealand US$34.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; East Asia (Japan, Republic of Korea)
            {/* US$25.00 */}
            US$28.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; China (Mainland, Taiwan, Hong Kong)
            {/* US$15.00 */}
            US$15.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; Southeast Asia & South Asia US$55.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; Southeast Asia & South Asia US$47.00
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>2. Mallet</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; U.S. & Canada US$20.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Latin America US$45.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Europe US$23.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Australia & New Zealand US$23.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; East Asia (Japan, Republic of Korea)
            US$17.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; China (Mainland, Taiwan, Hong Kong)
            US$13.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Southeast Asia & South Asia US$35.00
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>3. Hat & Pennant</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; U.S. & Canada US$15.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Latin America US$35.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Europe US$25.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Australia & New Zealand US$25.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; East Asia (Japan, Republic of Korea)
            US$8.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; China (Mainland, Taiwan, Hong Kong)
            US$10.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; Southeast Asia & South Asia US$25.00
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>4. Glove Oil</b>
            <br />
            Glove oil will be shipped for free if you purchase it with other
            products. Please understand that the glove oil is not to be sold
            separately.
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>Shipping Discount</p>
          <p style={styles.description(10)}>
            If you order more than 4 custom gloves or 4 stock gloves at a time,
            you’ll get 10% off the total shipping fee. Please understand that
            the shipping discount will be applied to the purchase within the
            same category.
          </p>

          <p style={styles.subTitle(64)}>Import Fees</p>
          <p style={styles.description(10)}>
            Items in your order that are designated for shipment from countries
            outside of the U.S. may be subject to taxes, customs duties and fees
            levied by the destination country ("Import Fees"). The recipient of
            the shipment is the importer of record in the destination country
            and is responsible for all Import Fees.
            <br />
            You will pay the import fees to your carrier when the parcel reaches
            its destination. To obtain details regarding the Actual Import Fees,
            or to obtain documentation or receipts in connection with customs
            clearance, you may contact the carrier specified in your shipment
            confirmation e-mail.
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>[Refund, Return & Exchange Policy]</p>

          <p style={styles.subTitle(64)}>Refund</p>
          <p style={styles.description(10)}>
            You can cancel the order of custom glove within 48 hours after you
            place the order. After 48 hours, we’ll start the production and we
            don’t refund your purchase.
          </p>

          <p style={styles.subTitle(64)}>Return & Exchange</p>
          <p style={styles.description(10)}>
            Our custom products are order-to-made and we don’t offer any return
            or exchange after unless the glove has any error, defect or damage
            due to our mistake. In such cases, you may email  Donald &nbsp;
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              (donald@gloveworks.net)
            </u>
            &nbsp; for assistance within 2 weeks after you receive your glove.
          </p>

          <p style={styles.subTitle(64)}>Gloveworks Warranty Policy</p>
          <p style={styles.description(10)}>
            We offer a full six-month warranty. If there’s any quality issue
            within 6 month after you purchase the glove, please email Donald
            <br />
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              (donald@gloveworks.net)
            </u>
            &nbsp; and we’ll figure out the best way to support you.
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: "auto",
    marginBottom: 64,
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  subTitle: (marginTop) => ({
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.33,
    color: "#000",
    marginTop,
  }),
  description: (marginTop) => ({
    fontSize: 14,
    marginTop,
    lineHeight: 1.57,
    color: "#142935",
  }),
  descTitle: {
    fontWeight: "bold",
  },
  email: {
    color: "#007dff",
    cursor: "pointer",
    outline: "none",
  },
};

export default EnShoppingPolicy;
