import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { none, isNil } from 'ramda';

import { confirmPurchase } from '../actions/creators';
import { checkedCurrencySymbol, returnComma } from '../../../common/Utils';

const img = '/svg/noun.svg';

const TotalPrice = ({ canPurchase, totalPrice, dispatch, i18n ,t }) => (
  <div id="custom-glove-overview" className={classNames('clearfix', { 'purchase-disabled': !canPurchase })}>
    <div className="pull-left price-wrap">
      <div className="tip">
      {t('customizeTotal')}
        <img alt="" src={img} className="tooltip" />
        <div className="tip-detail-price">
          {t('totalPriceNotice')}
        </div>
      </div>
      <span className="price">{checkedCurrencySymbol(i18n.language) + returnComma(totalPrice)}</span>
    </div>
    <div className="pull-right">
      <button
        className="proceed-to-purchase"
        onClick={() => canPurchase && dispatch(confirmPurchase())}
      >
          {t('customizePurchase')}
      </button>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const gloveTypeKeys = {
    'Single Welting': 'SingleWelting',
    'Double Welting': 'DoubleWelting',
    'First Baseman Mitt': 'FirstBase',
    'Catchers Mitt': 'Catcher',
    Softball: 'Softball',
  };

  const glove = state.glove;

  const leatherPrice = glove.leatherGrade ? parseFloat(glove.leatherGrade.prices[gloveTypeKeys[glove.gloveType]]) : 0;
  const personalizePrice = parseFloat(glove.wristPersonalizationType ? glove.wristPersonalizationType.price : 0) +
    parseFloat(glove.thumbPersonalizationType ? glove.thumbPersonalizationType.price : 0) +
    parseFloat(glove.liningPersonalizationType ? glove.liningPersonalizationType.price : 0);

  return {
    canPurchase: none(isNil, [glove.gloveType, glove.leatherGrade, glove.throwingHand]),
    totalPrice: leatherPrice + personalizePrice,
  };
};

export default withTranslation('translations')(connect(mapStateToProps)(TotalPrice));
