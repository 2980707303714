import React, { Component } from 'react';

class EnPolicy extends Component {
  render() {
    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>Privacy policy</p>

          <p style={styles.description(60)}>
            {`First of all, we understand your concerns about online security and privacy. And we’d like to emphasize that we do not sell, share, rent or trade your electronically provided personal information to others. We’ll do our best to keep your personal and payment information remains secure. Your privacy is protected.
              This Privacy Policy describes how your personal information is collected and used when you visit or make a purchase from www.gloveworks.net (the “Site”).`}
          </p>

          <p style={styles.subTitle(30)}>PERSONAL INFORMATION WE COLLECTS</p>
          <p style={styles.description(0)}>
            {`
              When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.
            `}
          </p>

          <p style={styles.subTitle(30)}>We collect Device Information using the following technologies:</p>
          <p style={styles.description(0)}>
            {`
              - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
              - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
              - “Web beacons”, “tags”, and “pixels” are electronic files used to record information about how you browse the Site.

              Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information, email address, and phone number. We refer to this information as “Order Information”.

              When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
            `}
          </p>

          <p style={styles.subTitle(30)}>HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
          <p style={styles.description(0)}>
            {`
              We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
              - Communicate with you;
              - Screen our orders for potential risk or fraud;
            `}
          </p>

          <p style={styles.subSubTitle(30)}>DATA RETENTION</p>
          <p style={styles.description(0)}>
            {`
              When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
            `}
          </p>

          <p style={styles.subSubTitle(30)}>CHANGES</p>
          <p style={styles.description(0)}>
            {`
              We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
            `}
          </p>

          <p style={styles.subSubTitle(30)}>MINORS</p>
          <p style={styles.description(0)}>
            {`
              If you are under 18, you may purchase our products only with the involvement of a parent or guardian. We do not knowingly collect personal information from children under the age of 13 without the consent of the child's parent or guardian.
            `}
          </p>

          <p style={styles.subSubTitle(30)}>CONTACT US</p>
          <p style={styles.description(10)}>
            For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e‑mail at
            {' '}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = 'mailto:donald@gloveworks.net')
              }
            >
              donald@gloveworks.net.
            </u>
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: 'auto',
    marginBottom: 64,
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: '#142935',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 50,
  },
  subTitle: marginTop => ({
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: '#000',
    marginTop,
  }),
  subSubTitle: marginTop => ({
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: '#000',
    marginTop,
  }),
  description: marginTop => ({
    fontSize: 14,
    marginTop,
    lineHeight: 1.57,
    color: '#142935',
    whiteSpace: 'pre-line',
  }),
  descTitle: {
    fontWeight: 'bold',
  },
  email: {
    color: '#007dff',
    cursor: 'pointer',
    outline: 'none',
  },
};

export default EnPolicy;
