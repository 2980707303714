import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Web from './shell/Web';
import Palm from './shell/Palm';
import Wrist from './shell/Wrist';
import Thumb from './shell/Thumb';
import IndexFinger from './shell/IndexFinger';
import MiddleFinger from './shell/MiddleFinger';
import RingFinger from './shell/RingFinger';
import Pinky from './shell/Pinky';
import FingerOption from './shell/FingerOption';
import ThumbWrist from './shell/ThumbWrist';
import Center from './shell/Center';
import Inbetween from './shell/Inbetween';
import WristColor from './shell/WristColor';
import UShape from './shell/UShape';
import ThumbSide from './shell/ThumbSide';
import PinkySide from './shell/PinkySide';
import WebColor from './shell/WebColor';
import Target from './shell/Target';
import Lining from './shell/Lining';
import FingerHood from './shell/FingerHood';
import { withTranslation } from 'react-i18next';
// import { changePreviewSide } from '../actions/creators';
const Components = {
  Web,
  Palm,
  Wrist,
  Thumb,
  'Index Finger': IndexFinger,
  'Middle Finger': MiddleFinger,
  'Ring Finger': RingFinger,
  Pinky,
  'Finger Option': FingerOption,
  ThumbWrist,
  Center,
  Inbetween,
  WristColor,
  'U-Shape': UShape,
  'Thumb Side': ThumbSide,
  'Pinky Side': PinkySide,
  WebColor,
  Target,
  Lining,
  'Finger Hood': FingerHood,
};

const Items = {
  'Single Welting': [
    'Web',
    'Palm',
    'Wrist',
    'Thumb',
    'Index Finger',
    'Middle Finger',
    'Ring Finger',
    'Pinky',
    'Finger Option',
  ],
  'Double Welting': [
    'Web',
    'Palm',
    { name: 'ThumbWrist', key: 'ThumbWrist' },
    'Center',
    { name: 'Bridge', key: 'Inbetween' },
    'Finger Option',
  ],
  'First Baseman Mitt': [
    'Web',
    'Palm',
    { name: 'Wrist', key: 'WristColor' },
    { name:'UShape', key:'U-Shape'},
    'Thumb Side',
    'Pinky Side',
  ],
  'Catchers Mitt': [
    { name: 'Web', key: 'WebColor' },
    'Palm',
    'Target',
    'Lining',
    'Thumb Side',
    'Pinky Side',
    'Finger Hood',
  ],
  Softball: [
    'Web',
    'Palm',
    'Wrist',
    'Thumb',
    'Index Finger',
    'Middle Finger',
    'Ring Finger',
    'Pinky',
    'Finger Option',
  ],
};

class ShellPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Web',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.gloveType !== this.props.gloveType) {
      if (nextProps.gloveType === 'Catchers Mitt') {
        this.setState({ activeItem: 'WebColor' });
      } else {
        this.setState({ activeItem: 'Web' });
      }
    }
  }

  onSelectItem(item) {
    console.log(item ,'item onSelectItem')
    this.setState({ activeItem: item });
  }
  textType(item){
    switch(item){
      case "indexfinger" : return "indexFinger"
      case "middlefinger" : return "middleFinger"
      case "ringfinger" : return "ringFinger"
      case "fingeroption" : return "fingerOption"
      default : return  item
    }
  }
  render() {
    const { isActive, gloveType, t } = this.props;
    const { activeItem } = this.state;
    const ActiveComponent = Components[activeItem];

    if (!isActive) { return null; }

    return (
      <div className="glove-panel-step clearfix">
        <div className="step-item-tab-view-wrap pull-left">
          {Items[gloveType].map((item) => {
            if (typeof item === 'object') {
              return (<div
                key={item.key}
                className={classNames('glove-step-item-tab', { active: activeItem === item.key })}
                onClick={() => this.onSelectItem(item.key)}
              >
                {t(this.textType(item.name.toLowerCase().replace(/(\s*)/g, "")))}
              </div>);
            }
              return (<div
                key={item}
                className={classNames('glove-step-item-tab', { active: activeItem === item })}
                onClick={() => this.onSelectItem(item)}
              >
                {t(this.textType(item.toLowerCase().replace(/(\s*)/g, "")))}
              </div>);
          })}
        </div>
        <ActiveComponent />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gloveType: state.glove.gloveType,
});

export default connect(mapStateToProps)(withTranslation()(ShellPanel));
