import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

const FlagButtons = ({
  title,
  className,
  flags,
  currentFlag,
  onClick,
}) => (
  <div className={className}>
    <h4>{title}</h4>
    <div className="flags-wrap clearfix">
      {flags.map(flag =>
        (<div
          key={flag.id}
          className={classNames('flag-button', { active: currentFlag && currentFlag.id === flag.id })}
          onClick={() => onClick(flag)}
        >
          <img
            src={flag.icon.url}
            alt=""
          />
          <div className="name">{flag.name}</div>
        </div>))}
    </div>
  </div>
);

const mapStateToProps = state => ({
  flags: state.builder.flags,
});

export default connect(mapStateToProps)(FlagButtons);
