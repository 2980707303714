import GloveRenderer from './GloveRenderer';

export default class CatchersMittRenderer extends GloveRenderer {
  constructor(props) {
    super(props);
    this.gloveType = 'Catchers Mitt';
  }

  renderWebSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let webShadowImageKey;
    let palmShadowImageKey, liningShadowImageKey,
        thumbSideShadowImageKey, fingerHoodShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
      webShadowImageKey = 'shadowPython';
    } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
      webShadowImageKey = 'shadowPaisley';
    } else {
      webShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.liningPython.url !== undefined && glove.liningPython.url !== null && glove.liningPython.name.toLowerCase().includes("monogram")) {
      liningShadowImageKey = 'shadowPython';
    } else if (glove.liningPython.name.toLowerCase().includes("paisley")) {
      liningShadowImageKey = 'shadowPaisley';
    } else {
      liningShadowImageKey = 'shadow';
    }
    if (glove.thumbSidePython.url !== undefined && glove.thumbSidePython.url !== null && glove.thumbSidePython.name.toLowerCase().includes("monogram")) {
      thumbSideShadowImageKey = 'shadowPython';
    } else if (glove.thumbSidePython.name.toLowerCase().includes("paisley")) {
      thumbSideShadowImageKey = 'shadowPaisley';
    } else {
      thumbSideShadowImageKey = 'shadow';
    }
    if (glove.fingerHoodPython.url !== undefined && glove.fingerHoodPython.url !== null && glove.fingerHoodPython.name.toLowerCase().includes("monogram")) {
      fingerHoodShadowImageKey = 'shadowPython';
    } else if (glove.fingerHoodPython.name.toLowerCase().includes("paisley")) {
      fingerHoodShadowImageKey = 'shadowPaisley';
    } else {
      fingerHoodShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('web', glove.webPython.hexCode, webShadowImageKey),
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('lining', glove.liningPython.hexCode, liningShadowImageKey),
      this.renderLayer('thumbSide', glove.thumbSidePython.hexCode, thumbSideShadowImageKey),
      this.renderLayer('fingerHood', glove.fingerHoodPython.hexCode, fingerHoodShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  })

  renderFingerSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let webShadowImageKey;
    let palmShadowImageKey, liningShadowImageKey,
        thumbSideShadowImageKey, pinkySideShadowImageKey, fingerHoodShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
      webShadowImageKey = 'shadowPython';
    } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
      webShadowImageKey = 'shadowPaisley';
    } else {
      webShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.liningPython.url !== undefined && glove.liningPython.url !== null && glove.liningPython.name.toLowerCase().includes("monogram")) {
      liningShadowImageKey = 'shadowPython';
    } else if (glove.liningPython.name.toLowerCase().includes("paisley")) {
      liningShadowImageKey = 'shadowPaisley';
    } else {
      liningShadowImageKey = 'shadow';
    }
    if (glove.thumbSidePython.url !== undefined && glove.thumbSidePython.url !== null && glove.thumbSidePython.name.toLowerCase().includes("monogram")) {
      thumbSideShadowImageKey = 'shadowPython';
    } else if (glove.thumbSidePython.name.toLowerCase().includes("paisley")) {
      thumbSideShadowImageKey = 'shadowPaisley';
    } else {
      thumbSideShadowImageKey = 'shadow';
    }
    if (glove.pinkySidePython.url !== undefined && glove.pinkySidePython.url !== null && glove.pinkySidePython.name.toLowerCase().includes("monogram")) {
      pinkySideShadowImageKey = 'shadowPython';
    } else if (glove.pinkySidePython.name.toLowerCase().includes("paisley")) {
      pinkySideShadowImageKey = 'shadowPaisley';
    } else {
      pinkySideShadowImageKey = 'shadow';
    }
    if (glove.fingerHoodPython.url !== undefined && glove.fingerHoodPython.url !== null && glove.fingerHoodPython.name.toLowerCase().includes("monogram")) {
      fingerHoodShadowImageKey = 'shadowPython';
    } else if (glove.fingerHoodPython.name.toLowerCase().includes("paisley")) {
      fingerHoodShadowImageKey = 'shadowPaisley';
    } else {
      fingerHoodShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('web', glove.webPython.hexCode, webShadowImageKey),
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('lining', glove.liningPython.hexCode, liningShadowImageKey),
      this.renderLayer('thumbSide', glove.thumbSidePython.hexCode, thumbSideShadowImageKey),
      this.renderLayer('pinkySide', glove.pinkySidePython.hexCode, pinkySideShadowImageKey),
      this.renderLayer('fingerHood', glove.fingerHoodPython.hexCode, fingerHoodShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  })

  renderPalmSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let webShadowImageKey;
    let palmShadowImageKey, targetShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
      webShadowImageKey = 'shadowPython';
    } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
      webShadowImageKey = 'shadowPaisley';
    } else {
      webShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.targetPython.url !== undefined && glove.targetPython.url !== null && glove.targetPython.name.toLowerCase().includes("monogram")) {
      targetShadowImageKey = 'shadowPython';
    } else if (glove.targetPython.name.toLowerCase().includes("paisley")) {
      targetShadowImageKey = 'shadowPaisley';
    } else {
      targetShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('web', glove.webPython.hexCode, webShadowImageKey),
      this.renderLayer('target', glove.targetPython.hexCode, targetShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => this.renderStampingLayer('stampingGW', glove.palmPython.hexCode, 'shadow')).then(() => {
      if (glove.stamping == null) {
        return Promise.resolve();
      }
      if (glove.stamping.id === 1) {
        return this.renderStampingLayer('stampingGameDay1', glove.palmPython.hexCode, palmShadowImageKey);
      } else if (glove.stamping.id === 2) {
        return this.renderStampingLayer('stampingGameDay2', glove.palmPython.hexCode, palmShadowImageKey);
      } else if (glove.stamping.id === 3) {
        return this.renderStampingLayer('stampingProSteerhide1', glove.palmPython.hexCode, palmShadowImageKey);
      } else if (glove.stamping.id === 4) {
        return this.renderStampingLayer('stampingProSteerhide2', glove.palmPython.hexCode, palmShadowImageKey);
      } else if (glove.stamping.id === 5) {
        return this.renderStampingLayer('stampingProKip1', glove.palmPython.hexCode, palmShadowImageKey);
      } else if (glove.stamping.id === 6) {
        return this.renderStampingLayer('stampingProKip2', glove.palmPython.hexCode, palmShadowImageKey);
      }
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })
}
