import React from 'react';
import { connect } from 'react-redux';

import Tabs from './Tabs';
import BasePanel from './BasePanel';
import ShellPanel from './ShellPanel';
import TrimPanel from './TrimPanel';
import PersonalizePanel from './PersonalizePanel';

const Sidebar = ({ currentTab }) => (
  <div className="custom-glove-panel-wrap">
    <div id="custom-glove-panel">
      <Tabs />
      <div id="custom-glove-panel-step-view-wrapper">
        <BasePanel isActive={currentTab === 'base'} />
        <ShellPanel isActive={currentTab === 'shell'} />
        <TrimPanel isActive={currentTab === 'trim'} />
        <PersonalizePanel isActive={currentTab === 'personalize'} />
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  currentTab: state.tabs.currentTab,
});

export default connect(mapStateToProps)(Sidebar);
