import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

const FontButtons = ({
  title,
  className,
  fonts,
  currentFont,
  onClick,
}) => (
  <div className={className}>
    <h4>{title}</h4>
    {fonts.map(font =>
        (<div
          key={font.id}
          className={classNames('font-button', { active: currentFont && currentFont.id === font.id })}
          onClick={() => onClick(font)}
        >
          <span className={font.className}>{font.name}</span>
        </div>))}
  </div>
);

const mapStateToProps = state => ({
  fonts: state.builder.fonts,
});

export default connect(mapStateToProps)(FontButtons);
