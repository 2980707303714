import React, { Component } from 'react';
// import UserManage from './UserManage';
// import { getItem } from './StorageUtils';
import SERVER_URL from './Settings';
import FireAuthUser from '../api/fireauthuser/FireAuthUser';

const WithSetUserManage = () => WrappedComponen => class extends Component {
  state = {
    userManage: null,
  }

  componentDidMount() {
    this.initRestAPI();
  }

  initRestAPI() {
    // const res = getItem('restAPI').auth_desc;
    const config = {
      apiKey: 'AIzaSyDZJYu8aSG26eFo1-3JDUmcQcQ5U0Gs6ZQ',
      authDomain: 'test-gloveworks-user.firebaseapp.com',
      databaseURL: 'https://test-gloveworks-user.firebaseio.com',
      projectId: 'test-gloveworks-user',
      storageBucket: 'test-gloveworks-user.appspot.com',
      messagingSenderId: '223445316770',
    };
    this.setState({
      // userManage: new UserManage(this, res),
      userManage: new FireAuthUser(SERVER_URL, 'gloveworks', config),
    });
  }

  render() {
    const { userManage } = this.state;

    console.log('red');

    return (
      <WrappedComponen {...this.props} userManage={userManage} />
    );
  }
};
export default WithSetUserManage;
