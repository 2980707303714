import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../components/input/Input';
import WithSetUserManage from '../../common/WithSetUserManage';

const check = '/svg/check_b.svg';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSend: false,
    };

    this.email = '';
  }

  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
  }

  onResetPw = async (email) => {
    const { userManage } = this.props;

    const res = await userManage.resetPw(email);

    console.log('res', res);
  }

  render() {
    return (
      <div>
        <div style={styles.findPasswordBox}>
          <p style={styles.title}>Forgot your password?</p>
          <p style={styles.subTitle}>
            {this.state.isSend ? 'An email has been sent with further instructions'
              : 'Enter your email address to reset your password'}

          </p>

          {this.state.isSend ?
            <img
              src={check}
              alt=""
              style={styles.checkImg}
            />
            : <div>
              <Input
                onChange={email => (this.email = email)}
                style={styles.input()}
                placeholder="Email address"
                placeholderColor="#868686"
                type="text"
              />

              <div
                style={styles.submitBtn}
                onClick={() => this.setState({ isSend: true }, () => this.onResetPw(this.email))}
                role="button"
                tabIndex="0"
              >
                SUBMIT
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

const styles = {
  findPasswordBox: {
    margin: 'auto',
    marginTop: 100,
    marginBottom: 124,
    backgroundColor: '#fff',
    width: 600,
    height: 500,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Roboto Condensed',
    color: '#000',
    fontWeight: 'bold',
    marginTop: 53,
    fontSize: 24,
  },
  subTitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 18,
    color: '#000',
    marginTop: 15,
  },
  input: (marginTop = 35) => ({
    width: 280 - 30,
    height: 42,
    border: '1px solid #ddd',
    outline: 'none',

    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#251f1b',
    marginTop,
  }),
  submitBtn: {
    marginTop: 21,
    width: 280,
    height: 42,
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Roboto Condensed',
    fontSize: 12,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkImg: {
    marginTop: 41,
  },
};

ResetPassword.propTypes = {
  userManage: PropTypes.object,
};

ResetPassword.defaultProps = {
  userManage: {},
};

export default WithSetUserManage()(ResetPassword);
