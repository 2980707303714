import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import { withTranslation } from "react-i18next";

import {
  checkedCurrencySymbol,
  settingCost,
  getPerItemCouponCost,
  returnComma,
} from "../../common/Utils";

import Step from "../../components/step/Step";
import CheckBox from "../../components/checkBox/CheckBox";
import CartItem from "../../components/item/CheckOutCartItem";
import DivButton from "../../components/button/DivButton";
import ConfirmCartModal from "../../components/modal/ConfirmCartModal";
import Accordion from "../../components/accordion/PaymentAccordion";
import NameForm from "../../containers/form/AccountAddressForm";

import { YEN_INCOM_TAX_PER } from "../../common/Constants";

const STEP_MENU = ["Shop", "My Cart", "Check out"];
const STEP = ["SHIPPING INFO", "PAYMENT INFO", "ORDER REVIEW"];

const arrowW = "/svg/arrow_wr.svg";
const arrowL = "/img/arrow_left_w.png";
const paypal = "/img/paypal.jpeg";
const paypalN = "/img/paypal_n.png";
const paypalJ = "/img/paypal_j.png";
const inicis = "/img/inicis.jpeg";
const paymentwall = "/img/paymentwall.jpeg";
const wallEn = "";
const wallJp = "";

const loader = "/assets/images/ajax_loader.gif";

function subtotal(lanCode, data) {
  let cost = 0;
  data.map((item) => (cost += settingCost(lanCode, item) * item.quantity));
  return cost;
}

function sumQty(data) {
  try {
    let qty = 0;
    data.map((item) => (qty += item.quantity));
    return qty;
  } catch (e) {
    return 1;
  }
}

class PaymentInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 2,
      paymentType: props.i18n.language === "ko" ? 4 : 1,
      isViewDetailModal: false,
      isLoaded: false,
    };

    this.viewDetailData = null;
    this.firstName = null;
    this.lastName = null;
    this.payInicis = true;
    this.payMentwall = true;
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }

  // coupon total Cost
  calCouponTotalCost(couponData) {
    console.log("couponData", couponData);
    if (!couponData) return null;

    // 아이템 마다 쿠폰 가격 저장 되어있는 배멸
    const couponTotalCostArr = this.props.data.map(
      (item) =>
        getPerItemCouponCost(item, couponData, this.props.i18n.language) *
        item.quantity
    );

    // 배열에 있는 값들 합산
    const couponTotalCost = couponTotalCostArr.reduce((a, b) => a + b, 0);
    return couponTotalCost;
  }

  renderStep() {
    return STEP.map((item, i) => (
      <div style={styles.stepItemBox} key={shortid.generate()}>
        <div style={styles.stepItemCircle(this.state.step > i)}>{i + 1}</div>
        <p style={styles.stepItemText(this.state.step > i)}>{item}</p>
      </div>
    ));
  }

  renderModal() {
    if (this.viewDetailData) {
      return (
        <ConfirmCartModal
          data={this.viewDetailData}
          isModal={this.state.isViewDetailModal}
          onClose={() => {
            this.viewDetailData = null;
            this.setState({ isViewDetailModal: false });
          }}
        />
      );
    }
    return null;
  }

  renderAddr = (data) => (
    <div style={{ marginTop: 24 }}>
      <div style={styles.addrRow}>
        <div>
          <p style={styles.addrTitle}>First name</p>
          <p style={styles.addrBodyText}>{data.name_first}</p>
        </div>
        <div style={{ marginLeft: 150 }}>
          <p style={styles.addrTitle}>List name</p>
          <p style={styles.addrBodyText}>{data.name_last}</p>
        </div>
      </div>

      <div style={{ marginTop: 16 }}>
        <p style={styles.addrTitle}>Company (optional)</p>
        <p style={styles.addrBodyText}>{data.company}</p>
      </div>

      <div style={{ marginTop: 16 }}>
        <p style={styles.addrTitle}>Address</p>
        <p style={styles.addrBodyText}>{data.addr}</p>
      </div>

      <div style={{ marginTop: 16 }}>
        <p style={styles.addrTitle}>City</p>
        <p style={styles.addrBodyText}>{data.city}</p>
      </div>

      <div style={{ ...styles.addrRow, marginTop: 16 }}>
        <div>
          <p style={styles.addrTitle}>Country</p>
          <p style={styles.addrBodyText}>{data.country}</p>
        </div>
        <div style={{ marginLeft: 120 }}>
          <p style={styles.addrTitle}>State</p>
          <p style={styles.addrBodyText}>{data.state}</p>
        </div>
        <div style={{ marginLeft: 120 }}>
          <p style={styles.addrTitle}>Zip Code</p>
          <p style={styles.addrBodyText}>{data.zipcode}</p>
        </div>
      </div>

      <div style={{ marginTop: 16 }}>
        <p style={styles.addrTitle}>Phone Number</p>
        <p style={styles.addrBodyText}>{data.phone}</p>
      </div>
    </div>
  );

  render() {
    const { isLoaded } = this.state;

    const { i18n, data, couponData, addressData, shippingNode, t } = this.props;

    const qty = sumQty(data);
    const isDiscountShppingFee = qty > 3;

    console.log("qty", qty);

    const shippingTotal =
      this.props.shippingData.customTotalFee +
      this.props.shippingData.stockTotalFee;

    const beforeDiscountShppingFee = isDiscountShppingFee
      ? shippingTotal * 0.2
      : 0;
    const discountShppingFee =
      i18n.language === "en"
        ? beforeDiscountShppingFee.toFixed(2)
        : Math.ceil(beforeDiscountShppingFee);

    const tax =
      i18n.language === "ja"
        ? Math.floor(
            Number(
              (subtotal(i18n.language, data) -
                this.calCouponTotalCost(couponData)) *
                YEN_INCOM_TAX_PER
            )
          )
        : 0;

    console.log(
      "subtotal",
      subtotal(i18n.language, data),
      "custom total",
      this.props.shippingData.customTotalFee,
      "stock total",
      this.props.shippingData.stockTotalFee,
      "coupon total",
      this.calCouponTotalCost(couponData),
      "discount shipping fee",
      discountShppingFee,
      "tax fee",
      tax
    );

    return (
      <div>
        {this.renderModal()}
        {/* {isLoaded && (
          <div style={styles.demmer}>
            <img src={loader} alt="" />
          </div>
        )} */}
        <Step menu={STEP_MENU} />

        <div style={styles.stepBox}>{this.renderStep()}</div>

        <Accordion
          styles={{
            container: styles.addressBox(),
            title: styles.addressBoxTitleAtfer,
          }}
          title={t("shippingAddress")}
        >
          {this.renderAddr(addressData.shippingAddr)}
        </Accordion>

        {shippingNode()}

        <Accordion
          styles={{
            container: styles.addressBox(20),
            title: styles.addressBoxTitleAtfer,
          }}
          title={t("shippingBillingAddr")}
        >
          {this.renderAddr(addressData.billingAddr)}
        </Accordion>

        <div style={styles.cartBox}>
          <div style={styles.addressBoxTitle}>{t("paymentInfoProduct")}</div>
          {data.map((item, i) => (
            <CartItem
              coupon={couponData}
              onViewDtail={(glove) => {
                setTimeout(() => window.scrollTo(0, 0), 1);
                this.viewDetailData = glove;
                this.setState({ isViewDetailModal: true });
              }}
              data={item}
              key={shortid.generate()}
              marginBottom={i === this.props.data.length - 1 ? 17 : 20}
            />
          ))}

          <div style={styles.couponBox(8)}>
            <p style={styles.cartSubTotalTtitle}>Cart Subtotal</p>
            <p style={styles.cartSubTotalCost}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === "en"
                ? returnComma(subtotal(i18n.language, data).toFixed(2))
                : returnComma(subtotal(i18n.language, data))}
            </p>
          </div>

          {this.calCouponTotalCost(couponData) > 0 ? (
            <div style={styles.couponBox(8)}>
              <p style={styles.cartSubTotalTtitle}>Coupon Discount</p>
              <p style={{ ...styles.cartSubTotalCost, color: "#d0021b" }}>
                -{checkedCurrencySymbol(i18n.language)}
                {i18n.language === "en"
                  ? returnComma(this.calCouponTotalCost(couponData).toFixed(2))
                  : returnComma(Math.ceil(this.calCouponTotalCost(couponData)))}
              </p>
            </div>
          ) : null}

          {/* OUTLIER - 20240122 - 2번 */}
          {/* {i18n.language === "ja" ? (
            <div style={styles.couponBox(8)}>
              <p style={styles.cartSubTotalTtitle}>消費税10%</p>
              <p style={styles.cartSubTotalCost}>
                {checkedCurrencySymbol(i18n.language)}
                {returnComma(Math.floor(tax))}
              </p>
            </div>
          ) : null} */}

          <div style={styles.couponBox(8)}>
            <p style={styles.cartSubTotalTtitle}>Shipping Fee Total</p>
            <p style={styles.cartSubTotalCost}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === "en"
                ? returnComma(shippingTotal.toFixed(2))
                : returnComma(shippingTotal)}
            </p>
          </div>

          <div style={styles.myDiv}>
            <p>
              {i18n.language === "en" || i18n.language === "ko"
                ? " * Import duties & taxes are due on delivery, paid to the carrier if any."
                : " 輸入関税及び手数料が課せられる場合があります。"}
            </p>
            <p>
              {i18n.language === "en" || i18n.language === "ko"
                ? " Details vary according to the relevant customs and tax authorities in the destination country"
                : " 商品が届く際に、配送業者が代金引 換としてお客様に請求します。"}
            </p>
            <p>
              {i18n.language === "en" || i18n.language === "ko"
                ? " "
                : " 通関の条件は、お届け国によって異なりますので、"}
            </p>
            <p>
              {i18n.language === "en" || i18n.language === "ko"
                ? " "
                : " 詳し い情報については最寄りの税関へお問い合わせください。"}
            </p>
          </div>

          {isDiscountShppingFee && (
            <div
              style={{
                margin: "auto",
                marginTop: 8,
                display: "flex",
                width: 948,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>*Shipping discount for ordering 4 or more items together.</p>
              <div
                style={{
                  width: 306,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: 24,
                }}
              >
                <p
                  style={{
                    color: "red",
                    fontFamily: "Roboto Condensed",
                    fontSize: 16,
                    letterSpacing: 0.9,
                  }}
                >
                  Shipping Discount
                </p>
                <p
                  style={{
                    color: "red",
                    fontFamily: "Roboto Condensed",
                    fontSize: 16,
                    letterSpacing: 0.9,
                  }}
                >
                  -{checkedCurrencySymbol(i18n.language)}
                  {i18n.language === "en"
                    ? returnComma(Number(discountShppingFee).toFixed(2))
                    : returnComma(Math.ceil(discountShppingFee))}
                </p>
              </div>
            </div>
          )}

          <div style={styles.cartUnderline} />

          <div style={styles.totalBox}>
            {/* OUTLIER - 240201 - 추가내용1 */}
            {/* {i18n.language === "ja" ? (
              <p
                style={{
                  width: "50%",
                  position: "absolute",
                  marginLeft: "28px",
                  fontSize: "16px",
                }}
              >
                表示価格は税込みです。送料及び輸入関税は含まれておりません。
              </p>
            ) : null} */}
            <p style={styles.totalText}>Total</p>
            <p style={styles.totalCost}>
              {checkedCurrencySymbol(i18n.language)}
              {i18n.language === "en"
                ? returnComma(
                    (
                      subtotal(i18n.language, data) +
                      shippingTotal -
                      this.calCouponTotalCost(couponData) -
                      discountShppingFee
                    ).toFixed(2)
                  )
                : returnComma(
                    Math.floor(
                      subtotal(i18n.language, data) +
                        // OUTLIER - 20240122 - 2번
                        // tax +
                        shippingTotal -
                        this.calCouponTotalCost(couponData) -
                        discountShppingFee
                    )
                  )}
            </p>
          </div>
        </div>

        <div style={styles.shippingBox}>
          <div style={styles.addressBoxTitle}>
            {t("payemntInfoPaymetnMethod")}
          </div>
          <div
            style={{
              ...styles.blinngRowBox,
              display: i18n.language === "ko" ? "none" : "flex",
            }}
          >
            <CheckBox
              styles={shippingCheckBoxStyles}
              isDiv
              isChecked={this.state.paymentType === 1}
              onClick={() => this.setState({ paymentType: 1 })}
            />

            {/* <img style={{ width: '70%' }} src={i18n.language === 'ja' ? paypalJ : paypalN} alt="" /> */}
            <img style={{ width: "70%" }} src={paypal} alt="" />
          </div>

          {/* OUTLIER - 20240124 - 8번 */}
          {/* {(i18n.language === "ko" || i18n.language === "en") && ( */}
          {i18n.language === "en" && (
            <div
              style={{
                ...styles.whatIsPaypal,
              }}
            >
              <a
                style={styles.paypalText}
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.paypal.com/us/webapps/mpp/paypal-popup"
              >
                What is Paypal?
              </a>
              <p style={styles.paypalBodytext}>
                &nbsp;&nbsp;You will be redirected to the Paypal website to
                complete your checkout.
              </p>
            </div>
          )}

          {i18n.language === "ja" && (
            <div
              style={{
                ...styles.whatIsPaypal,
                width: "70%",
              }}
            >
              <p style={styles.paypalBodytext}>
                ペイパルは、お支払い情報をお店に伝えずに安全・簡単な決済ができるデジタルお財布です。クレジット・デビットカード・銀行(みずほ銀行、三井住友銀行、三菱UFJ銀行、ゆうちょ銀行、りそな銀行、埼玉りそな銀行）でお支払いできます。詳しくは、
                <a
                  style={styles.paypalText}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.paypal.com/us/webapps/mpp/paypal-popup"
                >
                  こちら
                </a>
                をご覧ください。
              </p>
            </div>
          )}

          {/* OUTLIER - 20240124 - 8번 */}
          {/* {i18n.language === "ko" && (
            <p style={{ marginLeft: 64, fontSize: 12, marginTop: 10 }}>
              * 페이팔 결제는 원화가 아닌 USD로 결제됩니다.
            </p>
          )} */}
          {i18n.language === "ko" && (
            <div
              style={{
                ...styles.blinngRowBox,
                // display: i18n.language === 'ja' ? 'none' : 'flex',
              }}
            >
              <CheckBox
                styles={shippingCheckBoxStyles}
                isDiv
                isChecked={this.state.paymentType === 4}
                onClick={() => this.setState({ paymentType: 4 })}
              />

              <img
                style={{ width: "160px", marginLeft: "20px" }}
                src={inicis}
                alt=""
              />
            </div>
          )}
          {i18n.language !== "ko" && (
            <>
              <div
                style={{
                  ...styles.blinngRowBox,
                  marginTop: "30px",
                  // display: i18n.language === 'ja' ? 'none' : 'flex',
                }}
              >
                <CheckBox
                  styles={shippingCheckBoxStyles}
                  isDiv
                  isChecked={this.state.paymentType === 3}
                  onClick={() => this.setState({ paymentType: 3 })}
                />

                <div style={{ display: "flex" }}>
                  <div style={{ lineHeight: "91px" }}>
                    <img
                      style={{
                        width: "70%",
                        marginLeft: "20px",
                        verticalAlign: "middle",
                      }}
                      src={paymentwall}
                      alt=""
                    />
                  </div>
                  {/* <img src="/img/paymentWall_e.png" alt="" /> */}
                </div>
              </div>
              {/* <p style={{marginLeft:'35px'}}>(will be available soon)</p> */}
            </>
          )}

          {/* OUTLIER - 240122 - 1번 */}
          {i18n.language === "ko" ? (
            <div
              style={{
                ...styles.blinngRowBox,
                marginTop: 20,
                display: i18n.language === "en" ? "none" : "flex",
              }}
            >
              <CheckBox
                styles={shippingCheckBoxStyles}
                isDiv
                isChecked={this.state.paymentType === 2}
                onClick={() => this.setState({ paymentType: 2 })}
              />
              <p style={styles.transferText}>{"계좌 이체 주문"}</p>
            </div>
          ) : null}
          {/* <div
              style={{
                ...styles.blinngRowBox,
                marginTop: 20,
                display: i18n.language === "en" ? "none" : "flex",
              }}
            >
              <CheckBox
                styles={shippingCheckBoxStyles}
                isDiv
                isChecked={this.state.paymentType === 2}
                onClick={() => this.setState({ paymentType: 2 })}
              />
              <p style={styles.transferText}>
                {i18n.language === "ja" ? "銀行振り込み決済" : "계좌 이체 주문"}
              </p>
            </div> */}

          <div
            style={{
              display: this.state.paymentType === 2 ? "block" : "none",
              marginTop: 10,
              marginLeft: 65,
            }}
          >
            <p style={styles.nameTtitle}>
              - {i18n.language === "ja" ? "入金者名" : "입금자성함"}
            </p>
            <NameForm
              getFirstName={(value) => {
                this.firstName = value;
                console.log("firstName", this.firstName);
              }}
            />
            <p
              style={{
                ...styles.accountsText,
                color: "red",
                marginTop: 5,
                width: 910,
                whiteSpace: "pre-line",
              }}
            >
              {i18n.language === "ja"
                ? "ご注文から2営業日後が支払期限日となります。（購入の翌日から数えて土日祝日を除いた2営業日以内）期限を過ぎた場合には自動的にキャンセルとなります。注文の際に提出した入金者名と実際の入金者名は必ず一致しなければ確認ができませんのでご注意下さい"
                : "계좌 이체 주문시 입력한 입금자명과 실제입금자의 성명이 반드시 일치하여야 하며, 2일 이내로 입금을 하셔야 하며 입금되지 않은 주문은 자동취소 됩니다. "}
            </p>
            {/* <p style={styles.bankTtitle}>- Deposit bank</p> */}
            <p style={{ ...styles.accountsText, fontSize: 16, marginTop: 15 }}>
              {i18n.language === "ja"
                ? "振込先：みずほ銀行芝支店 普通口座 4637525 グラブワークスジャパン株式会社"
                : "입금하실 계좌: 우리은행 176-014453-13-101                예금주 : ㈜ 아산 코퍼레이션"}
            </p>
          </div>
        </div>

        <div style={styles.bottomBox}>
          <DivButton
            style={styles.bottomBtn}
            onClick={() => this.props.onPreview()}
          >
            <p style={styles.bottomBtnText}>PREVIEW</p>
            <img src={arrowL} style={styles.bottomBtnLeftArrow} alt="w_arrow" />
          </DivButton>
          <DivButton
            style={styles.bottomBtn}
            onClick={() =>
              this.setState({ isLoaded: true }, () =>
                this.props.onCheckOut(this.state.paymentType, this.firstName)
              )
            }
          >
            {isLoaded ? (
              <img src={loader} alt="" />
            ) : (
              <Fragment>
                <p style={styles.bottomBtnText}>CHECK OUT</p>
                <img src={arrowW} style={styles.bottomBtnArrow} alt="w_arrow" />
              </Fragment>
            )}
          </DivButton>
        </div>
      </div>
    );
  }
}

PaymentInfo.propTypes = {
  onCheckOut: PropTypes.func,
  data: PropTypes.array,
  onPreview: PropTypes.func,
  i18n: PropTypes.object,
  couponData: PropTypes.object,
  shippingData: PropTypes.object,
  addressData: PropTypes.object,
  shippingNode: PropTypes.func,
  t: PropTypes.func,
};

PaymentInfo.defaultProps = {
  onCheckOut: () => {},
  data: [],
  onPreview: () => {},
  i18n: {},
  couponData: null,
  shippingData: null,
  addressData: null,
  shippingNode: null,
  t: () => {},
};

const shippingCheckBoxStyles = {
  container: {
    width: 18,
    height: 18,
    border: "solid 2px #251f1b",
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    borderRadius: 9,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "left",
  },
  img: {
    display: null,
  },
  check: {
    width: 8,
    height: 8,
    backgroundColor: "#007dff",
    borderRadius: 4,
    cursor: "pointer",
  },
};

const styles = {
  demmer: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  myDiv: {
    marginLeft: 600,
    marginRight: 20,
    fontSize: 12,
    textAlign: "left",
  },
  stepBox: {
    width: 540,
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    marginTop: 37,
  },
  stepItemBox: {
    display: "flex",
    flexDirection: "column",
  },
  stepItemCircle: (isVisible) => ({
    width: 30,
    height: 30,
    backgroundColor: isVisible ? "#007dff" : "rgba(204, 204, 204, 0.5)",
    borderRadius: 15,
    color: isVisible ? "#fff" : "#aaa",
    fontSize: 16,
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  }),
  stepItemText: (isVisible) => ({
    textAlign: "center",
    color: isVisible ? "#251f1b" : "rgba(204, 204, 204, 0.5)",
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    marginTop: 9,
  }),
  addressBox: (marginTop = 38) => ({
    width: 996,
    backgroundColor: "#fff",
    margin: "auto",
    border: "solid 1px rgba(21, 41, 53, 0.1)",
    marginTop,
    paddingLeft: 39,
    paddingRight: 40,
    paddingBottom: 24,
    paddingTop: 24,
    boxSizing: "border-box",
  }),
  addressBoxTitle: {
    color: "#251f1b",
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    lineHeight: 1.39,
    display: "flex",
    marginLeft: 30,
    alignItems: "center",
    height: 75,
  },
  addressBoxTitleAtfer: {
    color: "#251f1b",
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    lineHeight: 1.39,
    display: "flex",
    alignItems: "center",
  },
  addAddressBtn: {
    width: 240,
    height: 40,
    position: "relative",
    backgroundColor: "#000",
    cursor: "pointer",
    marginTop: 30,
    marginBottom: 39,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 30,
  },
  addAddressText: {
    fontFamily: "Roboto Condensed",
    fontSize: 14,
    letterSpacing: 0.2,
    color: "#ffffff",
  },
  modalTitle: {
    marginTop: 35,
    marginLeft: 60,
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 1.39,
    color: "#251f1b",
  },
  input: (width = 200) => ({
    width,
    height: 40,
    border: "1px solid #d9d9d9",
    backgroundColor: "#f7f7f7",
    outline: "none",
    fontFamily: "Roboto Condensed",
    fontSize: 14,
    color: "#251f1b",
    marginTop: 6,
    paddingLeft: 15,
    paddingRight: 15,
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
  }),
  shippingBox: {
    width: 996,
    backgroundColor: "#fff",
    border: "solid 1px rgba(21, 41, 53, 0.1)",
    margin: "auto",
    marginTop: 20,
    paddingBottom: 30,
  },
  blinngRowBox: {
    marginLeft: 35,
    display: "flex",
    alignItems: "center",
  },
  shippingRowBox: {
    marginLeft: 35,
    display: "flex",
    flexDirection: "column",
  },
  shippinRowText: {
    float: "left",
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    lineHeight: 1.56,
    color: "#251f1b",
  },
  blinngRowText: {
    float: "left",
    marginLeft: 10,
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    lineHeight: 1.56,
    color: "#251f1b",
  },
  cartBox: {
    width: 996,
    backgroundColor: "#fff",
    border: "solid 1px rgba(21, 41, 53, 0.1)",
    margin: "auto",
    marginTop: 20,
    paddingBottom: 20,
  },
  cartBoxTitle: {
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    color: "#251f1b",
    letterSpacing: 1,
    lineHeight: 1.39,
    fontWeight: "bold",
    height: 76,
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
  },
  couponBox: (marginTop) => ({
    display: "flex",
    marginTop,
    height: 24,
    alignItems: "center",
  }),
  couponTitle: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    letterSpacing: 0.9,
    color: "#251f1b",
    marginLeft: 642,
    width: 168,
    textAlign: "right",
  },
  couponDiscount: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    letterSpacing: 0.9,
    color: "#251f1b",
    marginLeft: 50,
    width: 88,
    textAlign: "right",
  },
  cartSubTotalTtitle: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    letterSpacing: 0.9,
    color: "#251f1b",
    marginLeft: 642,
    width: 168,
    textAlign: "left",
  },
  cartSubTotalCost: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    letterSpacing: 0.9,
    color: "#251f1b",
    marginLeft: 50,
    width: 88,
    textAlign: "right",
  },
  cartUnderline: {
    width: 948,
    height: 2,
    backgroundColor: "rgba(21, 41, 53, 0.1)",
    margin: "auto",
    marginTop: 17,
  },
  totalBox: {
    display: "flex",
    alignItems: "center",
    marginTop: 21,
  },
  totalText: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    color: "#251f1b",
    lineHeight: 1.38,
    letterSpacing: 0.9,
    marginLeft: 762,
    fontWeight: "600",
  },
  totalCost: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    width: 120,
    textAlign: "right",
    color: "#251f1b",
    lineHeight: 1.25,
    letterSpacing: 1.5,
    marginLeft: 20,
    fontWeight: "600",
  },
  bottomBtn: {
    width: 204,
    height: 60,
    backgroundColor: "#251f1b",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 47,
  },
  bottomBtnText: {
    fontFamily: "Roboto Condensed",
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    fontSize: 18,
    lineHeight: 1.11,
    letterSpacing: 1,
  },
  bottomBtnArrow: {
    width: 6,
    height: 8,
    position: "absolute",
    right: 28,
    zIndex: 2,
    top: "50%",
    transform: "translate(0px, -50%)",
    MsTransform: "translate(0px, -50%)",
    Webkittransform: "translate(0px, -50%)",
  },
  bottomBtnLeftArrow: {
    width: 6,
    height: 8,
    position: "absolute",
    left: 26,
    zIndex: 2,
    top: "50%",
    transform: "translate(0px, -50%)",
    MsTransform: "translate(0px, -50%)",
    Webkittransform: "translate(0px, -50%)",
  },
  bottomBox: {
    margin: "auto",
    marginTop: 27,
    marginBottom: 34,
    width: 996,
    justifyContent: "flex-end",
    display: "flex",
  },
  nonmemberBox: {
    width: 996,
    height: 552,
    backgroundColor: "#fff",
    border: "solid 1px rgba(21, 41, 53, 0.1)",
    margin: "auto",
    marginTop: 37,
  },
  nonmemberInnerBox: {
    width: 743,
    height: 18 + 248 + 40,
    marginLeft: 30,
    marginTop: 24,
  },
  shippingTitle: {
    marginTop: 74,
    marginLeft: 30,
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: 1.39,
    color: "#251f1b",
  },
  emailSetBox: {
    marginLeft: 30,
    marginTop: 30,
    display: "flex",
    alignItems: "flex-end",
  },
  emailAddress: {
    fontFamily: "Roboto Condensed",
    color: "#251f1b",
    lineHeight: 1.39,
    fontSize: 18,
    fontWeight: "bold",
  },
  accountText: {
    fontFamily: "Roboto Condensed",
    color: "#666",
    fontSize: 12,
    marginLeft: 393,
  },
  loginText: {
    fontFamily: "Roboto Condensed",
    color: "#007dff",
    fontSize: 12,
    cursor: "pointer",
  },
  emailAddressBox: {
    marginLeft: 30,
  },
  addressForm: {
    marginTop: 24,
    marginLeft: 60,
  },
  paypalBox: {
    border: "solid 1px rgba(21, 41, 53, 0.1)",
    width: 116,
    height: 34,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 15,
  },
  whatIsPaypal: {
    display: "flex",
    marginLeft: 63,
    marginTop: 10,
  },
  paypalText: {
    fontSize: 12,
    fontWeight: 300,
    color: "#007dff",
  },
  paypalBodytext: {
    fontSize: 12,
    fontWeight: 300,
    color: "#4a4a4a",
  },
  addrTitle: {
    fontFamily: "Metropolis",
    fontSize: 12,
    fontWeight: "bold",
    color: "#515356",
  },
  addrRow: {
    display: "flex",
    alignItems: "center",
  },
  addrBodyText: {
    marginTop: 5,
    fontSize: 16,
    color: "#251f1b",
  },
  transferText: {
    fontFamily: "Metropolis",
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 1,
    color: "#251f1b",
    marginLeft: 15,
  },
  nameTtitle: {
    fontFamily: "Metropolis",
    fontSize: 14,
    letterSpacing: 0.8,
    color: "#251f1b",
    marginTop: 10,
    marginBottom: 10,
  },
  bankTtitle: {
    fontFamily: "Metropolis",
    fontSize: 14,
    letterSpacing: 0.8,
    color: "#251f1b",
    marginTop: 15,
    marginBottom: 3,
    fontWeight: 600,
  },
  accountsText: {
    fontFamily: "Metropolis",
    fontSize: 12,
    whiteSpace: "pre",
  },
};

export default withTranslation("translations")(PaymentInfo);
