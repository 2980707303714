import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../lib/modal';
import DivButton from '../../components/button/DivButton';

const Close = '/svg/close.svg';
// const arrowWhite = '/svg/arrow_wr.svg';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

class BaseModal extends Component {
  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.props.isModal}
        contentLabel="base modal"
        onRequestClose={this.props.onClose}
        style={styles.modal}
      >
        <DivButton
          style={styles.modalCloseImgBtn}
          onClick={this.props.onClose}
        >
          <img src={Close} alt="" />
        </DivButton>
        <p style={styles.modalTitle}>{this.props.bodyText}</p>
        <div style={styles.btnBox}>
          <DivButton
            style={styles.modalLeftBtn}
            onClick={this.props.leftBtn}
          >
            <div style={styles.modalBtnContentsBox}>
              <p style={styles.modalBtnText}>{this.props.leftBtnText}</p>
            </div>
          </DivButton>
          <DivButton
            style={styles.modalRightBtn}
            onClick={this.props.rightBtn}
          >
            <div style={styles.modalBtnContentsBox}>
              <p style={styles.modalRightBtnText}>{this.props.rightBtnText}</p>
            </div>
          </DivButton>
        </div>

      </Modal>
    );
  }
}

BaseModal.propTypes = {
  onClose: PropTypes.func,
  bodyText: PropTypes.string,
  btnText: PropTypes.string,
  isModal: PropTypes.bool,
  onNext: PropTypes.func,
};

BaseModal.defaultProps = {
  onClose: () => {},
  bodyText: '',
  btnText: 'CLOSE',
  isModal: false,
  onNext: () => {},
};


const styles = {
  modal: {
    content: {
      width: 480,
      height: 300,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      padding: '0px 20px 0px 20px',
      boxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
    },
    overlay: {
      zIndex: 3,
    },
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#251f1b',
    textAlign: 'center',
    marginTop: 60,
    whiteSpace: 'pre-line',
  },

  btnBox: {
    // marginTop: '50px',
    display: 'flex',
  },

  modalLeftBtn: {
    width: 204,
    height: 60,
    backgroundColor: '#251f1b',
    margin: 'auto',
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalRightBtn: {
    width: 204,
    height: 60,
    borderColor : '#251f1b',
    borderStyle : 'solid',
    borderWidth: '1px',
    // backgroundColor: '#251f1b',
    margin: 'auto',
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBtnArrow: {
    marginLeft: 16,
  },
  modalBtnText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
  },
  modalRightBtnText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#251f1b',
    textAlign: 'center',
    fontSize: 18,
  },
  modalBtnContentsBox: {
    height: 19,
    width: 'auto',
    display: 'flex',
  },
  modalCloseImgBtn: {
    position: 'absolute',
    top: 16,
    right: 16,
  },



};

export default BaseModal;


/*
.custom-glove-popup {
	position:absolute; z-index:9001;
	width:460px; top:300px; background: white;

	left:50%; margin-left: -230px; text-align: center;

	.base-alert-title {
		margin-top:48px;
		color: #aaa;
		font-size : 12px;
		text-align: center;
	}

	.base-alert-content {
		margin:0 30px;
		margin-top:40px; margin-bottom:200px;
		color: #111;
		font-size : 20px;
		font-weight: bold;
		text-align: center;
	}


	.close {
		position:absolute; left:50%; top:-49px; margin-left: -17px;
		width:34px; height:34px; border:0;
		background: url('/assets/images/popup-close.png');
		outline:none;
	}

	.ok {
		display : block;
		left : 30px; right:30px; bottom: 40px;
		width:auto;
		position:absolute;
		font-size : 20px;
		color : #aaa;
		cursor:pointer;
		padding:18px 0;
		border:1px solid #ccc
	}

	.popup-button {
		display : block;
		bottom: 40px;
		position:absolute;
		font-size : 20px;
		color : #aaa;
		cursor:pointer;
		padding:18px 0;
		border:1px solid #ccc
	}

	.popup-button.ok {
		left : 30px; right:30px; width:auto;
	}

	.popup-button.yes {
		left : 30px; width:186px;
	}

	.popup-button.cancel {
		right:30px; width:186px;
	}

	.popup-button:hover {background: #eee;}
}
*/