import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { getItem, removeItem } from '../../common/StorageUtils';
import { getCookie } from '../../common/Utils';
import _Fetch from '../../common/Fetches';
import { DeleteData } from '../../common/S3';

import DivButton from '../../components/button/DivButton';

const email = '/svg/email.svg';
const facebook = '/svg/facebook.svg';
const instagram = '/svg/instagram.svg';
// const pinterest = '/svg/pinterest.svg';
const twitter = '/svg/twitter.svg';

function checkeLng(i) {
  switch (i) {
    case 2:
      window.sessionStorage.setItem('lang', 'ko');
      return 'ko';
    case 3:
      window.sessionStorage.setItem('lang', 'ja');
      return 'ja';
    default:
      window.sessionStorage.setItem('lang', 'en');
      return 'en';
  }
}

class Footer extends Component {
  judgmentCardData = async (chooseLang) => {
    if (chooseLang === window.sessionStorage.getItem('lang')) return null;
    const userData =
      getCookie('userData') === '' ? null : JSON.parse(getCookie('userData'));
    if (userData) {
      const api = getItem('restAPI').model_desc;
      const res = await _Fetch(
        api.cart_get_my,
        `?pid_user=${userData.user.pid_user}`,
        [],
      );
      console.log('결과값', res);
      if (res.length > 0) {
        const results = res.map(async (item) => {
          const itemBody = JSON.parse(item.body);
          if (item.b_custom === 1) {
            console.log(itemBody);
            const promises = itemBody.images.map(async (req) => {
              try {
                return await DeleteData(req.Key);
              } catch (e) {
                return console.log(e);
              }
            });

            await Promise.all(promises);

            if (itemBody.glove.wristPersonalizationLogo) {
              await DeleteData(itemBody.glove.wristPersonalizationLogo.image.s3.Key);
            }
          }
          const body = {
            cart: {
              pid_cart: item.pid_cart,
              pid_user: userData.user.pid_user,
            },
          };
          const promise = await _Fetch(api.cart_delete, '', body);
          return promise;
        });

        const done = await Promise.all(results);
        return done;
      }
    }
    return removeItem('cart');
  };

  render() {
    const { history, i18n, t } = this.props;
    // console.log(i18n);
    return (
      <div
        style={styles.container(history.location.pathname === '/')}
        role="banner"
      >
        <div style={styles.underline} />

        <div style={styles.innerBox}>
          <div style={styles.left}>
            <div style={styles.leftTextBox}>
              <DivButton
                style={styles.home(history.location.pathname.split('/')[1] === 'faq')}
                onClick={() => history.push('/faq')}
              >
                {t('faqTitle')}
              </DivButton>
              <DivButton
                style={styles.policy(history.location.pathname.split('/')[1] === 'shoppingPolicy')}
                onClick={() => history.push('/shoppingPolicy')}
              >
                {t('ShoppingPolicyTitle')}
              </DivButton>
              <DivButton
                style={styles.policy(history.location.pathname.split('/')[1] === 'termsOfService')}
                onClick={() => history.push('/termsOfService')}
              >
                {t('TermsTitle')}
              </DivButton>
              <DivButton
                style={styles.policy(history.location.pathname.split('/')[1] === 'privacyPolicy')}
                onClick={() => history.push('/privacyPolicy')}
              >
                {t('privateTitle')}
              </DivButton>
              <DivButton
                style={styles.policy(history.location.pathname.split('/')[1] === 'contact')}
                onClick={() => history.push('/contact')}
              >
                {t('footerContactTitle')}
              </DivButton>
            </div>
            <p style={styles.copyright}>
              {t('footerText')}
            </p>
          </div>

          <div style={styles.right}>
            <div style={styles.rightTextBox}>
              <DivButton
                onClick={async () => {
                  await this.judgmentCardData('ko');
                  i18n.changeLanguage(checkeLng(2));
                }}
                style={styles.korea}
              >
                한국어
              </DivButton>
              <DivButton
                onClick={async () => {
                  await this.judgmentCardData('ja');
                  i18n.changeLanguage(checkeLng(3));
                }}
                style={styles.japan}
              >
                日本語
              </DivButton>
              <DivButton
                onClick={async () => {
                  await this.judgmentCardData('en');
                  i18n.changeLanguage(checkeLng(1));
                }}
                style={styles.english}
              >
                ENGLISH
              </DivButton>
              {/* <p style={styles.japan}>日本語</p>
              <p style={styles.english}>ENGLISH</p> */}
            </div>

            <div style={styles.shareBox}>
              <DivButton
                onClick={() =>
                  window.open('https://www.facebook.com/gloveworks')
                }
              >
                <img style={styles.shareImg} src={facebook} alt="" />
              </DivButton>
              <DivButton
                onClick={() =>
                  window.open('http://instagram.com/gloveworks_official')
                }
              >
                <img style={styles.shareImg} src={instagram} alt="" />
              </DivButton>
              <DivButton
                onClick={() =>
                  window.open('https://twitter.com/thegloveworks')
                }
              >
                <img style={styles.shareImg} src={twitter} alt="" />
              </DivButton>
              <div
                onClick={() =>
                  (document.location.href = 'mailto:contact@gloveworks.net')
                }
                role="button"
                tabIndex="-1"
              >
                <img style={styles.shareImg} src={email} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.object,
};

Footer.defaultProps = {
  history: {},
  i18n: {},
};

const styles = {
  container: isWhite => ({
    width: '100%',
    minWidth: 996,
    backgroundColor: isWhite ? '#fff' : 'transparent',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 3,
  }),
  leftTextBox: {
    // width: 314 + 40,
    width: 544 + 37 + 83,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  rightTextBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 44,
  },
  underline: {
    width: 996,
    height: 2,
    backgroundColor: '#edede9',
    position: 'relative,',
  },
  innerBox: {
    width: 996,
    display: 'flex',
    paddingRight:240,
    justifyContent: 'space-between',
    boxSizing:'border-box',
    position:'relative',
  },
  left: {
    height: '100%',
  },
  right: {
    position:'absolute',
    right:0,
    top:0,
    width: 170 + 60,
    height: '100%',
    // backgroundColor: 'red'
  },
  home: isFocus => ({
    fontFamily: 'Roboto Condensed',
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 2,
    cursor: 'pointer',
    fontWeight: isFocus ? 'bold' : 'normal',
    whiteSpace: 'nowrap',
  }),
  policy: isFocus => ({
    fontFamily: 'Roboto Condensed',
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 2,
    marginLeft: 30,
    cursor: 'pointer',
    fontWeight: isFocus ? 'bold' : 'normal',
    whiteSpace: 'nowrap',
  }),
  contact: {
    fontFamily: 'Roboto Condensed',
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 2,
    marginLeft: 30,
    cursor: 'pointer',
  },
  copyright: {
    fontFamily: 'Roboto Condensed',
    color: '#251f1b',
    fontSize: 14,
    // opacity: 0.34,
    whiteSpace: 'pre-line',
    lineHeight:'20px',
    marginTop: 29,
    marginBottom: 29,
  },
  korea: {
    fontFamily: 'Roboto Condensed',
    fontSize: 13,
    letterSpacing: 1.4,
    color: '#251f1b',
    marginLeft: 7.5,
    cursor: 'pointer',
  },
  japan: {
    fontFamily: 'Roboto Condensed',
    fontSize: 13,
    letterSpacing: 1.4,
    color: '#251f1b',
    cursor: 'pointer',
  },
  english: {
    width: 75,
    textAlign: 'left',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    letterSpacing: 1.4,
    color: '#251f1b',
    marginRight: 7.5,
    cursor: 'pointer',
  },
  shareBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
  shareImg: {
    cursor: 'pointer',
  },
  subscribeText: {
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 2.3,
    color: '#251f1b',
    marginTop: 26,
  },
  inputBox: {
    marginTop: 20,
    width: 314 + 40,
    height: 40,
    display: 'flex',
  },
  inputStyles: {
    padding: '12px 16px 12px 16px',
    color: '#000',
    fontSize: 13,
    letterSpacing: 0.1,
    fontWeight: '600',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    border: 'solid 1px #ddd',
    width: 314,
    height: 40,
    outline: 'none',
  },
  subscripbeBtn: {
    width: 40,
    height: 40,
    backgroundColor: '#edd167',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

Footer.propTypes = {
  t: PropTypes.func,
};

Footer.defaultProps = {
  t: () => {},
};

export default withRouter(withTranslation('translations')(Footer));
