import React from 'react';
import { connect } from 'react-redux';
import { findIndex } from 'ramda';
import { changePreviewSide, setPreviewLoading, completePreviewImages } from '../actions/creators';
import PreviewLoadingIndicator from './PreviewLoadingIndicator';
import Canvas from './Canvas';
import { withTranslation } from 'react-i18next';

class Preview extends React.Component {
  constructor(props) {
    super(props);

    this.webSideCanvas = {};
    this.fingerSideCanvas = {};
    this.palmSideCanvas = {};
  }

  componentWillReceiveProps(nextProps) {
    // console.log('will');

    const { dispatch } = this.props;

    if (nextProps.requestPending) {
      Promise.all([
        this.webSideCanvas.getImage(),
        this.fingerSideCanvas.getImage(),
        this.palmSideCanvas.getImage(),
      ]).then((dataUrls) => {
        dispatch(completePreviewImages(dataUrls));
      });
    }
  }

  render() {
    const {
      glove, currentSide, leftSide, rightSide, imageSources, dispatch,t
    } = this.props;

    return (
      <div id="custom-glove-preview">
        <div className="custom-glove-caution" style={{ display: 'block' }}>
          {t('customizeNotice')}
        </div>
        <button
          className="custom-glove-preview-arrow to-left-side"
          style={{ display: 'block' }}
          onClick={() => dispatch(changePreviewSide(leftSide))}
        />
        <button
          className="custom-glove-preview-arrow to-right-side"
          style={{ display: 'block' }}
          onClick={() => dispatch(changePreviewSide(rightSide))}
        />
        <PreviewLoadingIndicator />
        <div className="watermark" />
        <div>
          <Canvas
            side="palmSide"
            ref={ref => this.palmSideCanvas = ref}
            active={currentSide === 'palmSide'}
            glove={glove}
            imageSources={imageSources}
            onDrawStart={() => dispatch(setPreviewLoading(true))}
            onDrawEnd={() => dispatch(setPreviewLoading(false))}
          />
          <Canvas
            side="webSide"
            ref={ref => this.webSideCanvas = ref}
            active={currentSide === 'webSide'}
            glove={glove}
            imageSources={imageSources}
            onDrawStart={() => dispatch(setPreviewLoading(true))}
            onDrawEnd={() => dispatch(setPreviewLoading(false))}
          />
          <Canvas
            side="fingerSide"
            ref={ref => this.fingerSideCanvas = ref}
            active={currentSide === 'fingerSide'}
            glove={glove}
            imageSources={imageSources}
            onDrawStart={() => dispatch(setPreviewLoading(true))}
            onDrawEnd={() => dispatch(setPreviewLoading(false))}
          />
        </div>
      </div>
    );
  }
}

const sides = ['palmSide', 'webSide', 'fingerSide'];

const mapStateToProps = (state) => {
  const { side, requestPending } = state.preview;
  const index = findIndex(s => s === side)(sides);

  return {
    glove: state.glove,
    currentSide: side,
    leftSide: index > 0 ? sides[index - 1] : sides[2],
    rightSide: index < 2 ? sides[index + 1] : sides[0],
    imageSources: state.builder.gloveImageSources,
    requestPending,
  };
};

export default connect(mapStateToProps)(withTranslation()(Preview));
