import 'babel-polyfill';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import camelize from 'camelize';

import Application from './components/Application';
import configureStore from './store/configureStore';

import initState from './builder.json';
import gloveInitData from './glove.json';

import { getItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';

import leatherGrades from './common/leatherGrades';
import personalizePrice from './common/personalizePrice';

class Builder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log('will receive');
    this.setState({data: null}, () => this.initialize());

    // window.location.reload()
  }

  componentDidMount() {
    this.initialize();
  }

  async roofFetch(data, api) {
    try {
      const promises = data.map(async (item) => {
        const res = await _Fetch(api, `?seq=0&interval=100&ctg=${item}`, []);
        return res;
      });
      const promiseArr = await Promise.all(promises);
      return promiseArr;
    } catch (e) {
      console.log(e);
    }
  }

  async initialize() {
    const colorArr = [1, 2, 3, 4];
    const colorApi = getItem('restAPI').model_desc.custom_color_get_seqctg;
    const colorData = await this.roofFetch(colorArr, colorApi);
    // console.log(colorData); // select * from custom_color WHERE ctg_part=? ORDER BY pid_cust_color DESC LIMIT ?, ?

    const imageArr = [1, 2, 3];
    const imageApi = getItem('restAPI').model_desc.custom_image_get_seqctg;
    const imageData = await this.roofFetch(imageArr, imageApi);
    // console.log(imageData);

    const redefineData = (data) => data.map(item => ({
      id: item.pid_cust_color,
      name: item.name,
      url: item.img_uri,
      hex_code: item.hexcode,
      color_code: item.code,
      activated: true,
    }));

    const laceColors = redefineData(colorData[1]);
    const threadColors = redefineData(colorData[2]);
    const embroideryColors = redefineData(colorData[3]);

    const flags = imageData[0].map(item => ({
      id: item.pid_cust_img,
      name: item.name,
      icon: {
        url: item.img_uri,
        flag_preview: {
          url: item.img_uri,
        },
        flag: {
          url: item.img_uri,
        },
      },
      activated: true,
    }));
    const customImages = imageData[1].map(item => ({
      id: item.pid_cust_img,
      name: item.name,
      image: {
        url: item.img_uri,
        custom_image_preview: {
          url: item.img_uri,
        },
        custom_image: {
          url: item.img_uri,
        },
      },
      activated: true,
    }));
    const customPythons = imageData[2].map(item => ({
      id: item.pid_cust_img,
      name: item.name,
      image: {
        url: item.img_uri,
        custom_image_preview: {
          url: item.img_uri,
        },
        custom_image: {
          url: item.img_uri,
        },
      },
      activated: true,
    }));
    // console.log(customPythons);

    const glColors = colorData[0].filter(o => o.b_gameday === 1);
    const gamedayLeatherColors = glColors.map(item => ({
      id: item.pid_cust_color,
      name: item.name,
      url: item.img_uri,
      hex_code: item.hexcode,
      color_code: item.code,
    }));

    const slColors = colorData[0].filter(o => o.b_steerhide === 1);
    const proSteerhidLeatherColors = slColors.map(item => ({
      id: item.pid_cust_color,
      name: item.name,
      url: item.img_uri,
      hex_code: item.hexcode,
      color_code: item.code,
    }));

    const plColors = colorData[0].filter(o => o.b_prokip === 1);
    const proKipLeatherColors = plColors.map(item => ({
      id: item.pid_cust_color,
      name: item.name,
      url: item.img_uri,
      hex_code: item.hexcode,
      color_code: item.code,
    }));

    initState.builder.leatherColors[1] = gamedayLeatherColors;
    initState.builder.leatherColors[2] = proSteerhidLeatherColors;
    initState.builder.leatherColors[3] = proKipLeatherColors;
    initState.builder.laceColors = laceColors;
    initState.builder.threadColors = threadColors;
    initState.builder.embroideryColors = embroideryColors;

    initState.builder.flags = flags;
    initState.builder.customImages = customImages;
    initState.builder.customPythons = customPythons;

    initState.glove = gloveInitData.glove;

    // console.log(this.props.i18n.language);

    if (this.props.i18n.language === 'en') {
      initState.builder.leatherGrades = leatherGrades[0];
      initState.builder.wristPersonalizationTypes = personalizePrice.wristPersonalizationTypes[0];
      initState.builder.thumbPersonalizationTypes = personalizePrice.thumbPersonalizationTypes[0];
      initState.builder.liningPersonalizationTypes = personalizePrice.liningPersonalizationTypes[0];
    }

    if (this.props.i18n.language === 'ja') {
      initState.builder.leatherGrades = leatherGrades[1];
      initState.builder.wristPersonalizationTypes = personalizePrice.wristPersonalizationTypes[1];
      initState.builder.thumbPersonalizationTypes = personalizePrice.thumbPersonalizationTypes[1];
      initState.builder.liningPersonalizationTypes = personalizePrice.liningPersonalizationTypes[1];
    }

    if (this.props.i18n.language === 'ko') {
      initState.builder.leatherGrades = leatherGrades[2];
      initState.builder.wristPersonalizationTypes = personalizePrice.wristPersonalizationTypes[2];
      initState.builder.thumbPersonalizationTypes = personalizePrice.thumbPersonalizationTypes[2];
      initState.builder.liningPersonalizationTypes = personalizePrice.liningPersonalizationTypes[2];
    }

    this.setState({
      data: initState,
    });
  }

  render() {
    if (!this.state.data) return <div style={{ height: 800 }} />;

    const initialState = camelize(this.state.data) || {};

    const store = configureStore(initialState);

    // console.log(store, initialState);

    return (
      <Provider store={store}>
        <Application />
      </Provider>
    );
  }
}


export default withTranslation('translations')(Builder);
