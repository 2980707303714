import SERVER_URL from "./Settings";
import _Fetch from "./Fetches";
import { setItem } from "./StorageUtils";

async function getRestAPI() {
  try {
    const modelDesc = await _Fetch({
      method: "GET",
      url: `${SERVER_URL}/gloveworks/restapi/model_desc`,
    });

    const restAPI = {
      model_desc: {},
      auth_desc: {},
    };

    modelDesc.map((item) => {
      restAPI.model_desc[item.api_name] = {};
      restAPI.model_desc[item.api_name].url = SERVER_URL + item.api;
      restAPI.model_desc[item.api_name].method = item.method;
      return true;
    });

    const authDesc = await _Fetch({
      method: "GET",
      url: `${SERVER_URL}/gloveworks/restapi/auth_desc`,
    });

    authDesc.map((item) => {
      restAPI.auth_desc[item.api_name] = {};
      restAPI.auth_desc[item.api_name].url = SERVER_URL + item.api;
      restAPI.auth_desc[item.api_name].method = item.method;
      return true;
    });

    setItem("restAPI", restAPI);
  } catch (error) {
    console.log(error);
  }
}

export default getRestAPI;
