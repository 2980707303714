import React from 'react';
import ReactDOM from 'react-dom';

import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import i18n from './lib/i18n/i18n';
import Store from './store/Store';
import registerServiceWorker from './registerServiceWorker';

import './index.css';
import './common/GlobalStyles';
import ReactGA from 'react-ga';


if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}
ReactGA.initialize("UA-56478997-1");
/**
 * @param {Object} store redux store
 * @param {Object} i18n i18n store
 */
ReactDOM.render(
  <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
  , document.getElementById('root'),
);

// registerServiceWorker();
