// 쿠키 생성함수
export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      setCookie(cname, c.substring(name.length, c.length));
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return null;
}

// 쿠키 저장함수
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  const encodedValue = encodeURIComponent(cvalue);
  d.setTime(d.getTime() + (exdays * 60 * 60 * 1000));

  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${encodedValue};${expires};path=/`;
}

export function checkedCurrencySymbol(languageCode) {
  switch (languageCode) {
    case 'ko':
      return '₩';
    case 'ja':
      return '¥';
    default:
      return '$';
  }
}

export function settingCostWithCompare(lanCode, data) {
  switch (lanCode) {
    case 'ko':
      return {
        compare: data.compare_won,
        cost: data.cost_won,
      };
    case 'ja':
      return {
        compare: data.compare_yen,
        cost: data.cost_yen,
      };
    default:
      return {
        compare: data.compare_dollar,
        cost: data.cost_dollar,
      };
  }
}

export function settingCost(lanCode, data) {
  switch (lanCode) {
    case 'ko':
      return data.price_won;
    case 'ja':
      return data.price_yen;
    default:
      return data.price_dol;
  }
}

export const youtubeConvertId = (url) => {
  let link = url.trim();
  link = link.split('/');

  let id = '';

  if (/watch\?v=/.test(link[link.length - 1])) {
    let arr = [];
    arr = link[link.length - 1].split('watch?v=');
    id = arr[arr.length - 1];
    return id;
  }
  return link[link.length - 1];
};

export function convertDate(str) {
  const arr = str.split('T');
  const protoDate = arr[0].split('-');
  const time = arr[1].split('.000Z')[0];

  const convertMonthStr = (m) => {
    switch (m) {
      case '01':
        return 'JAN';
      case '02':
        return 'FEB';
      case '03':
        return 'MAR';
      case '04':
        return 'APR';
      case '05':
        return 'MAY';
      case '06':
        return 'JUN';
      case '07':
        return 'JUL';
      case '08':
        return 'AUG';
      case '09':
        return 'SEP';
      case '10':
        return 'OCT';
      case '11':
        return 'NOV';
      default:
        return 'DEC';
    }
  };

  const month = convertMonthStr(protoDate[1]);
  const day = protoDate[2];
  const year = protoDate[0];

  const date = `${month} ${day}, ${year}   ${time}`;
  return date;
}

export const returnComma = (num) => {
  // console.log('comma', num);
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function getDiscountCouponCost(data, language) {
  switch (language) {
    case 'ko':
      return data.dis_won;
    case 'ja':
      return data.dis_yen;
    default:
      return data.dis_dollar;
  }
}

export function getPerItemCouponCost(itemData, couponData, languageCode) {
  const body = JSON.parse(itemData.body);

  console.log('typ_coupon', couponData.typ_coupon);

  // percent
  if (couponData.typ_coupon === 1) {
    if (couponData.b_stock && body.pid_category > 0) {
      return settingCost(languageCode, itemData) * (couponData.dis_percent / 100);
    }
    if (body.pid_category < 0) {
      if (body.glove.leatherGrade.id === 1 && couponData.b_gameday) {
        return settingCost(languageCode, itemData) * (couponData.dis_percent / 100);
      }
      if (body.glove.leatherGrade.id === 2 && couponData.b_steerhide) {
        return settingCost(languageCode, itemData) * (couponData.dis_percent / 100);
      }
      if (body.glove.leatherGrade.id === 3 && couponData.b_prokip) {
        return settingCost(languageCode, itemData) * (couponData.dis_percent / 100);
      }
    }
  }

  // input cost
  if (couponData.typ_coupon === 2) {
    if (couponData.b_stock && body.pid_category > 0) {
      return getDiscountCouponCost(couponData, languageCode);
    }
    if (body.pid_category < 0) {
      if (body.glove.leatherGrade.id === 1 && couponData.b_gameday) {
        return getDiscountCouponCost(couponData, languageCode);
      }
      if (body.glove.leatherGrade.id === 2 && couponData.b_steerhide) {
        return getDiscountCouponCost(couponData, languageCode);
      }
      if (body.glove.leatherGrade.id === 3 && couponData.b_prokip) {
        return getDiscountCouponCost(couponData, languageCode);
      }
    }
  }

  return 0;
}

export function getCostSymbol(code) {
  switch (code) {
    case 3:
      return '₩';
    case 2:
      return '¥';
    default:
      return '$';
  }
}
