import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Step from '../../components/step/Step';
import EnShoppingPolicy from './EnShoppingPolicy';
import KoShoppingPolicy from './KoShoppingPolicy';
import JaShoppingPolicy from './JaShoppingPolicy';

const STEP_MENU = ['Shipping policy'];

class ShoppingPolicy extends Component {
  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  render() {
    const { i18n } = this.props;
    return (
      <div>
        <Step menu={STEP_MENU} />
        {i18n.language === 'ko' && <KoShoppingPolicy />}
        {i18n.language === 'en' && <EnShoppingPolicy />}
        {i18n.language === 'ja' && <JaShoppingPolicy />}
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: 'auto',
    marginBottom: 64,
  },
  title: {
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: '#142935',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 50,
  },
  subTitle: marginTop => ({
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: '#000',
    marginTop,
  }),
  description: marginTop => ({
    fontSize: 14,
    marginTop,
    lineHeight: 1.57,
    color: '#142935',
  }),
  descTitle: {
    fontWeight: 'bold',
  },
  email: {
    color: '#007dff',
    cursor: 'pointer',
    outline: 'none',
  },
};

export default withTranslation('translations')(ShoppingPolicy);
