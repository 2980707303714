import React, { Component } from 'react';
import shortid from 'shortid';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import _Fetch from '../../common/Fetches';
import { getItem } from '../../common/StorageUtils';
import { getCostSymbol, setCookie, getCookie } from '../../common/Utils';

import Input from '../../components/input/Input';
import DivButton from '../../components/button/DivButton';

import Modal from '../../lib/modal';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

const OrderCustomStatusImage = [
  '/svg/order_received.svg',
  '/img/20-2-x.png',
  '/img/21-2-x.png',
  '/img/22-2-xx.png',
  '/img/23-2-x.png',
];
const OrderCustomStatusWhiteImage = [
  '/img/28-2-x.png',
  '/img/20-2-w.png',
  '/img/21-2-xx.png',
  '/img/22-2-x.png',
  '/img/29-2-x.png',
];

const OrderStockStatusImage = [
  '/svg/order_received.svg',
  '/img/21-2-x.png',
  '/img/22-2-xx.png',
  '/img/23-2-x.png',
];
const OrderStockStatusWhiteImage = [
  '/img/28-2-x.png',
  '/img/21-2-xx.png',
  '/img/22-2-x.png',
  '/img/29-2-x.png',
];

const close = '/svg/ot_close.svg';
const line = '/svg/line.svg';
const refundMail = '/svg/refund_mail.svg';

function renderOpt(otp) {
  if (otp.length === 0) return null;

  const arr = otp.map((item, i) => (
    <div key={shortid.generate()} style={styles.optionBox}>
      <p style={styles.keyTitle}>{item.title}</p>
      <p style={styles.optValue}>{item.context}</p>
    </div>
  ));

  return <div style={styles.otpContainer}>{arr}</div>;
}

function getOrderStatusText(od) {
  // custom
  if (od.ctg_pd === 1) {
    if (od.stt_order === 5 || od.stt_order === 10) {
      return <p style={styles.oredrViewText}>(1-3 BUSINESS DAYS)</p>;
    }

    if (od.stt_order === 20) {
      return (
        <p style={{ ...styles.oredrViewText, marginLeft: 74 + 140 }}>
          (10-15 BUSINESS DAYS)
        </p>
      );
    }
    if (od.stt_order === 30) {
      return (
        <p style={{ ...styles.oredrViewText, marginLeft: 74 * 2 + 140 * 2 }}>
          (14-21 BUSINESS DAYS)
        </p>
      );
    }
    if (od.stt_order === 40) {
      return (
        <p style={{ ...styles.oredrViewText, marginLeft: 74 * 3 + 140 * 3 }}>
          (1-3 BUSINESS DAYS)
        </p>
      );
    }
  }

  if (od.stt_order === 5 || od.stt_order === 10) {
    return <p style={styles.oredrViewText}>(1-3 BUSINESS DAYS)</p>;
  }
  if (od.stt_order === 30) {
    return (
      <p style={{ ...styles.oredrViewText, marginLeft: 140 * 2 }}>
        (1-3 BUSINESS DAYS)
      </p>
    );
  }
  if (od.stt_order === 40) {
    return (
      <p style={{ ...styles.oredrViewText, marginLeft: 572.5 }}>
        (2-7 BUSINESS DAYS)
      </p>
    );
  }

  return null;
}

function circleBackgroundColor(i, od) {
  if (od.ctg_pd === 1) {
    if (i === 0 && od.stt_order > 9) return true;
    if (i === 1 && od.stt_order > 19) return true;
    if (i === 2 && od.stt_order > 29) return true;
    if (i === 3 && od.stt_order > 39) return true;
    if (i === 4 && od.stt_order > 49) return true;
  }

  if (i === 0 && od.stt_order > 9) return true;
  if (i === 1 && od.stt_order > 29) return true;
  if (i === 2 && od.stt_order > 39) return true;
  if (i === 3 && od.stt_order > 49) return true;

  return false;
}

class OrderTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      // email: '',
      email: 'react@r.com',
      // orderNumber: '',
      orderNumber: '20772',
      // orderNumber: 'yinuo31ljq5ngl3z5c',
      isViewImgModal: false,
    };

    this.modalImg = null;

    this.userData =
      getCookie('userData') === '' ? null : JSON.parse(getCookie('userData'));
  }

  componentDidMount() {
    document.body.style.overflow = 'auto';
    window.document.body.addEventListener('keyup', this.onkeyup);
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('keyup', this.onkeyup);
  }

  onkeyup = (e) => {
    if (e.keyCode === 13) return this.getOdData();

    return null;
  };

  async getOdData() {
    try {
      const api = getItem('restAPI').model_desc.od_search_withid;
      const query = `?id=${this.state.email}&str=${this.state.orderNumber}`;
      console.log(query);
      const res = await _Fetch(api, query, []);
      console.log(res);

      const odDetailApi = getItem('restAPI').model_desc.od_get_detail;
      const odDetailRes = await _Fetch(
        odDetailApi,
        `?pid=${res[0].pid_od}`,
        [],
      );
      console.log(odDetailRes);

      if (odDetailRes.od.stt_payment > 99) return alert('Already sent refund');

      return this.setState({
        data: odDetailRes,
      });
    } catch (e) {
      return console.log(e);
    }
  }

  async postRefund() {
    try {
      if (!this.userData) {
        return this.props.history.push('/login');
      }

      const api = getItem('restAPI').model_desc.shop_user_refund_od;
      const body = {
        od: {
          ...this.state.data.od,
          // stt_order: 105,
        },
      };
      console.log(body);
      const res = await _Fetch(api, '', body);
      console.log(res);
      return res;
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        alert('로그인 정보가 유효하지 않습니다. 다시 로그인 해주시길 바랍니다.');
        setCookie('userData', '', -1);
        return this.props.history.push('/login');
      }
      return e;
    }
  }

  renderOrderStockProcess() {
    const { data } = this.state;
    const menu = [
      'ORDER RECEIVED/ REVIEWED',
      'QI & SHIPPING PREPARATION',
      'ON DELIVERY',
      'DELIVERED',
    ];

    return (
      <div>
        <p style={styles.orderStatusTitle}>ORDER STATUS</p>
        <div style={{ position: 'relative' }}>
          <div style={styles.orderProcess}>
            {menu.map((item, i) => (
              <div key={shortid.generate()}>
                <div style={styles.circle(circleBackgroundColor(i, data.od))}>
                  <img
                    src={
                      circleBackgroundColor(i, data.od)
                        ? OrderStockStatusWhiteImage[i]
                        : OrderStockStatusImage[i]
                    }
                    alt=""
                  />
                </div>
                <p style={styles.circleText}>{item}</p>
              </div>
            ))}
          </div>
          <img src={line} alt="" style={styles.line} />
        </div>
        <div>{getOrderStatusText(data.od)}</div>
        {this.renderOrderStatusDecs()}
      </div>
    );
  }

  renderOrderCustomProcess() {
    const { data } = this.state;
    console.log(data.od);
    const menu = [
      'ORDER RECEIVED/ REVIEWED',
      'LEATHER SELECTION & CUTTING',
      'EMBROIDERY & SEWING',
      'QI & SHIPPING PREPARATION',
      'SHIPPED',
    ];

    return (
      <div>
        <p style={styles.orderStatusTitle}>ORDER STATUS</p>
        <div style={{ position: 'relative' }}>
          <div style={styles.orderProcess}>
            {menu.map((item, i) => (
              <div key={shortid.generate()}>
                <div style={styles.circle(circleBackgroundColor(i, data.od))}>
                  <img
                    src={
                      circleBackgroundColor(i, data.od)
                        ? OrderCustomStatusWhiteImage[i]
                        : OrderCustomStatusImage[i]
                    }
                    alt=""
                  />
                </div>
                <p style={styles.circleText}>{item}</p>
              </div>
            ))}
          </div>
          <img src={line} alt="" style={styles.line} />
        </div>
        <div>{getOrderStatusText(data.od)}</div>
        {this.renderOrderStatusDecs()}
      </div>
    );
  }

  renderModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.isViewImgModal}
        contentLabel="Add New Address"
        onRequestClose={() => {
          this.setState({ isViewImgModal: false });
          document.body.style.overflow = 'auto';
        }}
        style={modalStyles}
      >
        <div
          style={{
            position: 'absolute',
            width: 600,
            height: 600,
          }}
        >
          <DivButton
            style={styles.modalCloseBox}
            onClick={() => {
              this.setState({ isViewImgModal: false });
              document.body.style.overflow = 'auto';
            }}
          >
            <img src={close} alt="" />
          </DivButton>
          <img
            style={{ width: '100%', height: '100%' }}
            src={this.modalImg}
            alt=""
          />
        </div>
      </Modal>
    );
  }

  renderCustomProduct(data) {
    console.log('stt_order', data.od.stt_order);
    if (
      data.od.stt_order === 10 ||
      data.od.stt_order === 20 ||
      data.od.stt_order === 50
    ) {
      return null;
    }
    const items = data.od_pd.map((item, i) => (
      <div key={shortid.generate()} style={styles.customProductItemBox}>
        <div style={styles.customProductItemHeader}>
          <p
            style={{ ...styles.productHeaderText(460, 24), fontWeight: '600' }}
          >
            CUSTOMIZE
          </p>
          <p style={styles.productHeaderText(140, 0, 'center')}>
            {getCostSymbol(item.typ_cost) + item.cost.toFixed(2)}
          </p>
          <DivButton
            style={styles.customViewPhotoBtn(data.od.stt_order > 19)}
            onClick={() => {
              if (data.od.stt_order > 19) {
                this.modalImg = item.stage_uri;
                this.setState({ isViewImgModal: true });
                document.body.style.overflow = 'hidden';
              }
            }}
          >
            VIEW PHOTO
          </DivButton>
        </div>
        {renderOpt(JSON.parse(data.od_cust[i].body).options)}
      </div>
    ));

    return (
      <div style={styles.customProductBox}>
        <div style={styles.customProductHeader}>
          <p style={styles.productHeaderText(460, 24)}>PRODUCT</p>
          <p style={styles.productHeaderText(140, 0, 'center')}>PRICE</p>
          <p style={styles.productHeaderText(140, 0, 'center')}>SNEAK PEEK</p>
        </div>
        {items}
      </div>
    );
  }

  renderItemBox() {
    if (!this.state.data) return null;
    // 기성품일때
    if (this.state.data.od.ctg_pd === 2) {
      const items = this.state.data.od_pd.map(item => (
        <div style={styles.stockItemBox} key={shortid.generate()}>
          <p style={styles.stockItemTitle}>{item.title}</p>
          <p style={styles.stockItemCost}>
            {getCostSymbol(item.typ_cost) + item.cost.toFixed(2)}
          </p>
        </div>
      ));

      return (
        <div>
          <div style={styles.customProductBox}>
            <div
              style={{
                ...styles.customProductHeader,
                borderBottom: '1px solid #251f1b',
              }}
            >
              <p style={styles.productHeaderText(460, 24)}>PRODUCT</p>
              <p style={styles.productHeaderText(140, 0, 'center')}>PRICE</p>
              <p style={styles.productHeaderText(140, 0, 'center')} />
            </div>
            {items}
          </div>
        </div>
      );
    }

    if (this.state.data.od.ctg_pd === 1) {
      return this.renderCustomProduct(this.state.data);
    }

    return null;
  }

  renderOrderStatusDecs() {
    const { data } = this.state;
    const { t } = this.props;

    // custom
    if (data.od.ctg_pd === 1) {
      const menu = [
        t('orderTrackingStatusMenu1'),
        t('orderTrackingStatusMenu2'),
        t('orderTrackingStatusMenu3'),
        t('orderTrackingStatusMenu4'),
        t('orderTrackingStatusMenu5'),
      ];

      if (data.od.stt_order === 5 || data.od.stt_order === 10) {
        return (
          <div style={styles.statusTitleContainer}>
            <div style={styles.statusTitleBox}>
              Order Status :&nbsp;
              <p style={styles.decsSelctedTitle}>{menu[0]}</p>
            </div>
            <p style={styles.statusNotice}>
              {t('orderTrackingStatusDecs1')}
            </p>
            <div style={styles.refundBox}>
              <DivButton
                onClick={() =>
                  (document.location.href = 'mailto:donald@gloveworks.net')
                }
                style={styles.amendBtn}
              >
                <img src={refundMail} alt="" />
                &nbsp;{t('orderTrackingEditButtonText')}
              </DivButton>
            </div>
          </div>
        );
      }

      if (data.od.stt_order === 20) {
        return (
          <div style={styles.statusTitleContainer}>
            <div style={styles.statusTitleBox}>
              Order Status :&nbsp;
              <p style={styles.decsSelctedTitle}>{menu[1]}</p>
            </div>
            <p style={styles.statusNotice}>
              {t('orderTrackingStatusDecs2')}
            </p>
          </div>
        );
      }

      if (data.od.stt_order === 30) {
        return (
          <div style={styles.statusTitleContainer}>
            <div style={styles.statusTitleBox}>
              Order Status :&nbsp;
              <p style={styles.decsSelctedTitle}>{menu[2]}</p>
            </div>
            <p style={styles.statusNotice}>
              {t('orderTrackingStatusDecs3')}
            </p>
          </div>
        );
      }

      if (data.od.stt_order === 40) {
        return (
          <div style={styles.statusTitleContainer}>
            <div style={styles.statusTitleBox}>
              Order Status :&nbsp;
              <p style={styles.decsSelctedTitle}>{menu[3]}</p>
            </div>
            <div style={styles.statusNotice}>
              {t('orderTrackingStatusDecs4')}
            </div>
          </div>
        );
      }

      if (data.od.stt_order === 50) {
        return (
          <div style={styles.statusTitleContainer}>
            <div style={styles.statusTitleBox}>
              Order Status :&nbsp;
              <p style={styles.decsSelctedTitle}>{menu[4]}</p>
            </div>
            <div style={styles.statusNotice}>
              {t('orderTrackingStatusDecs5')}
              <br />
              <div style={{ display: 'flex' }}>
                Delivery via :&nbsp;
                <p style={styles.onDeliText}>{`[${data.od.via_delivery}] [${
                  data.od.uid_tracking
                }]`}</p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  tabIndex="-1"
                  role="button"
                  onClick={() =>
                    window.open('https://www.dhl.com/en/express/tracking.html')
                  }
                  style={styles.trackTheShipment}
                >
                  {t('orderTrackingViewButtonText')}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    const menu = [
      t('orderTrackingStatusMenu1'),
      t('orderTrackingStatusMenu4'),
      t('orderTrackingStatusMenu6'),
      t('orderTrackingStatusMenu7'),
    ];

    if (data.od.stt_order === 5 || data.od.stt_order === 10) {
      return (
        <div style={styles.statusTitleContainer}>
          <div style={styles.statusTitleBox}>
            Order Status :&nbsp;<p style={styles.decsSelctedTitle}>{menu[0]}</p>
          </div>
          <p style={styles.statusNotice}>
            {t('orderTrackingStatusDecs1')}
          </p>
          <div style={styles.refundBox}>
            <DivButton
              onClick={() =>
                (document.location.href = 'mailto:contact@gloveworks.net')
              }
              style={styles.amendBtn}
            >
              <img src={refundMail} alt="" />
              &nbsp;{t('orderTrackingEditButtonText')}
            </DivButton>
          </div>
        </div>
      );
    }

    if (data.od.stt_order === 30) {
      return (
        <div style={styles.statusTitleContainer}>
          <div style={styles.statusTitleBox}>
            Order Status :&nbsp;<p style={styles.decsSelctedTitle}>{menu[1]}</p>
          </div>
          <p style={styles.statusNotice}>
            {t('orderTrackingStatusDecs4')}
          </p>
        </div>
      );
    }

    if (data.od.stt_order === 40) {
      return (
        <div style={styles.statusTitleContainer}>
          <div style={styles.statusTitleBox}>
            Order Status :&nbsp;<p style={styles.decsSelctedTitle}>{menu[2]}</p>
          </div>
          <div style={styles.statusNotice}>
            {t('orderTrackingStatusDecs5')}
            {' '}
            <br />
            <div style={{ display: 'flex' }}>
              Delivery via :&nbsp;
              <p style={styles.onDeliText}>{`[${data.od.via_delivery}] [${
                data.od.uid_tracking
              }]`}</p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div
                tabIndex="-1"
                role="button"
                onClick={() =>
                  window.open('https://www.dhl.com/en/express/tracking.html')
                }
                style={styles.trackTheShipment}
              >
                {t('orderTrackingViewButtonText')}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (data.od.stt_order === 50) {
      return (
        <div style={styles.statusTitleContainer}>
          <div style={styles.statusTitleBox}>
            Order Status :&nbsp;<p style={styles.decsSelctedTitle}>{menu[3]}</p>
          </div>
          <p style={styles.statusNotice}>
            {t('orderTrackingStatusDecs6')}
          </p>
        </div>
      );
    }

    return null;
  }

  renderOrderProcess() {
    if (!this.state.data) return <div style={{ height: 570 }} />;
    // 기성품
    if (this.state.data.od.ctg_pd === 2) return this.renderOrderStockProcess();
    return this.renderOrderCustomProcess();
  }

  render() {
    const { t } = this.props;
    return (
      <div style={styles.container}>
        {this.renderModal()}
        <p style={styles.title}>{t('orderTrackingTrackYourOrder')}</p>
        <div style={styles.underline} />

        <p style={styles.notice}>{t('orderTrackingDesc')}</p>

        <div style={styles.inputBox}>
          <div>
            <p style={styles.inputTitle}>Email</p>
            <Input
              onChange={email => this.setState({ email })}
              style={styles.emailInput}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </div>

          <div style={{ marginLeft: 20 }}>
            <p style={styles.inputTitle}>Order Number</p>
            <Input
              onChange={orderNumber => this.setState({ orderNumber })}
              style={styles.emailInput}
              placeholder=""
              placeholderColor="transparent"
              type="text"
            />
          </div>

          <DivButton style={styles.myOrderBtn} onClick={() => this.getOdData()}>
            {t('orderTrackingTrackMyOrder')}
          </DivButton>
        </div>
        {this.renderOrderProcess()}
        {this.renderItemBox()}
      </div>
    );
  }
}

const modalStyles = {
  content: {
    width: 600,
    height: 600,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

const styles = {
  container: {
    width: 996,
    margin: 'auto',
    marginTop: 55,
    marginBottom: 296,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#142935',
  },
  underline: {
    width: 60,
    height: 2,
    backgroundColor: '#251f1b',
    marginTop: 10,
  },
  notice: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.3,
    color: '#251f1b',
    marginTop: 23,
  },
  inputBox: {
    display: 'flex',
    marginTop: 19,
    alignItems: 'flex-end',
  },
  inputTitle: {
    fontSize: 14,
    lineHeight: 1.29,
    color: '#3d3935',
  },
  emailInput: {
    width: 260,
    height: 40,
    outline: 'none',
    border: 'solid 1px #d9d9d9',
    backgroundColor: '#f7f7f7',
    marginTop: 6,
    padding: '9px 10px 9px 10px',
    fontSize: 16,
    lineHeight: 1.38,
    letterSpacing: 1,
    color: '#251f1b',
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
  },
  myOrderBtn: {
    width: 228,
    height: 40,
    backgroundColor: '#000',
    marginLeft: 20,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.29,
    letterSpacing: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  },
  orderStatusTitle: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#142935',
    marginTop: 50,
    lineHeight: 1.39,
  },
  orderProcess: {
    display: 'flex',
    marginTop: 25,
    justifyContent: 'space-between',
  },
  circle: isBlack => ({
    backgroundColor: isBlack ? '#251f1b' : '#f7f7f7',
    border: 'solid 1px #e6e6e6',
    width: 140,
    height: 140,
    borderRadius: 70,
    MozBoderRadius: 70,
    WebkitBorderRadius: 70,
    boxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  circleText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    width: 138,
    marginTop: 9,
    lineHeight: 1.43,
    color: '#3d3935',
    // textShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
  },
  customProductBox: {
    width: 788,
    backgroundColor: '#fff',
    marginTop: 65,
  },
  customProductHeader: {
    width: 788,
    height: 40,
    backgroundColor: '#fff',
    boxShadow: '0 1px 0 0 #251f1b',
    display: 'flex',
    alignItems: 'center',
  },
  productHeaderText: (width, marginLeft, textAlign = 'left') => ({
    width,
    marginLeft,
    fontSize: 12,
    lineHeight: 1.33,
    color: '#251f1b',
    textAlign,
  }),
  customProductItemBox: {
    width: 788,
    height: 170,
    backgroundColor: '#fff',
    boxShadow: '0 1px 0 0 #ccc',
  },
  customViewPhotoBtn: isAble => ({
    width: 140,
    height: 32,
    backgroundColor: isAble ? '#edd167' : '#ccc',
    color: isAble ? '#000' : '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13,
    fontWeight: '600',
  }),
  customProductItemHeader: {
    width: 788,
    height: 32,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    marginTop: 12,
  },
  otpContainer: {
    marginTop: 16,
    width: 200 + 200,
    marginLeft: 24,
  },
  optionBox: {
    display: 'flex',
    marginBottom: 6,
  },
  keyTitle: {
    width: 200,
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
  },
  optValue: {
    width: 200,
    textAlign: 'right',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    fontWeight: '600',
    color: '#251f1b',
    lineHeight: 1.57,
  },
  stockItemBox: {
    width: 788,
    height: 56,
    backgroundColor: '#fff',
    borderBottom: 'solid 1px #ccc',
    display: 'flex',
    alignItems: 'center',
  },
  stockItemTitle: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#251f1b',
    width: 460,
    marginLeft: 24,
  },
  stockItemCost: {
    width: 140,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 1.33,
  },
  modalImgBox: img => ({
    width: 600,
    height: 600,
    backgroundSize: '100% 100%',
    backgroundImage: `url(${img})`,
    position: 'absolute',
  }),
  modalCloseBox: {
    width: 48,
    height: 48,
    left: 528,
    top: 24,
    backgroundColor: '#fff',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #251f1b',
  },
  line: {
    position: 'absolute',
    top: 70,
    zIndex: -1,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  oredrViewText: {
    width: 140,
    textAlign: 'center',
    marginTop: 5,
    fontSize: 12,
    lineHeight: 1.67,
    color: '#007dff',
  },
  decsSelctedTitle: {
    color: '#007dff',
    lineHeight: 1.56,
    fontWeight: 'bold',
    fontSize: 16,
  },
  statusTitleBox: {
    display: 'flex',
    alignItems: 'center',
    color: '#251f1b',
    lineHeight: 1.29,
    fontWeight: '600',
    fontSize: 14,
  },
  statusTitleContainer: {
    marginTop: 61,
  },
  statusNotice: {
    marginTop: 5,
    width: 748,
    fontSize: 14,
    lineHeight: 1.29,
    color: '#251f1b',
  },
  onDeliText: {
    fontWeight: '600',
    fontSize: 14,
    color: '#251f1b',
  },
  trackTheShipment: {
    fontSize: 14,
    color: '#007dff',
    cursor: 'pointer',
  },
  refundBox: {
    marginTop: 15,
    display: 'flex',
  },
  amendBtn: {
    width: 260,
    height: 40,
    border: 'solid 1px #251f1b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginRight: 20,

    color: '#000',
    textShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
    lineHeight: 1.43,
    fontSize: 14,
  },
};

OrderTracking.propTypes = {
  history: PropTypes.object,
  t: PropTypes.func,
};

OrderTracking.defaultProps = {
  history: {},
  t: () => {},
};

export default withRouter(withTranslation('translations')(OrderTracking));
