import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { fromJS } from 'immutable';

import Step from '../../components/step/Step';
import Item from '../../components/card/MainCollectionsCard';
import Input from '../../components/input/Input';
import DivButton from '../../components/button/DivButton';

import { getItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';

// import Dump from './Dump.json';

const STEP_MENU = ['Shop'];

const magnifier = '/svg/magnifier.svg';
const morePlus = '/svg/morePlus.svg';

class Shop extends Component {
  constructor(props) {
    super(props);

    // ctgNum 0 = 서치시, 1 = 전체 상품, 그외 수는 카테고리 순
    this.state = {
      ctgNum: 1,
      isFocus: false,
      seq: 0,
      interval: 12,
      data: null,
    };
    // shop Item데이터를 immutable 데이터로 정의 한다.
    this.data = {};
    // 초기 요청 데이터
    this.reqData = [
      {
        query: `?seq=${this.state.seq}&interval=${this.state.interval}`,
        body: [],
        api: 'pd_get_seq_active',
        model: 'model_desc',
      },
      {
        query: '',
        body: [],
        api: 'category_get',
        model: 'model_desc',
      },
      {
        query: '',
        body: [],
        api: 'pd_count',
        model: 'model_desc',
      },
    ];

    // 페이지네이션을 위한 변수
    this.dataMaxLength = 0;

    this.searchInput = null;
  }

  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    this.initialize();
  }

  // 헤더 shop에서 Shop으로 라우팅 일시 데이터 다시 리로딩.
  componentWillReceiveProps(nextProps) {
    this.initialize();
  }

  // 초기 패치
  async initialize() {
    const promises = this.reqData.map(async (req, i) => {
      try {
        const api = getItem('restAPI')[req.model][req.api];
        const res = await _Fetch(api, req.query, req.body);
        return res;
      } catch (e) {
        return console.log(`${req.api} 레스트풀 API 데이터 가져오지 못했습니다.`);
      }
    });

    // 반복 비동기 작업마다 리턴 되는 값을 배열로 지님 타입은 배열
    const promisesArr = await Promise.all(promises);

    const results = {};
    // 오브젝트 타입으로 변환
    promisesArr.map((item, i) => results[this.reqData[i].api] = item);

    // 페이지네이션을 위한 데이터 맥스 렌스 값 할당
    try {
      // console.log(results);
      const maxLenghtArr = Object.values(results.pd_count[0]);
      const maxLenght = maxLenghtArr[0];
      this.dataMaxLength = maxLenght;
    } catch (e) {
      // alert('falied connect server');
      console.log(e);
    }
    const category = results.category_get.filter(item => item.pid_category !== -1);

    console.log(category);

    const completeData = {
      ...results,
      category_get: category,
    };

    console.log(completeData);

    this.data = fromJS(completeData);

    const sortCtg = (a, b) => {
      if (a.priority > b.priority) { return 1; }
      if (a.priority < b.priority) { return -1; }
      return 0;
    };

    // 카테고리 priority 값을 기준으로 내림 차순으로 소팅
    this.data = this.data.update('category_get', getCtgList => getCtgList.toJS().sort((a, b) => sortCtg(a, b)));
    // console.log(this.data.toJS());
    this.setState({ data: this.data.toJS() });
  }

  // 시퀀스 패치 추후 카테고리 별로 되게 수정 해야 함.
  setSeq(ctgNum) {
    this.setState({ seq: this.state.seq + 1 }, async () => {
      try {
        if (ctgNum === 1) {
          this.reqData[0].query = `?seq=${this.state.seq}&interval=${this.state.interval}`;
          const api = getItem('restAPI')[this.reqData[0].model][this.reqData[0].api];
          const res = await _Fetch(api, this.reqData[0].query, this.reqData[0].body);

          this.data = this.data.update('pd_get_seq_active', beforeList => beforeList.concat(res));

          console.log(this.data.toJS());

          return this.setState({ data: this.data.toJS() });
        }
        return this.setCtgData(this.state.data.category_get[ctgNum - 1], ctgNum - 1);
      } catch (e) {
        return console.log('failed pagenation');
      }
    });
  }

  // 카테고리 별 패치
  async setCtgData(item, index) {
    try {
      if (index === 0) {
        // allProduct
        this.setState({ ctgNum: index + 1 }, () => this.initialize());
      } else {
        const api = getItem('restAPI').model_desc.pd_get_seqctg_active;
        const res = await _Fetch(api, `?seq=${this.state.seq}&interval=${this.state.interval}&ctg=${item.pid_category}`, []);

        const ctgCountApi = getItem('restAPI').model_desc.pd_count_ctg;
        const ctgCountRes = await _Fetch(ctgCountApi, `?ctg=${item.pid_category}`, []);

        // 페이지네이션을 위한 데이터 맥스 렌스 값 할당
        const maxLenghtArr = Object.values(ctgCountRes[0]);
        const maxLenght = maxLenghtArr[0];
        this.dataMaxLength = maxLenght;
        // console.log(this.dataMaxLength, this.state.seq);

        // category 버튼을 클릭 할때 실행됨
        if (this.state.seq === 0) {
          // 카테고리에 맞는 처음 초기값으로 갱신
          this.data = this.data.set('pd_get_seq_active', res);
        }

        // more 버튼을 클릭할 때 실행됨
        if (this.state.seq > 0) {
          // console.log('seq');
          this.data = this.data.update('pd_get_seq_active', beforeList => beforeList.concat(res));
          // console.log(this.data.toJS());
        }

        this.setState({
          ctgNum: index + 1,
          data: this.data.toJS(),
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 서치 할 데이터 패치
  async setSearch(e) {
    try {
      if (e.key === 'Enter') {
        // 예제 http://programmingsummaries.tistory.com/313
        e.preventDefault();
        const api = getItem('restAPI').model_desc.pd_search;
        console.log(e.target.value, api);
        const res = await _Fetch(api, `?str=${e.target.value}`, []);
        console.log(res);
        this.data = this.data.set('pd_get_seq_active', res);
        console.log(this.data.toJS());
        this.setState({
          data: this.data.toJS(),
          ctgNum: 0,
        }, () => this.searchInput.blur());
      }
    } catch (err) {
      console.log(err);
    }
  }

  // 카테고리 로우
  renderCtgRow(item, i) {
    return (
      <div
        key={shortid.generate()}
        style={styles.collectionItem}
      >
        <hr style={styles.hrStyle} />
        <div
          role="button"
          tabIndex="0"
          onClick={() => this.setState({ seq: 0 }, () => this.setCtgData(item, i))}
          style={styles.tapText(this.state.ctgNum === (i + 1))}
        >
          {item.ctg_name.toUpperCase()}
        </div>
      </div>
    );
  }

  // 아이템 랜더 부분
  renderBody() {
    const { history } = this.props;
    const { data, ctgNum } = this.state;

    return (
      <div style={styles.bodyContets}>
        <div style={styles.tapBox}>
          {data.category_get.map((item, i) => this.renderCtgRow(item, i))}
          <hr style={styles.hrStyle} />
        </div>

        <div>
          <div style={styles.itemBox}>
            {data.pd_get_seq_active.filter((o) => {
              if (o.b_active === 0) console.log(o);
              return o.b_active;
            }).map((item, i) => (
              <div
                style={styles.rowItemsBox(i % 3 === 0 ? 0 : 24)}
                key={shortid.generate()}
              >
                <Item
                  data={item}
                  onClick={() => history.push(`/shop/${item.pid_pd}`)}
                />
              </div>
            ))}
          </div>
          {this.renderMoreWithEmptyNotice(ctgNum)}
        </div>

      </div>
    );
  }

  // 더보기 버튼 및 아이템 없을 시 문구
  renderMoreWithEmptyNotice(ctgNum) {
    // console.log(ctgNum, this.state.data.category_get[ctgNum - 1]);
    // 서치시 아이템 없을 경우
    if (ctgNum === 0 && this.state.data.pd_get_seq_active.length === 0) {
      return (
        <div>
          <p style={styles.emptySearchItemTitle}>Search Results</p>
          <p style={styles.emptySearchText}>No goods exist in the selected category.</p>
        </div>
      );
    }
    // 카테고리 아이템 없을 때
    if (this.state.data.pd_get_seq_active.length === 0 && ctgNum > 0 && this.dataMaxLength === 0) return <p style={styles.emptyCtgItemText}>No goods exist in the selected category.</p>;
    // 카테고리당 총 갯수와 데이터데 저장된 갯수가 같을 때
    if ((this.state.data.pd_get_seq_active.length === this.dataMaxLength)) return null;

    // 서치를 제외 한 카테고리 버튼 활성화 시 모어 버튼 랜더
    if (ctgNum > 0 && this.dataMaxLength > 0 && this.state.data.pd_get_seq_active.length > 0) {
      return (
        <DivButton
          style={styles.moreBtn}
          onClick={() => this.setSeq(ctgNum)}
        >
          <img style={styles.morePlusImg} src={morePlus} alt="" />
          <p style={styles.moreText}>MORE</p>
        </DivButton>
      );
    }

    return null;
  }

  renderSearch() {
    return (
      <div style={styles.searchTopBox}>
        <div style={styles.searchBox}>
          <img src={magnifier} alt="search" style={styles.searchImg} />

          <div style={styles.inputUnderlineBox(this.state.isFocus)}>
            <Input
              ref={comp => this.searchInput = comp}
              onKeyPress={e => this.setSearch(e)}
              onBlur={() => this.setState({ isFocus: false })}
              onFocus={() => this.setState({ isFocus: true })}
              onChange={searchWord => console.log(searchWord)}
              placeholder="SEARCH"
              placeholderColor="#9b9b9b"
              style={styles.searchInput}
              type="text"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.state;
    if (data === null) {
      return (
        <div style={styles.empty}>
          <Step menu={STEP_MENU} />
        </div>);
    }
    // console.log(this.state.data.category_get[this.state.ctgNum - 1].ctg_uri);
    return (
      <div>
        <Step menu={STEP_MENU} />

        <div
          style={styles.topBanner(data.category_get.length > 0 ? data.category_get[this.state.ctgNum < 1 ? 0 : this.state.ctgNum - 1].ctg_uri : null)}
        />
        {this.renderSearch()}
        {this.renderBody()}
      </div>
    );
  }
}

Shop.propTypes = {
  history: PropTypes.object,
};

Shop.defaultProps = {
  history: {},
};

const styles = {
  empty: {
    width: '100%',
    height: 1000,
  },
  topBanner: url => ({
    width: 996,
    height: 200,
    backgroundColor: '#d8d8d8',
    margin: 'auto',
    marginTop: 32,
    backgroundSize: '100% auto',
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
  tapBox: {
    width: 260,
  },
  tapText: choose => ({
    width: 260,
    height: 40,
    textDecoration: choose ? 'underline' : 'none',
    color: choose ? '#007dff' : '#000',
    cursor: 'pointer',
    fontFamily: 'Roboto Condensed',
    fontWeight: choose ? 'bold' : 'normal',
    fontSize: 14,
    lineHeight: 1.43,
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  }),
  itemBox: {
    width: (210 * 3) + (24 * 2),
  },
  searchTopBox: {
    width: 996,
    display: 'flex',
    margin: 'auto',
    marginTop: 47,
    justifyContent: 'flex-end',
  },
  searchBox: {
    display: 'flex',
    width: 24.1 + 5 + 648.9,
    height: 24,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  searchInput: {
    width: 628.9,
    height: 20,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    color: '#000',
    fontSize: 14,
    lineHeight: 1.57,
  },
  inputUnderlineBox: isFocus => ({
    width: 648.9,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: !isFocus ? 'solid 1px #9b9b9b' : 'solid 1px #000',
  }),
  searchImg: {
    width: 24.1,
    height: 24,
  },
  searchEmptyNotice: {
    textAlign: 'center',
    marginTop: 120,
    marginBottom: 1407,
    fontSize: 20,
    fontWeight: '600',
  },
  bodyContets: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 996,
    margin: 'auto',
    marginTop: 69,
    marginBottom: 253 - 24,
  },
  collectionItem: {
    width: 260,
    height: 60,
  },
  hrStyle: {
    display: 'block',
    height: 1,
    border: 0,
    borderTop: '1px solid #d8d8d8',
    padding: 0,
    margin: 0,
  },
  rowItemsBox: marginLeft => ({
    width: 210,
    height: 210,
    marginBottom: 24,
    border: 'solid 1px #eee',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    marginLeft,
    float: 'left',
  }),
  moreBtn: {
    width: 54 + 8 + 16,
    height: 22,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    clear: 'both',
  },
  morePlusImg: {
    width: 16,
    height: 16,
  },
  moreText: {
    color: '#000',
    fontSize: 18,
    fontWeight: '600',
  },
  emptyCtgItemText: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: '600',
  },
  emptySearchItemTitle: {
    fontSize: 20,
    fontWeight: '600',
  },
  emptySearchText: {
    marginTop: 20,
    fontSize: 16,
  },
};

export default withRouter(Shop);
