import React from 'react';
import classNames from 'classnames';

export default function ColorButtons({
  title,
  className,
  colors,
  currentColor,
  onClick,
}) {
  return (
    <div className={className}>
      <h4>{title}</h4>
      <div className="color-set clearfix">
        {colors.map(color =>
          (<div
            key={color.id}
            className={classNames('color-button', { active: currentColor && currentColor.id === color.id })}
            style={{ backgroundColor: color.hexCode }}
            onClick={() => onClick(color)}
          >
            <div className="name">{color.name}</div>
           </div>))}
      </div>
    </div>
  );
}
