import { pick, pickBy, test } from 'ramda';

import {
  SELECT_GLOVE_TYPE,
  CHANGE_LEATHER_GRADE,
  SELECT_THROWING_HAND,
  SELECT_SIZE,
  UPDATE_GLOVE,
} from '../constants/ActionTypes';

export default function glove(state = {}, action) {
  switch (action.type) {
    case SELECT_GLOVE_TYPE:
      return {
        ...pick(['leatherGrade', 'throwingHand', 'stamping'], state),
        gloveType: action.gloveType,
      };
    case CHANGE_LEATHER_GRADE:
      const personalizations = pickBy((v, k) => test(/Personalization/, k), state);
      return {
        ...action.defaults,
        ...pick(['gloveType', 'throwingHand', 'size'], state),
        ...personalizations,
        leatherGrade: action.leatherGrade,
        stamping: action.stamping,
      };
    case SELECT_THROWING_HAND:
      return {
        ...state,
        throwingHand: action.throwingHand,
      };
    case SELECT_SIZE:
      return {
        ...state,
        size: action.size,
      };
    case UPDATE_GLOVE:
      return {
        ...state,
        ...action.attributes,
      };
    default:
      return state;
  }
}
