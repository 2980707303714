import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const Wrist = ({
  types,
  currentType,
  onTypeClick,
  customPythons,
  currentCustomPython,
  onCustomPythonClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="WRIST COLOR"
      className="wrist-color-wrap"
      images={customPythons}
      currentCustomImage={currentCustomPython}
      onClick={onCustomPythonClick}
    />
    <div className="wrist-type-wrap">
      <h4>
          WRIST TYPE
        <br />
        <span className="help">Preview is not available.</span>
      </h4>
      {types.map(type =>
          (<div
            key={type.id}
            className={classNames('wrist-button', { active: currentType.id === type.id })}
            onClick={() => onTypeClick(type)}
          >
            <div className="wrist-type-preview">
              <img
                src={type.preview.url}
                alt=""
              />
            </div>
            {type.name}
          </div>))}
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const { wristPython, wristType } = state.glove;
  return {
    types: state.builder.wristTypes,
    currentType: wristType,
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    currentCustomPython: wristPython,
  };
};

const mapDispatchToProps = dispatch => ({
  onCustomPythonClick: python => dispatch(updateGlove({ wristPython: python })),
  onTypeClick: type => dispatch(updateGlove({ wristType: type })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wrist), 'webSide');
