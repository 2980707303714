import PreviewImage from '../lib/PreviewImage';
import ImageProcessLayer from '../lib/ImageProcessLayer';
import blendTwoImageData from '../lib/blendTwoImageData';
import blendTwoImageDataStamp from '../lib/blendTwoImageDataStamp';


export default class GloveRenderer {
  constructor(props) {
    this.side = props.side;
    this.canvas = props.canvas;
    this.imageSources = props.imageSources;
    // this.gloveType;
    this.images = {};
    this.layers = {};
  }

  loadImages = () => {
    // console.log('loadImages');
    const imageDic = this.imageSources[this.gloveType][this.side];
    let imagePromises = [];

    return new Promise((resolve, reject) => {
      imagePromises = Object.keys(imageDic).map((key) => {
        const src = imageDic[key];
        const previewImage = this.getOrCreateImage(key, src);

        return previewImage.loadImage();
      });

      Promise.all(imagePromises).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getOrCreateImage = (key, src) => {
    let image = this.images[key];

    if (image == null) { image = this.images[key] = new PreviewImage(src); }

    return image;
  }

  renderLayers = glove => new Promise((resolve, reject) => {
    let renderPromise;
    switch (this.side) {
      case 'webSide':
        renderPromise = this.renderWebSide;
        break;
      case 'fingerSide':
        renderPromise = this.renderFingerSide;
        break;
      case 'palmSide':
        renderPromise = this.renderPalmSide;
        break;
      default:
        break;
    }

    renderPromise(glove).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  })

  renderWebSide = glove => new Promise((resolve, reject) => {
    reject('need to be subclassesed');
  })

  renderFingerSide = glove => new Promise((resolve, reject) => {
    reject('need to be subclassesed');
  })

  renderPalmSide = glove => new Promise((resolve, reject) => {
    reject('need to be subclassesed');
  })

  renderLayer = (layerName, hex, shadowName) => {
    const ctx = this.canvas.getContext('2d');
    return new Promise((resolve, reject) => {
      if (!this.images[layerName]) { reject(`No image for layer ${layerName}`); }

      this.getOrCreateLayerOverwrite(layerName, shadowName, blendTwoImageData).getImageOverwrite(layerName, hex).then((resultImage) => {
        ctx.drawImage(resultImage, 0, 0);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  renderStampingLayer = (layerName, hex, shadowName) => {
    const ctx = this.canvas.getContext('2d');
    return new Promise((resolve, reject) => {
      if (!this.images[layerName]) { reject(`No Stamp image for layer ${layerName}`); }

      this.getOrCreateLayer(layerName, shadowName, blendTwoImageDataStamp).getImage(hex).then((resultImage) => {
        ctx.drawImage(resultImage, 0, 0);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getOrCreateLayer = (layerName, shadowName, blendingFunc) => {
    let layer = this.layers[layerName];

    if (!layer) {
      layer = this.layers[layerName] = new ImageProcessLayer({
        shadowImage: this.images[shadowName].image,
        image: this.images[layerName].image,
        blendingFunc,
      });
    }

    return layer;
  }

  getOrCreateLayerOverwrite = (layerName, shadowName, blendingFunc) => {
    let layer = this.layers[layerName];

    // if (!layer) {
      layer = this.layers[layerName] = new ImageProcessLayer({
        shadowImage: this.images[shadowName].image,
        image: this.images[layerName].image,
        blendingFunc,
      });
    // }

    return layer;
  }

  render = (glove) => {
    this.clearCanvas();
    return new Promise((resolve, reject) => {
      this.loadImages()
        .then(() => this.renderLayers(glove))
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  clearCanvas = () => {
    const ctx = this.canvas.getContext('2d');

    ctx.clearRect(0, 0, 538, 538);
    ctx.fillStyle = '#f7f6f5';
    ctx.fillRect(0, 0, 538, 538);
  }
}
