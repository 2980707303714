import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import camelize from 'camelize';

import { getCookie, getBase64, setCookie } from '../../../../common/Utils';
import { setItem, getItem } from '../../../../common/StorageUtils';
import _Fetch from '../../../../common/Fetches';
import { UploadImage } from '../../../../common/S3';
import { Consumer } from '../../../../Context';

import SingleWeltingSummary from '../summary/SingleWeltingSummary';
import DoubleWeltingSummary from '../summary/DoubleWeltingSummary';
import FirstBasemanMittSummary from '../summary/FirstBasemanMittSummary';
import CatchersMittSummary from '../summary/CatchersMittSummary';
import SoftballSummary from '../summary/SoftballSummary';

import {
  requestPreviewImages,
  closeAlert,
  submitOrder,
} from '../../actions/creators';

import { checkedCurrencySymbol, returnComma } from '../../../../common/Utils';
import leatherGrades from '../../common/leatherGrades';
import personalizePrice from '../../common/personalizePrice';

const SummaryComponents = {
  'Single Welting': SingleWeltingSummary,
  'Double Welting': DoubleWeltingSummary,
  'First Baseman Mitt': FirstBasemanMittSummary,
  'Catchers Mitt': CatchersMittSummary,
  Softball: SoftballSummary,
};

const loaderIconUrl = './assets/images/ajax_loader.gif';

function setCustumCtgNum(str) {
  switch (str) {
    case 'Single Welting':
      return -1;
    case 'Double Welting':
      return -2;
    case 'First Baseman Mitt':
      return -3;
    case 'Catchers Mitt':
      return -4;
    default:
      return -5;
  }
}

class ConfirmPurchaseAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    }
    this.additionalRequest = null;
  }

  componentWillMount() {
    this.props.requestPreviewImages();
  }

  // 카트에 아이템 정보를 세션 에다가 저장
  async setCartData(data) {
    const { actions } = this.props;
    const userData = JSON.parse(getCookie('userData'));
    console.log('userdata', userData);
    // console.log(data.glove.gloveType);

    // 비로그인시여도 로고 이미지가 있다면 S3에 업로드
    if (data.glove.wristPersonalizationLogo && !userData) {
      const logoImageBase64 = await getBase64(data.glove.wristPersonalizationLogo.image.file);
      const logoS3Img = await UploadImage(logoImageBase64, 'customize/logos');

      // 로고 이미지를 저장
      data.glove.wristPersonalizationLogo.image.s3 = logoS3Img;
      data.glove.wristPersonalizationLogo.image.url = logoS3Img.Location;
    }

    const gloveType = () => {
      switch (data.glove.gloveType) {
        case 'First Baseman Mitt':
          return 'FirstBase';
        case 'Catchers Mitt':
          return 'Catcher';
        default:
          return data.glove.gloveType.replace(/ /gi, '');
      }
    };

    const leatehrPrices = {
      en: parseFloat(leatherGrades[0].filter(item => item.id === data.glove.leatherGrade.id)[0].prices[gloveType()]),
      ja: parseFloat(leatherGrades[1].filter(item => item.id === data.glove.leatherGrade.id)[0].prices[gloveType()]),
      ko: parseFloat(leatherGrades[2].filter(item => item.id === data.glove.leatherGrade.id)[0].prices[gloveType()]),
    };

    const personalizePrices = {
      wristPersonalizationTypes: {
        en: parseFloat(personalizePrice.wristPersonalizationTypes[0].filter(item => item.id === data.glove.wristPersonalizationType.id)[0].price),
        ja: parseFloat(personalizePrice.wristPersonalizationTypes[1].filter(item => item.id === data.glove.wristPersonalizationType.id)[0].price),
        ko: parseFloat(personalizePrice.wristPersonalizationTypes[2].filter(item => item.id === data.glove.wristPersonalizationType.id)[0].price),
      },
      thumbPersonalizationTypes: {
        en: parseFloat(personalizePrice.thumbPersonalizationTypes[0].filter(item => item.id === data.glove.thumbPersonalizationType.id)[0].price),
        ja: parseFloat(personalizePrice.thumbPersonalizationTypes[1].filter(item => item.id === data.glove.thumbPersonalizationType.id)[0].price),
        ko: parseFloat(personalizePrice.thumbPersonalizationTypes[2].filter(item => item.id === data.glove.thumbPersonalizationType.id)[0].price),
      },
      liningPersonalizationTypes: {
        en: parseFloat(personalizePrice.liningPersonalizationTypes[0].filter(item => item.id === data.glove.liningPersonalizationType.id)[0].price),
        ja: parseFloat(personalizePrice.liningPersonalizationTypes[1].filter(item => item.id === data.glove.liningPersonalizationType.id)[0].price),
        ko: parseFloat(personalizePrice.liningPersonalizationTypes[2].filter(item => item.id === data.glove.liningPersonalizationType.id)[0].price),
      },
    };

    // console.log(personalizePrice.liningPersonalizationTypes[0].filter(item => item.id === data.glove.liningPersonalizationType.id)[0].price);

    // b_custom은 커스텀 1, 기성품 2
    // 쿠키에 담을 정보
    const obj = {
      pid_item: -1, // 대표님하고 상의
      uri_pd: data.images[0],
      body: JSON.stringify({
        additionalRequest: this.additionalRequest,
        title: 'Customize',
        options: [
          { title: 'Glove Type', context: data.glove.gloveType },
          { title: 'Leather Grade', context: data.glove.leatherGrade.name },
          { title: 'Throwing Hand', context: data.glove.throwingHand.name },
          { title: 'Size', context: data.glove.size.inches },
        ],
        glove: data.glove,
        images: data.images,
        weight: 500, // 임의로 정함 무게값
        pid_category: setCustumCtgNum(data.glove.gloveType),
        typ_delivery: 1,
      }),
      price_dol:
        leatehrPrices.en +
        personalizePrices.wristPersonalizationTypes.en +
        personalizePrices.thumbPersonalizationTypes.en +
        personalizePrices.liningPersonalizationTypes.en,
      price_yen:
        leatehrPrices.ja +
        personalizePrices.wristPersonalizationTypes.ja +
        personalizePrices.thumbPersonalizationTypes.ja +
        personalizePrices.liningPersonalizationTypes.ja,
      price_won:
        leatehrPrices.ko +
        personalizePrices.wristPersonalizationTypes.ko +
        personalizePrices.thumbPersonalizationTypes.ko +
        personalizePrices.liningPersonalizationTypes.ko,
      quantity: 1,
      b_custom: 1,
    };

    console.log(leatehrPrices, personalizePrices);

    console.log(obj);

    console.log(
      leatherGrades,
      data.glove.gloveType,
      this.props.i18n.language,
      data.glove.leatherGrade.id,
    );

    // console.log(leatherGrades[getLangCode(this.props.i18n.language)].filter(item => item.id === data.glove.leatherGrade.id)[0].prices[data.glove.gloveType.replace(/ /gi, '')]);
    // console.log(obj, this.additionalRequest);
    // console.log(JSON.parse(obj.body).glove.wristPersonalizationLogo);

    // 로그인 했을시 카트 테이블에 포스트
    if (userData) {
      try {
        const api = getItem('restAPI').model_desc.cart_insert;
        const promises = data.images.map(async item => await UploadImage(item));
        const images = await Promise.all(promises);
        // console.log(data.glove.wristPersonalizationLogo);

        if (data.glove.wristPersonalizationLogo) {
          const logoImageBase64 = await getBase64(data.glove.wristPersonalizationLogo.image.file);
          const logoS3Img = await UploadImage(
            logoImageBase64,
            'customize/logos',
          );

          // 로고 이미지를 저장
          data.glove.wristPersonalizationLogo.image.s3 = logoS3Img;
          data.glove.wristPersonalizationLogo.image.url = logoS3Img.Location;

          console.log(data.glove.wristPersonalizationLogo.image);
        }

        const body = {
          cart: {
            pid_user: userData.user.pid_user,
            ...obj,
            uri_pd: images[0].Location,
            body: JSON.stringify({
              title: 'Customize',
              options: [
                { title: 'Glove Type', context: data.glove.gloveType },
                {
                  title: 'Leather Grade',
                  context: data.glove.leatherGrade.name,
                },
                {
                  title: 'Throwing Hand',
                  context: data.glove.throwingHand.name,
                },
                { title: 'Size', context: data.glove.size.inches },
              ],
              glove: data.glove,
              images,
              additionalRequest: this.additionalRequest,
              weight: 500,
              pid_category: setCustumCtgNum(data.glove.gloveType),
              typ_delivery: 1,
            }),
          },
        };

        // cart DB에 적용
        await _Fetch(api, '', body);
        actions.increment();
      } catch (e) {
        console.log(e);
        if (e.status === 1164) {
          // alert('로그인 정보가 유효하지 않습니다. 다시 로그인 해주시길 바랍니다.');
          setCookie('userData', '', -1);
          this.props.history.push('/login');
        }
      }
    }

    // 비로그인시 세션에 카트를 담기위한 사전 처리
    if (getItem('cart') === null && !userData) {
      // console.log('setting cart');
      setItem('cart', [obj]);
      actions.increment();
      alert('added to cart');
      return this.props.closeAlert();
    }
    // 비로그인이고 세션에 값이 있을때
    if (getItem('cart') && !userData) {
      const arr = getItem('cart');
      arr.push(obj);
      // console.log('push cart');
      setItem('cart', arr);
      actions.increment();
      alert('added to cart');
      return this.props.closeAlert();
    }

    alert('added to cart');
    return this.props.closeAlert();
  }

  render() {
    const {
      glove,
      totalPrice,
      images,
      i18n,
      t,
      // dispatch
    } = this.props;
    const { isLoading } = this.state;
    const Summary = SummaryComponents[glove.gloveType];
    // console.log(glove);
    if (isEmpty(images)) {
      return (
        <div className="custom-glove-popup custom-glove-popup-confirmation">
          <div className="base-alert-title">PLEASE WAIT</div>
          <div className="base-alert-content">Saving glove image...</div>
          <button className="close" onClick={this.props.closeAlert} />
        </div>
      );
    }
    return (
      <div className="custom-glove-popup custom-glove-popup-confirmation">
        <div className="base-alert-title">{t('orderSummary')}</div>
        <div className="base-alert-title-sub">{t('orderSummarySub')}</div>
        <button className="close" onClick={this.props.closeAlert} />
        <div className="popup-row clearfix">
          <div className="popup-col-4 popup-overview">
            <div className="pull-left price-wrap">
              TOTAL
              <br />
              <span className="price">
                {checkedCurrencySymbol(i18n.language) + returnComma(totalPrice)}
              </span>
            </div>
            <div className="pull-right">
              {/* <button
                className="proceed-to-purchase"
                onClick={this.props.submitOrder}
                >
                  PROCEED TO CHECKOUT
              </button> */}
              <button
                className="proceed-to-purchase"
                onClick={() => !isLoading && this.setState({
                  isLoading: true,
                }, () => this.setCartData({ glove, totalPrice, images }))}
              >
                {isLoading ? <img src={loaderIconUrl} /> : t('addToCart')}
              </button>
            </div>
          </div>
        </div>
        <div className="popup-row clearfix">
          <div className="popup-col-1">
            <img className="overview-image" src={images[0]} alt="" />
          </div>
          <div className="popup-col-1">
            <img className="overview-image" src={images[1]} alt="" />
          </div>
          <div className="popup-col-1">
            <img className="overview-image" src={images[2]} alt="" />
          </div>
        </div>
        <Summary glove={glove} />
        <div className="popup-row clearfix">
          <div className="popup-col-4 additional_requirements">
            <h2>{t('specialRequests')}</h2>
            <textarea
              onChange={e => (this.additionalRequest = e.target.value)}
              id="glove_additional_request"
              rows="5"
              placeholder={t('specialRequestsText')}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const gloveTypeKeys = {
    'Single Welting': 'SingleWelting',
    'Double Welting': 'DoubleWelting',
    'First Baseman Mitt': 'FirstBase',
    'Catchers Mitt': 'Catcher',
    Softball: 'Softball',
  };

  const glove = state.glove;

  const leatherPrice = parseFloat(glove.leatherGrade.prices[gloveTypeKeys[glove.gloveType]]);
  const personalizePrice =
    parseFloat(glove.wristPersonalizationType.price) +
    parseFloat(glove.thumbPersonalizationType.price) +
    parseFloat(glove.liningPersonalizationType.price);

  return {
    glove,
    totalPrice: leatherPrice + personalizePrice,
    images: state.preview.images || [],
  };
};

const defaultMapContextToProps = value => ({
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withRouter(withTranslation('translations')(connect(
  mapStateToProps,
  { requestPreviewImages, closeAlert, submitOrder },
)(ConfirmPurchaseAlert))));
