import {
  SELECT_GLOVE_TYPE,
  CHANGE_PREVIEW_SIDE,
  SET_PREVIEW_LOADING,
  REQUEST_PREVIEW_IMAGES,
  COMPLETE_PREVIEW_IMAGES,
} from '../constants/ActionTypes';

const StartingSides = {
  'Single Welting': 'fingerSide',
  'Double Welting': 'fingerSide',
  'First Baseman Mitt': 'webSide',
  'Catchers Mitt': 'fingerSide',
  Softball: 'webSide',
};

export default function preview(state = { side: 'fingerSide', isLoading: false }, action) {
  switch (action.type) {
    case SELECT_GLOVE_TYPE:
      return {
        ...state,
        side: StartingSides[action.gloveType],
      };
    case CHANGE_PREVIEW_SIDE:
      return {
        ...state,
        side: action.side,
      };
    case SET_PREVIEW_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case REQUEST_PREVIEW_IMAGES:
      return {
        ...state,
        requestPending: true,
        images: [],
      };
    case COMPLETE_PREVIEW_IMAGES:
      return {
        ...state,
        requestPending: false,
        images: action.images,
      };
    default:
      return state;
  }
}
