import React from 'react';
import SERVER_URL from '../../common/Settings';
import { Wrapper } from './style';
import _Fetch from '../../common/Fetches';

/*--------------------------------------------------*/

const url = SERVER_URL + '/gloveworks/restapi/';
// url 뒤에 붙는 주소. 버튼누를때마다 바뀜
const restAPIs = ['model_desc', 'auth_desc'];

/*--------------------------------------------------*/

class RestAPI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [], // url+descList[desc] 로 불러온 전체 데이터
      restAPIkey: 0, // 현재 클릭한 descList 키값
      tableNames: [], // 테이블이름들
      tableName: '', // 현재 클릭한 테이블이름
      isLoaded: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  // 전체 데이터를 불러옵니다. restAPI 버튼을 누를때마다 실행됨.
  async getData() {
    try {
      // 전체 데이터
      const res = await _Fetch({
        method: 'GET',
        url: url + restAPIs[this.state.restAPIkey]
      });

      // 테이블이름들을 tableNames 배열에 넣습니다
      let tableNames = [];
      res.map((item, index, array) => {
        if (index === 0) {
          tableNames.push(item.table);
        } else if (index > 0) {
          if (array[index].table !== array[index - 1].table) {
            tableNames.push(item.table);
          }
        }
        return true;
      });

      this.setState({
        data: res,
        restAPIkey: this.state.restAPIkey,
        tableNames: tableNames,
        tableName: tableNames[0],
        isLoaded: true
      });
    } catch (error) {
      console.error(error);
    }
  }

  // restAPI 선택 버튼 목록
  renderRestAPI() {
    const list = restAPIs.map((item, index) => {
      return (
        <button
          key={index}
          onClick={() => {
            this.setState({ restAPIkey: index }, () => this.getData());
          }}
          className={index === this.state.restAPIkey ? 'clicked' : 'notclicked'}
        >
          {item}
        </button>
      );
    });

    return (
      <div>
        <span>rest API: </span>
        {list}
      </div>
    );
  }

  // 테이블명 선택 버튼 목록
  renderTableNames() {
    const list = this.state.tableNames.map((item, index) => {
      return (
        <button
          key={index}
          onClick={() => {
            this.setState({ tableName: item }, () => this.renderTable());
          }}
          className={item === this.state.tableName ? 'clicked' : 'notclicked'}
        >
          {item}
        </button>
      );
    });

    return (
      <div>
        <span>Table명: </span>
        {list}
      </div>
    );
  }

  // 테이블 출력
  renderTable() {
    const list = this.state.data.map((item, index) => {
      // 현재 테이블명 state값과 같은 테이블인 item들만 불러옴
      if (item.table === this.state.tableName) {
        return (
          <tr key={index}>
            <td>{item.method}</td>
            <td>{item.api_name}</td>
            <td>{item.api}</td>
            <td>{item.msg}</td>
          </tr>
        );
      }
      return true;
    });
    return list;
  }

  render() {
    const { isLoaded } = this.state;
    if (isLoaded) {
      return (
        <Wrapper>
          {this.renderRestAPI()}
          <hr />
          {this.renderTableNames()}
          <hr />
          <table>
            <thead>
              <tr>
                <th>method</th>
                <th>api name</th>
                <th>api</th>
                <th>message</th>
              </tr>
            </thead>
            <tbody>{this.renderTable()}</tbody>
          </table>
        </Wrapper>
      );
    } else {
      return <div />;
    }
  }
}

export default RestAPI;
