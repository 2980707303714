import React from 'react';
import { connect } from 'react-redux';

import { changeLeatherGrade, closeAlert } from '../../actions/creators';

const ChangeLeatherGradeAlert = ({ leatherGrade, dispatch }) => {
  const message = `You have chosen ${leatherGrade.name}.<br />The color options will be reset by choosing this.`;

  return (
    <div className="custom-glove-popup">
      <div className="base-alert-title">Alert Message</div>
      <div
        className="base-alert-content"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <button
        className="close"
        onClick={() => dispatch(closeAlert())}
      />
      <div
        className="popup-button yes"
        onClick={() => {
          dispatch(changeLeatherGrade(leatherGrade))
          .then(() => dispatch(closeAlert()));
        }}
      >
        OK
      </div>
      <div
        className="popup-button cancel"
        onClick={() => dispatch(closeAlert())}
      >
        Cancel
      </div>
    </div>
  );
};

export default connect()(ChangeLeatherGradeAlert);
