import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { convertDate, getCostSymbol, returnComma } from '../../common/Utils';
import _Fetch from '../../common/Fetches';
import { getItem } from '../../common/StorageUtils';

import DivButton from '../../components/button/DivButton';
import CartItem from '../../components/item/OrderDetailItem';
import ConfirmCartModal from '../../components/modal/ConfirmCartModalAnoter';

class OrderHistoryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isViewDetailModal: false,
      paymentData: null,
    };

    this.viewDetailData = null;
  }

  componentDidMount() {
    // document.body.style.overflow = 'auto';
    this.initialize();
    // this.testInitDataFectch();
  }

  async initialize() {
    try {
      const api = getItem('restAPI').model_desc.od_get_detail;
      const payApi = getItem('restAPI').model_desc.payment_get_detail;
      // console.log(this.props.data.pid_od);
      const res = await _Fetch(api, `?pid=${this.props.data.pid_od}`, []);
      const paymentRes = await _Fetch(
        payApi,
        `?pid=${this.props.data.pid_payment}`,
        [],
      );
      console.log('paymetn', paymentRes);
      // od_cust 자료가 안담겨서 리턴 될때도 있다 나중에 문제 야기시 대표님께 문의
      console.log('od', res);

      this.setState({ data: res, paymentData: paymentRes.payment });
    } catch (e) {
      console.log(e);
    }
  }

  renderModal() {
    if (this.viewDetailData) {
      return (
        <ConfirmCartModal
          data={this.viewDetailData}
          isModal={this.state.isViewDetailModal}
          onClose={() => {
            this.viewDetailData = null;
            // document.body.style.overflow = 'auto';
            this.setState({ isViewDetailModal: false });
          }}
        />
      );
    }
    return null;
  }

  render() {
    const { data, onBack } = this.props;
    const { paymentData } = this.state;

    // console.log(data);
    if (!this.state.data || !paymentData) return null;

    const processSubtotal = () => {
      let sum = 0;
      this.state.data.od_pd.map(item => (sum += (item.cost - item.dis_value) * item.quantity));
      return sum;
    };
    const processDiscount = () => {
      let sum = 0;
      this.state.data.od_pd.map(item => (sum += (item.dis_value) * item.quantity));
      return sum;
    };
    const discount = processDiscount();
    const subtotal = processSubtotal();
    const allCost = subtotal + data.cost_deli + data.tax;
    console.log(data);
    console.log('const pd', data.cost_pd, 'allCost', allCost);

    // 택배비 할인을 어떻게 적용 할건인지 데이터가 필요함
    // const discount = allCost > data.cost_pd ? allCost - data.cost_pd : 0;
    console.log(discount);
    const payMethode = (type) => {
      switch (type) {
        case  10 : return 'Paypal'
        case  11 : return 'Payment Wall'
        case  20 : return 'Paypal'
        case  21 : return 'Bank Transfer'
        case  22 : return 'Payment Wall'
        case  30 : return 'Paypal'
        case  31 : return 'Bank Transfer'
        default : return 'Inicis'
      }
    }

    return (
      <div style={styles.container}>
        {this.renderModal()}
        <DivButton style={styles.button} onClick={() => onBack()}>
          VIEW ORDER LIST
        </DivButton>

        <div style={styles.productInfoBox}>
          <div style={styles.itemBoxHeader}>
            <p style={styles.orderRevieText}>ORDER REVIEW</p>
            <p style={styles.orderNumberText}>{data.pid_od}</p>
          </div>
          <p style={styles.productTitle(16)}>
            {convertDate(data.date_creation)}
          </p>
          <p style={{ ...styles.productTitle(29), fontWeight: '600' }}>
            PRODUCT
          </p>
          <div style={styles.odrderItemBox}>
            {this.state.data.od_pd.map((item, i) => {
              console.log('item', item);
              const obj = {
                ...item,
                body: this.state.data.od_cust.filter(result => result.pid_od_pd === item.pid_od_pd)[0].body,
              };
              return (
                <CartItem
                  onViewDtail={(glove) => {
                    setTimeout(() => window.scrollTo(0, 0), 1);
                    this.viewDetailData = glove;
                    this.setState({ isViewDetailModal: true });
                    // document.body.style.overflow = 'hidden';
                  }}
                  data={obj}
                  key={shortid.generate()}
                  marginBottom={i === data.length - 1 ? 17 : 20}
                />
              );
            })}
          </div>

          {discount > 0 && (
            <div style={styles.couponBox(8)}>
              <p style={styles.cartSubTotalTtitle}>Discount</p>
              <p style={{ ...styles.cartSubTotalCost, color: '#d0021b' }}>
                -{getCostSymbol(this.state.data.od.typ_cost)}
                {this.state.data.od.typ_cost === 1
                  ? returnComma(discount.toFixed(2))
                  : returnComma(discount)}
              </p>
            </div>
          )}

          <div style={styles.couponBox(10)}>
            <p style={styles.cartSubTotalTtitle}>Subtotal</p>
            <p style={styles.cartSubTotalCost}>
              {getCostSymbol(this.state.data.od.typ_cost)}
              {this.state.data.od.typ_cost === 1
                ? returnComma(subtotal.toFixed(2))
                : returnComma(subtotal)}
            </p>
          </div>

          <div style={styles.couponBox(8)}>
            <p style={styles.cartSubTotalTtitle}>Shipping</p>
            <p style={styles.cartSubTotalCost}>
              {getCostSymbol(this.state.data.od.typ_cost)}
              {this.state.data.od.typ_cost === 1
                ? returnComma(data.cost_deli.toFixed(2))
                : returnComma(data.cost_deli)}
            </p>
          </div>

          {this.state.data.od.typ_cost === 2 && (
            <div style={styles.couponBox(8)}>
              <p style={styles.cartSubTotalTtitle}>Tax</p>
              <p style={styles.cartSubTotalCost}>
                {getCostSymbol(this.state.data.od.typ_cost)}
                {this.state.data.od.typ_cost === 1
                  ? returnComma(data.tax.toFixed(2))
                  : returnComma(data.tax)}
              </p>
            </div>
          )}

          <div style={styles.cartUnderline} />

          <div style={styles.totalBox}>
            <p style={styles.totalText}>Total</p>
            <p style={styles.totalCost}>
              {getCostSymbol(this.state.data.od.typ_cost)}
              {this.state.data.od.typ_cost === 1
                ? returnComma(parseFloat(data.cost_pd.toFixed(2)) + parseFloat(data.cost_deli.toFixed(2)))
                : returnComma(parseFloat(data.cost_pd) + parseFloat(data.cost_deli))}
            </p>
          </div>
        </div>

        <div style={styles.productInfoBox}>
          <p style={styles.orderInfoTitle}>ORDER INFORMATION</p>

          <div style={styles.paymentBox}>
            <p style={styles.addrTitle}>Shippiing Address</p>
            <p style={styles.addrText}>
              {this.state.data.od_addr[0].addr_etc === 'none' || !this.state.data.od_addr[0].addr_etc
                ? null
                : `${this.state.data.od_addr[0].addr_etc}, `}
              {`${this.state.data.od_addr[0].addr}, ${
                this.state.data.od_addr[0].city
              }, ${this.state.data.od_addr[0].state}, ${
                this.state.data.od_addr[0].country
              }`}
              <br />
              Zip : {this.state.data.od_addr[0].zipcode}
              <br />
            </p>
          </div>

          <div style={styles.paymentBox}>
            <p style={styles.addrTitle}>Payment Method</p>
            <p style={styles.addrText}>
              {
                payMethode(paymentData.typ_pay )
              }
            </p>
          </div>
        </div>
      </div>
    );
  }
}

OrderHistoryDetail.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func,
};

OrderHistoryDetail.defaultProps = {
  data: null,
  onBack: () => {},
};

const styles = {
  container: {
    width: 718,
    marginLeft: 45,
  },
  odrderItemBox: {
    width: 624,
    margin: 'auto',
    marginTop: 24,
  },
  cartSubTotalTtitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    width: 180,
    textAlign: 'left',
  },
  orderInfoTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#251f1b',
    marginTop: 24,
    marginLeft: 30,
  },
  button: {
    width: 240,
    height: 40,
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: 0.2,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
  },
  productInfoBox: {
    width: 740,
    border: 'solid 1px rgba(21, 41, 53, 0.1)',
    marginTop: 24,
    backgroundColor: '#fff',
  },
  itemBoxHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 58,
    marginTop: 30,
  },
  orderRevieText: {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#251f1b',
  },
  orderNumberText: {
    marginLeft: 31,
    fontSize: 18,
    lineHeight: 1.39,
    color: '#251f1b',
  },
  productTitle: marginTop => ({
    color: '#251f1b',
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    lineHeight: 1.56,
    letterSpacing: 0.9,
    marginTop,
    marginLeft: 58,
  }),
  cartUnderline: {
    width: 624,
    height: 2,
    backgroundColor: 'rgba(21, 41, 53, 0.1)',
    margin: 'auto',
    marginTop: 30,
  },
  couponBox: marginTop => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop,
    marginRight: 58,
    height: 24,
    alignItems: 'center',
  }),
  totalBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 28,
    marginRight: 58,
    justifyContent: 'flex-end',
    marginBottom: 28,
  },
  totalText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    color: '#251f1b',
    lineHeight: 1.38,
    letterSpacing: 0.9,
    fontWeight: '600',
    width: 150,
  },
  totalCost: {
    fontFamily: 'Roboto Condensed',
    fontSize: 24,
    textAlign: 'right',
    color: '#251f1b',
    lineHeight: 1.25,
    letterSpacing: 1.5,
    fontWeight: '600',
    width: 180,
  },
  cartSubTotalCost: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 0.9,
    color: '#251f1b',
    width: 150,
    textAlign: 'right',
  },
  paymentBox: {
    marginTop: 16,
    marginLeft: 30,
    marginBottom: 24,
  },
  addrTitle: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 1.39,
    letterSpacing: 1,
    color: '#251f1b',
  },
  addrText: {
    marginTop: 8,
    fontSize: 16,
    color: '#251f1b',
  },
};

export default OrderHistoryDetail;
