import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CheckBox from '../checkBox/CheckBox';

const check = '/svg/check_w.svg';

class AddressItem extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <div style={styles.container(this.props.isChecked)}>
        <p style={styles.address}>
          {data.addr_etc === 'none' || !data.addr_etc ? null : `${data.addr_etc}, `}
          {data.addr}
          <br />
          {`${data.city}, ${data.state}, ${data.country}`}
          <br />
          Zip : {data.zipcode}
          <br />
        </p>

        <div
          role="button"
          style={styles.textBtn}
          tabIndex="0"
          onClick={() => this.props.onUpdate(data)}
        >
          Edit
        </div>
        <div
          role="button"
          tabIndex="0"
          onClick={() => this.props.onDelete(data)}
          style={styles.textBtn}
        >
          Delete
        </div>
        <CheckBox
          onClick={() => this.props.onCecked(data)}
          checkImg={check}
          styles={checkStyles}
          isChecked={this.props.isChecked}
        />
      </div>
    );
  }
}

AddressItem.propTypes = {
  isChecked: PropTypes.bool,
  onCecked: PropTypes.func,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

AddressItem.defaultProps = {
  isChecked: false,
  onCecked: () => {},
  data: {},
  onUpdate: () => {},
  onDelete: () => {},
};

const checkStyles = {
  container: isChecked => ({
    width: 18,
    height: 18,
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isChecked ? '#007dff' : '#fff',
    border: isChecked ? 'none' : 'solid 1px #251f1b',
    borderRadius: 1,
    float: 'left',
    cursor: 'pointer',
    marginTop: 20,
    marginLeft: 18,
  }),
  img: {
    width: 14,
    height: 14,
  },
};

const styles = {
  container: isChecked => ({
    width: 936,
    height: 100,
    margin: 'auto',
    marginBottom: 10,
    border: isChecked ? 'solid 1px #007dff' : 'solid 1px #251f1b',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
  }),
  address: {
    width: 769 - 17,
    float: 'left',
    fontSize: 14,
    fontFamily: 'Roboto Condensed',
    lineHeight: 1.43,
    color: '#251f1b',
    marginLeft: 30,
    marginTop: 20,
  },
  textBtn: {
    float: 'left',
    cursor: 'pointer',
    marginTop: 20,
    fontSize: 13,
    color: '#251f1b',
    textDecoration: 'underline',
    marginLeft: 17,
  },
};

export default AddressItem;
