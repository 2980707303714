export const SELECT_TAB = 'SELECT_TAB';
export const OPEN_ALERT = 'OPEN_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const SELECT_GLOVE_TYPE = 'SELECT_GLOVE_TYPE';
export const CHANGE_LEATHER_GRADE = 'CHANGE_LEATHER_GRADE';
export const SELECT_THROWING_HAND = 'SELECT_THROWING_HAND';
export const SELECT_SIZE = 'SELECT_SIZE';

export const CHANGE_PREVIEW_SIDE = 'CHANGE_PREVIEW_SIDE';
export const SET_PREVIEW_LOADING = 'SET_PREVIEW_LOADING';

export const REQUEST_PRESETS = 'REQUEST_PRESETS';
export const RECEIVE_PRESETS = 'RECEIVE_PRESETS';

export const UPDATE_GLOVE = 'UPDATE_GLOVE';

export const REQUEST_PREVIEW_IMAGES = 'REQUEST_PREVIEW_IMAGES';
export const COMPLETE_PREVIEW_IMAGES = 'COMPLETE_PREVIEW_IMAGES';
