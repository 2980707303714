import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { youtubeConvertId } from '../../common/Utils';

import YouTube from '../../lib/youtube/YouTube';

class BasicPost extends Component {
  renderYotube() {
    if (this.props.data.youtube_uri === 'none') return null;
    return (
      <div style={styles.youtubeBox}>
        <YouTube
          // videoId="L8_AFsEmnmY"
          videoId={youtubeConvertId(this.props.data.youtube_uri)}
          opts={styles.youtube}
        />
      </div>
    );
  }

  renderDesc() {
    const { data } = this.props;

    const arr = [];
    for (let i = 1; i < 4; i += 1) {
      if (
        data[`desc_title${i}`] !== 'none' ||
        data[`desc_detail${i}`] !== 'none'
      ) {
        const item = {
          title: data[`desc_title${i}`],
          desc: data[`desc_detail${i}`],
        };

        arr.push(<div key={shortid.generate()} style={styles.descriptionBox}>
          <p style={styles.title}>{item.title}</p>
          <p style={styles.description}>{item.desc}</p>
        </div>);
      }
    }

    if (arr.length === 0) return null;

    return arr;
  }

  render() {
    return (
      <div>
        {this.renderYotube()}
        {this.renderDesc()}
      </div>
    );
  }
}

BasicPost.propTypes = {
  data: PropTypes.object,
};

BasicPost.defaultProps = {
  data: {},
};

const styles = {
  title: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'left',
    color: '#251f1b',
    lineHeight: 1.39,
    letterSpacing: 1,
  },
  descriptionBox: {
    width: 996,
    margin: 'auto',
    marginTop: 40,
  },
  description: {
    marginTop: 12,
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.43,
    letterSpacing: 0.4,
    whiteSpace: 'pre-wrap',
  },
  youtubeBox: {
    width: 996,
    height: 378,
    margin: 'auto',
    marginTop: 40,
  },
  youtube: {
    width: '996',
    height: '378',
  },
};

export default BasicPost;
