import { combineReducers } from 'redux';

// action 객체를 받았을 때, 데이터를 어떻게 바꿀지 처리할지 정의하는 객체입니다.
import {
  SIDEBAR_MODE,
  SETSUBTOTAL,
} from '../actions/Actions';

const initialState = {
  value: 0,
};

function sidebarModeReducer(state = false, action) {
  switch (action.type) {
    case SIDEBAR_MODE:
      return Object.assign({}, state, {
        sidebarMode: action.sidebarMode,
      });
    default:
      return { sidebarMode: state };
  }
}

function subtotal(state = initialState, action) {
  switch (action.type) {
    case SETSUBTOTAL:
      return Object.assign({}, state, {
        value: action.value,
      });
    default:
      return state;
  }
}

const Reducers = combineReducers({
  sidebarModeReducer,
  subtotal,
});

export default Reducers;
