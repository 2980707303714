import React, { Component, Fragment } from 'react';

import { getCookie } from '../../common/Utils';

import Modal from '../../containers/baseModal/BaseModal';

import DivButton from '../../components/button/DivButton';

class MyAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      bodyText: '',
    };

    this.userData =
      getCookie('userData') === '' ? null : JSON.parse(getCookie('userData'));
    this.onNext = this.closeModal;
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: '',
    });
  };

  render() {
    const { isModal, bodyText } = this.state;
    return (
      <Fragment>
        <Modal
          isModal={isModal}
          bodyText={bodyText}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        <div style={styles.container}>
          <p style={styles.title(0)}>My Account</p>
          <p style={styles.title(35)}>ID (E-mail)</p>
          <p style={styles.email}>{this.userData.user.id}</p>
          <p style={styles.title(24)}>Reset Password</p>
          <DivButton
            style={styles.button}
            onClick={() => this.openModal('send email')}
          >
            Send Email
          </DivButton>
          <p style={styles.title(24)}>Membership Withdrawal</p>
          <DivButton
            style={styles.button}
            onClick={() => this.openModal('Withdrawal')}
          >
            Withdrawal
          </DivButton>
        </div>
      </Fragment>
    );
  }
}

const styles = {
  container: {
    marginTop: 30,
    marginLeft: 24,
  },
  title: marginTop => ({
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 1.56,
    color: '#251f1b',
    marginTop,
  }),
  email: {
    fontSize: 16,
    lineHeight: 1.56,
    color: '#251f1b',
    marginTop: 8,
  },
  button: {
    display: 'flex',
    width: 150,
    height: 30,
    backgroundColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: '600',
    color: '#fff',
    marginTop: 8,
  },
};

export default MyAccount;
