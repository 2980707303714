import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const PinkySide = ({
  customPythons,
  currentCustomPython,
  onCustomPythonClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="PINKY SIDE COLOR"
      className="finger-side-color-wrap"
      images={customPythons}
      currentCustomImage={currentCustomPython}
      onClick={onCustomPythonClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { pinkySidePython } = state.glove;

  return {
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    currentCustomPython: pinkySidePython,
  };
};

const mapDispatchToProps = dispatch => ({
  onCustomPythonClick: color => dispatch(updateGlove({ pinkySidePython: color })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PinkySide), 'fingerSide');
