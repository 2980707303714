import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import shortid from "shortid";
import { fromJS } from "immutable";
import { withTranslation } from "react-i18next";

import { getItem, setItem } from "../../common/StorageUtils";
import {
  getCookie,
  checkedCurrencySymbol,
  setCookie,
  settingCost,
  getPerItemCouponCost,
  returnComma,
} from "../../common/Utils";
import _Fetch from "../../common/Fetches";
import { DeleteData } from "../../common/S3";
import { YEN_INCOM_TAX_PER } from "../../common/Constants";
// import SERVER_URL from '../../common/Settings';

import Modal2 from "../../containers/baseModal/BaseModal";
import ConfirmModal from "../../containers/baseModal/ConfirmModal";

import Step from "../../components/step/Step";
import Item from "../../components/item/MyCartItem";
import DivButton from "../../components/button/DivButton";
import Input from "../../components/input/Input";
import ConfirmCartModal from "../../components/modal/ConfirmCartModal";

import ShippingInfo from "./ShippingInfo";
import PaymentInfo from "./PaymentInfo";

import Modal from "../../lib/modal";

import Notice from "./Notice";
import NoticePolicy from "./NoticePolicy";

import { Consumer } from "../../Context";
import CheckInput from "../../components/input/CheckInput";

Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.3)";

const STEP_MENU = ["Shop", "My Cart"];
const info = "/svg/info.svg";
const arrowWhite = "/svg/arrow_wr.svg";
const close = "/svg/close.svg";
const couponCheck = "/svg/couponCheck.svg";

const RedicrectionUrl = () => {
  const env = process.env.NODE_ENV;

  console.log("-----------------", env);

  const isDev = env === "development";
  // const isSatge = env === 'stage';
  // const isProd = env = 'production';

  if (isDev) return "http://localhost:3001/orderReview";

  // if (isSatge) return 'https://glocli.gloveworks.net/orderReview';

  let rediUrl = document.location.origin
    ? document.location.origin
    : "https://www.gloveworks.net";
  rediUrl += "/orderReview";
  return rediUrl;

  // return 'https://www.gloveworks.net/orderReview';
  // return 'https://client.gloveworks.net/orderReview';
};

/** @description 초기 subtotal 값 계산 initData() 에서 호출
 * @param {Array} data 카트 아이템 정보
 * @return {Number} 서브토탈
 */
function initCalSubtotal(data, i18n) {
  let subtotal = 0;
  data.map(
    (item) => (subtotal += settingCost(i18n.language, item) * item.quantity)
  );
  return Math.abs(subtotal);
}

function settingCountryCode(lanCode) {
  switch (lanCode) {
    case "ko":
      return 3;
    case "ja":
      return 2;
    default:
      return 1;
  }
}

function setCouponInputBorderColor(data) {
  if (data === null) return "#dadada";
  if (data === undefined) return "#e85454";
  return "#40c380";
}

function sumQty(data) {
  let qty = 0;
  data.map((item) => (qty += item.quantity));
  return qty;
}

class Cart extends Component {
  constructor(props) {
    super(props);
    /** @description state 설명
     * @property {Array} data 수량정보를 제외한 아이템 정보들 (나중에 pid_pd를 카트 id로 변경 필요)
     * @property {Number} step SPA 형식으로 0일땐 카트 0이상일시 체크아웃 단계별
     * @property {Number} subtotal this.ssubtotal 사전 처리후 담을 변수
     *
     * @description 멤버 변수 설명 this 생략
     * @property {Number} subtotal state subtotal 넣기 전 사전 처리를 위한 아이템 총 가격 변수
     * @property {Array} data state data에 넣기전 사전처리 값을 담을 변수
     * @property {Object} userData 로그인시 쿠키값에서 유저 정보를 지닌 변수
     */
    this.state = {
      data: null,
      subtotal: 0,
      step: 0,
      isViewDetailModal: false,
      coupon: null,
      totalCouponCost: 0,
      isModal: false,
      isPolicyModal: false,
      isModal2: false,
      bodyText2: "",
      isPrepaidModal: false,
    };

    // subtotal 계산을 위한 변수
    this.subtotal = 0;

    // 카트아이템과 수량 정보를 지닌 데이트 immutable 데이터로 정의 한다.
    this.data = [];

    // 유저 데이터 담을 변수
    this.userData = null;

    // 커스텀 아이템 뷰 디테일 아이템을 담을 변수
    this.viewDetailData = null;

    // 쇼핑 어드레스를 담을 변수
    this.shippingAddr = null;

    // 빌링 어드레스를 담을 변수
    this.billingAddr = null;

    // 배송비 담을 부분
    this.deliCost = null;

    this.orderId = [];

    this.IMP = window.IMP;

    this.couponInput = null;
    this.checkArr = [false, false];
    this.shippingNode = null;

    this.onNext = this.closeModal;
    this.onPrepaidNext = this.closePrepaidModal;

    this.prepaidModalLeftBtn = () => {
      this.props.history.push("/orderHistory");
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#fafafa";
    window.document.body.addEventListener("keyup", this.onkeyup);

    try {
      window.gtag("event", "conversion", {
        send_to: "AW-10904637938/HULmCMbF8b4DEPKj3s8o",
        transaction_id: "",
      });
    } catch (e) {
      console.log("gtag error");
    }

    this.initData();
  }

  componentWillUnmount() {
    window.document.body.removeEventListener("keyup", this.onkeyup);
  }

  // 싱글 페이지라 헤더에서 다시 Shop 아이콘 클릭시 초기 화념으로 전환을 위해 선언
  componentWillReceiveProps(nextProps) {
    if (this.couponInput) {
      this.couponInput.value = "";
    }
    this.setState(
      {
        step: 0,
        coupon: null,
        totalCouponCost: 0,
      },
      () => this.initData()
    );
  }

  onkeyup = (e) => {
    const { step } = this.state;
    if (e.keyCode === 13 && step === 0) return this.onCoupon();

    return null;
  };

  openModal = (bodyText2) => {
    this.setState({
      isModal2: true,
      bodyText2,
    });
  };

  closeModal = () => {
    this.setState({
      isModal2: false,
      bodyText2: "",
    });
  };

  openPrepaidModal = (bodyPrepaidText) => {
    this.setState({
      isPrepaidModal: true,
      bodyPrepaidText,
    });
  };

  closePrepaidModal = () => {
    this.setState({
      isPrepaidModal: false,
      bodyPrepaidText: "",
    });
  };

  async initData() {
    this.userData =
      getCookie("userData") === "" ? null : JSON.parse(getCookie("userData"));
    console.log(this.userData);
    let data = [];
    // 로그인 햇을 경우 카트는 DB에서 가져온다.
    if (this.userData) {
      try {
        const api = getItem("restAPI").model_desc.cart_get_my;
        const res = await _Fetch(
          api,
          `?pid_user=${this.userData.user.pid_user}`,
          []
        );
        // console.log(res);
        data = res;
      } catch (e) {
        console.log(e);
      }
    } else {
      // 비로그인시 카트 데이터는 세션 스토리지에서 가져온다.
      // 세션 스토리지에 아이템 정보 유무 판단 후 데이터가 있다면 세션스토리지 cart부분에서 데이터 가져옴 없다면 빈배열
      data = getItem("cart") === null ? [] : getItem("cart");
    }

    // 세션스토리지에서 가져온 데이터에 수량 정보 추가 임뮤터블 데이터 형식으로 저장
    // immutable 참고 페이지 주소: http://untangled.io/the-missing-immutable-js-manual/
    this.data = fromJS(data);
    console.log(this.data.toJS());

    // subtotal 계산
    this.subtotal = initCalSubtotal(this.data.toJS(), this.props.i18n);

    // 스테이트에는 임뮤터블 데이터가 저장 되지 않게 한다. 배열 타입으로 들어가야됌
    this.setState({
      data: this.data.toJS(),
      subtotal: this.subtotal,
    });
  }

  async onDelete(item, idx) {
    const { actions } = this.props;
    // 삭제할 데이터 immutable 삭제 후 재 할당
    this.data = this.data.delete(idx);

    // subtotal 계산
    this.subtotal =
      Number(this.subtotal) -
      settingCost(this.props.i18n.language, item) * item.quantity;
    const itemBody = JSON.parse(item.body);

    if (this.userData) {
      try {
        console.log(item);
        // 로그인시 커스텀 이미지 S3에서 삭제처리 작업.
        if (item.b_custom === 1) {
          console.log(itemBody);
          const promises = itemBody.images.map(async (req) => {
            try {
              return await DeleteData(req.Key);
            } catch (e) {
              return console.log(e);
            }
          });

          await Promise.all(promises);

          if (itemBody.glove.wristPersonalizationLogo) {
            await DeleteData(
              itemBody.glove.wristPersonalizationLogo.image.s3.Key
            );
          }
        }
        const api = getItem("restAPI").model_desc.cart_delete;
        const body = {
          cart: {
            pid_cart: item.pid_cart,
            pid_user: this.userData.user.pid_user,
          },
        };
        console.log(body);
        await _Fetch(api, "", body);
      } catch (e) {
        console.log(e);
        if (e.status === 1164) {
          this.openModal(
            "Your login information is invalid. Please login again."
          );
          setCookie("userData", "", -1);
          this.props.history.push("/login");
        }
      }
    } else {
      if (item.b_custom === 1 && itemBody.glove.wristPersonalizationLogo) {
        await DeleteData(itemBody.glove.wristPersonalizationLogo.image.s3.Key);
      }
      // 세션 스토리지에 데이터 수정
      setItem("cart", this.data.toJS());
    }

    if (this.couponInput) {
      this.couponInput.value = "";
    }

    return this.setState(
      {
        data: this.data.toJS(),
        subtotal: this.subtotal,
        coupon: null,
        totalCouponCost: 0,
      },
      () => {
        const { data } = this.state;
        actions.setCount(data.length);
      }
    );
  }

  /** @description 수량 업데이트 버튼을 클릭 했을 때 호출
   * @param {Object} item 카트 정보
   * @param {String} inputQty 인풋 입력된 숫자를 지
   * @param {Number} idx 아이템 인덱스
   */
  async onCalculation(item, inputQty, idx) {
    // 수량 값 셋팅
    this.data = this.data.setIn([idx, "quantity"], Number(inputQty));
    console.log(this.data.toJS()[idx].quantity);

    // 변화된 수량
    const afterQty = this.data.toJS()[idx].quantity;
    // total cost 계산
    if (afterQty !== 0) {
      if (item.quantity > afterQty) {
        this.subtotal =
          Number(this.subtotal) -
          settingCost(this.props.i18n.language, item) *
            (item.quantity - afterQty);
      } else {
        this.subtotal =
          Number(this.subtotal) +
          settingCost(this.props.i18n.language, item) *
            (afterQty - item.quantity);
      }
    }

    if (this.userData) {
      console.log(Number(inputQty), item.pid_cart);
      try {
        const api = getItem("restAPI").model_desc.cart_update;
        const body = {
          cart: {
            pid_cart: item.pid_cart,
            quantity: Number(inputQty),
            pid_user: this.userData.user.pid_user,
          },
        };
        console.log(body);
        await _Fetch(api, "", body);
      } catch (e) {
        console.log(e);
        if (e.status === 1164) {
          this.openModal(
            "Your login information is invalid. Please login again."
          );
          setCookie("userData", "", -1);
          this.props.history.push("/login");
        }
      }
    } else {
      setItem("cart", this.data.toJS());
    }

    if (this.couponInput) {
      this.couponInput.value = "";
    }

    return this.setState({
      data: this.data.toJS(),
      subtotal: this.subtotal,
      coupon: null,
      totalCouponCost: 0,
    });
  }

  iamportModule(paymentRes) {
    this.IMP.request_pay(paymentRes.imp_params, (rsp) => {
      if (rsp.success) {
        this.props.history.push("/orderReview");
      }
      if (rsp.success) {
        console.log("성공");
      } else {
        const msg = rsp.error_msg;
        this.openModal(msg);
      }
      // let msg = '결제가 완료되었습니다.';
      // msg += `고유ID : ${rsp.imp_uid}`;
      // msg += `상점 거래ID : ${rsp.merchant_uid}`;
      // msg += `결제 금액 : ${rsp.paid_amount}`;
      // msg += `카드 승인번호 : ${rsp.apply_num}`;
      //       this.openModal(msg);
      // if (rsp.success) {
      //   window.$.ajax({
      //     url: '/payments/complete',
      //     type: 'POST',
      //     dataType: 'json',
      //     data: {
      //       imp_uid: rsp.imp_uid,
      //     },
      //   }).done((data) => {
      //     let msg = '결제가 완료되었습니다.';
      //     msg += `고유ID : ${rsp.imp_uid}`;
      //     msg += `상점 거래ID : ${rsp.merchant_uid}`;
      //     msg += `결제 금액 : ${rsp.paid_amount}`;
      //     msg += `카드 승인번호 : ${rsp.apply_num}`;
      //     this.openModal(msg);
      //   });
      // } else {
      //   const msg = rsp.error_msg;
      //   this.openModal(msg);
      // }
    });
  }

  // 게좌 이체시 리덕스 카운트 처리
  onManageStateAccChkOut = (firstName) => {
    console.log("name", firstName);
    if (!firstName) return this.openModal("Please entered name");

    const { coupon } = this.state;

    console.log("acc coupon", coupon);

    return this.onAccountCheckOut(firstName, coupon);
  };

  // 계좌이체시
  async onAccountCheckOut(firstName, coupon) {
    try {
      const odAddr = this.shippingAddr;
      delete odAddr.date_creation;
      delete odAddr.pid_addr;
      delete odAddr.b_base;
      delete odAddr.pid_user;

      const paymentApi = getItem("restAPI").model_desc.shop_user_init_payment;

      const qty = sumQty(this.state.data);
      const isDiscountShppingFee = qty > 3;

      const shippingTotal =
        this.deliCost.customTotalFee + this.deliCost.stockTotalFee;

      const beforeDiscountShppingFee = isDiscountShppingFee
        ? shippingTotal * 0.2
        : 0;

      const discountShppingFee =
        this.props.i18n.language === "en"
          ? beforeDiscountShppingFee.toFixed(2)
          : Math.ceil(beforeDiscountShppingFee);

      const couponFee =
        this.props.i18n.language === "en"
          ? this.calCouponCost(coupon, this.state.data).toFixed(2)
          : Math.ceil(this.calCouponCost(coupon, this.state.data));

      //  OUTLIER - 20240122 - 2번
      // const tax =
      //   this.props.i18n.language === "ja"
      //     ? Math.floor(Number((this.subtotal - couponFee) * YEN_INCOM_TAX_PER))
      //     : 0;
      const tax = 0;

      console.log(
        "subtotal",
        this.subtotal,
        "custom total",
        this.deliCost.customTotalFee,
        "stock total",
        this.deliCost.stockTotalFee,
        "coupon total",
        this.state.totalCouponCost,
        "discount shipping fee",
        discountShppingFee,
        "tax fee",
        tax
      );

      const paymentBody = {
        payment: {
          pid_user: this.userData.user.pid_user,
          ctg_country: settingCountryCode(this.props.i18n.language),
          typ_pay: this.props.i18n.language === "ja" ? 21 : 31,
          typ_cost: settingCountryCode(this.props.i18n.language),
          cost_pd:
            this.props.i18n.language === "en"
              ? Number((this.state.subtotal - couponFee).toFixed(2))
              : Number(this.state.subtotal + tax - couponFee),
          cost_deli:
            this.props.i18n.language === "en"
              ? Number(
                  (
                    this.deliCost.customTotalFee +
                    this.deliCost.stockTotalFee -
                    discountShppingFee
                  ).toFixed(2)
                )
              : Math.floor(
                  Number(
                    this.deliCost.customTotalFee +
                      this.deliCost.stockTotalFee -
                      discountShppingFee
                  )
                ),
          tax,
        },
        od_addr: odAddr,
        redi_url: RedicrectionUrl(),
      };
      const paymentRes = await _Fetch(paymentApi, "", paymentBody);
      console.log(paymentRes);
      const pidPayment = paymentRes.pid_payment;

      const shopOdApi =
        getItem("restAPI").model_desc.shop_user_insert_glove_order;

      const stock = this.state.data.filter((item) => item.b_custom === 2);
      const customize = this.state.data.filter((item) => item.b_custom === 1);

      console.log("data", this.state.data);

      // console.log(shopOdApi, stock, customize);

      const senderId = `${firstName}`;

      console.log("senderId", senderId);

      if (stock.length > 0 && customize.length === 0) {
        const stockRes = await this.postStockAccount(
          shopOdApi,
          pidPayment,
          stock,
          odAddr,
          senderId,
          coupon
        );
        this.orderId.push(stockRes);

        const data = {
          item: this.state.data,
          shippingAddr: this.shippingAddr,
          billingAddr: this.billingAddr,
          orderIdData: this.orderId,
          pidPayment,
          coupon,
          shippingCostData: this.deliCost,
          senderId,
        };

        console.log(data);
        setItem("orderReviewData", data);
        this.props.history.push("/orderReview");
      }

      if (customize.length > 0 && stock.length === 0) {
        const customRes = await this.postCustomAccount(
          shopOdApi,
          pidPayment,
          customize,
          odAddr,
          senderId,
          coupon
        );
        this.orderId.push(customRes);

        const data = {
          item: this.state.data,
          shippingAddr: this.shippingAddr,
          billingAddr: this.billingAddr,
          orderIdData: this.orderId,
          pidPayment,
          coupon,
          shippingCostData: this.deliCost,
          senderId,
        };

        console.log(data);
        setItem("orderReviewData", data);
        this.props.history.push("/orderReview");
      }

      if (customize.length > 0 && stock.length > 0) {
        const stockRes = await this.postStockAccount(
          shopOdApi,
          pidPayment,
          stock,
          odAddr,
          senderId,
          coupon
        );
        this.orderId.push(stockRes);
        const customRes = await this.postCustomAccount(
          shopOdApi,
          pidPayment,
          customize,
          odAddr,
          senderId,
          coupon
        );
        this.orderId.push(customRes);

        const data = {
          item: this.state.data,
          shippingAddr: this.shippingAddr,
          billingAddr: this.billingAddr,
          orderIdData: this.orderId,
          pidPayment,
          coupon,
          shippingCostData: this.deliCost,
          senderId,
        };

        console.log(data);
        setItem("orderReviewData", data);
        this.props.history.push("/orderReview");
        // console.log(customRes);
      }
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        this.props.history.push("/login");
      }
    }
  }

  // 결제 시 패치 작업
  async onCheckOut(paymentType) {
    console.log(paymentType, "paymentType11111");
    try {
      const odAddr = this.shippingAddr;
      delete odAddr.date_creation;
      delete odAddr.pid_addr;
      delete odAddr.b_base;
      delete odAddr.pid_user;

      const paymentApi = getItem("restAPI").model_desc.shop_user_init_payment;

      const qty = sumQty(this.state.data);
      const isDiscountShppingFee = qty > 3;

      const shippingTotal =
        this.deliCost.customTotalFee + this.deliCost.stockTotalFee;

      const beforeDiscountShppingFee = isDiscountShppingFee
        ? shippingTotal * 0.2
        : 0;
      const discountShppingFee =
        this.props.i18n.language === "en"
          ? beforeDiscountShppingFee.toFixed(2)
          : Math.ceil(beforeDiscountShppingFee);

      const protoCouponFee = this.state.totalCouponCost;
      const couponFee =
        this.props.i18n.language === "en"
          ? protoCouponFee.toFixed(2)
          : Math.ceil(protoCouponFee);

      // OUTLIER - 20240122 - 2번
      // const tax =
      //   this.props.i18n.language === "ja"
      //     ? Math.floor(Number((this.subtotal - couponFee) * YEN_INCOM_TAX_PER))
      //     : 0;
      const tax = 0;
      //paypal 일시
      const processTypPay = (lang) => {
        console.log(processTypPay, "processTypPay");
        switch (lang) {
          case "en":
            return 10;
          case "ja":
            return 20;
          default:
            return 30;
        }
      };
      //paymentWall 일시
      const paymentWallPay = (lang) => {
        console.log(paymentWallPay, "paymentWallPay");
        switch (lang) {
          case "en":
            return 11;
          default:
            return 22;
        }
      };
      const payTpye = (type) => {
        switch (type) {
          case 1:
            return processTypPay(this.props.i18n.language);
          case 3:
            return paymentWallPay(this.props.i18n.language);
          default:
            return 32;
        }
      };

      const typPay = payTpye(paymentType);

      console.log("typPay", typPay);

      const paymentBody = {
        payment: {
          pid_user: this.userData.user.pid_user,
          ctg_country: settingCountryCode(this.props.i18n.language),
          // typ_pay: this.props.i18n.language === 'en' ? 10 : 30,
          typ_pay: typPay,
          typ_cost: settingCountryCode(this.props.i18n.language),
          cost_pd:
            this.props.i18n.language === "en"
              ? Number((this.state.subtotal - couponFee).toFixed(2))
              : Number(this.state.subtotal + tax - couponFee),
          cost_deli:
            this.props.i18n.language === "en"
              ? Number(
                  (
                    this.deliCost.customTotalFee +
                    this.deliCost.stockTotalFee -
                    discountShppingFee
                  ).toFixed(2)
                )
              : Math.floor(
                  Number(
                    this.deliCost.customTotalFee +
                      this.deliCost.stockTotalFee -
                      discountShppingFee
                  )
                ),
          tax,
        },
        od_addr: odAddr,
        redi_url: RedicrectionUrl(),
      };
      const paymentRes = await _Fetch(paymentApi, "", paymentBody);
      console.log("paymentBody", paymentBody);
      this.IMP.init(paymentRes.imp_uid);

      const pidPayment = paymentRes.pid_payment;

      const shopOdApi =
        getItem("restAPI").model_desc.shop_user_insert_glove_order;

      const stock = this.state.data.filter((item) => item.b_custom === 2);
      const customize = this.state.data.filter((item) => item.b_custom === 1);

      // console.log(shopOdApi, stock, customize);

      if (stock.length > 0 && customize.length === 0) {
        const stockRes = await this.postStock(
          shopOdApi,
          pidPayment,
          stock,
          odAddr
        );
        this.orderId.push(stockRes);

        const data = {
          item: this.state.data,
          shippingAddr: this.shippingAddr,
          billingAddr: this.billingAddr,
          orderIdData: this.orderId,
          pidPayment,
          coupon: this.state.coupon,
          shippingCostData: this.deliCost,
          senderId: "",
        };

        console.log(data);
        setItem("orderReviewData", data);

        this.iamportModule(paymentRes);
      }

      if (customize.length > 0 && stock.length === 0) {
        const customRes = await this.postCustom(
          shopOdApi,
          pidPayment,
          customize,
          odAddr
        );
        this.orderId.push(customRes);

        const data = {
          item: this.state.data,
          shippingAddr: this.shippingAddr,
          billingAddr: this.billingAddr,
          orderIdData: this.orderId,
          pidPayment,
          coupon: this.state.coupon,
          shippingCostData: this.deliCost,
          senderId: "",
        };

        console.log(data);
        setItem("orderReviewData", data);

        this.iamportModule(paymentRes);
      }

      if (customize.length > 0 && stock.length > 0) {
        const stockRes = await this.postStock(
          shopOdApi,
          pidPayment,
          stock,
          odAddr
        );
        this.orderId.push(stockRes);
        const customRes = await this.postCustom(
          shopOdApi,
          pidPayment,
          customize,
          odAddr
        );
        this.orderId.push(customRes);

        const data = {
          item: this.state.data,
          shippingAddr: this.shippingAddr,
          billingAddr: this.billingAddr,
          orderIdData: this.orderId,
          pidPayment,
          coupon: this.state.coupon,
          shippingCostData: this.deliCost,
          senderId: "",
        };

        console.log(data);
        setItem("orderReviewData", data);
        // console.log(customRes);
        this.iamportModule(paymentRes);
      }
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        this.props.history.push("/login");
      }
    }
  }

  // 사용한 쿠폰 정보 저장
  async postCouponUsed(odPdArr, odPdIdArr, odID) {
    const { coupon } = this.state;
    if (!coupon) return null;

    try {
      const api = getItem("restAPI").model_desc.coupon_used_insert;

      const promises = odPdArr.map(async (item, i) => {
        if (item.dis_value > 0) {
          console.log(item);
          const body = {
            coupon_used: {
              pid_coupon: item.pid_coupon,
              pid_od_pd: odPdIdArr[i],
              pid_user: this.userData.user.pid_user,
              id: this.userData.user.id,
              typ_coupon: item.dis_typ,
              typ_cost: item.typ_cost,
              dis_value: item.dis_value,
              pid_od: odID,
            },
          };
          console.log(body);
          return _Fetch(api, "", body);
        }
        return 0;
      });

      const promisesRes = await Promise.all(promises);
      return console.log(promisesRes);
    } catch (e) {
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        return this.props.history.push("/login");
      }
      return console.log(e);
    }
  }

  // od_pd에 커스텀 등록
  async postCustom(
    shopOdApi,
    pidPayment,
    customize,
    odAddr,
    senderId = "none"
  ) {
    const customWeight = customize.reduce(
      (a, b) => a + JSON.parse(b.body).weight,
      0
    );

    function subtotal(lanCode, data) {
      let cost = 0;
      data.map((item) => (cost += settingCost(lanCode, item) * item.quantity));
      return cost;
    }

    const customTotal = subtotal(this.props.i18n.language, customize);

    const settingGloveTypeCode = (gloveTypeStr) => {
      switch (gloveTypeStr) {
        case "Single Welting":
          return 1;
        case "Double Welting":
          return 2;
        case "First Baseman Mitt":
          return 3;
        case "Catchers Mitt":
          return 4;
        default:
          return 5;
      }
    };

    const odCustomArr = [];
    const customOdPdArr = customize.map((item) => {
      const itemBody = JSON.parse(item.body);
      odCustomArr.push({
        body: item.body,
        body_origin: item.body,
      });

      return {
        pid_user: this.userData.user.pid_user,
        pid_category: itemBody.pid_category,
        pid_pd: item.pid_item,
        title: itemBody.title,
        pd_uri: item.uri_pd,
        stage_uri: "none",
        quantity: item.quantity,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost: settingCost(this.props.i18n.language, item).toFixed(2),
        typ_glove: settingGloveTypeCode(itemBody.glove.gloveType),
        leather_grade: itemBody.glove.leatherGrade.id,

        pid_coupon:
          this.state.coupon &&
          getPerItemCouponCost(
            item,
            this.state.coupon,
            this.props.i18n.language
          ) > 0
            ? this.state.coupon.pid_coupon
            : 0,
        uuid_coupon:
          this.state.coupon &&
          getPerItemCouponCost(
            item,
            this.state.coupon,
            this.props.i18n.language
          ) > 0
            ? this.state.coupon.uuid_coupon
            : "none",
        dis_typ:
          this.state.coupon &&
          getPerItemCouponCost(
            item,
            this.state.coupon,
            this.props.i18n.language
          ) > 0
            ? this.state.coupon.typ_coupon
            : 0,
        dis_value: this.state.coupon
          ? getPerItemCouponCost(
              item,
              this.state.coupon,
              this.props.i18n.language
            )
          : 0,
        typ_delivery: itemBody.typ_delivery,
      };
    });

    const qty = sumQty(this.state.data);
    const isDiscountShppingFee = qty > 3;

    const shippingTotal = this.deliCost.customTotalFee;

    const beforeDiscountShppingFee = isDiscountShppingFee
      ? shippingTotal * 0.2
      : 0;
    const discountShppingFee =
      this.props.i18n.language === "en"
        ? beforeDiscountShppingFee.toFixed(2)
        : Math.ceil(beforeDiscountShppingFee);

    const couponFee =
      this.props.i18n.language === "en"
        ? this.calCouponCost(this.state.coupon, customize).toFixed(2)
        : Math.ceil(this.calCouponCost(this.state.coupon, customize));

    // OUTLIER - 20240122 - 2번
    // const tax =
    //   this.props.i18n.language === "ja"
    //     ? Math.floor(Number((customTotal - couponFee) * YEN_INCOM_TAX_PER))
    //     : 0;
    const tax = 0;

    const customBody = {
      od: {
        pid_payment: pidPayment,
        pid_user: this.userData.user.pid_user,
        ctg_pd: 1,
        id: this.userData.user.id,
        user_name: `${this.shippingAddr.name_first} ${this.shippingAddr.name_last}`,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost_pd:
          this.props.i18n.language === "en"
            ? Number((this.state.subtotal - couponFee).toFixed(2))
            : Number(this.state.subtotal + tax - couponFee),
        cost_deli:
          this.props.i18n.language === "en"
            ? Number(
                (
                  this.deliCost.customTotalFee +
                  this.deliCost.stockTotalFee -
                  discountShppingFee
                ).toFixed(2)
              )
            : Math.floor(
                Number(
                  this.deliCost.customTotalFee +
                    this.deliCost.stockTotalFee -
                    discountShppingFee
                )
              ),
        tax,
        // uid_tracking: shortid.generate(),
        // via_delivery: 'test',
        shipping: `weight: ${customWeight}`,
        cnt_item: customize.length,
        title: JSON.parse(customize[0].body).title,
        sender_name: senderId,
      },
      od_addr: odAddr,
      od_pd_arr: customOdPdArr,
      od_cust_arr: odCustomArr,
    };

    // console.log('custom', customBody);
    try {
      const res = await _Fetch(shopOdApi, "", customBody);
      // console.log('custom', res);
      await this.postCouponUsed(customOdPdArr, res.pd_arr, res.od);
      return res;
      // return _Fetch(shopOdApi, '', customBody);
    } catch (e) {
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        return this.props.history.push("/login");
      }
      return e;
    }
  }

  // 기성품 od_pd 에 등록
  async postStock(shopOdApi, pidPayment, stock, odAddr, senderId = "none") {
    const stockWeight = stock.reduce(
      (a, b) => a + JSON.parse(b.body).weight,
      0
    );

    console.log("stock", stock);

    function subtotal(lanCode, data) {
      let cost = 0;
      data.map((item) => (cost += settingCost(lanCode, item) * item.quantity));
      return cost;
    }

    const stockTotal = subtotal(this.props.i18n.language, stock);

    const odCustomArr = [];
    const stockOdPdArr = stock.map((item) => {
      const itemBody = JSON.parse(item.body);
      // const optBody = itemBody.options.length === 0 ? JSON.stringify('none') : JSON.stringify(itemBody.options);
      odCustomArr.push({
        body: item.body,
        body_origin: item.body,
      });
      return {
        pid_user: this.userData.user.pid_user,
        pid_category: itemBody.pid_category,
        pid_pd: item.pid_item,
        title: itemBody.title,
        pd_uri: item.uri_pd,
        quantity: item.quantity,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost: settingCost(this.props.i18n.language, item).toFixed(2),

        pid_coupon:
          this.state.coupon &&
          getPerItemCouponCost(
            item,
            this.state.coupon,
            this.props.i18n.language
          ) > 0
            ? this.state.coupon.pid_coupon
            : 0,
        uuid_coupon:
          this.state.coupon &&
          getPerItemCouponCost(
            item,
            this.state.coupon,
            this.props.i18n.language
          ) > 0
            ? this.state.coupon.uuid_coupon
            : "none",
        dis_typ:
          this.state.coupon &&
          getPerItemCouponCost(
            item,
            this.state.coupon,
            this.props.i18n.language
          ) > 0
            ? this.state.coupon.typ_coupon
            : 0,
        dis_value: this.state.coupon
          ? getPerItemCouponCost(
              item,
              this.state.coupon,
              this.props.i18n.language
            )
          : 0,

        typ_delivery: itemBody.typ_delivery,
        pid_pd_inv: itemBody.pid_pd_inv,
      };
    });

    const qty = sumQty(this.state.data);
    const isDiscountShppingFee = qty > 3;

    const shippingTotal = this.deliCost.stockTotalFee;

    const beforeDiscountShppingFee = isDiscountShppingFee
      ? shippingTotal * 0.2
      : 0;
    const discountShppingFee =
      this.props.i18n.language === "en"
        ? beforeDiscountShppingFee.toFixed(2)
        : Math.ceil(beforeDiscountShppingFee);

    const couponFee =
      this.props.i18n.language === "en"
        ? this.calCouponCost(this.state.coupon, stock).toFixed(2)
        : Math.ceil(this.calCouponCost(this.state.coupon, stock));

    // OUTLIER - 20240122 - 2번
    // const tax =
    //   this.props.i18n.language === "ja"
    //     ? Math.floor(Number((stockTotal - couponFee) * YEN_INCOM_TAX_PER))
    //     : 0;
    const tax = 0;

    const stockBody = {
      od: {
        pid_payment: pidPayment,
        pid_user: this.userData.user.pid_user,
        ctg_pd: 2,
        id: this.userData.user.id,
        user_name: `${this.shippingAddr.name_first} ${this.shippingAddr.name_last}`,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost_pd:
          this.props.i18n.language === "en"
            ? Number((this.state.subtotal - couponFee).toFixed(2))
            : Number(this.state.subtotal + tax - couponFee),
        cost_deli:
          this.props.i18n.language === "en"
            ? Number(
                (
                  this.deliCost.customTotalFee +
                  this.deliCost.stockTotalFee -
                  discountShppingFee
                ).toFixed(2)
              )
            : Math.floor(
                Number(
                  this.deliCost.customTotalFee +
                    this.deliCost.stockTotalFee -
                    discountShppingFee
                )
              ),
        tax,
        // uid_tracking: shortid.generate(),
        // via_delivery: 'test',
        shipping: `weight: ${stockWeight}`,
        cnt_item: stock.length,
        title: JSON.parse(stock[0].body).title,
        sender_name: senderId,
      },
      od_addr: odAddr,
      od_pd_arr: stockOdPdArr,
      od_cust_arr: odCustomArr,
    };

    try {
      // console.log('stock', stockBody);
      const res = await _Fetch(shopOdApi, "", stockBody);
      // console.log('stock', res);
      await this.postCouponUsed(stockOdPdArr, res.pd_arr, res.od);
      return res;
      // return _Fetch(shopOdApi, '', stockBody);
    } catch (e) {
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        return this.props.history.push("/login");
      }
      return e;
    }
  }

  // od_pd에 커스텀 등록
  async postCustomAccount(
    shopOdApi,
    pidPayment,
    customize,
    odAddr,
    senderId,
    coupon
  ) {
    const customWeight = customize.reduce(
      (a, b) => a + JSON.parse(b.body).weight,
      0
    );

    function subtotal(lanCode, data) {
      let cost = 0;
      data.map((item) => (cost += settingCost(lanCode, item) * item.quantity));
      return cost;
    }

    const customTotal = subtotal(this.props.i18n.language, customize);

    const settingGloveTypeCode = (gloveTypeStr) => {
      switch (gloveTypeStr) {
        case "Single Welting":
          return 1;
        case "Double Welting":
          return 2;
        case "First Baseman Mitt":
          return 3;
        case "Catchers Mitt":
          return 4;
        default:
          return 5;
      }
    };

    const odCustomArr = [];
    const customOdPdArr = customize.map((item) => {
      const itemBody = JSON.parse(item.body);
      odCustomArr.push({
        body: item.body,
        body_origin: item.body,
      });

      return {
        pid_user: this.userData.user.pid_user,
        pid_category: itemBody.pid_category,
        pid_pd: item.pid_item,
        title: itemBody.title,
        pd_uri: item.uri_pd,
        stage_uri: "none",
        quantity: item.quantity,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost: settingCost(this.props.i18n.language, item).toFixed(2),
        typ_glove: settingGloveTypeCode(itemBody.glove.gloveType),
        leather_grade: itemBody.glove.leatherGrade.id,

        pid_coupon:
          coupon &&
          getPerItemCouponCost(item, coupon, this.props.i18n.language) > 0
            ? coupon.pid_coupon
            : 0,
        uuid_coupon:
          coupon &&
          getPerItemCouponCost(item, coupon, this.props.i18n.language) > 0
            ? coupon.uuid_coupon
            : "none",
        dis_typ:
          coupon &&
          getPerItemCouponCost(item, coupon, this.props.i18n.language) > 0
            ? coupon.typ_coupon
            : 0,
        dis_value: coupon
          ? getPerItemCouponCost(item, coupon, this.props.i18n.language)
          : 0,
        typ_delivery: itemBody.typ_delivery,
      };
    });

    const qty = sumQty(this.state.data);
    const isDiscountShppingFee = qty > 3;

    const shippingTotal = this.deliCost.customTotalFee;

    const beforeDiscountShppingFee = isDiscountShppingFee
      ? shippingTotal * 0.2
      : 0;
    const discountShppingFee =
      this.props.i18n.language === "en"
        ? beforeDiscountShppingFee.toFixed(2)
        : Math.ceil(beforeDiscountShppingFee);

    const couponFee =
      this.props.i18n.language === "en"
        ? this.calCouponCost(coupon, customize).toFixed(2)
        : Math.ceil(this.calCouponCost(coupon, customize));

    //  OUTLIER - 20240122 - 2번
    // const tax =
    //   this.props.i18n.language === "ja"
    //     ? Math.floor(Number((customTotal - couponFee) * YEN_INCOM_TAX_PER))
    //     : 0;
    const tax = 0;

    const customBody = {
      od: {
        pid_payment: pidPayment,
        pid_user: this.userData.user.pid_user,
        ctg_pd: 1,
        id: this.userData.user.id,
        user_name: `${this.shippingAddr.name_first} ${this.shippingAddr.name_last}`,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost_pd:
          this.props.i18n.language === "en"
            ? Number((this.state.subtotal - couponFee).toFixed(2))
            : Number(this.state.subtotal + tax - couponFee),
        cost_deli:
          this.props.i18n.language === "en"
            ? Number(
                (
                  this.deliCost.customTotalFee +
                  this.deliCost.stockTotalFee -
                  discountShppingFee
                ).toFixed(2)
              )
            : Math.floor(
                Number(
                  this.deliCost.customTotalFee +
                    this.deliCost.stockTotalFee -
                    discountShppingFee
                )
              ),
        tax,
        // uid_tracking: shortid.generate(),
        // via_delivery: 'test',
        shipping: `weight: ${customWeight}`,
        cnt_item: customize.length,
        title: JSON.parse(customize[0].body).title,
        sender_name: senderId,
      },
      od_addr: odAddr,
      od_pd_arr: customOdPdArr,
      od_cust_arr: odCustomArr,
    };

    // console.log('custom', customBody);
    try {
      const res = await _Fetch(shopOdApi, "", customBody);
      // console.log('custom', res);
      await this.postCouponUsed(customOdPdArr, res.pd_arr, res.od);
      return res;
      // return _Fetch(shopOdApi, '', customBody);
    } catch (e) {
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        return this.props.history.push("/login");
      }
      return e;
    }
  }

  // 기성품 od_pd 에 등록
  async postStockAccount(
    shopOdApi,
    pidPayment,
    stock,
    odAddr,
    senderId,
    coupon
  ) {
    const stockWeight = stock.reduce(
      (a, b) => a + JSON.parse(b.body).weight,
      0
    );

    console.log("stock", stock);

    function subtotal(lanCode, data) {
      let cost = 0;
      data.map((item) => (cost += settingCost(lanCode, item) * item.quantity));
      return cost;
    }

    const stockTotal = subtotal(this.props.i18n.language, stock);

    const odCustomArr = [];
    const stockOdPdArr = stock.map((item) => {
      const itemBody = JSON.parse(item.body);
      // const optBody = itemBody.options.length === 0 ? JSON.stringify('none') : JSON.stringify(itemBody.options);
      odCustomArr.push({
        body: item.body,
        body_origin: item.body,
      });
      return {
        pid_user: this.userData.user.pid_user,
        pid_category: itemBody.pid_category,
        pid_pd: item.pid_item,
        title: itemBody.title,
        pd_uri: item.uri_pd,
        quantity: item.quantity,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost: settingCost(this.props.i18n.language, item).toFixed(2),

        pid_coupon:
          coupon &&
          getPerItemCouponCost(item, coupon, this.props.i18n.language) > 0
            ? coupon.pid_coupon
            : 0,
        uuid_coupon:
          coupon &&
          getPerItemCouponCost(item, coupon, this.props.i18n.language) > 0
            ? coupon.uuid_coupon
            : "none",
        dis_typ:
          coupon &&
          getPerItemCouponCost(item, coupon, this.props.i18n.language) > 0
            ? coupon.typ_coupon
            : 0,
        dis_value: coupon
          ? getPerItemCouponCost(item, coupon, this.props.i18n.language)
          : 0,

        typ_delivery: itemBody.typ_delivery,
        pid_pd_inv: itemBody.pid_pd_inv,
      };
    });

    const qty = sumQty(this.state.data);
    const isDiscountShppingFee = qty > 3;

    const shippingTotal = this.deliCost.stockTotalFee;

    const beforeDiscountShppingFee = isDiscountShppingFee
      ? shippingTotal * 0.2
      : 0;
    const discountShppingFee =
      this.props.i18n.language === "en"
        ? beforeDiscountShppingFee.toFixed(2)
        : Math.ceil(beforeDiscountShppingFee);

    const couponFee =
      this.props.i18n.language === "en"
        ? this.calCouponCost(coupon, stock).toFixed(2)
        : Math.ceil(this.calCouponCost(coupon, stock));

    // OUTLIER - 20240122 - 2번
    // const tax =
    //   this.props.i18n.language === "ja"
    //     ? Math.floor(Number((stockTotal - couponFee) * YEN_INCOM_TAX_PER))
    //     : 0;
    const tax = 0;

    const stockBody = {
      od: {
        pid_payment: pidPayment,
        pid_user: this.userData.user.pid_user,
        ctg_pd: 2,
        id: this.userData.user.id,
        user_name: `${this.shippingAddr.name_first} ${this.shippingAddr.name_last}`,
        typ_cost: settingCountryCode(this.props.i18n.language),
        cost_pd:
          this.props.i18n.language === "en"
            ? Number((this.state.subtotal - couponFee).toFixed(2))
            : Number(this.state.subtotal + tax - couponFee),
        cost_deli:
          this.props.i18n.language === "en"
            ? Number(
                (
                  this.deliCost.customTotalFee +
                  this.deliCost.stockTotalFee -
                  discountShppingFee
                ).toFixed(2)
              )
            : Math.floor(
                Number(
                  this.deliCost.customTotalFee +
                    this.deliCost.stockTotalFee -
                    discountShppingFee
                )
              ),
        tax,
        // uid_tracking: shortid.generate(),
        // via_delivery: 'test',
        shipping: `weight: ${stockWeight}`,
        cnt_item: stock.length,
        title: JSON.parse(stock[0].body).title,
        sender_name: senderId,
      },
      od_addr: odAddr,
      od_pd_arr: stockOdPdArr,
      od_cust_arr: odCustomArr,
    };

    try {
      // console.log('stock', stockBody);
      const res = await _Fetch(shopOdApi, "", stockBody);
      // console.log('stock', res);
      await this.postCouponUsed(stockOdPdArr, res.pd_arr, res.od);
      return res;
      // return _Fetch(shopOdApi, '', stockBody);
    } catch (e) {
      if (e.status === 1164) {
        this.openModal(
          "Your login information is invalid. Please login again."
        );
        setCookie("userData", "", -1);
        return this.props.history.push("/login");
      }
      return e;
    }
  }

  async onCoupon() {
    // console.log(this.couponInput.value);
    if (!this.couponInput || !this.couponInput.value) {
      return this.openModal("Please, set coupon code.");
    }

    try {
      console.log("request coupon api");
      const api = getItem("restAPI").model_desc.shop_user_search_coupon_name;
      const res = await _Fetch(api, `?str=${this.couponInput.value}`, []);
      console.log("coupon", res, res.length);

      if (res.length === 0) {
        return this.setState({ coupon: res[0] }, () =>
          this.openModal("The coupon code entered is invalid.")
        );
      }

      return this.calCouponTotalCost(res[0]);
    } catch (e) {
      console.log("err", e);
      if (e.status === 1261) {
        return alert("exceed limit coupon used");
      }
      if (e.status === 1260) {
        return alert("exceed coupon expiary time");
      }
      return alert(e.results);
    }
  }

  // coupon total Cost
  calCouponTotalCost(couponData) {
    if (!couponData) return null;

    // 아이템 마다 쿠폰 가격 저장 되어있는 배멸
    const couponTotalCostArr = this.state.data.map(
      (item) =>
        getPerItemCouponCost(item, couponData, this.props.i18n.language) *
        item.quantity
    );

    console.log("couponTotalCostArr", couponTotalCostArr);

    // 배열에 있는 값들 합산
    const couponTotalCost = couponTotalCostArr.reduce((a, b) => a + b, 0);

    console.log("couponTotalCost", couponTotalCost);

    if (couponTotalCost === 0) {
      return alert("No coupons apply.");
    }

    // console.log(couponTotalCost, couponTotalCostArr);
    return this.setState(
      {
        totalCouponCost: couponTotalCost,
        coupon: couponData,
      },
      () => this.openModal("The coupon has been applied.")
    );
  }

  calCouponCost(couponData, data) {
    if (!couponData) return 0;

    // 아이템 마다 쿠폰 가격 저장 되어있는 배멸
    const couponTotalCostArr = data.map(
      (item) =>
        getPerItemCouponCost(item, couponData, this.props.i18n.language) *
        item.quantity
    );

    // 배열에 있는 값들 합산
    const couponTotalCost = couponTotalCostArr.reduce((a, b) => a + b, 0);
    // console.log(couponTotalCost, couponTotalCostArr);
    return couponTotalCost;
  }

  renderEmpty() {
    return (
      <div>
        <p style={styles.itemBoxNotice}>There are no items in your cart.</p>
        <DivButton
          style={styles.goShoppingBtn}
          onClick={() => this.props.history.push("/shop")}
        >
          <p style={styles.goShoppingTitle}>GO SHOPPING</p>
          <img src={arrowWhite} style={styles.goShoppingArrow} alt="w_arrow" />
        </DivButton>
      </div>
    );
  }

  renderItem(data) {
    return data.map((item, i) => (
      <Item
        onCalculation={(inputQty) => this.onCalculation(item, inputQty, i)}
        quantity={item.quantity}
        coupon={this.state.coupon}
        data={item}
        onViewDtail={async () => {
          await setTimeout(() => window.scrollTo(0, 0), 1);
          this.viewDetailData = item;
          this.setState({ isViewDetailModal: true });
          // document.body.style.overflow = 'hidden';
        }}
        key={shortid.generate()}
        marginBottom={i === data.length - 1 ? 33 : 40}
        onDelete={() => this.onDelete(item, i)}
      />
    ));
  }

  renderSubTotal = (lang) => {
    switch (lang) {
      case "en":
        return returnComma(Number(this.state.subtotal).toFixed(2));
      case "ja":
        return returnComma(Number(this.state.subtotal));
      // return returnComma(Number(this.state.subtotal) + Number(this.state.subtotal) * YEN_INCOM_TAX_PER);
      default:
        return returnComma(Number(this.state.subtotal));
    }
  };

  renderTerms = () => {
    const lang = window.localStorage.getItem("i18nextLng");
    switch (lang) {
      case "en":
        return (
          <div>
            <p style={styles.footerNotice}>
              By continuing, you agree to Gloveworks’
            </p>
            <div style={styles.footerSubNotice}>
              <div
                role="button"
                tabIndex="-1"
                onClick={() => this.setState({ isModal: true })}
                style={styles.bluePointText}
              >
                Terms of Service
              </div>
              <p style={styles.normalText}>&nbsp;and&nbsp;</p>
              <div
                role="button"
                tabIndex="-1"
                onClick={() => this.setState({ isPolicyModal: true })}
                style={styles.bluePointText}
              >
                Privacy Policy
              </div>
            </div>
          </div>
        );
      case "ja":
        return (
          <div>
            <div style={styles.footerSubNoticeJp}>
              <div
                role="button"
                tabIndex="-1"
                onClick={() =>
                  this.props.i18n.language === "en"
                    ? this.setState({ isModal: true })
                    : window.open("https://www.gloveworks.net/termsOfService")
                }
                style={styles.bluePointText}
              >
                利用規約
              </div>
              <p style={styles.normalText}>と</p>
              <div
                role="button"
                tabIndex="-1"
                onClick={() =>
                  this.props.i18n.language === "en"
                    ? this.setState({ isPolicyModal: true })
                    : window.open("https://www.gloveworks.net/privacyPolicy")
                }
                style={styles.bluePointText}
              >
                個人情報保護方針
              </div>
              <p style={styles.normalText}>に同意します。</p>
            </div>
          </div>
        );
      // return returnComma(Number(this.state.subtotal) + Number(this.state.subtotal) * YEN_INCOM_TAX_PER);
      default:
        return (
          <div>
            <p style={styles.footerNotice}>주문진행을 함으로써 글러브웍스의</p>
            <div style={styles.footerSubNotice}>
              <div
                role="button"
                tabIndex="-1"
                onClick={() =>
                  this.props.i18n.language === "en"
                    ? this.setState({ isModal: true })
                    : window.open("https://www.gloveworks.net/termsOfService")
                }
                style={styles.bluePointText}
              >
                이용약관
              </div>
              <p style={styles.normalText}>과&nbsp;</p>
              <div
                role="button"
                tabIndex="-1"
                onClick={() =>
                  this.props.i18n.language === "en"
                    ? this.setState({ isPolicyModal: true })
                    : window.open("https://www.gloveworks.net/privacyPolicy")
                }
                style={styles.bluePointText}
              >
                개인정보처리방침
              </div>
            </div>
            <p style={styles.footerNoticeJp}>에 동의합니다.</p>
          </div>
        );
    }
  };

  renderSummaryBox() {
    const { t } = this.props;
    return (
      <div style={styles.summaryBox(this.state.totalCouponCost > 0)}>
        <p style={styles.summaryTitle}>SUMMARY</p>

        <div style={styles.couponInputBox}>
          {this.state.coupon ? (
            <img style={styles.couponCheckImg} src={couponCheck} alt="" />
          ) : null}
          <Input
            ref={(couponInput) => (this.couponInput = couponInput)}
            placeholder={t("cartCouponCode")}
            onChange={(value) => (this.couponInput.value = value)}
            type="text"
            style={styles.couponInputStyle(
              setCouponInputBorderColor(this.state.coupon)
            )}
            placeholderColor="#c8c8c8"
          />
        </div>

        <div style={styles.applyBox}>
          <DivButton
            style={styles.applyCouponBtn}
            onClick={() => this.onCoupon()}
          >
            {t("cartApplyCoupon")}
          </DivButton>
          <DivButton
            style={styles.closeBtn}
            onClick={() => {
              if (this.couponInput) {
                this.couponInput.value = "";
              }
              this.setState({
                coupon: null,
                totalCouponCost: 0,
              });
            }}
          >
            <img src={close} alt="" style={styles.closeImg} />
          </DivButton>
        </div>

        <div style={styles.couponNoticeTextBox}>{t("cartDesc")}</div>

        <div style={styles.underline(24)} />

        <div style={styles.costBox(16)}>
          <p style={styles.cartTotalText}>{t("cartTotal")}</p>
          <p style={styles.cartTotalText}>
            {checkedCurrencySymbol(this.props.i18n.language)}
            {this.renderSubTotal(this.props.i18n.language)}
          </p>
        </div>

        {this.state.totalCouponCost > 0 ? (
          <div style={styles.costBox(8)}>
            <p style={{ ...styles.cartTotalText, color: "#d0021b" }}>
              Coupon Discount
            </p>
            <p style={{ ...styles.cartTotalText, color: "#d0021b" }}>
              -{checkedCurrencySymbol(this.props.i18n.language)}
              {this.props.i18n.language === "en"
                ? returnComma(Number(this.state.totalCouponCost).toFixed(2))
                : returnComma(Number(this.state.totalCouponCost))}
            </p>
          </div>
        ) : null}

        <div style={styles.costBox(8)}>
          <p style={styles.totalText}>{t("cartSubTotal")}</p>
          <p style={styles.totalText}>
            {checkedCurrencySymbol(this.props.i18n.language)}
            {this.props.i18n.language === "en"
              ? returnComma(
                  (
                    Number(this.state.subtotal) - this.state.totalCouponCost
                  ).toFixed(2)
                )
              : returnComma(
                  Number(this.state.subtotal) - this.state.totalCouponCost
                )}
          </p>
        </div>

        <div style={styles.noticeBox}>
          <img alt="" style={styles.helpImg} src={info} />
          <p style={styles.noticeText}>{t("cartTaxDesc")}</p>
        </div>

        <div style={styles.underline(16)} />

        <div style={styles.policeBox}>
          <CheckInput getChecked={(value) => (this.checkArr[0] = value)} />
          {this.renderTerms()}
        </div>

        <div style={styles.policeBottomBox}>
          <CheckInput getChecked={(value) => (this.checkArr[1] = value)} />
          <p style={styles.normalBottomText}>{t("cartAgree")}</p>
        </div>

        <DivButton
          style={styles.checkoutBtnBox}
          onClick={async () => {
            //이후 그대로진행
            if (!this.checkArr[0] || !this.checkArr[1]) {
              return this.openModal("Please check all check boxes.");
            }

            if (!this.userData) return this.props.history.push("/login");
            console.log(this.state.data);
            if (
              this.state.data.length === 1 &&
              JSON.parse(this.state.data[0].body).typ_delivery === 4
            ) {
              return this.openModal("Oils can not be purchased separately.");
            }

            const oilData = this.state.data.filter(
              (item) => JSON.parse(item.body).typ_delivery === 4
            );

            if (oilData.length > 1) {
              return this.openModal("Only one kind of oil can be purchased");
            }

            if (oilData.length > 0 && oilData[0].quantity > 3) {
              return this.openModal("You can not purchase more than 3 oils.");
            }

            //결제전 세팅된 시간안에 이미 결제한 내역인지 체크
            let isExist = "false";
            const api =
              getItem("restAPI").model_desc
                .shop_user_check_previous_payment_log;
            const prepaidRes = await _Fetch(
              api,
              `?pid_user=${this.userData.user.pid_user}`,
              []
            );

            // console.log(res);
            console.log("previous check res : ", prepaidRes);
            if (prepaidRes == "true") return this.openPrepaidModal();

            if (this.userData && this.state.data.length > 0) {
              return this.setState({ step: 1 });
            }
            return this.openModal("Please include at least one item.");
          }}
        >
          <p style={styles.checkoutTitle}>{t("cartCheckOut")}</p>
          <img src={arrowWhite} style={styles.goShoppingArrow} alt="w_arrow" />
        </DivButton>
      </div>
    );
  }

  renderTermsOfServiceModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.isModal}
        contentLabel="Add New Address"
        onRequestClose={() => this.setState({ isModal: false })}
        style={AddressModalStyles}
      >
        <p style={styles.modalTitle}>Terms of Service</p>

        <div
          tabIndex="-1"
          role="button"
          onClick={() => this.setState({ isModal: false })}
          style={styles.noticeModalImg}
        >
          <img alt="" src={close} />
        </div>

        <Notice />
      </Modal>
    );
  }

  renderPolicyModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.isPolicyModal}
        contentLabel="Add New Address"
        onRequestClose={() => this.setState({ isPolicyModal: false })}
        style={AddressModalStyles}
      >
        <p style={styles.modalTitle}>Privacy Policy</p>

        <div
          tabIndex="-1"
          role="button"
          onClick={() => this.setState({ isPolicyModal: false })}
          style={styles.noticeModalImg}
        >
          <img alt="" src={close} />
        </div>

        <NoticePolicy />
      </Modal>
    );
  }

  // 결제 진행 까지 SPA로 구성
  render() {
    const { isModal2, bodyText2, isPrepaidModal } = this.state;
    if (isModal2) {
      return (
        <Modal2
          isModal={isModal2}
          bodyText={bodyText2}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
      );
    }

    if (isPrepaidModal) {
      return (
        <ConfirmModal
          isModal={isPrepaidModal}
          bodyText={this.props.t("cartCheckPrepaid")}
          leftBtnText={this.props.t("cartCheckPrepaidLeft")}
          rightBtnText={this.props.t("cartCheckPrepaidRight")}
          onClose={this.closePrepaidModal}
          onNext={this.onPrepaidNext}
          leftBtn={this.prepaidModalLeftBtn}
          rightBtn={(checkAddr, billingAddr, deliCost, shippingNode) => {
            console.log("right Btn Click");
            this.setState({
              isPrepaidModal: false,
              bodyPrepaidText: "",
              step: 1,
            });
          }}
        />
      );
    }

    if (this.state.step === 1) {
      return (
        <ShippingInfo
          itemData={this.state.data}
          onNext={(checkAddr, billingAddr, deliCost, shippingNode) => {
            this.shippingAddr = checkAddr;
            this.billingAddr = billingAddr;
            this.deliCost = deliCost;
            this.setState({ step: 2 });
            this.shippingNode = shippingNode;
          }}
        />
      );
    }
    if (this.state.step === 2) {
      return (
        <PaymentInfo
          data={this.state.data}
          couponData={this.state.coupon}
          onCheckOut={(paymentType, firstName) =>
            paymentType === 2
              ? this.onManageStateAccChkOut(firstName)
              : this.onCheckOut(paymentType)
          }
          onPreview={() => this.setState({ step: 1 })}
          shippingData={this.deliCost}
          addressData={{
            shippingAddr: this.shippingAddr,
            billingAddr: this.billingAddr,
          }}
          shippingNode={this.shippingNode}
        />
      );
    }
    // console.log(this.state.data);
    if (!this.state.data) {
      return <div style={styles.emptyContainer} />;
    }

    return (
      <div>
        {!this.viewDetailData ? null : (
          <ConfirmCartModal
            data={this.viewDetailData}
            isModal={this.state.isViewDetailModal}
            onClose={() => {
              this.viewDetailData = null;
              // document.body.style.overflow = 'auto';
              this.setState({ isViewDetailModal: false });
            }}
          />
        )}

        {this.state.isModal ? this.renderTermsOfServiceModal() : null}
        {this.state.isPolicyModal ? this.renderPolicyModal() : null}

        <Step menu={STEP_MENU} />

        <div style={styles.innerContainer}>
          <div style={styles.emptyItemBox(this.state.data.length === 0)}>
            <div
              style={styles.ItemBoxTitle}
            >{`MY CART(${this.state.data.length})`}</div>
            {this.state.data.length === 0
              ? this.renderEmpty()
              : this.renderItem(this.state.data)}
          </div>
          {this.renderSummaryBox()}
        </div>
      </div>
    );
  }
}

const AddressModalStyles = {
  content: {
    width: 960,
    height: 640,
    backgroundColor: "#fff",
    border: "solid 1px rgba(21, 41, 53, 0.1)",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 8,
  },
};

const styles = {
  emptyContainer: {
    width: "100%",
    height: 1300,
  },
  innerContainer: {
    display: "flex",
    width: 672 + 30 + 294,
    margin: "auto",
    marginTop: 32,
    marginBottom: 255,
  },
  ItemBoxTitle: {
    display: "flex",
    alignItems: "center",
    height: 70,
    fontFamily: "Roboto Condensed",
    color: "#251f1b",
    fontSize: 18,
    lineHeight: 1.39,
    letterSpacing: 1,
    fontWeight: "bold",
    marginLeft: 30,
  },
  emptyItemBox: (isItem) => ({
    width: 672,
    height: isItem ? 483 : "auto",
    backgroundColor: "#fff",
    border: "1px solid rgba(21, 41, 53, 0.1)",
    float: "left",
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
  }),
  itemBoxNotice: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    fontWeight: "600",
    color: "#251f1b",
    marginTop: 115,
    textAlign: "center",
  },
  summaryBox: (isCoupon) => ({
    width: 294,
    height: isCoupon ? 643 : 606,
    backgroundColor: "#fff",
    border: "1px solid rgba(21, 41, 53, 0.1)",
    marginLeft: 30,
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    float: "right",
  }),
  summaryTitle: {
    fontFamily: "Roboto Condensed",
    fontSize: 18,
    fontWeight: "600",
    lineHeight: 1.39,
    letterSpacing: 1,
    color: "#251f1b",
    marginTop: 24,
    marginLeft: 24,
  },
  costBox: (marginTop) => ({
    width: 246,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "auto",
    marginTop,
  }),
  totalText: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 1.39,
    letterSpacing: 1,
    color: "#251f1b",
  },
  noticeBox: {
    margin: "auto",
    marginTop: 16,
    width: 246,
    height: 52,
    borderRadius: 2,
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  helpImg: {
    width: 14,
    height: 14,
    float: "left",
    marginLeft: 13,
    marginTop: 9,
  },
  noticeText: {
    width: 200,
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 0.3,
    color: "#251f1b",
    marginLeft: 9,
    float: "left",
    marginTop: 8,
    fontFamily: "Roboto Condensed",
  },
  underline: (marginTop) => ({
    margin: "auto",
    marginTop,
    width: 246,
    height: 1,
    backgroundColor: "rgba(21, 41, 53, 0.1)",
  }),
  footerNotice: {
    margin: "auto",
    width: 246,
    fontFamily: "Roboto Condensed",
    fontSize: 12,
    lineHeight: 1.83,
    color: "#251f1b",
    textAlign: "left",
    marginTop: 16,
  },
  footerNoticeJp: {
    margin: "auto",
    width: 246,
    fontFamily: "Roboto Condensed",
    fontSize: 12,
    lineHeight: 1.83,
    color: "#251f1b",
    textAlign: "left",
  },
  bluePointText: {
    fontFamily: "Roboto Condensed",
    fontSize: 12,
    lineHeight: 1.83,
    color: "#007dff",
    textAlign: "left",
    cursor: "pointer",
  },
  normalText: {
    fontFamily: "Roboto Condensed",
    fontSize: 12,
    lineHeight: 1.83,
    color: "#251f1b",
    textAlign: "left",
  },
  footerSubNoticeJp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 246,
    marginTop: 16,
  },
  footerSubNotice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 246,
  },
  checkoutBtnBox: {
    width: 246,
    height: 40,
    backgroundColor: "#251f1b",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: 20,
    position: "relative",
  },
  applyCouponBtn: {
    width: 198,
    height: 40,
    border: "solid 1px #251f1b",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 1.43,
    letterSpacing: 1,
    color: "#251f1b",
  },
  goShoppingBtn: {
    width: 240,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
    position: "relative",
    margin: "auto",
    marginTop: 35,
  },
  goShoppingTitle: {
    fontSize: 14,
    fontWeight: "600",
    letterSpacing: 0.2,
    color: "#fff",
  },
  goShoppingArrow: {
    width: 6,
    height: 8,
    position: "absolute",
    right: 10,
    zIndex: 2,
    top: 15,
  },
  couponInputBox: {
    marginTop: 20,
    marginLeft: 24,
    width: 246,
    height: 40,
    position: "relative",
  },
  couponCheckImg: {
    position: "absolute",
    top: "50%",
    right: 10,
    transform: "translateY(-50%)",
  },
  couponInputStyle: (borderColor) => ({
    width: 246,
    height: 40,
    backgroundColor: "#f7f7f7",
    border: `solid 1px ${borderColor}`,
    boxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    MozBoxSizing: "border-box",
    padding: 10,
    color: "#251f1b",
    fontSize: 16,
    lineHeight: 1.5,
    outline: "none",
  }),
  applyBox: {
    width: 246,
    height: 40,
    margin: "auto",
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    border: "solid 1px #251f1b",
  },
  closeImg: {
    width: 16,
    height: 16,
  },
  couponNoticeTextBox: {
    margin: "auto",
    width: 246,
    marginTop: 8,
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: 11,
    lineHeight: 1.45,
  },
  cartTotalText: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1,
    color: "#251f1b",
  },
  checkoutTitle: {
    fontSize: 14,
    lineHeight: 1.36,
    letterSpacing: 0.5,
    color: "#fff",
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1.2,
    color: "#142935",
    fontFamily: "Roboto Condensed",
    marginTop: 20,
    marginLeft: 40,
  },
  noticeModalImg: {
    position: "absolute",
    top: 20,
    right: 40,
    cursor: "pointer",
  },
  policeBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 24,
  },
  policeBottomBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingLeft: 24,
    marginTop: 15,
    width: 246,
  },
  normalBottomText: {
    width: 224,
    fontFamily: "Metropolis",
    fontSize: 12,
    lineHeight: 1.67,
    letterSpacing: -0.3,
    color: "#251f1b",
  },
};

Cart.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.object,
  actions: PropTypes.object,
  // step: PropTypes.number,
  t: PropTypes.func,
};

Cart.defaultProps = {
  history: {},
  i18n: {},
  actions: null,
  // step: 2,
  t: () => {},
};

const defaultMapContextToProps = (value) => ({
  step: value.step,
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(
  withRouter(withTranslation("translations")(Cart))
);
