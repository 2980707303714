import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Input = styled.input`
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: 'Metropolis';
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: 'Metropolis';
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: 'Metropolis';
}`;

class InputBox extends PureComponent {
  render() {
    const {
      placeholder,
      style,
      placeholderColor,
      type,
      getRef,
      onChange,
      onFocus,
      onBlur,
      onKeyPress,
    } = this.props;

    return (
      <Input
        onChange={e => onChange(e.target.value)}
        style={style}
        type={type}
        placeholder={placeholder}
        color={placeholderColor}
        fontSize={style.fontSize}
        innerRef={comp => getRef(comp)}
        onFocus={e => onFocus(e)}
        onBlur={e => onBlur(e)}
        onKeyPress={e => onKeyPress(e)}
      />
    );
  }
}

InputBox.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  style: PropTypes.object,
  getRef: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
};

InputBox.defaultProps = {
  type: null,
  style: {},
  onChange: () => {},
  placeholder: '',
  placeholderColor: '',
  getRef: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyPress: () => {},
};

export default InputBox;
