import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import ColorButtons from '../ColorButtons';

const Lacing = ({
  colors,
  currentColor,
  onColorClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <ColorButtons
      title="LACING COLOR"
      className="lacing-color-wrap"
      colors={colors}
      currentColor={currentColor}
      onClick={onColorClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { lacingColor } = state.glove;
  return {
    colors: state.builder.laceColors,
    currentColor: lacingColor,
  };
};

const mapDispatchToProps = dispatch => ({
  onColorClick: color => dispatch(updateGlove({ lacingColor: color })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Lacing);
