import React from 'react';
import { connect } from 'react-redux';

import ChangeGloveTypeAlert from './alerts/ChangeGloveTypeAlert';
import ChangeLeatherGradeAlert from './alerts/ChangeLeatherGradeAlert';
import SelectPresetAlert from './alerts/SelectPresetAlert';
import LeaveBasePanelAlert from './alerts/LeaveBasePanelAlert';
import ConfirmPurchaseAlert from './alerts/ConfirmPurchaseAlert';

const Alerts = {
  CHANGE_GLOVE_TYPE: ChangeGloveTypeAlert,
  CHANGE_LEATHER_GRADE: ChangeLeatherGradeAlert,
  SELECT_PRESET: SelectPresetAlert,
  LEAVE_BASE_PANEL: LeaveBasePanelAlert,
  CONFIRM_PURCHASE: ConfirmPurchaseAlert,
};

const Alert = ({ isOpen, alertType, alertProps }) => {
  if (!isOpen) { return null; }

  const SpecificAlert = Alerts[alertType];
  return <SpecificAlert {...alertProps} />;
};

const mapStateToProps = state => state.alert;

export default connect(mapStateToProps)(Alert);
