import React from 'react';
import { connect } from 'react-redux';
import { updateGlove } from '../../actions/creators';

export default function gloveControl(Component) {
  const GloveControl = props => <Component {...props} />;

  const mapStateToProps = (state, props) => ({
    active: state.glove[props.attrName].id === props.attrValue.id,
  });

  const mapDispatchToProps = (dispatch, props) => ({
    onClick: () => dispatch(updateGlove({ [props.attrName]: props.attrValue })),
  });

  return connect(mapStateToProps, mapDispatchToProps)(GloveControl);
}
