import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import '../../common/GlobalStyles';
import { getCookie, setCookie } from '../../common/Utils';

import Dropbox from '../../components/newDropbox/Dropbox';
import LangDropdown from '../../components/dropbox/LangDropdown';
import Badge from '../../components/badge/Badge';
import { Consumer } from '../../Context';

import './NewHeader.css';

const logo = '/logo.png';
const cart = '/svg/cart.svg';
const cartEnable = '/svg/cart_enable.svg';

const MENU = t => [
  { id: 1001, item: t('headerMenuOurStory') },
  { id: 1002, item: t('headerMenuCustom') },
  { id: 1003, item: t('headerMenuShop') },
];

class NewHeader extends Component {
  constructor(props) {
    super(props);
    this.onMenu = (i, history) => {
      switch (i) {
        case 1:
          history.push('/ourStory');
          break;
        case 2:
          history.push('/customize');
          break;
        case 3:
          history.push('/shop');
          break;
        default:
      }
    };
  }

  renderMenu(history, t) {
    return MENU(t).map(({ item, id }, i) => (
      <div
        className="menu"
        style={styles.leftMenuText(history.location.pathname.split('/')[1].toLowerCase() ===
            item.replace(/\s/gi, '').toLowerCase() ||
            (i === 2 &&
              history.location.pathname.split('/')[1] === 'shopItemDetail') ||
            (i === 1 &&
              history.location.pathname.split('/')[1].toLowerCase() ===
                'customize'))}
        key={id}
        onClick={() => this.onMenu(i + 1, history)}
        role="button"
        tabIndex="0"
      >
        {item}
      </div>
    ));
  }

  render() {
    const { history, actions, t } = this.props;
    // console.log('pathname', history.location.pathname);
    return (
      <div style={styles.container(history.location.pathname === '/')}>
        <div onClick={() => history.push('/')} role="button" tabIndex="0">
          <img src={logo} style={styles.logo} alt="logo" />
        </div>

        <div style={styles.topMenu}>
          <Dropbox
            onLogin={() => history.push('/login')}
            onHome={() => {
              setCookie('userData', '', -1);
              actions.reset();
              history.push('/');
            }}
            onMypage={index => history.push('/orderHistory', { index })}
          />

          <LangDropdown />
        </div>

        <div style={styles.innerCotainer}>
          <div>{this.renderMenu(history, t)}</div>
          <div style={styles.rightBox}>
            <div
              className="menu"
              style={styles.leftMenuText(history.location.pathname.split('/')[1].toLowerCase() ===
                  'gallery')}
              role="button"
              tabIndex="0"
              onClick={() => history.push('/gallery')}
            >
              {t('headerMenuGallery')}
            </div>
            <div
              className="menu"
              style={styles.leftMenuText(history.location.pathname.split('/')[1].toLowerCase() ===
                  'ordertracking')}
              role="button"
              tabIndex="0"
              onClick={() => history.push('/orderTracking')}
            >
              {t('headerMenuOrderTracking')}
            </div>

            <div
              style={styles.leftMenuText(false)}
              role="button"
              tabIndex="0"
              onClick={() => history.push('/cart')}
            >
              <Badge />
              <img
                src={history.location.pathname === '/cart' ? cartEnable : cart}
                style={styles.cart}
                alt="cart"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: isBorder => ({
    width: 960,
    backgroundColor: '#fff',
    position: 'relative',
    margin: 'auto',
    // borderBottom: isBorder ? '2px solid #e0e0e0' : 'none',
    paddingTop: 24,
    paddingBottom: 22,
    boxSizing: 'border-box',
  }),
  rightBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topMenu: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCotainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  dropbox: {
    width: 140,
    position: 'absolute',
    backgroundColor: '#f6f6f5',
    top: 80,
    zIndex: 255,
    left: 700,
  },
  avatar: {
    width: 28,
    height: 28,
    marginLeft: 46,
    cursor: 'pointer',
  },
  cart: {
    width: 28,
    height: 28,
    marginLeft: getCookie('userData') ? 13 : 0,
    cursor: 'pointer',
  },
  glass: {
    width: 28,
    height: 28,
    marginLeft: 30,
    cursor: 'pointer',
  },
  logo: {
    width: 140,
    height: 82,
    cursor: 'pointer',
    margin: 'auto',
    display: 'block',
  },
  leftMenuText: choose => ({
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    textAlign: 'center',
    letterSpacing: 2,
    color: '#251f1b',
    margin: 0,
    marginLeft: 30,
    cursor: 'pointer',
    float: 'left',
    position: 'relative',
    fontWeight: choose ? 600 : 'normal',
  }),
};

NewHeader.propTypes = {
  history: PropTypes.object,
  actions: PropTypes.object,
  t: PropTypes.func,
};

NewHeader.defaultProps = {
  history: {},
  actions: null,
  t: () => {},
};

const defaultMapContextToProps = value => ({
  actions: value.actions,
});

export default Consumer(defaultMapContextToProps)(withRouter(withTranslation('translations')(NewHeader)));
