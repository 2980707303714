import React from 'react';
import PropTypes from 'prop-types';

export default class Dots extends React.Component {
  onClick(key) {
    return () => {
      if (typeof this.props.onClick === 'function' ||
        typeof this.props.onDotsClick === 'function') {
        const onClick = this.props.onClick || this.props.onDotsClick;
        onClick(key);
      }
    };
  }

  getDots() {
    const dots = [];
    for (let i = 0; i < this.props.length || 0; i++) {
      const style = {
        width: '2px',
        height: '2px',
        margin: '0 15px',
        borderRadius: '50%',
        display: 'inline-block',
        background: 'rgba(255, 255, 255, 0.5)',
        padding: '2px',
      };

      if (i === this.props.activeDot) {
        style.background = '#FFFFFF';
        style.width = '6px';
        style.height = '6px';
      }

      dots.push(<li
        onClick={this.onClick(i)}
        style={style}
        key={i}
      />);
    }
    return dots;
  }

  render() {
    const dots = this.getDots();
    const style = {
      zIndex: 2,
      left: '0',
      margin: '0',
      padding: '0',
      width: '100%',
      bottom: '45px',
      listStyle: 'none',
      textAlign: 'center',
      position: 'absolute',
      cursor: 'pointer',
    };
    return (
      <ul style={style}>
        {dots}
      </ul>
    );
  }
}

Dots.propTypes = {
  activeDot: PropTypes.number,
  length: PropTypes.number,
};
