import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../lib/modal';
import DivButton from '../../components/button/DivButton';

const Close = '/svg/close.svg';
// const arrowWhite = '/svg/arrow_wr.svg';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.3)';

class BaseModal extends Component {
  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.props.isModal}
        contentLabel="base modal"
        onRequestClose={this.props.onClose}
        style={styles.modal}
      >
        <DivButton
          style={styles.modalCloseImgBtn}
          onClick={this.props.onClose}
        >
          <img src={Close} alt="" />
        </DivButton>
        <p style={styles.modalTitle}>{this.props.bodyText}</p>
        <DivButton
          style={styles.modalBtn}
          onClick={this.props.onNext}
        >
          <div style={styles.modalBtnContentsBox}>
            <p style={styles.modalBtnText}>{this.props.btnText}</p>
          </div>
        </DivButton>
      </Modal>
    );
  }
}

BaseModal.propTypes = {
  onClose: PropTypes.func,
  bodyText: PropTypes.string,
  btnText: PropTypes.string,
  isModal: PropTypes.bool,
  onNext: PropTypes.func,
};

BaseModal.defaultProps = {
  onClose: () => {},
  bodyText: '',
  btnText: 'CLOSE',
  isModal: false,
  onNext: () => {},
};


const styles = {
  modal: {
    content: {
      width: 480,
      height: 260,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      padding: '0px 20px 0px 20px',
      boxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
    },
    overlay: {
      zIndex: 3,
    },
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#251f1b',
    textAlign: 'center',
    marginTop: 60,
    whiteSpace: 'pre-line',
  },
  modalBtn: {
    width: 204,
    height: 60,
    backgroundColor: '#251f1b',
    margin: 'auto',
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBtnArrow: {
    marginLeft: 16,
  },
  modalBtnText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 18,
  },
  modalBtnContentsBox: {
    height: 19,
    width: 'auto',
    display: 'flex',
  },
  modalCloseImgBtn: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
};

export default BaseModal;
