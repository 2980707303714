import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';

function coalesceNonElementChildren(children, coalesceNodes) {
  const coalescedChildren = [];

  let contiguousNonElements = [];
  Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      contiguousNonElements.push(child);
      return;
    }

    if (contiguousNonElements.length) {
      coalescedChildren.push(coalesceNodes(contiguousNonElements, coalescedChildren.length));
      contiguousNonElements = [];
    }

    coalescedChildren.push(child);
  });

  if (contiguousNonElements.length) {
    coalescedChildren.push(coalesceNodes(contiguousNonElements, coalescedChildren.length));
  }

  return coalescedChildren;
}

class HoverBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };
  }

  handleMouseHover(str) {
    if (str === 'onMouseLeave') {
      this.setState({
        isHovering: false,
      });
    } else {
      this.setState({
        isHovering: true,
      });
    }
  }

  renderGroupedChildren() {
    const { hoverBoxStyles } = this.props;

    const getChildren = coalesceNonElementChildren(this.props.children, (children, index) => (
      <p
        key={index}
        style={hoverBoxStyles.text}
      >
        {children}
      </p>
    ));

    switch (getChildren.length) {
      case 0:
        return null;
      case 1:
        return getChildren[0];
      default:
        return getChildren;
    }
  }

  render() {
    const { hoverBoxStyles } = this.props;

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={() => this.props.onClick()}
        onMouseEnter={() => this.handleMouseHover('onMouseEnter')}
        onMouseLeave={() => this.handleMouseHover('onMouseLeave')}
        style={hoverBoxStyles.container(this.state.isHovering, this.props.choose)}
      >
        {this.renderGroupedChildren()}

      </div>
    );
  }
}

HoverBox.propTypes = {
  hoverBoxStyles: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  choose: PropTypes.bool,
};

HoverBox.defaultProps = {
  hoverBoxStyles: {},
  children: {},
  onClick: () => {},
  choose: false,
};

export default HoverBox;
