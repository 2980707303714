import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const Web = ({
  types,
  currentType,
  onTypeClick,
  customPythons,
  currentCustomPython,
  onCustomPythonClick,
}) => {
  // console.log(types);
  // console.log(currentType);
  // console.log(customPythons);
  // console.log(currentCustomPython);
  return (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="WEB COLOR"
      className="web-color-wrap"
      images={customPythons}
      currentCustomImage={currentCustomPython}
      onClick={onCustomPythonClick}
    />
    <div className="web-type-wrap clearfix">
      <h4>
          WEB TYPE
        <br />
        <span className="help">Web Type marked with * is available for preview.</span>
      </h4>
      {types.map(type =>
          (<div
            key={type.id}
            className={classNames('web-button', { active: currentType && currentType.id === type.id })}
            onClick={() => onTypeClick(type)}
          >
            <div className="img_wrap">
              <img
                src={type.photo.url}
                alt=""
              />
              {type.previewAvailable &&
                <div className="preview_available">*</div>
              }
            </div>
            <div className="name">{type.name}</div>
          </div>))}
    </div>
  </div>);
};

const mapStateToProps = (state) => {
  //console.log(state.glove);
  const {
    gloveType, leatherGrade, webPython, webType,
  } = state.glove;
  // console.log(state.builder.leatherColors[leatherGrade.id]);
  // console.log(state.glove.webPython);

  return {
    customPythons: state.builder.leatherColors[leatherGrade.id],
    currentCustomPython: webPython,
    types: state.builder.webTypes[gloveType],
    currentType: webType,
  };
};

const mapDispatchToProps = dispatch => ({
  onCustomPythonClick: python => dispatch(updateGlove({ webPython: python })),
  onTypeClick: type => dispatch(updateGlove({ webType: type })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Web), 'webSide');
