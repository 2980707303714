import React from 'react';
// import classNames from 'classnames';

export default function TextInput({
  title,
  className,
  currentText,
  maxLength = '8',
  onChange,
}) {
  return (
    <div className={className}>
      <h4>{title}</h4>
      <input
        type="text"
        placeholder={`Maximum ${maxLength} characters`}
        maxLength={maxLength}
        value={currentText || ''}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
}
