import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import WithMutilRequest from '../../common/WithMutilRequest';

import { Dots, Slides, ArrowLeft, ArrowRight } from '../../lib/slide';
import YouTube from '../../lib/youtube/YouTube';
import { getItem } from '../../common/StorageUtils';
import _Fetch from '../../common/Fetches';

import Collections from '../../containers/slide/CollectionsSlide';
import HoverBox from '../../components/hoverBox/HoverBox';

const videoId = 'aa8_vWhhZbc';

const ReqData = [
  {
    query: '',
    body: [],
    api: 'collection_get',
    database: 'model_desc',
  },
];

const hoverBoxStyles = {
  container: (isHovering, isChoose) => ({
    width: '50%',
    height: '100%',
    backgroundColor: isHovering ? 'rgba(0, 0, 0, 0.4)' : 'transparent',
    opacity: isHovering ? 1 : 0,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    float: 'left',
    justifyContent: 'center',
  }),
  text: {
    margin: 0,
    fontFamily: 'Roboto',
    color: '#251f1b',
    fontSize: 14,
  },
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: null,
    };
  }

  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0), 1);
    this.initialize();
  }

  initialize = async () => {
    const api = getItem('restAPI').model_desc.banner_get;
    const res = await _Fetch(api);
    console.log(res);

    this.setState({
      banners: res,
    });
  };

  constBanners = (banners) => {
    banners.sort((a, b) => a.priority - b.priority);
    console.log('banners', banners);

    const bannerArr = banners.map((item) => {
      if (item.typ_banner === 1) {
        return (
          <div
            role="button"
            tabIndex={-1}
            key={item.pid_banner}
            style={secStyles.containerCursor(item.uri_image)}
            onClick={() => window.open(item.uri_link1)}
          />
        );
      }
      if (item.typ_banner === 2) {
        return (
          <div
            key={item.pid_banner}
            style={secStyles.container(item.uri_image)}
          >
            <div>
              <div
                style={{
                  width: '50%',
                  float: 'right',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    width: '283px',
                    fontSize: '36px',
                    fontWeight: '500',
                    lineHeight: '40px',
                  }}
                >
                  {item.title1}
                </p>
                <p
                  style={{
                    width: '333px',
                    fontSize: '18px',
                    fontWeight: '100',
                    lineHeight: '25px',
                    margin: '30px 0',
                  }}
                >
                  {item.desc1}
                </p>
                <div
                  style={{
                    width: '215px',
                    height: '53px',
                    backgroundColor: '#000',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open(item.uri_link1)}
                  role="button"
                  tabIndex={-1}
                >
                  {/* <img src={arrow} style={secStyles.img} alt="arrow" /> */}
                  <p style={{ fontSize: '14px', color: '#fff' }}>
                    {item.btn_text1}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (item.typ_banner === 3) {
        return (
          <div
            key={item.pid_banner}
            style={secStyles.container(item.uri_image)}
          >
            <div>
              <div
                style={{
                  width: '50%',
                  float: 'left',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    width: '283px',
                    fontSize: '36px',
                    fontWeight: '500',
                    lineHeight: '40px',
                  }}
                >
                  {item.title1}
                </p>
                <p
                  style={{
                    width: '333px',
                    fontSize: '18px',
                    fontWeight: '100',
                    lineHeight: '25px',
                    margin: '30px 0',
                  }}
                >
                  {item.desc1}
                </p>
                <div
                  style={{
                    width: '215px',
                    height: '53px',
                    backgroundColor: '#000',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open(item.uri_link1)}
                  role="button"
                  tabIndex={-1}
                >
                  {/* <img src={arrow} style={secStyles.img} alt="arrow" /> */}
                  <p style={{ fontSize: '14px', color: '#fff' }}>
                    {item.btn_text1}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          key={item.pid_banner}
          style={{ ...secStyles.container(item.uri_image), display: 'block' }}
        >
          <HoverBox hoverBoxStyles={hoverBoxStyles}>
            <p style={secStyles.fourTitle}>{item.title1}</p>
            <p style={secStyles.fourSubtitle}>{item.desc1}</p>
            <div
              role="button"
              tabIndex={-1}
              onClick={() => window.open(item.uri_link1)}
              style={secStyles.fourButton}
            >
              {item.btn_text1}
            </div>
          </HoverBox>
          <HoverBox hoverBoxStyles={hoverBoxStyles}>
            <p style={secStyles.fourTitle}>{item.title2}</p>
            <p style={secStyles.fourSubtitle}>{item.desc2}</p>
            <div
              role="button"
              tabIndex={-1}
              onClick={() => window.open(item.uri_link2)}
              style={secStyles.fourButton}
            >
              {item.btn_text2}
            </div>
          </HoverBox>
        </div>
      );
    });

    return bannerArr;
  };

  render() {
    const {
      history, data, t, i18n,
    } = this.props;
    const { banners } = this.state;

    if (data === null || !banners) {
      return <div style={styles.containerEmpty} />;
    }

    return (
      <div style={styles.container}>
        <div style={{ width: 960, margin: 'auto' }}>
          <Slides
            dots={Dots}
            width="100%"
            height="626px"
            delay={5000}
            duration={1600}
            autoplay
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
          >
            {this.constBanners(banners)}
          </Slides>
        </div>

        <div style={styles.haedline}>
          <div style={styles.bigImage}>
            <YouTube videoId={videoId} opts={styles.youtube} />
          </div>
          <div style={styles.headlineRightBox}>
            <p style={styles.headlineTitle}>From Our Hands to Yours</p>
            <div style={styles.underline} />
            <p style={{ ...styles.headlineContext, lineHeight: i18n.language === 'en' ? 1.57 : 1.8 }}>
              {t('mainYours')}
            </p>
          </div>
        </div>

        <Collections
          data={data.collection_get.filter(item => item.pid_category !== -1)}
          onClick={id => history.push(`/shop/${id}`)}
        />

        <div style={{ height: 250 }} />
      </div>
    );
  }
}

Main.propTypes = {
  history: PropTypes.object,
  data: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

Main.defaultProps = {
  history: {},
  data: null,
  t: () => {},
  i18n: {},
};

const secStyles = {
  container: uri => ({
    backgroundColor: '#fff',
    position: 'relative',
    backgroundSize: '100% auto',
    backgroundImage: `url(${uri})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  containerCursor: uri => ({
    backgroundColor: '#fff',
    position: 'relative',
    backgroundSize: '100% auto',
    backgroundImage: `url(${uri})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
  title: {
    fontFamily: 'Roboto',
    fontWeight: '600',
    fontSize: '30px',
    color: '#fff',
    margin: 0,
    textAlign: 'right',
    marginLeft: 634,
    width: 284,
  },
  btnText: {
    fontFamily: 'Roboto',
    fontWeight: '600',
    color: '#000',
    fontSize: 14,
    textAlign: 'center',
  },
  btnText2: {
    fontFamily: 'Roboto',
    fontWeight: '600',
    color: '#000',
    fontSize: 18,
    textAlign: 'center',
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#fff',
    margin: 0,
    textAlign: 'right',
    marginLeft: 618,
    marginTop: 16,
    width: 302,
    lineHeight: 'normal',
  },
  button: {
    marginTop: 30,
    width: 216,
    height: 48,
    backgroundColor: '#edd167',
    marginLeft: 704,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    width: 6,
    height: 8,
    position: 'absolute',
    right: 16,
    zIndex: 255,
  },
  threeTitle: {
    fontFamily: 'Roboto',
    marginTop: 120,
    marginLeft: 40,
    width: 284,
    fontSize: 30,
    fontWeight: '600',
    color: '#251f1b',
  },
  threeSubtitle: {
    fontFamily: 'Roboto',
    width: 302,
    marginLeft: 40,
    marginTop: 16,
    color: '#251f1b',
    lineHeight: 'normal',
  },
  threeButton: {
    fontFamily: 'Roboto',
    marginTop: 24,
    width: 216,
    height: 48,
    backgroundColor: '#edd167',
    marginLeft: 40,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fourTitle: {
    fontSize: 30,
    fontWeight: '600',
    color: '#fff',
  },
  fourSubtitle: {
    marginTop: 20,
    fontSize: 18,
    color: '#fff',
  },
  fourButton: {
    marginTop: 40,
    width: 216,
    height: 48,
    backgroundColor: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: '600',
    color: '#fff',
    lineHeight: 1.33,
  },
};

const styles = {
  containerEmpty: {
    width: '100%',
    height: 1300,
  },
  container: {
    backgroundColor: '#fff',
  },
  haedline: {
    width: 320 + 636 + 40,
    margin: 'auto',
    marginTop: 80,
    height: 360,
  },
  bigImage: {
    float: 'left',
  },
  headlineRightBox: {
    width: 320,
    height: '100%',
    marginLeft: 40,
    float: 'left',
  },
  headlineTitle: {
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 1.2,
    color: '#251f1b',
  },
  underline: {
    marginTop: 10,
    width: 72,
    height: 1,
    backgroundColor: '#251f1b',
  },
  headlineContext: {
    marginTop: 25,
    width: 285,
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.57,
    textAlign: 'left',
    color: '#251f1b',
    fontWeight: '300',
  },
  ourStoryBox: {
    wide: 996,
    marginTop: 142,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logoImg: {
    width: 132,
    height: 76,
  },
  ourStoryTitle: {
    fontSize: 24,
    textAlign: 'center',
    marginTop: 40,
    fontWeight: '600',
    letterSpacing: 0.4,
    color: '#152935',
  },
  ourStoryContenrsBox: {
    width: 636,
    margin: 'auto',
    marginTop: 40,
    fontSize: 15,
    color: '#152935',
    letterSpacing: 0.4,
    fontFamily: 'Roboto',
    fontWeight: '300',
  },
  readMoreText: {
    fontSize: 15,
    fontWeight: '600',
    color: '#edd167',
    letterSpacing: 0.4,
    cursor: 'pointer',
    outline: 'none',
  },
  ourImg: {
    width: 240,
    height: 240,
    backgroundColor: 'brown',
  },
  ourImgBox: {
    display: 'flex',
    width: 240 * 4,
    margin: 'auto',
    marginTop: 64,
    marginBottom: 230,
  },
  youtube: {
    height: '360',
    width: '636',
  },
};

export default withRouter(withTranslation('translations')(WithMutilRequest(ReqData)(Main)));
