import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const MiddleFinger = ({
  customPythons,
  insideCurrentCustomPython,
  onInsideCustomPythonClick,
  outsideCurrentCustomPython,
  onOutsideCustomPythonClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="MIDDLE FINGER INSIDE COLOR"
      className="finger-inside-color-wrap"
      images={customPythons}
      currentCustomImage={insideCurrentCustomPython}
      onClick={onInsideCustomPythonClick}
    />
    <CustomPythonButtons
      title="MIDDLE FINGER OUTSIDE COLOR"
      className="finger-outside-color-wrap"
      images={customPythons}
      currentCustomImage={outsideCurrentCustomPython}
      onClick={onOutsideCustomPythonClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { middleInsidePython, middleOutsidePython } = state.glove;

  return {
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    insideCurrentCustomPython: middleInsidePython,
    outsideCurrentCustomPython: middleOutsidePython,
  };
};

const mapDispatchToProps = dispatch => ({
  onInsideCustomPythonClick: python => dispatch(updateGlove({ middleInsidePython: python })),
  onOutsideCustomPythonClick: python => dispatch(updateGlove({ middleOutsidePython: python })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MiddleFinger), 'fingerSide');
