import React from 'react';
// import classNames from 'classnames';

import TextInput from './TextInput';
import FontButtons from './FontButtons';
import ColorButtons from './ColorButtons';

export default function TextOptions({
  className,
  textName,
  currentText,
  onTextChange,
  maxLength,
  currentFont,
  onFontClick,
  colors,
  currentColor,
  onColorClick,
}) {
  return (
    <div className={className}>
      <TextInput
        title={`TYPE YOUR ${textName}`}
        className="input-wrap"
        currentText={currentText}
        maxLength={maxLength}
        onChange={onTextChange}
      />
      <FontButtons
        title={`${textName} FONT`}
        className="font-wrap clearfix"
        currentFont={currentFont}
        onClick={font => onFontClick(font)}
      />
      <ColorButtons
        title={`${textName} COLOR`}
        className="text-color-wrap"
        colors={colors}
        currentColor={currentColor}
        onClick={color => onColorClick(color)}
      />
    </div>
  );
}
