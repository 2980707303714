import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { updateGlove } from '../../actions/creators';
import TextOptions from '../TextOptions';

import gloveControl from '../higher-order/GloveControl';

import { checkedCurrencySymbol, returnComma } from '../../../../common/Utils';

const LiningPersonalizationTypeButton = gloveControl(({
  active,
  onClick,
  children,
}) => (
  <div
    className={classNames('glove-personalization-type-button clearfix', { active })}
    onClick={onClick}
  >
    {children}
  </div>
));

const LiningPersonalize = ({
  liningTypes,
  currentType,
  currentText,
  onTextChange,
  currentFont,
  onFontClick,
  textColors,
  currentTextColor,
  onTextColorClick,
  i18n,
}) => {
  let fontSize;
  if (currentText) {
    if (currentText.length < 3) {
      fontSize = '50px';
    } else if (currentText.length < 5) {
      fontSize = '34px';
    } else if (currentText.length < 7) {
      fontSize = '23px';
    } else if (currentText.length < 9) {
      fontSize = '15px';
    }
  }

  return (
    <div className="step-item-view-wrap pull-right">
      <div className="personalization-preview-wrap">
        <h4>LINING PREVIEW</h4>
        <div className="personalization-preview lining-personalization-preview">
          <img
            className="background"
            alt="Personalization preview"
            src="/personalization-preview.png"
          />
          <div className="drawArea">
            <p
              className={classNames('lining-text', currentFont.className)}
              style={{ color: currentTextColor.hexCode, fontSize }}
            >
              {currentText}
            </p>
          </div>
        </div>
      </div>
      <div className="personalization-type-wrap">
        <h4>LINING EMBROIDERY TYPE</h4>
        {liningTypes.map(type =>
          (<LiningPersonalizationTypeButton key={type.id} attrName="liningPersonalizationType" attrValue={type}>
            <div className="pull-left">{type.name}</div>
            {type.price > 0 &&
              <div className="price pull-right">{checkedCurrencySymbol(i18n.language) + returnComma(parseInt(type.price, 10))}</div>
            }
           </LiningPersonalizationTypeButton>))}
      </div>
      <div className="personalization-type-view-wrap">
        {currentType.name === 'TEXT' &&
          <TextOptions
            className="glove-personalization-type-view"
            textName="LINING TEXT"
            currentText={currentText}
            onTextChange={onTextChange}
            maxLength="8"
            currentFont={currentFont}
            onFontClick={onFontClick}
            colors={textColors}
            currentColor={currentTextColor}
            onColorClick={onTextColorClick}
          />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    liningPersonalizationType,
    liningPersonalizationText,
    liningPersonalizationFont,
    liningPersonalizationTextColor,
  } = state.glove;

  return {
    liningTypes: state.builder.liningPersonalizationTypes,
    currentType: liningPersonalizationType,
    currentText: liningPersonalizationText,
    currentFont: liningPersonalizationFont || { id: 1, fontClass: 'HarlowSolidSBOP-Regular' },
    textColors: state.builder.embroideryColors,
    currentTextColor: liningPersonalizationTextColor,
  };
};

const mapDispatchToProps = dispatch => ({
  onTextChange: text => dispatch(updateGlove({ liningPersonalizationText: text })),
  onFontClick: font => dispatch(updateGlove({ liningPersonalizationFont: font })),
  onTextColorClick: color => dispatch(updateGlove({ liningPersonalizationTextColor: color })),
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiningPersonalize));
