import React from 'react';

import TotalPrice from './TotalPrice';
import Preview from './Preview';
import Presets from './Presets';
import { withTranslation } from 'react-i18next';

const Overview = ({t}) => {
  return (
    <div className="custom-glove-preview-wrap">
      <TotalPrice />
      <Preview />
      <Presets />
      <p
        style={{
          width: 940,
          margin: 'auto',
          marginTop: 56,
          color: 'red',
        }}
      >
        <strong
          role="button"
          tabIndex="-1"
          onClick={() =>
            window.open('https://docs.google.com/document/d/19uq7oipcMk04d46Ggw6E3bMc1lMHdmZ_K7flQjLdIbE')
          }
          style={{
            fontWeight: 'bold',
            color: 'blue',
            fontStyle: 'italic',
            cursor: 'pointer',
          }}
        >
          {t('findForMe')}
        </strong>{' '}
      </p>

      <p
        style={{
          width: 940,
          margin: 'auto',
          marginTop: 10,
          color: 'red',
        }}
      >
        <strong
          role="button"
          tabIndex="-1"
          onClick={() =>
            window.open('https://www.dropbox.com/s/ypnn3wif1btdnrq/OPTION%20LIST.xlsx?dl=0')
          }
          style={{
            fontWeight: 'bold',
            color: 'blue',
            fontStyle: 'italic',
            cursor: 'pointer',
          }}
        >
          {t('builderProvides')}
        </strong>{' '}
      </p>
    </div>
  );
}

export default (withTranslation()(Overview));