import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import shortid from "shortid";

import { getCookie, getCostSymbol, returnComma } from "../../common/Utils";
import _Fetch from "../../common/Fetches";
import { getItem } from "../../common/StorageUtils";

import Step from "../../components/step/Step";
import DivButton from "../../components/button/DivButton";
import Pagination from "../../lib/pagination/Pagination";

import AddressBook from "./AddressBook";
import OrderHistoryDetail from "./OrderHistoryDetail";
import MyAccount from "./MyAccount";

const PER_PAGE = 7;

const STEP_MENU = ["Order History"];
const STEP_MENU_ANOTHER_TYPE = ["Address Book"];

const SIDE_MENU = [
  { id: 2001, title: "Order History" },
  { id: 2002, title: "Address Book" },
  { id: 2003, title: "My Account" },
];

const arrowWhite = "/svg/arrow_wr.svg";
const front = "/svg/front.svg";
const end = "/svg/end.svg";
const next = "/svg/next.svg";
const pre = "/svg/pre.svg";

/**
 * @param {number} status 1. received, 2. in propduct, 3. on Delivery, 4. delivered 5.'REFUNDED'
 */
function orderHistoryStatusText(status) {
  console.log("orderHistoryStatusText", status, typeof status);
  if (status === 5) return "Unpaid";

  if (status === 10) return "Paid";

  // OUTLIER - 240201 - additional request 1
  if (status === 11) return "Confirmed";

  if (status === 20) return "In Embroidery";

  if (status === 30) return "In Production";

  if (status === 40) return "On Delivery";

  if (status === 50) return "Delivered";

  if (status === 100 || status === 105) return "Refunded";

  return "ERROR";
}

function renderEmptyOrderHistory(history) {
  return (
    <div style={styles.contents}>
      <p style={styles.contentsTitle}>Order History</p>
      <p style={styles.emptyNoitce}>You haven&apos;t placed any orders yet.</p>
      <DivButton onClick={() => history.push("/shop")} style={styles.btn}>
        <img src={arrowWhite} style={styles.btnArrow} alt="w_arrow" />
        <p style={styles.btnText}>GO SHOPPING</p>
      </DivButton>
    </div>
  );
}

function productName(name) {
  const isCustom = name === "Customize";

  if (isCustom) return "custom";

  return name;
}

class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabIndex:
        typeof props.location.state === "undefined"
          ? 1
          : props.location.state.index,
      activePage: 1,
      isOrderDetail: false,
      data: null,
      seq: 0,
    };

    // 유저 데이터 담을 변수
    this.userData = null;

    // 페이지네이션을 위한 변수
    this.maxLength = 0;

    this.selectOrderItem = null;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tabIndex: nextProps.location.state.index,
      isOrderDetail: false,
    });
  }

  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0), 1);
    document.body.style.backgroundColor = "#fafafa";
    this.initialize();
  }

  async initialize() {
    try {
      this.userData =
        getCookie("userData") === "" ? null : JSON.parse(getCookie("userData"));
      console.log(this.userData);
      const api = getItem("restAPI").model_desc.od_get_sequser;
      const res = await _Fetch(
        api,
        `?seq=${this.state.seq}&interval=7&pid_user=${this.userData.user.pid_user}`,
        []
      );
      console.log("odData", res);

      const cntApi = getItem("restAPI").model_desc.od_count_my;
      const cntRes = await _Fetch(
        cntApi,
        `?pid_user=${this.userData.user.pid_user}`,
        []
      );

      // console.log(cntRes);
      const maxLenghtArr = Object.values(cntRes[0]);
      const maxLenght = maxLenghtArr[0];
      // console.log(maxLenght);
      this.maxLength = maxLenght;

      this.setState({
        data: res,
      });
    } catch (e) {
      console.log(e);
    }
  }

  nextPageData(activePage) {
    this.setState(
      {
        seq: activePage - 1,
        activePage,
      },
      async () => {
        try {
          const api = getItem("restAPI").model_desc.od_get_sequser;
          const res = await _Fetch(
            api,
            `?seq=${this.state.seq}&interval=7&pid_user=${this.userData.user.pid_user}`,
            []
          );
          console.log(res);

          this.setState({
            data: res,
          });
        } catch (e) {
          console.log(e);
        }
      }
    );
  }

  renderSideMenu(focus) {
    return SIDE_MENU.map((item, i) => (
      <div
        role="button"
        tabIndex={i}
        onClick={() =>
          this.setState(
            {
              tabIndex: i + 1,
              seq: 0,
              activePage: 1,
              isOrderDetail: false,
            },
            () => (i === 0 ? this.initialize() : null)
          )
        }
        key={item.id}
        style={styles.sideMenuRow(i + 1 === focus)}
      >
        <div style={styles.sideMenuFocus(i + 1 === focus)} />
        {item.title}
      </div>
    ));
  }

  renderBoard(activePage) {
    console.log(this.state.data);
    return (
      <div style={styles.contents}>
        <p style={styles.contentsTitle}>Order History</p>

        <table style={styles.table}>
          <thead style={styles.thead}>
            <tr style={styles.theadTr}>
              <th style={styles.theadTh}>ORDER No.</th>
              <th style={styles.theadTh}>ORDER Date.</th>
              <th style={styles.theadTh}>PRODUCT</th>
              <th style={styles.theadTh}>TOTAL</th>
              <th style={styles.theadTh}>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item, i) => (
              <tr
                key={shortid.generate()}
                role="button"
                tabIndex={i}
                onClick={() => {
                  this.selectOrderItem = item;
                  this.setState({ isOrderDetail: true });
                }}
                style={styles.tbodyTr}
              >
                <th style={styles.theadTh}>{item.pid_od}</th>
                <th style={styles.theadTh}>
                  {item.date_creation.split("T")[0]}
                </th>
                <th style={styles.tbodyThItemTitle}>
                  {productName(item.title)}
                </th>
                {/* 여기서도 택배비 할인이나 쿠폰 할인을 적용한 금액을 어떻게 보여줄건가에 대한 공식 추가 필요 */}
                <th style={styles.theadTh}>
                  {getCostSymbol(item.typ_cost)}
                  {item.typ_cost === 1
                    ? returnComma(
                        parseFloat(item.cost_pd.toFixed(2)) +
                          parseFloat(item.cost_deli.toFixed(2))
                      )
                    : returnComma(
                        parseFloat(item.cost_pd) + parseFloat(item.cost_deli)
                      )}
                </th>
                <th style={styles.theadTh}>
                  {console.log("item", item)}
                  {orderHistoryStatusText(item.stt_order)}
                </th>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          firstPageText={<img src={front} alt="first" />}
          lastPageText={<img src={end} alt="last" />}
          nextPageText={<img src={next} alt="next" />}
          prevPageText={<img src={pre} alt="pre" />}
          firstStyle={styles.paginationItem(0, 6)}
          lastStyle={styles.paginationItem(6)}
          nextStyle={styles.paginationItem(24)}
          preStyle={styles.paginationItem()}
          pageStyle={styles.paginationItem(24)}
          activePage={activePage}
          itemsCountPerPage={PER_PAGE}
          totalItemsCount={this.maxLength}
          onChange={(index) => this.nextPageData(index)}
        />
      </div>
    );
  }

  renderOrderHistoryContents(length) {
    if (!this.state.data) return null;
    if (length === 0) return renderEmptyOrderHistory(this.props.history);
    if (!this.state.isOrderDetail) {
      return this.renderBoard(this.state.activePage);
    }

    return (
      <OrderHistoryDetail
        data={this.selectOrderItem}
        onBack={() => this.setState({ isOrderDetail: false })}
      />
    );
  }

  renderBodyContents() {
    if (this.state.tabIndex === 1) {
      return this.renderOrderHistoryContents(this.maxLength);
    }
    if (this.state.tabIndex === 2) return <AddressBook />;
    return <MyAccount />;
  }

  render() {
    return (
      <div>
        <Step
          menu={this.state.tabIndex === 1 ? STEP_MENU : STEP_MENU_ANOTHER_TYPE}
        />
        <div style={styles.container(this.state.isOrderDetail)}>
          <div style={styles.body}>
            <div style={styles.sideMenu}>
              {this.renderSideMenu(this.state.tabIndex)}
            </div>
            {this.renderBodyContents()}
          </div>
        </div>
      </div>
    );
  }
}

OrderHistory.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

OrderHistory.defaultProps = {
  location: {},
  history: null,
};

const styles = {
  container: (isOrderDetail) => ({
    margin: "auto",
    marginTop: 37,
    width: 996,
    minHeight: 760,
    backgroundColor: isOrderDetail ? "transparent" : "#fff",
    border: isOrderDetail ? "none" : "solid 1px rgba(21, 41, 53, 0.1)",
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    paddingBottom: 40,
    marginBottom: 209,
  }),
  title: {
    marginTop: 30,
    marginLeft: 20,
    fontFamily: "Roboto Condensed",
    fontWeight: "600",
    color: "#f0c72c",
  },
  body: {
    display: "flex",
  },
  sideMenu: {
    width: 152,
    minHeight: 556,
    borderRight: "1px solid #d8d8d8",
    marginTop: 30,
    marginLeft: 20,
  },
  sideMenuRow: (isFocus) => ({
    width: 152,
    height: 25,
    display: "flex",
    alignItems: "center",
    fontFamily: "Roboto Condensed",
    fontWeight: isFocus ? "bold" : "normal",
    fontSize: "14px",
    color: "#251f1b",
    marginBottom: 15,
    cursor: "pointer",
  }),
  sideMenuFocus: (isFocus) => ({
    width: 2,
    height: 25,
    backgroundColor: isFocus ? "#edd167" : "transparent",
    marginRight: 12,
  }),
  contents: {
    marginTop: 30,
    marginLeft: 45,
  },
  contentsTitle: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    color: "#251f1b",
  },
  emptyNoitce: {
    fontFamily: "Roboto Condensed",
    fontWeight: "600",
    fontSize: 16,
    marginTop: 79,
    marginLeft: 298 - 45,
  },
  btn: {
    width: 240,
    height: 40,
    backgroundColor: "#000",
    marginLeft: 313 - 45,
    marginTop: 35,
    cursor: "pointer",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btnArrow: {
    position: "absolute",
    right: 16,
    zIndex: 255,
    margin: 0,
  },
  btnText: {
    fontFamily: "Roboto Condensed",
    fontWeight: "600",
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
  },
  table: {
    width: 740,
    marginTop: 64,
  },
  thead: {
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    borderBottom: "solid 1px #251f1b",
    borderTop: "solid 1px #cccccc",
  },
  theadTr: {
    height: 40,
  },
  theadTh: {
    display: "table-cell",
    verticalAlign: "middle",
    color: "#251f1b",
    fontSize: 12,
  },
  tbodyTr: {
    height: 56,
    cursor: "pointer",
    boxSizing: "border-box",
    MozBoxSizing: "border-box",
    WebkitBoxSizing: "border-box",
    borderBottom: "solid 1px #ccc",
    outline: 0,
  },
  tbodyThItemTitle: {
    display: "table-cell",
    verticalAlign: "middle",
    color: "#251f1b",
    fontSize: 12,
    fontWeight: "600",
  },
  paginationItem: (marginLeft = 0, marginRight = 0) => ({
    marginRight,
    marginLeft,
    cursor: "pointer",
  }),
};

export default withRouter(OrderHistory);
