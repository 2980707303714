import React from 'react';
import { connect } from 'react-redux';

import Sidebar from './Sidebar';
import Overview from './Overview';
import Alert from './Alert';
import '../assets/stylesheets/style.scss';

class Application extends React.Component {
  render() {
    const { isAlertOpen ,t} = this.props;
    return (
      <div className="application clearfix" id="custom-glove">
        <Overview />
        <Sidebar />
        <Alert />
        <div className="custom-glove-popup-background" style={{ display: isAlertOpen ? 'block' : 'none' }} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAlertOpen: state.alert.isOpen,
});

export default connect(mapStateToProps)(Application);
