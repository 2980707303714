import React, { Component } from 'react';
import shortid from 'shortid';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getItem } from '../../common/StorageUtils';
import { getCookie, setCookie } from '../../common/Utils';
import _Fetch from '../../common/Fetches';

import Modal from '../../containers/baseModal/BaseModal';

import DivButton from '../../components/button/DivButton';
import AddressForm from '../../containers/form/AddressForm';

class AddressBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isAddAddress: false,
      isModal: false,
      bodyText: '',
    };

    this.userData = null;

    // 업데이트 할 주소 목록을 모달 주소 폼에 전달하기위히 주소값을 담을 변수.
    this.updateData = null;

    this.onNext = this.closeModal;
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isAddAddress: false,
    });
  }

  openModal = (bodyText) => {
    this.setState({
      isModal: true,
      bodyText,
    });
  };

  closeModal = () => {
    this.setState({
      isModal: false,
      bodyText: '',
    });
  };

  async initialize() {
    this.userData = JSON.parse(getCookie('userData'));
    try {
      const api = getItem('restAPI').model_desc.user_addr_get_my;
      const res = await _Fetch(
        api,
        `?pid_user=${this.userData.user.pid_user}`,
        [],
      );

      const sortCtg = (a, b) => {
        if (a.b_base < b.b_base) {
          return 1;
        }
        if (a.b_base > b.b_base) {
          return -1;
        }
        return 0;
      };

      this.setState({
        data: res.sort((a, b) => sortCtg(a, b)),
        isAddAddress: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async onSaveAddress(item) {
    try {
      const api = getItem('restAPI').model_desc.user_addr_insert;
      const body = {
        user_addr: {
          ...item,
          pid_user: this.userData.user.pid_user,
        },
      };
      await _Fetch(api, '', body);
      this.initDefaultAddress(item);
      this.initialize();
    } catch (e) {
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
    }
  }

  async initDefaultAddress(item) {
    try {
      if (item.b_base === 1 && this.state.data.length > 0) {
        const idx = this.state.data.findIndex(x => x.b_base === item.b_base);
        if (idx === -1) {
          console.log(idx);
          return null;
        }
        console.log(this.state.data[idx]);
        const putApi = getItem('restAPI').model_desc.user_addr_update;
        const putBody = {
          user_addr: {
            b_base: 0,
            pid_user: this.userData.user.pid_user,
            pid_addr: this.state.data[idx].pid_addr,
          },
        };
        return await _Fetch(putApi, '', putBody);
      }
      return null;
    } catch (e) {
      return console.log(e);
    }
  }

  async updateAdress(item) {
    console.log(item);
    try {
      const api = getItem('restAPI').model_desc.user_addr_update;
      const body = {
        user_addr: {
          ...item,
          pid_user: this.userData.user.pid_user,
        },
      };
      console.log(body);
      await _Fetch(api, '', body);
      this.initDefaultAddress(item);
      this.initialize();
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
    }
  }

  async onDeleteAddres(item) {
    try {
      const api = getItem('restAPI').model_desc.user_addr_delete;
      const body = {
        user_addr: {
          pid_addr: item.pid_addr,
          pid_user: this.userData.user.pid_user,
        },
      };
      console.log(body);
      await _Fetch(api, '', body);
      this.initialize();
    } catch (e) {
      console.log(e);
      if (e.status === 1164) {
        this.openModal('Your login information is invalid. Please login again.');
        setCookie('userData', '', -1);
        this.props.history.push('/login');
      }
    }
  }

  renderAddressRow(item) {
    return (
      <div key={shortid.generate()} style={addressRowStyles.container}>
        <p style={addressRowStyles.addressText}>
          {item.addr_etc === 'none' || !item.addr_etc
            ? null
            : `${item.addr_etc}, `}
          {`${item.addr}, ${item.city}, ${item.state}, ${item.country}`}
          <br />
          Zip : {item.zipcode}
          <br />
        </p>

        <div style={addressRowStyles.btnBox}>
          <DivButton
            style={addressRowStyles.btn()}
            onClick={() => {
              this.updateData = item;
              this.setState({ isAddAddress: true });
            }}
          >
            Edit
          </DivButton>
          <DivButton
            style={addressRowStyles.btn(17)}
            onClick={() => this.onDeleteAddres(item)}
          >
            Delete
          </DivButton>
        </div>
      </div>
    );
  }

  renderEmpty() {
    return (
      <div style={styles.contents}>
        <p style={styles.contentsTitle}>Address Book</p>
        <p style={styles.emptyNoitce}>
          There are currently no saved addresses.
        </p>
        <DivButton
          onClick={() => {
            this.updateData = null;
            this.setState({ isAddAddress: true });
          }}
          style={styles.btn}
        >
          + &nbsp;&nbsp;ADD NEW ADDRESS
        </DivButton>
      </div>
    );
  }

  renderBody() {
    if (!this.state.isAddAddress) {
      return (
        <div>
          {this.state.data.length > 4 ? (
            <div style={addressRowStyles.emptyBtn} />
          ) : (
            <DivButton
              onClick={() => {
                this.updateData = null;
                this.setState({ isAddAddress: true });
              }}
              style={addressRowStyles.addBtn}
            >
              + &nbsp;&nbsp;ADD NEW ADDRESS
            </DivButton>
          )}
          {this.state.data.map((item, i) => this.renderAddressRow(item))}
        </div>
      );
    } else if (!this.state.isAddAddress && this.state.data < 1) {
      return this.renderEmpty(this);
    }
    return (
      <AddressForm
        onUpdate={item => this.updateAdress(item)}
        data={this.updateData}
        onSave={item => this.onSaveAddress(item)}
        style={styles.addressForm}
        onCancel={() => this.setState({ isAddAddress: false })}
      />
    );
  }

  render() {
    const { isModal, bodyText } = this.state;
    if (!this.state.data) return null;
    console.log(this.state.data);
    return (
      <div>
        <Modal
          isModal={isModal}
          bodyText={bodyText}
          onClose={this.closeModal}
          onNext={this.onNext}
        />
        {this.renderBody()}
      </div>
    );
  }
}

AddressBook.propTypes = {
  history: PropTypes.object,
};

AddressBook.defaultProps = {
  history: {},
};

const addressRowStyles = {
  container: {
    width: 718,
    height: 100,
    border: 'solid 1px #251f1b',
    position: 'relative',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    padding: '20px 30px 20px 30px',
    marginBottom: 20,
    marginLeft: 45,
  },
  addressText: {
    color: '#251f1b',
    fontSize: 14,
    lineHeight: 1.43,
  },
  btnBox: {
    width: 25 + 17 + 41,
    position: 'absolute',
    top: 10,
    right: 20,
    display: 'flex',
    justifyContent: 'between-space',
  },
  btn: marginLeft => ({
    fontSize: 13,
    color: '#251f1b',
    marginLeft,
    textDecoration: 'underline',
  }),
  addBtn: {
    width: 240,
    height: 40,
    backgroundColor: '#000',
    marginLeft: 523,
    marginTop: 30,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 16,
  },
  emptyBtn: {
    width: 240,
    height: 40,
    backgroundColor: 'transparent',
    marginLeft: 523,
    marginTop: 14,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 16,
  },
};

const styles = {
  contents: {
    marginTop: 31,
    marginLeft: 45,
  },
  contentsTitle: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    color: '#251f1b',
  },
  emptyNoitce: {
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    fontSize: 16,
    marginTop: 79,
    marginLeft: 255 - 45,
  },
  btn: {
    width: 240,
    height: 40,
    backgroundColor: '#000',
    marginLeft: 293 - 45,
    marginTop: 35,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto Condensed',
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
  },
  addressForm: {
    marginTop: 56,
    marginLeft: 45,
  },
};

export default withRouter(AddressBook);
