/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Step from '../../components/step/Step';
import EnFaq from './EnFaq';
import KoFaq from './KoFaq';
import JaFaq from './JaFaq';

const STEP_MENU = ['FAQ'];

class Faq extends Component {
  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  render() {
    const { i18n } = this.props;
    return (
      <div>
        <Step menu={STEP_MENU} />
        {i18n.language === 'ko' && <KoFaq {...this.props} />}
        {i18n.language === 'en' && <EnFaq {...this.props} />}
        {i18n.language === 'ja' && <JaFaq {...this.props} />}
      </div>
    );
  }
}

Faq.propTypes = {
  history: PropTypes.object,
  i18n: PropTypes.object,
};

Faq.defaultProps = {
  history: {},
  i18n: {},
};

export default withTranslation('translations')(Faq);
