/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import PropTypes from "prop-types";

class EnFaq extends Component {
  render() {
    const { history } = this.props;

    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>FAQS</p>

          <p style={styles.subTitle(64)}>[1] GLOVE</p>

          <p style={styles.question(10)}>
            1. Can I make any special requests that are not mentioned is the
            glove builder?
          </p>
          <p style={styles.descrpiotn(10)}>
            You can make any special requests you would like in the Additional
            Request text box. (Please note: special requests may effect the cost
            of the order, and is subject to availability.)
            <br />
            <br />
            Please click this{" "}
            <a
              style={{ fontWeight: "bold" }}
              href="https://www.dropbox.com/s/ypnn3wif1btdnrq/OPTION%20LIST.xlsx?dl=0"
              target="_blank"
            >
              link
            </a>{" "}
            to see what&#39;s possible and addtional price.
          </p>

          <p style={styles.question(24)}>
            2. How long will it take to receive my Gloveworks glove?
          </p>
          <p style={styles.descrpiotn(10)}>
            For stock gloves or glove accessories, your order will be shipped
            1-2 days after your order. You can expect to receive your order
            within 5 business days.
            <br />
            <br />
            {/* OUTLIER - 240201 - 요청사항 2번 */}
            {/* For custom gloves, you can expect a timeframe of 4-6 weeks to get
            the glove after you place the order. Please understand that our
            custom gloves are a one-of-a-kind handcrafted product, and each
            individual glove takes a lot of time and effort to create. We put
            all of our gloves through a rigorous quality inspection to make sure
            it is in perfect condition. We believe it is important to invest the
            time necessary to make a product suitable for you. */}
            For custom gloves, you can expect a timeframe of 4-16 weeks to get
            the glove after you place the order. In cases with many special
            options, during long holidays such as the year-end or sale period,
            the production time leading up to delivery may be extended. Please
            understand that our custom gloves are a one-of-a-kind handcrafted
            product, and each individual glove takes a lot of time and effort to
            create. We put all of our gloves through a rigorous quality
            inspection to make sure it is in perfect condition. We believe it is
            important to invest the time necessary to make a product suitable
            for you.
          </p>

          <p style={styles.question(24)}>
            3. I’ve received a message from you saying you cannot do the
            embroidery I requested, why?
          </p>
          <p style={styles.descrpiotn(10)}>
            Please understand that we cannot accept any logo request that
            infringes on a companies copyright. We also will not produce a glove
            that contains hate speech, hostile political message, vandalism,
            coarse language, etc.
            <br />
            <br />
            Please note, it is the responsibilty of the customer to review any
            possible copyright infringement before ordering a custom glove.
          </p>

          <p style={styles.subTitle(64)}>[2] LEATHER</p>

          <p style={styles.question(10)}>
            1. I’ve received my glove, and the color of it looks different from
            the original preview. Why?
          </p>
          <p style={styles.descrpiotn(10)}>
            The color of actual item may vary from the colors or photo from our
            website due to the your monitor’s display or the lighting during the
            photo.
          </p>

          <p style={styles.question(24)}>
            2. My white glove looks like gray glove/I ordered the white colored
            glove with the white lacing and it is not what I imagined.
          </p>
          <p style={styles.descrpiotn(10)}>
            Our white leather is “Off-White” not “Snow-white” because it is the
            natural leather. Our leather is coloured via dye, and we don’t use
            any pigmentation on the surface at all. It may make leather look
            darker than the graphic color on the screen, but it keeps the
            integrity of the leather. This promotes long lasting quality.
          </p>

          <p style={styles.question(24)}>
            3. I see some sort of stretch mark on my glove. What is it?
          </p>
          <p style={styles.descrpiotn(10)}>
            We get our leather from nature. This means the leather has had the
            natural ability to constantly adjust to its environment. The texture
            and color differences are proof nature doesn’t affect the strength
            or ability of the glove and its leather. It may vary from hide to
            hide, and even within each individual hide. Also, each leather is
            dyed slightly differently based on its individual cell structure.
            This may may cause minor shade differences. We believe this is the
            one of the elements that make each glove special and one-of-a-kind!
          </p>

          <p style={styles.question(24)}>
            4. What color is the lining or lining fur?
          </p>
          <p style={styles.descrpiotn(10)}>
            The lining color will be the same as the palm color. The lining fur
            color will be black in Game Day glove, white in Pro Steerhide and
            Pro Keep glove, and in the fastback glove, there is no fur at all.
          </p>

          <p style={styles.question(24)}>
            5. What are the differences between Game Day, Pro Steerhide, and Pro
            Kip?
          </p>
          <p style={styles.descrpiotn(10)}>
            Our gloves are crafted with Heavy Texas steer-hide and European kip.
            <br />
            <br />
            The Game Day series is made with Korean/Taiwanese tanned steer-hide.
            This glove is easy to break-in and ready to play with, so we
            recommend this if you customize your glove for the first time or use
            it as a youth player.
            <br />
            <br />
            The Pro Steer-hide series is made with Japanese tanned steer-hide,
            which is the most popular material for pro players. The leather is
            taken from the back shoulder of grown steer. It means the leather is
            durable and stiff.
            <br />
            <br />
            The Pro Kip series is made with Japanese tanned European Kip, which
            is considered the premium leather from young calf skin. It is
            lightest and softest leather preferred by pro players who are
            looking for speed and comfort.
            <br />
            <br />
            Gloveworks has a strong and long partnership with top tanneries in
            the world who are specialize in baseball leather. No matter what
            series you choose, you will be getting a top-quality product.
          </p>

          <p style={styles.subTitle(64)}>[3] CUSTOMIZATION</p>

          <p style={styles.question(10)}>
            1. Will I receive an email when I place an order?
          </p>
          <p style={styles.descrpiotn(10)}>
            No. We don’t send the order confirmation email separately but we
            have “
            <font
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() => history.push("./orderTracking")}
            >
              order tracking
            </font>
            ” menu that you can always check the status of your order with your
            email and order number.
          </p>

          <p style={styles.question(24)}>
            2. I placed an order, but I’d like to change something. Can I fix
            the order or cancel it?
          </p>
          <p style={styles.descrpiotn(10)}>
            You have 48 hours to log into the website and review your order
            details. The order history will provide all the information you need
            to review to ensure you are getting the product you want. If you do
            not cancel or change the order within 48 hours, the order will be
            started. Please note that any order started will not be considered
            for a refund.
          </p>

          <p style={styles.subTitle(64)}>[4] SHIPPING</p>

          <p style={styles.question(10)}>1. How will my glove be shipped?</p>
          <p style={styles.descrpiotn(10)}>
            All packages will be shipped via registered mail such as DHL, FEDEX,
            SF EXPRESS (local Chinese). Our factories and warehouses are located
            in China, US and Japan.
          </p>

          <p style={styles.question(24)}>
            2. Do you ship to PO BOX or RR addresses?
          </p>
          <p style={styles.descrpiotn(10)}>
            No, we don’t ship to PO BOX or RR addresses. Please provide your
            physical address.
          </p>

          <p style={styles.question(24)}>
            3. Do I have to pay any custom duties or tariff?
          </p>
          <p style={styles.descrpiotn(10)}>
            Items in your order that are designated for shipment from countries
            outside of the U.S. may be subject to taxes, customs duties and fees
            levied by the destination country (”Import Fees”). The recipient of
            the shipment is the importer of record in the destination country
            and is responsible for all Import Fees.
            <br />
            <br />
            You will pay the import fees to your carrier when the parcel reaches
            its destination. To obtain details regarding the Actual Import Fees,
            or to obtain documentation or receipts in connection with customs
            clearance, you may contact the Carrier specified in your shipment
            confirmation e-mail.
          </p>

          <p style={styles.subTitle(64)}>[Return, Exchange & Refund]</p>

          <p style={styles.question(10)}>
            1. I’ve received my custom glove but I don’t like it. Can I return
            or exchange it?
          </p>
          <p style={styles.descrpiotn(10)}>
            Our custom products are made-to-order, and we don’t offer any return
            or exchange unless the glove has any error, defect, or damage due to
            our mistake. In such cases, you may email Donald
            (donald@gloveworks.net) for assistance within 2 weeks of receiving
            your glove.
          </p>

          <p style={styles.question(24)}>2. Do you have a warranty program?</p>
          <p style={styles.descrpiotn(10)}>
            Your satisfaction is our number one priority. We offer a full
            six-month warranty. If there is any quality issue within 6 month
            after you purchase the glove, please email Donald
            (donald@gloveworks.net) and we will figure out the best way to
            resolve the situation.
          </p>

          <p style={styles.subTitle(64)}>[ETC]</p>

          <p style={styles.question(10)}>
            Do you have any other question? Please email us!
          </p>
          <p style={styles.descrpiotn(10)}>
            - English and Korean Inquiry:{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:contact@gloveworks.net")
              }
            >
              contact@gloveworks.net
            </u>
            <br />- Japanese inquiry:{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                // OUTLIER - 20240123 - 추가 변경
                // (document.location.href = "mailto:gloveworks.jp@gloveworks.net")
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              {/* gloveworks.jp@gloveworks.net */}
              donald@gloveworks.net
            </u>
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: "auto",
    marginBottom: 64,
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  subTitle: (marginTop) => ({
    color: "#000",
    lineHeight: 1.33,
    fontWeight: "bold",
    fontSize: 18,
    marginTop,
  }),
  question: (marginTop) => ({
    marginTop,
    color: "#007dff",
    lineHeight: 1.5,
    fontWeight: "600",
    fontSize: 16,
  }),
  descrpiotn: (marginTop) => ({
    marginTop,
    color: "#142935",
    lineHeight: 1.57,
    fontSize: 14,
  }),
  email: {
    color: "#007dff",
    cursor: "pointer",
    outline: "none",
    fontWeight: "bold",
  },
};

EnFaq.propTypes = {
  history: PropTypes.object,
};

EnFaq.defaultProps = {
  history: {},
};

export default EnFaq;
