import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';

const StampingPersonalize = ({
  stampings,
  currentStamping,
  onStampingClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <div className="personalization-type-view-wrap">
      <h4>STAMPING TYPE</h4>
      <div className="stamping-wrap">
        {stampings.map(stamping =>
            (<div
              key={stamping.id}
              className={classNames('stamping-button', { active: currentStamping && currentStamping.id === stamping.id })}
              onClick={() => onStampingClick(stamping)}
            >
              <div className="img_wrap">
                <img
                  src={stamping.image.url}
                  alt=""
                />
              </div>
              <div className="name">{stamping.name}</div>
            </div>))}
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => {
  const { leatherGrade, stamping } = state.glove;

  return {
    stampings: state.builder.stampings[leatherGrade.id],
    currentStamping: stamping,
  };
};

const mapDispatchToProps = dispatch => ({
  onStampingClick: stamping => dispatch(updateGlove({ stamping })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(StampingPersonalize), 'palmSide');
