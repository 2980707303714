import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import syncPreview from '../higher-order/SyncPreview';
import CustomPythonButtons from '../CustomPythonButtons';

const ThumbWrist = ({
  customPythons,
  insideCurrentPython,
  onInsideCustomPythonClick,
  outsideCurrentPython,
  onOutsideCustomPythonClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <CustomPythonButtons
      title="WRIST & THUMB COLOR"
      className="finger-inside-color-wrap"
      images={customPythons}
      currentCustomImage={insideCurrentPython}
      onClick={onInsideCustomPythonClick}
    />
    <CustomPythonButtons
      title="THUMB OUTSIDE COLOR"
      className="finger-outside-color-wrap"
      images={customPythons}
      currentCustomImage={outsideCurrentPython}
      onClick={onOutsideCustomPythonClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { thumbInsidePython, thumbOutsidePython } = state.glove;

  return {
    customPythons: state.builder.leatherColors[state.glove.leatherGrade.id],
    insideCurrentPython: thumbInsidePython,
    outsideCurrentPython: thumbOutsidePython,
  };
};

const mapDispatchToProps = dispatch => ({
  onInsideCustomPythonClick: color => dispatch(updateGlove({ thumbInsidePython: color })),
  onOutsideCustomPythonClick: color => dispatch(updateGlove({ thumbOutsidePython: color })),
});

export default syncPreview(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThumbWrist), 'webSide');
