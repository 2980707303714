import {getCookie, setCookie} from './Utils';

// api = {
//   method : (메소드),
//   url    : (route 경로)
// }
const _Fetch = (api, query = '', body) => {
  //_Fetch -> url은 host + url로 완성된 상태로 넣어야함
  if(api.method === "GET"){
    return _getFetch(api.url + query);
  }

  else if(api.method === "PUT"){
    return _putFetch(api.url + query, body);
  }

  else if(api.method === "POST"){
    return _postFetch(api.url + query, body);
  }

  else if(api.method === "DELETE"){
    return  _deleteFetch(api.url + query, body);
  }
}

const _getFetch = (url) => {

  var userData = getCookie('userData') ? JSON.parse(getCookie('userData')) : false;

  var token = userData ? userData.token : "NOT_HAVE_TOKEN";
  var id    = userData ? userData.user.id : "NOT_HAVE_ID";

  let options = {
    "token" : token,
    "id"    : id,
    "uid"   : 'wfwfqef123120dsfwefew012313dfwefweo',
  }

  // console.log('opt', options);

  // window IE에서 cache-control을 no-cache로 처리. 데이터 페칭 작업시 문제가 생기면 주석처리해보고 작업해보세요.
  var agent = navigator.userAgent.toLowerCase();
  if ( (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf("msie") !== -1)) {
    options["Pragma"] = "no-cache";
  }

  return new Promise((resolve, reject) => {
    fetch( url, {
      method  : 'GET',
      headers : {...options},
    })
    .then((res) => {
      if(res.status !== 200){
        console.log('서버 응답',res)
        alert('Server error.');
        return res.statusText
      }
      else {
        return res.json();
      }
    })
    .then((res) => JSON.parse(res))
    .then((data) => {
      // console.log(data);
      if(data.status === 200){
        resolve(data.results);
      }
      else if(data.status === 1103){
        resolve([]);
      }
      else if(data.status === 1116) {
        alert("You have been signed out.");
        setCookie("userData" , '');
        console.log(data);
        reject(data)
      }
      else if(data.status === 1106){
        console.log(data);
        reject(data)
      }
      else if(data.status === 1000) {
        console.log(data);
        reject(data)
      }
      else if(data.status === 1120){
        resolve([])
      }
      else {
        console.log(data);
        reject(data)
      }
    })
    .catch((e) => {
      console.log(e);
      reject(e);
    })
  })
};

const _putFetch = (url, body) => {
  var userData = getCookie('userData') ? JSON.parse(getCookie('userData')) : false;

  var token = userData ? userData.token : "NOT_HAVE_TOKEN";
  var id    = userData ? userData.user.id : "NOT_HAVE_ID";

  let options = {
    'Accept'       : 'application/json',
    'Content-Type' : 'application/json',
    "token"        : token,
    "id"           : id
  }
  return new Promise((resolve, reject) => {
    fetch( url, {
      method  : 'PUT',
      headers : {...options},
      body    : JSON.stringify(body),
    })
    .then((res) => {
      if(res.status !== 200){
        console.log('서버 응답',res);
        alert('Server error.');
        reject();
      } else {
        return res.json();
      }
    })
    .then((res) => JSON.parse(res))
    .then((data) => {
      if(data.status === 200){
        resolve(data.results);
      }
      else if(data.status === 1116 || data.status === 1117) {
        alert("You have been signed out.");
        setCookie("userData" , '');
        console.log(data);
        reject(data)
      }
      else if(data.status === 1108){
        console.log(data);
        reject(data)
      }
      else{
        console.log(data);
        reject(data)
      };
    })
    .catch((e)=>{
      console.log(e);
      reject(e);
    })
  });
}

const _postFetch = (url, body) => {

  var userData = getCookie('userData') ? JSON.parse(getCookie('userData')) : false;

  var token = userData ? userData.token : "NOT_HAVE_TOKEN";
  var id    = userData ? userData.user.id : "NOT_HAVE_ID";

  let options = {
    'Accept'       : 'application/json',
    'Content-Type' : 'application/json',
    'token'        : token,
    'id'           : id
  }
  // console.log(options);
  return new Promise((resolve, reject)=>{
    fetch( url, {
      method  : 'POST',
      headers : {...options},
      body    : JSON.stringify(body),
    })
    .then((res )=> {
      if(res.status !== 200){
        console.log('서버 응답',res);
        alert('Server error.');
        return res.statusText
      }
      else{
        return res.json();
      }
    })
    .then((res) => JSON.parse(res))
    .then((data) => {
      if(data.status === 200){
        // console.log(data);
        resolve(data.results);
      }
      else if(data.status === 1116 || data.status === 1117) {
        alert("You have been signed out.");
        setCookie("userData" , '');
        console.log(data);
        resolve(data)
      }
      else if(data.status === 1105 || data.status === 1100) {
        console.log(data);
        reject(data);
      }
      else {
        console.log(data);
        reject(data)
      }
    })
    .catch((e)=>{
      console.log(e);
      reject(e);
    })
  });
}

const _deleteFetch = (url, body) => {
  var userData = getCookie('userData') ? JSON.parse(getCookie('userData')) : false;

  var token = userData ? userData.token : "NOT_HAVE_TOKEN";
  var id    = userData ? userData.user.id : "NOT_HAVE_ID";

  let options = {
    'Accept'       : 'application/json',
    'Content-Type' : 'application/json',
    'token'        : token,
    'id'           : id
  }
  return new Promise((resolve, reject) => {
    fetch( url, {
      method: 'DELETE',
      headers: {...options},
      body: JSON.stringify(body),
    })
    .then((res) => {
      if(res.status !== 200){
        console.log('서버 응답',res);
        alert('Server error.');
        return res.statusText
      } else {
        return res.json();
      }
    })
    .then((res) => JSON.parse(res))
    .then((data) => {
      if(data.status === 200){
        resolve(data.results);
      }
      else if(data.status === 1116 || data.status === 1117) {
        alert("You have been signed out.");
        setCookie("userData" , '');
        reject(data)
      }
      else {
        console.log(data);
        reject(data)
      };
    }).catch((e) => {
      console.log(e);
      reject(e);
    })
  });
}


export default _Fetch;
