import { combineReducers } from 'redux';
import glove from './glove';
import builder from './builder';
import tabs from './tabs';
import presets from './presets';
import alert from './alert';
import preview from './preview';

const rootReducer = combineReducers({
  glove,
  builder,
  tabs,
  presets,
  alert,
  preview,
});

export default rootReducer;
