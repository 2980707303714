import React, { Component } from 'react';
import Builder from '../../lib/builder';
import Step from '../../components/step/Step';

const STEP_MENU = ['Customize'];

class Customize extends Component {
  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
  }

  render() {
    return (
      <div>
        <Step menu={STEP_MENU} />

        <div style={{ marginTop: 60, marginBottom: 100 }}>
          <Builder />
        </div>

        {/* <p
          style={{
            width: 940,
            margin: 'auto',
            marginTop: 10,
            marginBottom: 60,
            color: 'red',
          }}
          >
          * Please click{' '}
          <strong
            role="button"
            tabIndex="-1"
            onClick={() => window.open('https://docs.google.com/spreadsheets/d/1EMH4FsXfWkSznBTFqDhNUtIJlV8LiIKy_2gAvuldmp8/edit?usp=sharing')}
            style={{
          fontWeight: 'bold',
          color: 'blue',
          fontStyle: 'italic',
          cursor: 'pointer',
            }}
          >
            here to
          </strong>{' '}
          find out how to choose the right pattern and size for me.
        </p> */}
      </div>
    );
  }
}

export default Customize;
