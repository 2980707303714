const personalizePrice = {
  wristPersonalizationTypes: [
    [
      {
        "id":1,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":2,
        "name":"DEFAULT",
        "price":"0.0",
        "behavior":"default",
      },
      {
        "id":3,
        "name":"TEXT",
        "price":"10.0",
        "behavior":"text",
      },
      {
        "id":4,
        "name":"LOGO",
        "price":"20.0",
        "behavior":"logo",
      },
      {
        "id":5,
        "name":"FLAG",
        "price":"10.0",
        "behavior":"flag",
      },
      {
        "id":12,
        "name":"IMAGE",
        "price":"10.0",
        "behavior":"image",
      }
    ],
    [
      {
        "id":1,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":2,
        "name":"DEFAULT",
        "price":"0.0",
        "behavior":"default",
      },
      {
        "id":3,
        "name":"TEXT",
        "price":"1000",
        "behavior":"text",
      },
      {
        "id":4,
        "name":"LOGO",
        "price":"2000",
        "behavior":"logo",
      },
      {
        "id":5,
        "name":"FLAG",
        "price":"1000",
        "behavior":"flag",
      },
      {
        "id":12,
        "name":"IMAGE",
        "price":"1000",
        "behavior":"image",
      }
    ],
    [
      {
        "id":1,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":2,
        "name":"DEFAULT",
        "price":"0.0",
        "behavior":"default",
      },
      {
        "id":3,
        "name":"TEXT",
        "price":"10000",
        "behavior":"text",
      },
      {
        "id":4,
        "name":"LOGO",
        "price":"20000",
        "behavior":"logo",
      },
      {
        "id":5,
        "name":"FLAG",
        "price":"10000",
        "behavior":"flag",
      },
      {
        "id":12,
        "name":"IMAGE",
        "price":"10000",
        "behavior":"image",
      }
    ],
  ],
  thumbPersonalizationTypes: [
    [
      {
        "id":8,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":9,
        "name":"DEFAULT",
        "price":"0.0",
        "behavior":"default",
      },
      {
        "id":10,
        "name":"NUMBER",
        "price":"0.0",
        "behavior":"number",
      },
      {
        "id":11,
        "name":"FLAG",
        "price":"10.0",
        "behavior":"flag",
      }
    ],
    [
      {
        "id":8,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":9,
        "name":"DEFAULT",
        "price":"0.0",
        "behavior":"default",
      },
      {
        "id":10,
        "name":"NUMBER",
        "price":"0.0",
        "behavior":"number",
      },
      {
        "id":11,
        "name":"FLAG",
        "price":"1000",
        "behavior":"flag",
      }
    ],
    [
      {
        "id":8,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":9,
        "name":"DEFAULT",
        "price":"0.0",
        "behavior":"default",
      },
      {
        "id":10,
        "name":"NUMBER",
        "price":"0.0",
        "behavior":"number",
      },
      {
        "id":11,
        "name":"FLAG",
        "price":"10000",
        "behavior":"flag",
      }
    ],
  ],
  liningPersonalizationTypes: [
    [
      {
        "id":6,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":7,
        "name":"TEXT",
        "price":"10.0",
        "behavior":"text",
      }
    ],
    [
      {
        "id":6,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":7,
        "name":"TEXT",
        "price":"1000",
        "behavior":"text",
      }
    ],
    [
      {
        "id":6,
        "name":"NONE",
        "price":"0.0",
        "behavior":"none",
      },
      {
        "id":7,
        "name":"TEXT",
        "price":"10000",
        "behavior":"text",
      }
    ],
  ],
};

export default personalizePrice;
