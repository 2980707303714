export default class PreviewImage {
  constructor(src) {
    this.src = src;
    this.loaded = false;
    this.image = undefined;
  }

  loadImage() {
    return new Promise((resolve, reject) => {
      if (this.loaded === true) {
        resolve();
      }

      // this.image = new Image;
      this.image = new Image();

      this.image.onload = () => {
        this.loaded = true;
        resolve();
      };

      this.image.onerror = () => {
        reject(`Image load error - ${this.src}`);
      };

      this.image.src = this.src;

      // console.log(this.image.src);
    });
  }
}
