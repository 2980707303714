import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { getCostSymbol, returnComma } from '../../common/Utils';

function renderOpt(otp) {
  if (otp.length === 0) return null;
  console.log(otp);

  const arr = otp.map((item, i) => (
    <div key={shortid.generate()} style={styles.optionBox}>
      <p style={styles.keyTitle}>{item.title}</p>
      <p style={styles.optValue}>{item.context}</p>
    </div>
  ));

  return <div style={styles.otpContainer}>{arr}</div>;
}

class OrderDetailItem extends PureComponent {
  render() {
    const { data } = this.props;
    // console.log(JSON.parse(data.body));
    return (
      <div style={styles.container(this.props.marginBottom)}>
        <div style={styles.innerContainer}>
          <img alt="" style={styles.img} src={data.pd_uri} />

          <div style={styles.centerBox}>
            <div style={styles.titleBox}>
              <p style={styles.title}>{data.title}</p>
              {data.pid_category === -1 || data.pid_pd === -1 ? (
                <u
                  style={styles.viewDetailText}
                  role="button"
                  tabIndex="-1"
                  onClick={() =>
                    this.props.onViewDtail({ ...data, price_dol: data.cost })
                  }
                >
                  View Detail
                </u>
              ) : null}
            </div>
            {JSON.parse(data.body) === 'none'
              ? null
              : renderOpt(JSON.parse(data.body).options)}
            <div style={styles.qtyBox}>
              <p style={styles.multiply}>QTY</p>
              <p style={styles.quantity}>{data.quantity}</p>
            </div>
          </div>

          <p style={styles.cost(54)}>
            {getCostSymbol(data.typ_cost)}
            {data.typ_cost === 1
              ? returnComma(data.cost.toFixed(2))
              : returnComma(data.cost)}
          </p>
        </div>
      </div>
    );
  }
}

OrderDetailItem.propTypes = {
  marginBottom: PropTypes.number,
  data: PropTypes.object,
  onViewDtail: PropTypes.func,
};

OrderDetailItem.defaultProps = {
  marginBottom: 20,
  data: {},
  onViewDtail: () => {},
};

const styles = {
  container: marginBottom => ({
    width: '100%',
    minHeight: 120,
    display: 'flex',
    justifyContent: 'center',
    marginBottom,
    backgroundColor: '#fff',
  }),
  qtyBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  innerContainer: {
    width: 948,
    display: 'flex',
    borderBottom: '2px solid rgba(37, 31, 27, 0.12)',
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    paddingBottom: 16,
  },
  img: {
    width: 98,
    height: 98,
  },
  centerBox: {
    // width: 412,
    width: 360,
    marginLeft: 24,
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 18,
  },
  title: {
    width: 282,
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    fontWeight: 600,
    color: '#251f1b',
  },
  viewDetailText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#c77b46',
    lineHeight: 1.14,
    cursor: 'pointer',
    outline: 'none',
  },
  counterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  qtyText: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.38,
    letterSpacing: 1,
    color: '#251f1b',
    marginRight: 17,
  },
  rightBox: {
    width: 88,
    marginLeft: 14,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  cost: marginLeft => ({
    width: 88,
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 1,
    textAlign: 'right',
    color: '#251f1b',
    marginLeft,
  }),
  trashBox: {
    width: '100%',
    height: 30,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trash: {
    cursor: 'pointer',
  },
  optionBox: {
    width: 360,
    display: 'flex',
    marginBottom: 4,
    alignItems: 'center',
  },
  keyTitle: {
    width: 220,
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
    opacity: 0.4,
  },
  optValue: {
    width: 140,
    textAlign: 'right',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
    color: '#251f1b',
    lineHeight: 1.57,
  },
  otpContainer: {
    marginBottom: 0,
  },
  multiply: {
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 1,
    color: '#251f1b',
  },
  quantity: {
    width: 24,
    textAlign: 'right',
    fontFamily: 'Roboto Condensed',
    fontSize: 16,
    letterSpacing: 1,
    color: '#251f1b',
  },
};

export default OrderDetailItem;
