import React from 'react';
import { connect } from 'react-redux';
import { changePreviewSide } from '../../actions/creators';

export default function syncPreview(Component, side) {
  class SyncPreview extends React.Component {
    componentDidMount() {
      this.props.changePreviewSide(side);
    }

    render() {
      const { changePreviewSide, ...otherProps } = this.props;
      return <Component {...otherProps} />;
    }
  }

  return connect(null, { changePreviewSide })(SyncPreview);
}
