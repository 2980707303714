import React, { Component } from "react";

const tableStyle = {
  table: {
    width: "100%",
    marginTop: 50,
    textAlign: "left",
  },
  tr: {
    height: 45,
  },
};

class JaShoppingPolicy extends Component {
  render() {
    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>特定商取引法に基づく表記</p>

          <table style={tableStyle.table}>
            <tr style={tableStyle.tr}>
              <th>会社名</th>
              <th>グラブワークスジャパン株式会社</th>
            </tr>
            <tr style={tableStyle.tr}>
              <th>代表者</th>
              <th>Song Jihoon</th>
            </tr>
            <tr style={tableStyle.tr}>
              <td>所在地</td>
              <td>
                東京都港区芝浦4－10－1－3713
                <br />
                〒108-0023
              </td>
            </tr>
            <tr style={tableStyle.tr}>
              <th>電話番号</th>
              <th>(050) 5479-8973, 12:00~16:00, (週末・韓国の祝日除外)</th>
            </tr>
            <tr style={tableStyle.tr}>
              <th>サイトURL</th>
              <th>https://jp.gloveworks.net</th>
            </tr>
            <tr style={tableStyle.tr}>
              <td>お問い合わせ</td>
              <td>
                {/* OUTLIER - 20240122 - 6번 */}
                {/* <u
                  style={styles.email}
                  tabIndex="-1"
                  role="button"
                  onClick={() =>
                    (document.location.href =
                      "mailto:Gloveworks.jp@gloveworks.net")
                  }
                >
                  Gloveworks.jp@gloveworks.net
                </u> */}
                <u
                  style={styles.email}
                  tabIndex="-1"
                  role="button"
                  onClick={() =>
                    (document.location.href = "mailto:donald@gloveworks.net")
                  }
                >
                  donald@gloveworks.net
                </u>
                <br />
              </td>
            </tr>
            <tr style={tableStyle.tr}>
              <td>商品の価格</td>
              <td>各商品ページをご参照ください。</td>
            </tr>
            <tr style={tableStyle.tr}>
              <td>商品代金以外の必要料金</td>
              <td>
                消費税、送料、輸入関税、決済手数料（銀行の振り込み手数料など）
              </td>
            </tr>
            <tr style={tableStyle.tr}>
              <td>お支払い方法</td>
              <td>
                PayPal（クレジット・デビットカード、銀行振り込み）
                <br />
                Paymentwall(ペイメントウォール, コンビニ決済)
                <br />
                銀行振り込み
              </td>
            </tr>
            <br />
            <tr style={tableStyle.tr}>
              <td>商品の引渡し時期</td>
              <td>「特定商取引法に基づく表記」をご覧ください。</td>
            </tr>
            <tr style={tableStyle.tr}>
              <td>返品の可否と条件</td>
              <td>「特定商取引法に基づく表記」をご覧ください。</td>
            </tr>
          </table>

          <p style={styles.subTitle(64)}>配送状況照会</p>
          <p style={styles.description(10)}>
            製品の発送の際に追跡ナンバーと配送業者名などの情報が含まれる配送のご案内メールを差し上げます。またグラブワークスのウェブサイト上でお客様のアカウントにログインされると注文状況からご確認いただけます。
          </p>

          <p style={styles.subTitle(64)}>配送方法</p>
          <p style={styles.description(10)}>
            全ての製品はDHL、FEDEX、SF
            EXPRESS(中国国内の配送)などグラブワークスと提携している配送業者を通じて配送されます。グラブワークスの生産施設と物流倉庫は中国、アメリカ、日本にそれぞれ位置しています。
            <br />
            <br />
            配送業者の業務ポリシーによりPOボックス、またはRR(Rural
            Route)の住所へは配送ができかねます。より早く正確な配送のため、お客様の実際のご住所をご提示くださるようお願い致します。お客様の誤った情報提供または配送業者による配送事故はグラブワークスでは一切の責任を負いかねますので、あらかじめご了承ください。
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>配送料</p>
          <p style={styles.description(10)}>
            配送料は製品のカテゴリーと配送地域により以下のように設定されています。
            <br />
            <br />
            <b style={styles.descTitle}>
              1.
              グラブ(カスタムグラブ/既成グラブ/トレーナーグラブ/板グラブ（パンケーキグラブ）/プロワンシリーズ)及びグラブ叩き台
            </b>
            <br />
            {/* OUTLIER - 20240122 - 4번 */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp; アメリカ＆カナダ　3000円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; アメリカ＆カナダ　4500円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 中南米　7500円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; ヨーロッパ　 3500円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; ヨーロッパ　 4500円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; オーストラリア＆ニュージーランド　 3500円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; オーストラリア＆ニュージーランド　 4500円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア(日本、大韓民国)　 2500円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア(日本、大韓民国)　 2800円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 中国、台湾、香港　 1500円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア＆南アジア　 5500円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア＆南アジア　 4700円
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>2. マレット</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; アメリカ＆カナダ　2000円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 中南米　4500円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 中南米　5500円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; ヨーロッパ　 2300円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; ヨーロッパ　 2500円
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; オーストラリア＆ニュージーランド　2300円 */}
            &nbsp;&nbsp;&nbsp;&nbsp; オーストラリア＆ニュージーランド　2500円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア(日本、大韓民国) 1700円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 中国、台湾、香港 1300円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア＆南アジア　3500円
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>3. キャップ＆ペナント</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; アメリカ＆カナダ 1500円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 中南米 3500円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; ヨーロッパ　 2500円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; オーストラリア＆ニュージーランド　2500円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア(日本、大韓民国) 1300円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 中国、台湾、香港 1000円
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 東南アジア＆南アジア　 2500円
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>4. グラブオイル</b>
            <br />
            グラブオイルは他の製品と一緒にご購入いただく場合のみご注文が可能です。なお配送費は無料です。グラブオイルの個別でのご購入はお断りしておりますのであらかじめご了承ください。大量購入をご希望の方はメール(
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:contact@gloveworks.net")
              }
            >
              contact@gloveowork.net
            </u>
            )にてお問合せください。
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>配送料の割引</p>
          <p style={styles.description(10)}>
            4点以上のカスタムグラブまたは既成グラブを一度にご注文いただいた場合、配送料の10％を割引致します。配送料の割引は同じカテゴリーの製品をご購入される場合のみ適用されますのであらかじめご了承ください。
          </p>

          <p style={styles.subTitle(64)}>関税</p>
          <p style={styles.description(10)}>
            グラブワークスの製品は中国にて生産及び発送され、配送先の物品輸入に関する法規に基づき輸入通関時に関税が課せられます。関税は製品をお受け取りになる際に配送業者へ直接お支払いください
            <br />
            <br />
            関税または輸入通関に関する詳細、領収書、関連書類などがご必要な場合は配送のご案内メールに記載されている配送業者へお問い合わせください。
            <br />
            <br />
            詳しい内容は{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.customs.go.jp/tsukan/index.htm"
              target="_blank"
              rel="noreferrer"
            >
              こちらのリンク
            </a>
            をご参考ください。
          </p>

          <p style={styles.subTitle(64)}>[返金・返品及び交換ポリシー]</p>

          <p style={styles.subTitle(64)}>ご注文のキャンセル及び返金</p>
          <p style={styles.description(10)}>
            カスタムグラブの場合ご注文から48時間以内においてキャンセルが可能です。ご注文から48時間が経過しますと製作に取り掛かるため、それ以降のキャンセル及び返金はお受けできません。
          </p>

          <p style={styles.subTitle(64)}>返品及び交換</p>
          <p style={styles.description(10)}>
            グラブワークスのカスタムグラブはお客様のお好みに合わせて作られたオリジナルのオーダーメイド製品です。従って、お客様のご都合によるご返品またはご交換はお受けできません。不良品、誤送付品などによる問題が発生した場合には、お手数ですが製品のお受け取り日より2週間以内に{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            へメールにてご連絡ください。
          </p>

          <p style={styles.subTitle(64)}>グラブワークス保証サービス</p>
          <p style={styles.description(10)}>
            グラブワークスは製品のお受け取りから６ヵ月間のメーカー保証をご提供します。製品のお受け取りから６ヵ月以内に品質に問題が発生した場合はお手数ですが、
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            へメールにてお問合せください。弊社が問題を解決するべく最善を尽くします。
          </p>

          <p style={styles.subTitle(64)}>商品の引渡し時期</p>
          <p style={styles.description(10)}>
            【レディ・メイド製品（アパレル、アクセサリーなどの既製品）
            <br />
            ご注文（決済完了分）を頂いてから3営業日－14営業日後で発送致します。
            <br />
            オーダーメードと同時に注文された場合、別途のご希望が無ければオーダーメード製品と一緒に発送致します。
            <br />
            <br />
            【オーダーメード製品（オーダーグラブ）】
            <br />
            おおよその目安ですが、通常の場合ご注文（決済完了、詳細の確認完了）を頂いてから発送まで4週～16週程かかります。特殊オプションが多い場合、年末年始などの長期休業日を挟んでの製作の場合には出荷までの時間が延びる場合がございます。発送通知は、お申込みの際に登録されたお客様のメールアドレス宛までにご連絡致します。
            <br />
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: "auto",
    marginBottom: 64,
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  subTitle: (marginTop) => ({
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.33,
    color: "#000",
    marginTop,
  }),
  description: (marginTop) => ({
    whiteSpace: "pre-line",
    fontSize: 14,
    marginTop,
    lineHeight: 1.57,
    color: "#142935",
  }),
  descTitle: {
    fontWeight: "bold",
  },
  email: {
    color: "#007dff",
    cursor: "pointer",
    outline: "none",
  },
};

export default JaShoppingPolicy;
