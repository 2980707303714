import {
  REQUEST_PRESETS,
  RECEIVE_PRESETS,
} from '../constants/ActionTypes';

export default function presets(state = {}, action) {
  switch (action.type) {
    case REQUEST_PRESETS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PRESETS:
      return {
        isFetching: false,
        presets: action.presets,
        pagination: action.pagination,
      };
    default:
      return state;
  }
}
