import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DivButton from '../button/DivButton';

const down = '/svg/triangle-copy.svg';
const up = 'svg/triangle-up.svg';

class PaymentAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { styles, title, children } = this.props;
    const { isOpen } = this.state;

    return (
      <div style={styles.container}>
        <div style={Styles.header}>
          <div style={styles.title}>{title}</div>
          <DivButton style={Styles.button} onClick={this.toggle}>
            <img src={isOpen ? up : down} alt="" />
          </DivButton>
        </div>

        {isOpen && children}
      </div>
    );
  }
}

const Styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

PaymentAccordion.propTypes = {
  styles: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
};

PaymentAccordion.defaultProps = {
  styles: {
    container: {},
    title: '',
  },
  title: '',
  children: null,
};

export default PaymentAccordion;
