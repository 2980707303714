import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { selectLeatherGrade } from "../../actions/creators";
import { checkedCurrencySymbol, returnComma } from "../../../../common/Utils";
import { T } from "ramda";
import i18n from "../../../i18n/i18n";

let LeatherGradeButton = ({
  active,
  gloveType,
  leatherGrade,
  onClick,
  i18n,
  t,
}) => {
  const { name, description, prices } = leatherGrade;

  const gloveTypeKeys = {
    "Single Welting": "SingleWelting",
    "Double Welting": "DoubleWelting",
    "First Baseman Mitt": "FirstBase",
    "Catchers Mitt": "Catcher",
    Softball: "Softball",
  };
  const nameTrim = (name) => {
    const txt = name.replace(/ /g, "");
    return txt;
  };

  const descriptionTrim = (description) => {
    const txt = description.replace(/ /g, "").split(".");
    return txt[0];
  };

  // console.log(i18n.language);

  return (
    <div
      className={classNames("leather-grade-button clearfix", { active })}
      onClick={onClick}
    >
      <div className="text pull-left">
        {t(`${nameTrim(name)}`)}
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: t(`${descriptionTrim(description)}`),
          }}
        />
      </div>
      <div className="price pull-right">
        {checkedCurrencySymbol(i18n.language) +
          returnComma(prices[gloveTypeKeys[gloveType]])}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  gloveType: state.glove.gloveType,
  active:
    state.glove.leatherGrade &&
    props.leatherGrade.id === state.glove.leatherGrade.id,
});

const mapDispatchToLinkProps = (dispatch, props) => ({
  onClick: () => {
    if (!props.active) {
      dispatch(selectLeatherGrade(props.leatherGrade));
    }
  },
});

LeatherGradeButton = withTranslation("translations")(
  connect(mapStateToProps, mapDispatchToLinkProps)(LeatherGradeButton)
);

const LeatherGrades = ({ leatherGrades, t }) => {
  return (
    <div className="leather-grade-buttons">
      <h4 style={{ display: "flex", justifyContent: "space-between" }}>
        {t("customizeLEATHERGRADE")}
        {/* OUTLIER - 20240122 - 9번 - 2 */}
        {i18n.language === "ja" ? (
          <p style={{ width: "60%" }}>
            表示価格は税込みです。送料及び輸入関税は含まれておりません。
          </p>
        ) : null}
      </h4>
      {leatherGrades.map((leatherGrade) => (
        <LeatherGradeButton key={leatherGrade.id} leatherGrade={leatherGrade} />
      ))}
      {/* OUTLIER - 20240122 - 9번 - 2 */}
      {i18n.language === "ja" ? (
        <p>表示価格は税込みです。送料及び輸入関税は含まれておりません。</p>
      ) : null}
    </div>
  );
};

export default withTranslation("translations")(LeatherGrades);
