import GloveRenderer from './GloveRenderer';
// import glove from '../reducers/glove'; // try to modify glove state
// import {UPDATE_GLOVE} from '../constants/ActionTypes'; // try to modify glove state

export default class SingleWeltingRenderer extends GloveRenderer {
  constructor(props) {
    super(props);
    this.gloveType = 'Single Welting';
  }

  renderWebSide = glove => new Promise((resolve, reject) => {
    // console.log(glove({}, {type: UPDATE_GLOVE, attributes: {}})); // wrong use case
    let shadowImageKey;
    let wristShadowImageKey, palmShadowImageKey,
        thumbInsideShadowImageKey, thumbOutsideShadowImageKey,
        indexInsideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.wristPython.url !== undefined && glove.wristPython.url !== null && glove.wristPython.name.toLowerCase().includes("monogram")) {
      wristShadowImageKey = 'shadowPython';
    } else if (glove.wristPython.name.toLowerCase().includes("paisley")) {
      wristShadowImageKey = 'shadowPaisley';
    } else {
      wristShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.thumbInsidePython.url !== undefined && glove.thumbInsidePython.url !== null && glove.thumbInsidePython.name.toLowerCase().includes("monogram")) {
      thumbInsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbInsidePython.name.toLowerCase().includes("paisley")) {
      thumbInsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbInsideShadowImageKey = 'shadow';
    }
    if (glove.thumbOutsidePython.url !== undefined && glove.thumbOutsidePython.url !== null && glove.thumbOutsidePython.name.toLowerCase().includes("monogram")) {
      thumbOutsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbOutsidePython.name.toLowerCase().includes("paisley")) {
      thumbOutsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbOutsideShadowImageKey = 'shadow';
    }
    if (glove.indexInsidePython.url !== undefined && glove.indexInsidePython.url !== null && glove.indexInsidePython.name.toLowerCase().includes("monogram")) {
      indexInsideShadowImageKey = 'shadowPython';
    } else if (glove.indexInsidePython.name.toLowerCase().includes("paisley")) {
      indexInsideShadowImageKey = 'shadowPaisley';
    } else {
      indexInsideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('wrist', glove.wristPython.hexCode, wristShadowImageKey),
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('thumbInside', glove.thumbInsidePython.hexCode, thumbInsideShadowImageKey),
      this.renderLayer('thumbOutside', glove.thumbOutsidePython.hexCode, thumbOutsideShadowImageKey),
      this.renderLayer('indexInside', glove.indexInsidePython.hexCode, indexInsideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('welting', glove.weltingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      let fingerHoodShadow, fingerPadShadow;
      if (glove.fingerOptionPython.url !== undefined && glove.fingerOptionPython.url !== null && glove.fingerOptionPython.name.toLowerCase().includes("monogram")) {
        fingerHoodShadow = 'fingerHoodShadowPython';
        fingerPadShadow = 'fingerPadShadowPython';
      } else if (glove.fingerOptionPython.name.toLowerCase().includes("paisley")) {
        fingerHoodShadow = 'fingerHoodShadowPaisley';
        fingerPadShadow = 'fingerPadShadowPaisley';
      } else {
        fingerHoodShadow = 'fingerHoodShadow';
        fingerPadShadow = 'fingerPadShadow';
      }
      if (glove.fingerOption.name === 'FINGER HOOD') {
        return Promise.all([
          this.renderLayer('fingerHoodThread', glove.threadColor.hexCode, fingerHoodShadow),
          this.renderLayer('fingerHoodLeather', glove.fingerOptionPython.hexCode, fingerHoodShadow),
        ]);
      } else if (glove.fingerOption.name === 'FINGER PAD') {
        return Promise.all([
          this.renderLayer('fingerPadThread', glove.threadColor.hexCode, fingerPadShadow),
          this.renderLayer('fingerPadLeather', glove.fingerOptionPython.hexCode, fingerPadShadow),
        ]);
      }

      return Promise.resolve();
    }).then(() => {
      let webLeatherImageKey,
        webLacingImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPython';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPython';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPython';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPython';
        } else if (glove.webType.id === 12) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPython';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPython';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPaisley';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPaisley';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPaisley';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPaisley';
        } else if (glove.webType.id === 12) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPaisley';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPaisley';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPaisley';
        }
      } else {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5Shadow';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6Shadow';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7Shadow';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1Shadow';
        } else if (glove.webType.id === 12) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2Shadow';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3Shadow';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4Shadow';
        }
      }

      return Promise.all([
        this.renderLayer(webLeatherImageKey, glove.webPython.hexCode, webShadowImageKey),
        this.renderLayer(webLacingImageKey, glove.lacingColor.hexCode, webShadowImageKey),
      ]);
    })
      .then(() => {
        let webThreadImageKey,
          webShadowImageKey;

        if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
          if (glove.webType.id >= 22 && glove.webType.id <= 24) {
            webThreadImageKey = 'web5Thread';
            webShadowImageKey = 'web5ShadowPython';
          } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
            webThreadImageKey = 'web6Thread';
            webShadowImageKey = 'web6ShadowPython';
          } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
            webThreadImageKey = 'web7Thread';
            webShadowImageKey = 'web7ShadowPython';
          } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
            webThreadImageKey = 'web1Thread';
            webShadowImageKey = 'web1ShadowPython';
          } else if (glove.webType.id === 12) {
            webThreadImageKey = 'web2Thread';
            webShadowImageKey = 'web2ShadowPython';
          } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
            webThreadImageKey = 'web3Thread';
            webShadowImageKey = 'web3ShadowPython';
          } else {
            webThreadImageKey = 'web4Thread';
            webShadowImageKey = 'web4ShadowPython';
          }
        } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
          if (glove.webType.id >= 22 && glove.webType.id <= 24) {
            webThreadImageKey = 'web5Thread';
            webShadowImageKey = 'web5ShadowPaisley';
          } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
            webThreadImageKey = 'web6Thread';
            webShadowImageKey = 'web6ShadowPaisley';
          } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
            webThreadImageKey = 'web7Thread';
            webShadowImageKey = 'web7ShadowPaisley';
          } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
            webThreadImageKey = 'web1Thread';
            webShadowImageKey = 'web1ShadowPaisley';
          } else if (glove.webType.id === 12) {
            webThreadImageKey = 'web2Thread';
            webShadowImageKey = 'web2ShadowPaisley';
          } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
            webThreadImageKey = 'web3Thread';
            webShadowImageKey = 'web3ShadowPaisley';
          } else {
            webThreadImageKey = 'web4Thread';
            webShadowImageKey = 'web4ShadowPaisley';
          }

        } else {
          if (glove.webType.id >= 22 && glove.webType.id <= 24) {
            webThreadImageKey = 'web5Thread';
            webShadowImageKey = 'web5Shadow';
          } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
            webThreadImageKey = 'web6Thread';
            webShadowImageKey = 'web6Shadow';
          } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
            webThreadImageKey = 'web7Thread';
            webShadowImageKey = 'web7Shadow';
          } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
            webThreadImageKey = 'web1Thread';
            webShadowImageKey = 'web1Shadow';
          } else if (glove.webType.id === 12) {
            webThreadImageKey = 'web2Thread';
            webShadowImageKey = 'web2Shadow';
          } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
            webThreadImageKey = 'web3Thread';
            webShadowImageKey = 'web3Shadow';
          } else {
            webThreadImageKey = 'web4Thread';
            webShadowImageKey = 'web4Shadow';
          }
        }

        return this.renderLayer(webThreadImageKey, glove.threadColor.hexCode, webShadowImageKey);
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })

  renderFingerSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let wristShadowImageKey, palmShadowImageKey,
        indexInsideShadowImageKey, indexOutsideShadowImageKey,
        middleInsideShadowImageKey, middleOutsideShadowImageKey,
        ringInsideShadowImageKey, ringOutsideShadowImageKey,
        pinkyInsideShadowImageKey, pinkyOutsideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.wristPython.url !== undefined && glove.wristPython.url !== null && glove.wristPython.name.toLowerCase().includes("monogram")) {
      wristShadowImageKey = 'shadowPython';
    } else if (glove.wristPython.name.toLowerCase().includes("paisley")) {
      wristShadowImageKey = 'shadowPaisley';
    } else {
      wristShadowImageKey = 'shadow';
    }
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.indexInsidePython.url !== undefined && glove.indexInsidePython.url !== null && glove.indexInsidePython.name.toLowerCase().includes("monogram")) {
      indexInsideShadowImageKey = 'shadowPython';
    } else if (glove.indexInsidePython.name.toLowerCase().includes("paisley")) {
      indexInsideShadowImageKey = 'shadowPaisley';
    } else {
      indexInsideShadowImageKey = 'shadow';
    }
    if (glove.indexOutsidePython.url !== undefined && glove.indexOutsidePython.url !== null && glove.indexOutsidePython.name.toLowerCase().includes("monogram")) {
      indexOutsideShadowImageKey = 'shadowPython';
    } else if (glove.indexOutsidePython.name.toLowerCase().includes("paisley")) {
      indexOutsideShadowImageKey = 'shadowPaisley';
    } else {
      indexOutsideShadowImageKey = 'shadow';
    }
    if (glove.middleInsidePython.url !== undefined && glove.middleInsidePython.url !== null && glove.middleInsidePython.name.toLowerCase().includes("monogram")) {
      middleInsideShadowImageKey = 'shadowPython';
    } else if (glove.middleInsidePython.name.toLowerCase().includes("paisley")) {
      middleInsideShadowImageKey = 'shadowPaisley';
    } else {
      middleInsideShadowImageKey = 'shadow';
    }
    if (glove.middleOutsidePython.url !== undefined && glove.middleOutsidePython.url !== null && glove.middleOutsidePython.name.toLowerCase().includes("monogram")) {
      middleOutsideShadowImageKey = 'shadowPython';
    } else if (glove.middleOutsidePython.name.toLowerCase().includes("paisley")) {
      middleOutsideShadowImageKey = 'shadowPaisley';
    } else {
      middleOutsideShadowImageKey = 'shadow';
    }
    if (glove.ringInsidePython.url !== undefined && glove.ringInsidePython.url !== null && glove.ringInsidePython.name.toLowerCase().includes("monogram")) {
      ringInsideShadowImageKey = 'shadowPython';
    } else if (glove.ringInsidePython.name.toLowerCase().includes("paisley")) {
      ringInsideShadowImageKey = 'shadowPaisley';
    } else {
      ringInsideShadowImageKey = 'shadow';
    }
    if (glove.ringOutsidePython.url !== undefined && glove.ringOutsidePython.url !== null && glove.ringOutsidePython.name.toLowerCase().includes("monogram")) {
      ringOutsideShadowImageKey = 'shadowPython';
    } else if (glove.ringOutsidePython.name.toLowerCase().includes("paisley")) {
      ringOutsideShadowImageKey = 'shadowPaisley';
    } else {
      ringOutsideShadowImageKey = 'shadow';
    }
    if (glove.pinkyInsidePython.url !== undefined && glove.pinkyInsidePython.url !== null && glove.pinkyInsidePython.name.toLowerCase().includes("monogram")) {
      pinkyInsideShadowImageKey = 'shadowPython';
    } else if (glove.pinkyInsidePython.name.toLowerCase().includes("paisley")) {
      pinkyInsideShadowImageKey = 'shadowPaisley';
    } else {
      pinkyInsideShadowImageKey = 'shadow';
    }
    if (glove.pinkyOutsidePython.url !== undefined && glove.pinkyOutsidePython.url !== null && glove.pinkyOutsidePython.name.toLowerCase().includes("monogram")) {
      pinkyOutsideShadowImageKey = 'shadowPython';
    } else if (glove.pinkyOutsidePython.name.toLowerCase().includes("paisley")) {
      pinkyOutsideShadowImageKey = 'shadowPaisley';
    } else {
      pinkyOutsideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('wrist', glove.wristPython.hexCode, wristShadowImageKey),
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('indexInside', glove.indexInsidePython.hexCode, indexInsideShadowImageKey),
      this.renderLayer('indexOutside', glove.indexOutsidePython.hexCode, indexOutsideShadowImageKey),
      this.renderLayer('middleInside', glove.middleInsidePython.hexCode, middleInsideShadowImageKey),
      this.renderLayer('middleOutside', glove.middleOutsidePython.hexCode, middleOutsideShadowImageKey),
      this.renderLayer('ringInside', glove.ringInsidePython.hexCode, ringInsideShadowImageKey),
      this.renderLayer('ringOutside', glove.ringOutsidePython.hexCode, ringOutsideShadowImageKey),
      this.renderLayer('pinkyInside', glove.pinkyInsidePython.hexCode, pinkyInsideShadowImageKey),
      this.renderLayer('pinkyOutside', glove.pinkyOutsidePython.hexCode, pinkyOutsideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('welting', glove.weltingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      let fingerHoodShadow, fingerPadShadow;
      if (glove.fingerOptionPython.url !== undefined && glove.fingerOptionPython.url !== null && glove.fingerOptionPython.name.toLowerCase().includes("monogram")) {
        fingerHoodShadow = 'fingerHoodShadowPython';
        fingerPadShadow = 'fingerPadShadowPython';
      } else if (glove.fingerOptionPython.name.toLowerCase().includes("paisley")) {
        fingerHoodShadow = 'fingerHoodShadowPaisley';
        fingerPadShadow = 'fingerPadShadowPaisley';
      } else {
        fingerHoodShadow = 'fingerHoodShadow';
        fingerPadShadow = 'fingerPadShadow';
      }
      if (glove.fingerOption.name === 'FINGER HOOD') {
        return Promise.all([
          this.renderLayer('fingerHoodThread', glove.threadColor.hexCode, fingerHoodShadow),
          this.renderLayer('fingerHoodLeather', glove.fingerOptionPython.hexCode, fingerHoodShadow),
        ]);
      } else if (glove.fingerOption.name === 'FINGER PAD') {
        return Promise.all([
          this.renderLayer('fingerPadThread', glove.threadColor.hexCode, fingerPadShadow),
          this.renderLayer('fingerPadLeather', glove.fingerOptionPython.hexCode, fingerPadShadow),
        ]);
      }

      return Promise.resolve();
    }).then(() => this.renderStampingLayer('stampingLogo', glove.palmPython.hexCode, 'shadow'))
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })

  renderPalmSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let palmShadowImageKey,
        thumbInsideShadowImageKey, thumbOutsideShadowImageKey,
        indexInsideShadowImageKey, indexOutsideShadowImageKey,
        middleInsideShadowImageKey, middleOutsideShadowImageKey,
        ringInsideShadowImageKey, ringOutsideShadowImageKey,
        pinkyInsideShadowImageKey, pinkyOutsideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.thumbInsidePython.url !== undefined && glove.thumbInsidePython.url !== null && glove.thumbInsidePython.name.toLowerCase().includes("monogram")) {
      thumbInsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbInsidePython.name.toLowerCase().includes("paisley")) {
      thumbInsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbInsideShadowImageKey = 'shadow';
    }
    if (glove.thumbOutsidePython.url !== undefined && glove.thumbOutsidePython.url !== null && glove.thumbOutsidePython.name.toLowerCase().includes("monogram")) {
      thumbOutsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbOutsidePython.name.toLowerCase().includes("paisley")) {
      thumbOutsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbOutsideShadowImageKey = 'shadow';
    }
    if (glove.indexInsidePython.url !== undefined && glove.indexInsidePython.url !== null && glove.indexInsidePython.name.toLowerCase().includes("monogram")) {
      indexInsideShadowImageKey = 'shadowPython';
    } else if (glove.indexInsidePython.name.toLowerCase().includes("paisley")) {
      indexInsideShadowImageKey = 'shadowPaisley';
    } else {
      indexInsideShadowImageKey = 'shadow';
    }
    if (glove.indexOutsidePython.url !== undefined && glove.indexOutsidePython.url !== null && glove.indexOutsidePython.name.toLowerCase().includes("monogram")) {
      indexOutsideShadowImageKey = 'shadowPython';
    } else if (glove.indexOutsidePython.name.toLowerCase().includes("paisley")) {
      indexOutsideShadowImageKey = 'shadowPaisley';
    } else {
      indexOutsideShadowImageKey = 'shadow';
    }
    if (glove.middleInsidePython.url !== undefined && glove.middleInsidePython.url !== null && glove.middleInsidePython.name.toLowerCase().includes("monogram")) {
      middleInsideShadowImageKey = 'shadowPython';
    } else if (glove.middleInsidePython.name.toLowerCase().includes("paisley")) {
      middleInsideShadowImageKey = 'shadowPaisley';
    } else {
      middleInsideShadowImageKey = 'shadow';
    }
    if (glove.middleOutsidePython.url !== undefined && glove.middleOutsidePython.url !== null && glove.middleOutsidePython.name.toLowerCase().includes("monogram")) {
      middleOutsideShadowImageKey = 'shadowPython';
    } else if (glove.middleOutsidePython.name.toLowerCase().includes("paisley")) {
      middleOutsideShadowImageKey = 'shadowPaisley';
    } else {
      middleOutsideShadowImageKey = 'shadow';
    }
    if (glove.ringInsidePython.url !== undefined && glove.ringInsidePython.url !== null && glove.ringInsidePython.name.toLowerCase().includes("monogram")) {
      ringInsideShadowImageKey = 'shadowPython';
    } else if (glove.ringInsidePython.name.toLowerCase().includes("paisley")) {
      ringInsideShadowImageKey = 'shadowPaisley';
    } else {
      ringInsideShadowImageKey = 'shadow';
    }
    if (glove.ringOutsidePython.url !== undefined && glove.ringOutsidePython.url !== null && glove.ringOutsidePython.name.toLowerCase().includes("monogram")) {
      ringOutsideShadowImageKey = 'shadowPython';
    } else if (glove.ringOutsidePython.name.toLowerCase().includes("paisley")) {
      ringOutsideShadowImageKey = 'shadowPaisley';
    } else {
      ringOutsideShadowImageKey = 'shadow';
    }
    if (glove.pinkyInsidePython.url !== undefined && glove.pinkyInsidePython.url !== null && glove.pinkyInsidePython.name.toLowerCase().includes("monogram")) {
      pinkyInsideShadowImageKey = 'shadowPython';
    } else if (glove.pinkyInsidePython.name.toLowerCase().includes("paisley")) {
      pinkyInsideShadowImageKey = 'shadowPaisley';
    } else {
      pinkyInsideShadowImageKey = 'shadow';
    }
    if (glove.pinkyOutsidePython.url !== undefined && glove.pinkyOutsidePython.url !== null && glove.pinkyOutsidePython.name.toLowerCase().includes("monogram")) {
      pinkyOutsideShadowImageKey = 'shadowPython';
    } else if (glove.pinkyOutsidePython.name.toLowerCase().includes("paisley")) {
      pinkyOutsideShadowImageKey = 'shadowPaisley';
    } else {
      pinkyOutsideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('thumbInside', glove.thumbInsidePython.hexCode, thumbInsideShadowImageKey),
      this.renderLayer('thumbOutside', glove.thumbOutsidePython.hexCode, thumbOutsideShadowImageKey),
      this.renderLayer('indexInside', glove.indexInsidePython.hexCode, indexInsideShadowImageKey),
      this.renderLayer('indexOutside', glove.indexOutsidePython.hexCode, indexOutsideShadowImageKey),
      this.renderLayer('middleInside', glove.middleInsidePython.hexCode, middleInsideShadowImageKey),
      this.renderLayer('middleOutside', glove.middleOutsidePython.hexCode, middleOutsideShadowImageKey),
      this.renderLayer('ringInside', glove.ringInsidePython.hexCode, ringInsideShadowImageKey),
      this.renderLayer('ringOutside', glove.ringOutsidePython.hexCode, ringOutsideShadowImageKey),
      this.renderLayer('pinkyInside', glove.pinkyInsidePython.hexCode, pinkyInsideShadowImageKey),
      this.renderLayer('pinkyOutside', glove.pinkyOutsidePython.hexCode, pinkyOutsideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('welting', glove.weltingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => {
      let webLeatherImageKey,
        webLacingImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPython';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPython';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPython';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPython';
        } else if (glove.webType.id === 12) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPython';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPython';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPaisley';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPaisley';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPaisley';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPaisley';
        } else if (glove.webType.id === 12) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPaisley';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPaisley';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPaisley';
        }
      } else {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5Shadow';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6Shadow';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7Shadow';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1Shadow';
        } else if (glove.webType.id === 12) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2Shadow';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3Shadow';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4Shadow';
        }
      }

      return Promise.all([
        this.renderLayer(webLeatherImageKey, glove.webPython.hexCode, webShadowImageKey),
        this.renderLayer(webLacingImageKey, glove.lacingColor.hexCode, webShadowImageKey),
      ]);
    }).then(() => {
      let webThreadImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webThreadImageKey = 'web5Thread';
          webShadowImageKey = 'web5ShadowPython';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webThreadImageKey = 'web6Thread';
          webShadowImageKey = 'web6ShadowPython';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webThreadImageKey = 'web7Thread';
          webShadowImageKey = 'web7ShadowPython';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPython';
        } else if (glove.webType.id === 12) {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPython';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webThreadImageKey = 'web3Thread';
          webShadowImageKey = 'web3ShadowPython';
        } else {
          webThreadImageKey = 'web4Thread';
          webShadowImageKey = 'web4ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webThreadImageKey = 'web5Thread';
          webShadowImageKey = 'web5ShadowPaisley';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webThreadImageKey = 'web6Thread';
          webShadowImageKey = 'web6ShadowPaisley';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webThreadImageKey = 'web7Thread';
          webShadowImageKey = 'web7ShadowPaisley';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPaisley';
        } else if (glove.webType.id === 12) {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPaisley';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webThreadImageKey = 'web3Thread';
          webShadowImageKey = 'web3ShadowPaisley';
        } else {
          webThreadImageKey = 'web4Thread';
          webShadowImageKey = 'web4ShadowPaisley';
        }
      } else {
        if (glove.webType.id >= 22 && glove.webType.id <= 24) {
          webThreadImageKey = 'web5Thread';
          webShadowImageKey = 'web5Shadow';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 19) {
          webThreadImageKey = 'web6Thread';
          webShadowImageKey = 'web6Shadow';
        } else if (glove.webType.id >= 13 && glove.webType.id <= 16) {
          webThreadImageKey = 'web7Thread';
          webShadowImageKey = 'web7Shadow';
        } else if (glove.webType.id >= 17 && glove.webType.id <= 24) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1Shadow';
        } else if (glove.webType.id === 12) {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2Shadow';
        } else if (glove.webType.id >= 7 && glove.webType.id <= 11) {
          webThreadImageKey = 'web3Thread';
          webShadowImageKey = 'web3Shadow';
        } else {
          webThreadImageKey = 'web4Thread';
          webShadowImageKey = 'web4Shadow';
        }
      }

      return this.renderLayer(webThreadImageKey, glove.threadColor.hexCode, webShadowImageKey);
    }).then(() => this.renderStampingLayer('stampingGW', glove.palmPython.hexCode, 'shadow'))
      .then(() => {
        if (glove.stamping == null) {
          return Promise.resolve();
        }
        if (glove.stamping.id === 1) {
          return this.renderStampingLayer('stampingGameDay1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 2) {
          return this.renderStampingLayer('stampingGameDay2', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 3) {
          return this.renderStampingLayer('stampingProSteerhide1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 4) {
          return this.renderStampingLayer('stampingProSteerhide2', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 5) {
          return this.renderStampingLayer('stampingProKip1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 6) {
          return this.renderStampingLayer('stampingProKip2', glove.palmPython.hexCode, 'shadow');
        }
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })
}
