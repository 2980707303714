const leatherGrades = [
  [
    {
      id: 1,
      name: "GAME DAY",
      description: "Korean tanned steerhide.",
      prices: {
        SingleWelting: "119.90",
        DoubleWelting: "119.90",
        FirstBase: "119.90",
        Catcher: "119.90",
        Softball: "119.90",
      },
    },
    {
      id: 2,
      name: "PRO STEERHIDE",
      description: "Japanese tanned pro steerhide.",
      prices: {
        SingleWelting: "179.90",
        DoubleWelting: "179.90",
        FirstBase: "179.90",
        Catcher: "179.90",
        Softball: "179.90",
      },
    },
    {
      id: 3,
      name: "PRO KIP",
      description: "Japanese tanned European kip.",
      prices: {
        SingleWelting: "219.90",
        DoubleWelting: "219.90",
        FirstBase: "219.90",
        Catcher: "219.90",
        Softball: "219.90",
      },
    },
  ],
  [
    {
      id: 1,
      name: "GAME DAY",
      description: "Korean tanned steerhide.",
      // OUTLIER - 20240122 - 9번
      // prices: {
      //   SingleWelting: "12900",
      //   DoubleWelting: "12900",
      //   FirstBase: "12900",
      //   Catcher: "12900",
      //   Softball: "12900",
      // },
      prices: {
        SingleWelting: "15900",
        DoubleWelting: "15900",
        FirstBase: "15900",
        Catcher: "15900",
        Softball: "15900",
      },
    },
    {
      id: 2,
      name: "PRO STEERHIDE",
      description: "Japanese tanned pro steerhide.",
      // OUTLIER - 20240122 - 9번
      // prices: {
      //   SingleWelting: "19900",
      //   DoubleWelting: "19900",
      //   FirstBase: "19900",
      //   Catcher: "19900",
      //   Softball: "19900",
      // },
      prices: {
        SingleWelting: "22900",
        DoubleWelting: "22900",
        FirstBase: "22900",
        Catcher: "22900",
        Softball: "22900",
      },
    },
    {
      id: 3,
      name: "PRO KIP",
      description: "Japanese tanned European kip.",
      // OUTLIER - 20240122 - 9번
      // prices: {
      //   SingleWelting: "24900",
      //   DoubleWelting: "24900",
      //   FirstBase: "24900",
      //   Catcher: "24900",
      //   Softball: "24900",
      // },
      prices: {
        SingleWelting: "28900",
        DoubleWelting: "28900",
        FirstBase: "28900",
        Catcher: "28900",
        Softball: "28900",
      },
    },
  ],
  [
    {
      id: 1,
      name: "GAME DAY",
      description: "Korean tanned steerhide.",
      // OUTLIER - 20240122 - 9번
      // prices: {
      //   SingleWelting: "139000",
      //   DoubleWelting: "139000",
      //   FirstBase: "139000",
      //   Catcher: "139000",
      //   Softball: "139000",
      // },
      prices: {
        SingleWelting: "149000",
        DoubleWelting: "149000",
        FirstBase: "149000",
        Catcher: "149000",
        Softball: "149000",
      },
    },
    {
      id: 2,
      name: "PRO STEERHIDE",
      description: "Japanese tanned pro steerhide.",
      // OUTLIER - 20240122 - 9번
      // prices: {
      //   SingleWelting: "199000",
      //   DoubleWelting: "199000",
      //   FirstBase: "199000",
      //   Catcher: "199000",
      //   Softball: "199000",
      // },
      prices: {
        SingleWelting: "209000",
        DoubleWelting: "209000",
        FirstBase: "209000",
        Catcher: "209000",
        Softball: "209000",
      },
    },
    {
      id: 3,
      name: "PRO KIP",
      description: "Japanese tanned European kip.",
      // OUTLIER - 20240122 - 9번
      // prices: {
      //   SingleWelting: "299000",
      //   DoubleWelting: "299000",
      //   FirstBase: "299000",
      //   Catcher: "299000",
      //   Softball: "299000",
      // },
      prices: {
        SingleWelting: "309000",
        DoubleWelting: "309000",
        FirstBase: "309000",
        Catcher: "309000",
        Softball: "309000",
      },
    },
  ],
];

export default leatherGrades;
