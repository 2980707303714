import React from 'react';
import { connect } from 'react-redux';

import { updateGlove } from '../../actions/creators';
import ColorButtons from '../ColorButtons';

const Welting = ({
  colors,
  currentColor,
  onColorClick,
}) => (
  <div className="step-item-view-wrap pull-right">
    <ColorButtons
      title="WELTING COLOR"
      className="welting-color-wrap"
      colors={colors}
      currentColor={currentColor}
      onClick={onColorClick}
    />
  </div>
);

const mapStateToProps = (state) => {
  const { weltingColor } = state.glove;
  const allColors = state.builder.leatherColors[state.glove.leatherGrade.id].filter(o => o.url === undefined || o.url === null);
  return {
    colors: allColors,
    currentColor: weltingColor,
  };
};

const mapDispatchToProps = dispatch => ({
  onColorClick: color => dispatch(updateGlove({ weltingColor: color })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Welting);
