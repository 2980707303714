import React, { Component } from "react";

class KoShoppingPolicy extends Component {
  render() {
    return (
      <div>
        <div style={styles.container}>
          <p style={styles.title}>배송정책</p>

          <p style={styles.subTitle(64)}>배송조회</p>
          <p style={styles.description(10)}>
            제품 발송 시 운송장번호와 배송업체명 등의 정보가 포함된 배송안내
            이메일이 발송됩니다. 또한 글러브웍스 웹사이트에서 고객계정에
            로그인하시면 주문상태를 확인하실 수 있습니다.
          </p>

          <p style={styles.subTitle(64)}>배송방법</p>
          <p style={styles.description(10)}>
            모든 제품은 DHL, FEDEX, SF EXPRESS(중국 내 배송) 등 글러브웍스와
            계약된 특송업체를 통해 배송됩니다. 글러브웍스의 생산시설과
            물류창고는 중국과 미국, 일본에 위치하고 있습니다.
            <br />
            <br />
            특송업체들의 업무정책에 따라 PO박스, 사서함, 또는 RR(Rural Route)
            주소로는 배송이 되지 않습니다. 고객은 빠르고 정확한 배송을 위해
            실제로 존재하는 주소를 제공해야만 합니다. 고객의 잘못된 정보 제공나
            특송업체의 실수로 인한 배송사고는 글러브웍스에서 책임을 지지
            않습니다.
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>배송비</p>
          <p style={styles.description(10)}>
            배송비는 제품군과 배송지역에 따라 다음과 같이 책정됩니다.
            <br />
            <br />
            <b style={styles.descTitle}>
              1. 글러브(커스텀 글러브/기성 글러브/트레이너 글러브/팬케이크
              글러브/프로원 시리즈) 및 파운딩 패드
            </b>
            <br />
            {/* OUTLIER - 20240122 - 4번 내용 */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 미국 & 캐나다	US$30.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 미국 & 캐나다 US$40.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 중남미	US$75.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 중남미 US$75.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 유럽 US$35.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 유럽 US$40.00 - US$45.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 호주, 뉴질랜드  US$35.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 호주, 뉴질랜드 US$40.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 동아시아(일본, 한국) 	US$25.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 동아시아(일본, 한국) US$28.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 중국, 타이완, 홍콩US$15.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 중국, 타이완, 홍콩US$15.00
            <br />
            {/* &nbsp;&nbsp;&nbsp;&nbsp; 동남아시아 & 남아시아US$55.00 */}
            &nbsp;&nbsp;&nbsp;&nbsp; 동남아시아 & 남아시아US$47.00
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>2. 말렛</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 미국 & 캐나다US$20.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 중남미 US$45.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 유럽 US$23.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 호주 & 뉴질랜드US$23.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 동아시아 (일본, 한국) US$17.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 중국, 타이완, 홍콩US$13.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 동남아시아 & 남아시아US$35.00
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>3. 모자 & 페넌트</b>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 미국 & 캐나다 US$15.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 중남미 US$35.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 유럽 US$25.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 호주 & 뉴질랜드US$25.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 동아시아(일본, 한국) US$13.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 중국, 타이완, 홍콩 US$10.00
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp; 동남아시아 & 남아시아 US$25.00
            <br />
            <br />
            <br />
            <b style={styles.descTitle}>4. 글러브 오일</b>
            <br />
            글러브오일은 다른 제품을 구매할 시에만 함께 구매가 가능하며 배송비는
            무료입니다. 글러브오일은 개별구매가 되지 않는 점을 양해
            부탁드립니다. 대량 구매를 원하시는 경우 이메일(
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:contact@gloveworks.net")
              }
            >
              contact@gloveowork.net
            </u>
            )로 문의 바랍니다.
            <br />
            <br />
          </p>

          <p style={styles.subTitle(64)}>배송비 할인</p>
          <p style={styles.description(10)}>
            4개 이상의 커스텀 글러브 또는 기성글러브를 일시에 구매하는 경우 총
            배송비의 10%가 할인됩니다. 배송비 할인은 동일 카테고리 내에서 구매할
            시에만 적용된다는 점을 양해 부탁드립니다.
          </p>

          <p style={styles.subTitle(64)}>관세</p>
          <p style={styles.description(10)}>
            글러브웍스의 제품은 중국에서 생산·발송되며 배송 목적국의 물품수입
            관련 법규에 의거해 수입통관 시 관부가세가 부과됩니다. 관부과세는
            제품 수령 시 특송업체에 직접 지불하시면 됩니다.
            <br />
            <br />
            관부과세 또는 수입통관과 관련해 세부정보, 영수증, 관련 서류 등이
            필요한 경우 배송안내 이메일에 기재된 특송업체에게 요청하실 수
            있습니다
            <br />
            <br />더 자세한 내용은{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.customs.go.kr/kcshome/main/content/ContentView.do?contentId=CONTENT_ID_000000481&layoutMenuNo=10"
              target="_blank"
              rel="noreferrer"
            >
              이곳
            </a>
            을 참고해주세요.
          </p>

          <p style={styles.subTitle(64)}>[환불· 반품 및 교환정책]</p>

          <p style={styles.subTitle(64)}>주문 취소 및 환불</p>
          <p style={styles.description(10)}>
            커스텀 글러브의 경우 주문 후24시간 이내 취소가 가능합니다. 주문 후
            24시간이 지나면 제작이 시작되며, 제작이 시작된 이후에는 주문취소 및
            환불이 불가능합니다.
          </p>

          <p style={styles.subTitle(64)}>반품 및 교환</p>
          <p style={styles.description(10)}>
            글러브웍스 커스텀 글러브는 고객 개개인의 취향에 따라 독창적으로
            만들어진 제품입니다. 따라서 제품 제작과정에서 저희 측 잘못으로
            발생한 오류나 결함, 손상이 아닌 단순 변심이나 고객 경우 반품이나
            교환이 불가합니다. 저희 측 잘못으로 문제가 발생했을 경우 제품 수령
            후 2주 이내에{" "}
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            로 이메일을 보내주십시오(한국어 서비스 가능).
          </p>

          <p style={styles.subTitle(64)}>글러브웍스 워런티 정책</p>
          <p style={styles.description(10)}>
            글러브웍스는 제품수령 후 6개월 간 워런티를 보장합니다. 제품 수령 후
            6개월 이내에 품질문제가 발생했을 경우
            <u
              style={styles.email}
              tabIndex="-1"
              role="button"
              onClick={() =>
                (document.location.href = "mailto:donald@gloveworks.net")
              }
            >
              donald@gloveworks.net
            </u>
            로 이메일을 보내주시면 저희가 문제를 해결할 수 있는 최선의 방법을
            찾아내겠습니다.
          </p>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: 996,
    margin: "auto",
    marginBottom: 64,
  },
  title: {
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    lineHeight: 1.33,
    letterSpacing: 0.2,
    color: "#142935",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 50,
  },
  subTitle: (marginTop) => ({
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.33,
    color: "#000",
    marginTop,
  }),
  description: (marginTop) => ({
    fontSize: 14,
    marginTop,
    lineHeight: 1.57,
    color: "#142935",
  }),
  descTitle: {
    fontWeight: "bold",
  },
  email: {
    color: "#007dff",
    cursor: "pointer",
    outline: "none",
  },
};

export default KoShoppingPolicy;
