import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';

import createConsumer from './common/createConsumer';

const defaultContext = {
  state: { count: 1, step: 0 },
  selectImageArr: [
    {
      id: 0,
    },
    {
      id: 1,
    },
  ],
  actions: {
    increment: () => {},
    decrement: () => {},
    add: () => {},
    cut: () => {},
  },
};

const Context = createContext(defaultContext);

const { Provider: BaseProvider, Consumer: BaseConsumer } = Context;

class Provider extends Component {
  state = {
    count: 0,
    step: 0,
  };

  selectImageArr = [];

  actions = {
    setCount: (count) => {
      this.setState({ count });
    },
    cartStepReset: (step, callback) => {
      console.log('cartStepReset', step);
      this.setState({ step, count: 0 }, callback);
    },
    reset: (callback) => {
      this.setState({ count: 0 }, callback);
    },
    increment: () => {
      this.setState(({ count }) => ({
        count: count + 1,
      }));
    },
    decrement: () => {
      this.setState(({ count }) => ({
        count: count - 1,
      }));
    },
    add: (item) => {
      this.selectImageArr.push(item);
    },
    cut: (item) => {
      const idx = this.selectImageArr.findIndex(x => x.id === item.id);

      if (idx === -1) {
        return alert('Product index not found');
      }

      return this.selectImageArr.splice(idx, 1);
    },
  };

  componentDidUpdate(prevProps, prevState) {
    console.log('Context did update');
  }

  render() {
    const { children } = this.props;
    const { count, step } = this.state;
    const { selectImageArr, actions } = this;

    const defaultValue = {
      count,
      step,
      actions,
      selectImageArr,
    };

    return <BaseProvider value={defaultValue}>{children}</BaseProvider>;
  }
}

const Consumer = createConsumer(BaseConsumer);

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export { Provider, Consumer };
