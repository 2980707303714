import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Step from '../../components/step/Step';
import EnPolicy from './EnPolicy';
import KoPolicy from './KoPolicy';
import JaPolicy from './JaPolicy';

const STEP_MENU = ['Terms of service'];

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.document.body.style.backgroundColor = '#fafafa';
    setTimeout(() => window.scrollTo(0, 0), 1);
  }

  render() {
    const { i18n } = this.props;
    return (
      <Fragment>
        <Step menu={STEP_MENU} />
        {i18n.language === 'ko' && <KoPolicy />}
        {i18n.language === 'en' && <EnPolicy />}
        {i18n.language === 'ja' && <JaPolicy />}
      </Fragment>
    );
  }
}

PrivacyPolicy.propTypes = {
  i18n: PropTypes.object,
};

PrivacyPolicy.defaultProps = {
  i18n: {},
};

export default withTranslation('translations')(PrivacyPolicy);
