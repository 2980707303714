import React from 'react';
import { connect } from 'react-redux';
import GloveAttribute from './GloveAttribute';

const SingleWeltingSummary = ({ glove }) => (
  <div className="popup-row clearfix">
    <div className="popup-col-1">
      <div className="overview-group overview-group-base">
        <h2>Base</h2>
        <GloveAttribute name="GLOVE TYPE" value={glove.gloveType} />
        <GloveAttribute name="LEATHER GRADE" value={glove.leatherGrade.name} />
        <GloveAttribute name="THROWING HAND" value={glove.throwingHand.name} />
        <GloveAttribute name="SIZE" value={glove.size.inches} />
      </div>
      <div className="overview-group overview-group-web">
        <h2>Web <span>Shell</span></h2>
        <GloveAttribute name="WEB COLOR" value={glove.webPython.name} />
        <GloveAttribute name="WEB TYPE" image={glove.webType.photo.url} />
      </div>
    </div>
    <div className="popup-col-1">
      <div className="overview-group overview-group-fingers">
        <h2>Fingers <span>Shell</span></h2>
        <GloveAttribute name="THUMB INSIDE COLOR" value={glove.thumbInsidePython.name} />
        <GloveAttribute name="THUMB OUTSIDE COLOR" value={glove.thumbOutsidePython.name} />
        <GloveAttribute name="INDEX FINGER INSIDE COLOR" value={glove.indexInsidePython.name} />
        <GloveAttribute name="INDEX FINGER OUTSIDE COLOR" value={glove.indexOutsidePython.name} />
        <GloveAttribute name="MIDDLE FINGER INSIDE COLOR" value={glove.middleInsidePython.name} />
        <GloveAttribute name="MIDDLE FINGER OUTSIDE COLOR" value={glove.middleOutsidePython.name} />
        <GloveAttribute name="RING FINGER INSIDE COLOR" value={glove.ringInsidePython.name} />
        <GloveAttribute name="RING FINGER OUTSIDE COLOR" value={glove.ringOutsidePython.name} />
        <GloveAttribute name="PINKY INSIDE COLOR" value={glove.pinkyInsidePython.name} />
        <GloveAttribute name="PINKY OUTSIDE COLOR" value={glove.pinkyOutsidePython.name} />
      </div>
    </div>
    <div className="popup-col-1">
      <div className="overview-group overview-group-palm-wrist">
        <h2>Palm & Wrist <span>Shell</span></h2>
        <GloveAttribute name="PALM COLOR" value={glove.palmPython.name} />
        <GloveAttribute name="WRIST COLOR" value={glove.wristPython.name} />
        <GloveAttribute name="WRIST TYPE" value={glove.wristType.name} />
      </div>
      <div className="overview-group overview-group-finger-option">
        <h2>Finger Option <span>Shell</span></h2>
        <GloveAttribute name="FINGER OPTION TYPE" value={glove.fingerOption.name} />
        {glove.fingerOption.id !== 1 &&
        <GloveAttribute name="FINGER OPTION COLOR" value={glove.fingerOptionPython.name} />
          }
      </div>
      <div className="overview-group overview-group-trim">
        <h2>Trim</h2>
        <GloveAttribute name="BINDING COLOR" value={glove.bindingColor.name} />
        <GloveAttribute name="WELTING COLOR" value={glove.weltingColor.name} />
        <GloveAttribute name="LACING COLOR" value={glove.lacingColor.name} />
        <GloveAttribute name="STITCHING COLOR" value={glove.threadColor.name} />
      </div>
    </div>
    <div className="popup-col-1">
      <div className="overview-group overview-group-personalize">
        <h2>Personalize</h2>
        <GloveAttribute name="WRIST PERSONALIZATION TYPE" value={glove.wristPersonalizationType.name} />
        {glove.wristPersonalizationType.name === 'DEFAULT' && glove.wristType !== 'VELCRO' &&
        <GloveAttribute name="WRIST PATCH" value={glove.wristPatch.name} />
          }
        {glove.wristPersonalizationType.name === 'TEXT' && glove.wristPersonalizationText &&
        <div>
          <GloveAttribute name="WRIST TEXT" value={glove.wristPersonalizationText} />
          <GloveAttribute name="WRIST TEXT FONT" value={glove.wristPersonalizationFont.name} />
          <GloveAttribute name="WRIST TEXT COLOR" value={glove.wristPersonalizationTextColor.name} />
        </div>
          }
        {glove.wristPersonalizationType.name === 'LOGO' && glove.wristPersonalizationLogo &&
        <GloveAttribute name="WRIST LOGO" image={glove.wristPersonalizationLogo.image.url} />
          }
        {glove.wristPersonalizationType.name === 'FLAG' &&
        <GloveAttribute name="WRIST FLAG" image={glove.wristPersonalizationFlag.icon.flagPreview.url} />
          }
        {glove.wristPersonalizationType.name === 'IMAGE' &&
        <GloveAttribute name="WRIST IMAGE" image={glove.wristPersonalizationCustomImage.image.url} />
          }
        {glove.thumbPersonalizationText &&
        <div>
          <GloveAttribute name="THUMB TEXT" value={glove.thumbPersonalizationText} />
          <GloveAttribute name="THUMB TEXT FONT" value={glove.thumbPersonalizationFont.name} />
          <GloveAttribute name="THUMB TEXT COLOR" value={glove.thumbPersonalizationTextColor.name} />
        </div>
          }
        <GloveAttribute name="THUMB CIRCLE OPTION" value={glove.thumbPersonalizationType.name} />
        {glove.thumbPersonalizationType.name === 'NUMBER' &&
        <GloveAttribute name="THUMB CIRCLE NUMBER" value={glove.thumbPersonalizationNumber} />
          }
        {glove.thumbPersonalizationType.name === 'FLAG' &&
        <GloveAttribute name="THUMB CIRCLE FLAG" image={glove.thumbPersonalizationFlag.icon.flagPreview.url} />
          }
        <GloveAttribute name="LINING PERSONALIZATION TYPE" value={glove.liningPersonalizationType.name} />
        {glove.liningPersonalizationType.name === 'TEXT' && glove.liningPersonalizationText &&
        <div>
          <GloveAttribute name="LINING TEXT" value={glove.liningPersonalizationText} />
          <GloveAttribute name="LINING TEXT FONT" value={glove.liningPersonalizationFont.name} />
          <GloveAttribute name="LINING TEXT COLOR" value={glove.liningPersonalizationTextColor.name} />
        </div>
          }
        {glove.stamping &&
        <GloveAttribute name="STAMPING" image={glove.stamping.image.url} />
          }
      </div>
    </div>
  </div>
);

export default connect()(SingleWeltingSummary);
