import GloveRenderer from './GloveRenderer';

export default class DoubleWeltingRenderer extends GloveRenderer {
  constructor(props) {
    super(props);
    this.gloveType = 'Double Welting';
  }

  renderWebSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let palmShadowImageKey,
        thumbInsideShadowImageKey, thumbOutsideShadowImageKey,
        centerIndexShadowImageKey,
        inBetweenShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.thumbInsidePython.url !== undefined && glove.thumbInsidePython.url !== null && glove.thumbInsidePython.name.toLowerCase().includes("monogram")) {
      thumbInsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbInsidePython.name.toLowerCase().includes("paisley")) {
      thumbInsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbInsideShadowImageKey = 'shadow';
    }
    if (glove.thumbOutsidePython.url !== undefined && glove.thumbOutsidePython.url !== null && glove.thumbOutsidePython.name.toLowerCase().includes("monogram")) {
      thumbOutsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbOutsidePython.name.toLowerCase().includes("paisley")) {
      thumbOutsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbOutsideShadowImageKey = 'shadow';
    }
    if (glove.centerIndexPython.url !== undefined && glove.centerIndexPython.url !== null && glove.centerIndexPython.name.toLowerCase().includes("monogram")) {
      centerIndexShadowImageKey = 'shadowPython';
    } else if (glove.centerIndexPython.name.toLowerCase().includes("paisley")) {
      centerIndexShadowImageKey = 'shadowPaisley';
    } else {
      centerIndexShadowImageKey = 'shadow';
    }
    if (glove.inBetweenPython.url !== undefined && glove.inBetweenPython.url !== null && glove.inBetweenPython.name.toLowerCase().includes("monogram")) {
      inBetweenShadowImageKey = 'shadowPython';
    } else if (glove.inBetweenPython.name.toLowerCase().includes("paisley")) {
      inBetweenShadowImageKey = 'shadowPaisley';
    } else {
      inBetweenShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('thumbInside', glove.thumbInsidePython.hexCode, thumbInsideShadowImageKey),
      this.renderLayer('thumbOutside', glove.thumbOutsidePython.hexCode, thumbOutsideShadowImageKey),
      this.renderLayer('centerIndex', glove.centerIndexPython.hexCode, centerIndexShadowImageKey),
      this.renderLayer('inbetween', glove.inBetweenPython.hexCode, inBetweenShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('welting', glove.weltingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      let fingerHoodShadow, fingerPadShadow;
      if (glove.fingerOptionPython.url !== undefined && glove.fingerOptionPython.url !== null && glove.fingerOptionPython.name.toLowerCase().includes("monogram")) {
        fingerHoodShadow = 'fingerHoodShadowPython';
        fingerPadShadow = 'fingerPadShadowPython';
      } else if (glove.fingerOptionPython.name.toLowerCase().includes("paisley")) {
        fingerHoodShadow = 'fingerHoodShadowPaisley';
        fingerPadShadow = 'fingerPadShadowPaisley';
      } else {
        fingerHoodShadow = 'fingerHoodShadow';
        fingerPadShadow = 'fingerPadShadow';
      }
      if (glove.fingerOption.name === 'FINGER HOOD') {
        return Promise.all([
          this.renderLayer('fingerHoodThread', glove.threadColor.hexCode, fingerHoodShadow),
          this.renderLayer('fingerHoodLeather', glove.fingerOptionPython.hexCode, fingerHoodShadow),
        ]);
      } else if (glove.fingerOption.name === 'FINGER PAD') {
        return Promise.all([
          this.renderLayer('fingerPadThread', glove.threadColor.hexCode, fingerPadShadow),
          this.renderLayer('fingerPadLeather', glove.fingerOptionPython.hexCode, fingerPadShadow),
        ]);
      }

      return Promise.resolve();
    }).then(() => {
      let webLeatherImageKey,
        webLacingImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPython';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPython';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPython';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPython';
        } else if (glove.webType.id === 36) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPython';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPython';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPaisley';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPaisley';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPaisley';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPaisley';
        } else if (glove.webType.id === 36) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPaisley';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPaisley';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPaisley';
        }
      } else {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5Shadow';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6Shadow';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7Shadow';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1Shadow';
        } else if (glove.webType.id === 36) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2Shadow';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3Shadow';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4Shadow';
        }
      }

      return Promise.all([
        this.renderLayer(webLeatherImageKey, glove.webPython.hexCode, webShadowImageKey),
        this.renderLayer(webLacingImageKey, glove.lacingColor.hexCode, webShadowImageKey),
      ]);
    })
      .then(() => {
        let webThreadImageKey,
          webShadowImageKey;

        if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
          if (glove.webType.id >= 46 && glove.webType.id <= 48) {
            webThreadImageKey = 'web5Thread';
            webShadowImageKey = 'web5ShadowPython';
          } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
            webThreadImageKey = 'web6Thread';
            webShadowImageKey = 'web6ShadowPython';
          } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
            webThreadImageKey = 'web7Thread';
            webShadowImageKey = 'web7ShadowPython';
          } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
            webThreadImageKey = 'web1Thread';
            webShadowImageKey = 'web1ShadowPython';
          } else if (glove.webType.id === 36) {
            webThreadImageKey = 'web2Thread';
            webShadowImageKey = 'web2ShadowPython';
          } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
            webThreadImageKey = 'web3Thread';
            webShadowImageKey = 'web3ShadowPython';
          } else {
            webThreadImageKey = 'web4Thread';
            webShadowImageKey = 'web4ShadowPython';
          }
        } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
          if (glove.webType.id >= 46 && glove.webType.id <= 48) {
            webThreadImageKey = 'web5Thread';
            webShadowImageKey = 'web5ShadowPaisley';
          } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
            webThreadImageKey = 'web6Thread';
            webShadowImageKey = 'web6ShadowPaisley';
          } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
            webThreadImageKey = 'web7Thread';
            webShadowImageKey = 'web7ShadowPaisley';
          } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
            webThreadImageKey = 'web1Thread';
            webShadowImageKey = 'web1ShadowPaisley';
          } else if (glove.webType.id === 36) {
            webThreadImageKey = 'web2Thread';
            webShadowImageKey = 'web2ShadowPaisley';
          } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
            webThreadImageKey = 'web3Thread';
            webShadowImageKey = 'web3ShadowPaisley';
          } else {
            webThreadImageKey = 'web4Thread';
            webShadowImageKey = 'web4ShadowPaisley';
          }
        } else {
          if (glove.webType.id >= 46 && glove.webType.id <= 48) {
            webThreadImageKey = 'web5Thread';
            webShadowImageKey = 'web5Shadow';
          } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
            webThreadImageKey = 'web6Thread';
            webShadowImageKey = 'web6Shadow';
          } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
            webThreadImageKey = 'web7Thread';
            webShadowImageKey = 'web7Shadow';
          } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
            webThreadImageKey = 'web1Thread';
            webShadowImageKey = 'web1Shadow';
          } else if (glove.webType.id === 36) {
            webThreadImageKey = 'web2Thread';
            webShadowImageKey = 'web2Shadow';
          } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
            webThreadImageKey = 'web3Thread';
            webShadowImageKey = 'web3Shadow';
          } else {
            webThreadImageKey = 'web4Thread';
            webShadowImageKey = 'web4Shadow';
          }
        }

        return this.renderLayer(webThreadImageKey, glove.threadColor.hexCode, webShadowImageKey);
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })

  renderFingerSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let palmShadowImageKey,
        thumbInsideShadowImageKey,
        centerIndexShadowImageKey, centerMiddleImageKey, centerRingImageKey,
        pinkyOutsideShadowImageKey,
        inBetweenShadowImageKey;   
    shadowImageKey = 'shadow';
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.thumbInsidePython.url !== undefined && glove.thumbInsidePython.url !== null && glove.thumbInsidePython.name.toLowerCase().includes("monogram")) {
      thumbInsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbInsidePython.name.toLowerCase().includes("paisley")) {
      thumbInsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbInsideShadowImageKey = 'shadow';
    }
    if (glove.centerIndexPython.url !== undefined && glove.centerIndexPython.url !== null && glove.centerIndexPython.name.toLowerCase().includes("monogram")) {
      centerIndexShadowImageKey = 'shadowPython';
    } else if (glove.centerIndexPython.name.toLowerCase().includes("paisley")) {
      centerIndexShadowImageKey = 'shadowPaisley';
    } else {
      centerIndexShadowImageKey = 'shadow';
    }
    if (glove.centerMiddlePython.url !== undefined && glove.centerMiddlePython.url !== null && glove.centerMiddlePython.name.toLowerCase().includes("monogram")) {
      centerMiddleImageKey = 'shadowPython';
    } else if (glove.centerMiddlePython.name.toLowerCase().includes("paisley")) {
      centerMiddleImageKey = 'shadowPaisley';
    } else {
      centerMiddleImageKey = 'shadow';
    }
    if (glove.centerRingPython.url !== undefined && glove.centerRingPython.url !== null && glove.centerRingPython.name.toLowerCase().includes("monogram")) {
      centerRingImageKey = 'shadowPython';
    } else if (glove.centerRingPython.name.toLowerCase().includes("paisley")) {
      centerRingImageKey = 'shadowPaisley';
    } else {
      centerRingImageKey = 'shadow';
    }
    if (glove.pinkyOutsidePython.url !== undefined && glove.pinkyOutsidePython.url !== null && glove.pinkyOutsidePython.name.toLowerCase().includes("monogram")) {
      pinkyOutsideShadowImageKey = 'shadowPython';
    } else if (glove.pinkyOutsidePython.name.toLowerCase().includes("paisley")) {
      pinkyOutsideShadowImageKey = 'shadowPaisley';
    } else {
      pinkyOutsideShadowImageKey = 'shadow';
    }
    if (glove.inBetweenPython.url !== undefined && glove.inBetweenPython.url !== null && glove.inBetweenPython.name.toLowerCase().includes("monogram")) {
      inBetweenShadowImageKey = 'shadowPython';
    } else if (glove.inBetweenPython.name.toLowerCase().includes("paisley")) {
      inBetweenShadowImageKey = 'shadowPaisley';
    } else {
      inBetweenShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('thumbInside', glove.thumbInsidePython.hexCode, thumbInsideShadowImageKey),
      this.renderLayer('centerIndex', glove.centerIndexPython.hexCode, centerIndexShadowImageKey),
      this.renderLayer('centerMiddle', glove.centerMiddlePython.hexCode, centerMiddleImageKey),
      this.renderLayer('centerRing', glove.centerRingPython.hexCode, centerRingImageKey),
      this.renderLayer('pinkyOutside', glove.pinkyOutsidePython.hexCode, pinkyOutsideShadowImageKey),
      this.renderLayer('inbetween', glove.inBetweenPython.hexCode, inBetweenShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('welting', glove.weltingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => this.renderLayer('thread', glove.threadColor.hexCode, 'shadow')).then(() => {
      let fingerHoodShadow, fingerPadShadow;
      if (glove.fingerOptionPython.url !== undefined && glove.fingerOptionPython.url !== null && glove.fingerOptionPython.name.toLowerCase().includes("monogram")) {
        fingerHoodShadow = 'fingerHoodShadowPython';
        fingerPadShadow = 'fingerPadShadowPython';
      } else if (glove.fingerOptionPython.name.toLowerCase().includes("paisley")) {
        fingerHoodShadow = 'fingerHoodShadowPaisley';
        fingerPadShadow = 'fingerPadShadowPaisley';
      } else {
        fingerHoodShadow = 'fingerHoodShadow';
        fingerPadShadow = 'fingerPadShadow';
      }
      if (glove.fingerOption.name === 'FINGER HOOD') {
        return Promise.all([
          this.renderLayer('fingerHoodThread', glove.threadColor.hexCode, fingerHoodShadow),
          this.renderLayer('fingerHoodLeather', glove.fingerOptionPython.hexCode, fingerHoodShadow),
        ]);
      } else if (glove.fingerOption.name === 'FINGER PAD') {
        return Promise.all([
          this.renderLayer('fingerPadThread', glove.threadColor.hexCode, fingerPadShadow),
          this.renderLayer('fingerPadLeather', glove.fingerOptionPython.hexCode, fingerPadShadow),
        ]);
      }

      return Promise.resolve();
    }).then(() => this.renderStampingLayer('stampingLogo', glove.palmPython.hexCode, 'shadow'))
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })

  renderPalmSide = glove => new Promise((resolve, reject) => {
    console.log(glove);
    let shadowImageKey;
    let palmShadowImageKey,
        thumbInsideShadowImageKey, thumbOutsideShadowImageKey,
        inBetweenShadowImageKey,
        pinkyOutsideShadowImageKey;
    shadowImageKey = 'shadow';
    if (glove.palmPython.url !== undefined && glove.palmPython.url !== null && glove.palmPython.name.toLowerCase().includes("monogram")) {
      palmShadowImageKey = 'shadowPython';
    } else if (glove.palmPython.name.toLowerCase().includes("paisley")) {
      palmShadowImageKey = 'shadowPaisley';
    } else {
      palmShadowImageKey = 'shadow';
    }
    if (glove.thumbInsidePython.url !== undefined && glove.thumbInsidePython.url !== null && glove.thumbInsidePython.name.toLowerCase().includes("monogram")) {
      thumbInsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbInsidePython.name.toLowerCase().includes("paisley")) {
      thumbInsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbInsideShadowImageKey = 'shadow';
    }
    if (glove.thumbOutsidePython.url !== undefined && glove.thumbOutsidePython.url !== null && glove.thumbOutsidePython.name.toLowerCase().includes("monogram")) {
      thumbOutsideShadowImageKey = 'shadowPython';
    } else if (glove.thumbOutsidePython.name.toLowerCase().includes("paisley")) {
      thumbOutsideShadowImageKey = 'shadowPaisley';
    } else {
      thumbOutsideShadowImageKey = 'shadow';
    }
    if (glove.inBetweenPython.url !== undefined && glove.inBetweenPython.url !== null && glove.inBetweenPython.name.toLowerCase().includes("monogram")) {
      inBetweenShadowImageKey = 'shadowPython';
    } else if (glove.inBetweenPython.name.toLowerCase().includes("paisley")) {
      inBetweenShadowImageKey = 'shadowPaisley';
    } else {
      inBetweenShadowImageKey = 'shadow';
    }
    if (glove.pinkyOutsidePython.url !== undefined && glove.pinkyOutsidePython.url !== null && glove.pinkyOutsidePython.name.toLowerCase().includes("monogram")) {
      pinkyOutsideShadowImageKey = 'shadowPython';
    } else if (glove.pinkyOutsidePython.name.toLowerCase().includes("paisley")) {
      pinkyOutsideShadowImageKey = 'shadowPaisley';
    } else {
      pinkyOutsideShadowImageKey = 'shadow';
    }
    const baseLayerPromises = [
      this.renderLayer('palm', glove.palmPython.hexCode, palmShadowImageKey),
      this.renderLayer('thumbInside', glove.thumbInsidePython.hexCode, thumbInsideShadowImageKey),
      this.renderLayer('thumbOutside', glove.thumbOutsidePython.hexCode, thumbOutsideShadowImageKey),
      this.renderLayer('inbetween', glove.inBetweenPython.hexCode, inBetweenShadowImageKey),
      this.renderLayer('pinkyOutside', glove.pinkyOutsidePython.hexCode, pinkyOutsideShadowImageKey),
      this.renderLayer('binding', glove.bindingColor.hexCode, 'shadow'),
      this.renderLayer('welting', glove.weltingColor.hexCode, 'shadow'),
      this.renderLayer('lacing', glove.lacingColor.hexCode, 'shadow'),
    ];

    Promise.all(baseLayerPromises).then(() => {
      let webLeatherImageKey,
        webLacingImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPython';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPython';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPython';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPython';
        } else if (glove.webType.id === 36) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPython';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPython';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5ShadowPaisley';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6ShadowPaisley';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7ShadowPaisley';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1ShadowPaisley';
        } else if (glove.webType.id === 36) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2ShadowPaisley';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3ShadowPaisley';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4ShadowPaisley';
        }
      } else {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web5Leather';
          webLacingImageKey = 'web5Lacing';
          webShadowImageKey = 'web5Shadow';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webLeatherImageKey = 'web6Leather';
          webLacingImageKey = 'web6Lacing';
          webShadowImageKey = 'web6Shadow';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webLeatherImageKey = 'web7Leather';
          webLacingImageKey = 'web7Lacing';
          webShadowImageKey = 'web7Shadow';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webLeatherImageKey = 'web1Leather';
          webLacingImageKey = 'web1Lacing';
          webShadowImageKey = 'web1Shadow';
        } else if (glove.webType.id === 36) {
          webLeatherImageKey = 'web2Leather';
          webLacingImageKey = 'web2Lacing';
          webShadowImageKey = 'web2Shadow';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webLeatherImageKey = 'web3Leather';
          webLacingImageKey = 'web3Lacing';
          webShadowImageKey = 'web3Shadow';
        } else {
          webLeatherImageKey = 'web4Leather';
          webLacingImageKey = 'web4Lacing';
          webShadowImageKey = 'web4Shadow';
        }
      }

      return Promise.all([
        this.renderLayer(webLeatherImageKey, glove.webPython.hexCode, webShadowImageKey),
        this.renderLayer(webLacingImageKey, glove.lacingColor.hexCode, webShadowImageKey),
      ]);
    }).then(() => {
      let webThreadImageKey,
        webShadowImageKey;

      if (glove.webPython.url !== undefined && glove.webPython.url !== null && glove.webPython.name.toLowerCase().includes("monogram")) {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webThreadImageKey = 'web5Thread';
          webShadowImageKey = 'web5ShadowPython';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webThreadImageKey = 'web6Thread';
          webShadowImageKey = 'web6ShadowPython';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webThreadImageKey = 'web7Thread';
          webShadowImageKey = 'web7ShadowPython';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPython';
        } else if (glove.webType.id === 36) {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPython';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webThreadImageKey = 'web3Thread';
          webShadowImageKey = 'web3ShadowPython';
        } else {
          webThreadImageKey = 'web4Thread';
          webShadowImageKey = 'web4ShadowPython';
        }
      } else if (glove.webPython.name.toLowerCase().includes("paisley")) {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webThreadImageKey = 'web5Thread';
          webShadowImageKey = 'web5ShadowPaisley';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webThreadImageKey = 'web6Thread';
          webShadowImageKey = 'web6ShadowPaisley';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webThreadImageKey = 'web7Thread';
          webShadowImageKey = 'web7ShadowPaisley';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1ShadowPaisley';
        } else if (glove.webType.id === 36) {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2ShadowPaisley';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webThreadImageKey = 'web3Thread';
          webShadowImageKey = 'web3ShadowPaisley';
        } else {
          webThreadImageKey = 'web4Thread';
          webShadowImageKey = 'web4ShadowPaisley';
        }
      } else {
        if (glove.webType.id >= 46 && glove.webType.id <= 48) {
          webThreadImageKey = 'web5Thread';
          webShadowImageKey = 'web5Shadow';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 43) {
          webThreadImageKey = 'web6Thread';
          webShadowImageKey = 'web6Shadow';
        } else if (glove.webType.id >= 37 && glove.webType.id <= 40) {
          webThreadImageKey = 'web7Thread';
          webShadowImageKey = 'web7Shadow';
        } else if (glove.webType.id >= 41 && glove.webType.id <= 48) {
          webThreadImageKey = 'web1Thread';
          webShadowImageKey = 'web1Shadow';
        } else if (glove.webType.id === 36) {
          webThreadImageKey = 'web2Thread';
          webShadowImageKey = 'web2Shadow';
        } else if (glove.webType.id >= 31 && glove.webType.id <= 35) {
          webThreadImageKey = 'web3Thread';
          webShadowImageKey = 'web3Shadow';
        } else {
          webThreadImageKey = 'web4Thread';
          webShadowImageKey = 'web4Shadow';
        }
      }

      return this.renderLayer(webThreadImageKey, glove.threadColor.hexCode, webShadowImageKey);
    }).then(() => this.renderStampingLayer('stampingGW', glove.palmPython.hexCode, 'shadow'))
      .then(() => {
        if (glove.stamping == null) {
          return Promise.resolve();
        }
        if (glove.stamping.id === 1) {
          return this.renderStampingLayer('stampingGameDay1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 2) {
          return this.renderStampingLayer('stampingGameDay2', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 3) {
          return this.renderStampingLayer('stampingProSteerhide1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 4) {
          return this.renderStampingLayer('stampingProSteerhide2', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 5) {
          return this.renderStampingLayer('stampingProKip1', glove.palmPython.hexCode, 'shadow');
        } else if (glove.stamping.id === 6) {
          return this.renderStampingLayer('stampingProKip2', glove.palmPython.hexCode, 'shadow');
        }
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  })
}
